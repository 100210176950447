import { Popover } from '@material-ui/core';
import styled from 'styled-components';

export const PopoverContainer = styled(Popover)`
  margin-top: 10px;
  margin-left: 5px;
  z-index: 3;
  max-width: 150px;

  padding: 4px;

	.MuiButton-root {
		margin-top: 8px;
		color: #000;
	}
`;

export const FlagContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;

  width: 100%;
  height: 26px;

  margin-top: 4px;

  :hover {
    cursor: pointer;
  }

  .MuiOutlinedInput-root {
		margin-left: 8px;
    max-height: 26px;
  }
`;

export const FlagIcon = styled.button<{ color: string }>`
  background: ${(props) => (props.color ? props.color : '#06fb00')};
  border: 1px solid #fff;
  border-radius: 50%;
  position: absolute;
  right: 40px;
  top: 10px;
  width: 17px !important;
  height: 17px !important;

  :hover {
    cursor: pointer;
  }
`;

export const Flag = styled.div<{ color: string }>`
  background: ${(props) => (props.color ? props.color : '#06fb00')};
  border: 1px solid #c0c0c0;
  border-radius: 50%;
  min-width: 14px !important;
  height: 14px !important;
`;
