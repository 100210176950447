export const slugify = (str: string, withUnderscore: boolean = false) => {
  str = removeEmoji(str);

  const map: { [key: string]: string } = {
    a: 'á|à|ã|â|ä|À|Á|Ã|Â|Ä',
    e: 'é|è|ê|ë|É|È|Ê|Ë',
    i: 'í|ì|î|ï|Í|Ì|Î|Ï',
    o: 'ó|ò|ô|õ|ö|Ó|Ò|Ô|Õ|Ö',
    u: 'ú|ù|û|ü|Ú|Ù|Û|Ü',
    c: 'ç|Ç',
    n: 'ñ|Ñ',
  };
  for (const pattern in map) {
    str = str.replace(new RegExp(map[pattern], 'g'), pattern);
  }
  str = str.replace(/\s/, '');

  return str
    .replace(
      withUnderscore ? prohibitedCharsWithoutUnderscore : prohibitedChars,
      ''
    )
    .toLocaleLowerCase();
};

export const prohibitedChars =
  /[~`!@ªº§#$%^&*()+=_{}[\];:'"<>.,/\\?´|ç é è À-ú]/g;
export const prohibitedCharsWithoutUnderscore =
  /[~/\-`!@ªº§#$%^&*()+={}[\];:'"<>.,/\\?´|ç é è À-ú]/g;
export const prohibitedCharsWithoutUnderscoreAndNumbers =
  /[~/\-`!@ªº§#$%^&*()+=_{}[\];:'"<>.,/\\?´|ç é è À-ú 0 1 2 3 4 5 6 7 8 9]/g;
export const prohibitedCharsAndAllowSpace =
  /[~`!@ªº§#$%^&*()+=_{}[\];:'"<>.,/\\?´|çéèÀ-ú]/g;
export const prohibitedCharsToTemplateName =
  /[~/\-`!@ªº§#$%^&*()+={}[\];:'"<>.,/\\?´|ç é è À-ú]/g;

export const removeEmoji = (str: string) => {
  return str.replace(
    /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff]|\uFE0F)/g,
    ''
  );
};

export const validateTemplateName = (value: string) => {
  let validatedValue;
  const charsToReplace = prohibitedCharsToTemplateName;
  validatedValue = value.replace(charsToReplace, '');
  return validatedValue;
};

export const validateVarNames = (
  e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
  key: 'value' | 'name' | 'vartype' | null = null,
  isVariable = false
) => {
  let value;
  const charsToReplace = isVariable
    ? String(e.target.value).length === 1
      ? prohibitedCharsWithoutUnderscoreAndNumbers
      : prohibitedCharsWithoutUnderscore
    : prohibitedChars;
  if (key === 'name' || key === 'value') {
    value = String(e.target.value).replace(charsToReplace, '');
  } else {
    value = String(e.target.value);
  }
  return value;
};

export const validateVarNamesAndAllowSpace = (
  e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
  key: 'value' | 'name' | 'vartype' | null = null
) => {
  let value;
  if (key === 'name' || key === 'value') {
    value = String(e.target.value).replace(prohibitedCharsAndAllowSpace, '');
  } else {
    value = String(e.target.value);
  }
  return value;
};

export const REGEXP_NOT_ALPHANUMERIC_UNDERSCORE_DASGH =
  /[~/`!@ªº§#$%^&*()+={}[\];:'"<>.,/\\?´|ç é è À-ú]/g;

export const replaceAlphaNumericAndUndescoreDash = (word: string) => {
  return word.replace(REGEXP_NOT_ALPHANUMERIC_UNDERSCORE_DASGH, '');
};
