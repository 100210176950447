/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Grid, IconButton, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';

import * as S from './styles';

import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import { Autocomplete } from '@material-ui/lab';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Visibility from '@material-ui/icons/Visibility';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { IMessagesNotUnderstood } from 'services/ConversationService/types';
import ModalMisunderstoodPhrases from 'views/Canvas/Main/ModalMisunderstoodPhrases';
import useTranslator from 'utils/hooks/Translator';
import { usePermissions } from 'contexts/Permissions/permissionsContext';
import { useFlow } from 'contexts/Flow/flowContext';

interface IPhraseProps {
  customer: string;
  message: IMessagesNotUnderstood;
  botName: string;
  destinationIntents: string[];
  confirmMessage: (
    intentDestination: string,
    message: IMessagesNotUnderstood
  ) => void;
  deleteMessage: (message: IMessagesNotUnderstood) => void;
  index: number;
}

const Phrase: React.FC<IPhraseProps> = (props: IPhraseProps) => {
  const {
    customer,
    message,
    botName,
    destinationIntents,
    confirmMessage,
    deleteMessage,
    index,
  } = props;

  const [openModal, setOpenModal] = useState(false);
  const { state } = useFlow();
  const { hasPermissionToAction } = usePermissions();
  const [selectedDestinationIntent, setSelectedDestinationIntent] =
    useState('');
  const [error, setError] = useState('');
  const { getTranslation } = useTranslator();
  const isFlowWrite = hasPermissionToAction({
    agent: botName,
    company: state.companyName || '',
    action: ['flow:write'],
  });
  const formatText = (message: string) =>
    message.length > 50 ? `${message.slice(1, 47)}...` : message;

  const saveMessage = () => {
    if (selectedDestinationIntent) {
      setError('');
      confirmMessage(selectedDestinationIntent, message);
    } else
      setError(getTranslation('modal.fixPhrases.destinationIntentValidation'));
  };

  return (
    <S.Container>
      <Grid>
        <S.GridItem>
          <Grid item style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton onClick={() => setOpenModal(true)}>
              <Visibility color="primary" />
            </IconButton>
            <IconButton onClick={() => {}}>
              <FormatQuoteIcon color="primary" />
            </IconButton>
            <Typography color="primary" variant="subtitle2">
              {formatText(message.message.msg)}
            </Typography>
          </Grid>
          <Grid
            item
            style={{
              display: 'flex',
              alignItems: 'center',
              margin: '8px 16px',
            }}
          >
            <Autocomplete
              value={selectedDestinationIntent}
              disabled={!isFlowWrite}
              options={destinationIntents}
              noOptionsText={getTranslation('noResults')}
              renderOption={(option) => (
                <S.AutoCompleteOption>{option}</S.AutoCompleteOption>
              )}
              onChange={(_: any, newValue: string | null) => {
                if (newValue) {
                  setError('');
                  setSelectedDestinationIntent(newValue);
                }
              }}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={getTranslation('modal.fixPhrases.destinationIntent')}
                  placeholder={getTranslation('selectOne')}
                  error={!!error}
                  helperText={error}
                />
              )}
            />
            {isFlowWrite && (
              <>
                <IconButton
                  onClick={() => {
                    deleteMessage(message);
                    setSelectedDestinationIntent('');
                    setError('');
                  }}
                >
                  <HighlightOffIcon />
                </IconButton>

                <IconButton onClick={() => saveMessage()}>
                  <CheckCircleOutlineIcon />
                </IconButton>
              </>
            )}
          </Grid>
        </S.GridItem>
      </Grid>

      <ModalMisunderstoodPhrases
        open={openModal}
        bot_name={botName || ''}
        customer={customer}
        handleClose={() => {
          setOpenModal(false);
        }}
        messageSelected={message}
        index={index}
      />
    </S.Container>
  );
};

export default Phrase;
