import styled from 'styled-components';
import MttGrid from '../../Material/MttGrid/MttGrid';
import MttButton from '../../Material/MttButton/MttButton';

export const Container = styled(MttGrid)`
  width: 100%;
  height: 74px;

  background-color: #f2f2f2;

  display: flex;
  grid-template-columns: 138px 1fr;

  z-index: 100;
`;

export const ContentNameFlux = styled(MttGrid)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  min-width: 170px;

  /* padding: 5px; */

  .MuiTypography-subtitle1 {
    font-weight: bold;
  }
  .MuiTypography-subtitle2 {
    font-weight: bold;
  }
`;

export const ContentButtons = styled(MttGrid)`
  width: calc(100% - 16px);
  height: 100%;

  background-color: #fff;

  padding: 0 8px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const GridLeftButtons = styled(MttGrid).attrs({})`
  .MuiButton-colorInherit {
    background-color: #878787;
    color: #fff;
    margin-right: 8px;

    :hover {
      background-color: #6c50a4;
    }
  }

  /* width: 556px; */
  height: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const GridRightButtons = styled(MttGrid)`
  /* width: 350px; */
  height: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const GridAlertButtons = styled(MttGrid)`
  height: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SaveAndCloseGrid = styled(MttGrid).attrs({
  className: 'greenButtonMaterial',
})`
  margin-right: 10px;
`;

export const DiscardAndCloseGrid = styled(MttGrid).attrs({
  className: 'redButtonMaterial',
})``;

export const GridGrayButtons = styled(MttGrid)`
  width: 544px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledMttButton = styled(MttButton).attrs({
  className: 'grayButtonsMaterial',
})``;

export const StyledMttButtonPublic = styled(MttButton)``;

export const ContentDialog = styled(MttGrid)`
  width: 409px;
  height: 125px;

  background-color: #fff;

  padding: 16px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ButtonsDialog = styled(MttGrid)`
  width: 100%;
  display: flex;

  justify-content: flex-end;
  align-items: center;
`;

export const GridButtons = styled(MttGrid)`
  width: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ContentGridButtons = styled(MttGrid)`
  padding: 4px;

  .MuiTypography-subtitle2 {
    font-weight: bold;
  }

  cursor: pointer;
`;
