import { IAppContextActions } from 'contexts/App/types';
import { json } from '../RequestService';
import { IBaseResponse } from '../RequestService/Response';
import {
  IGetFlagsResponse,
  IUpdateFlagsRequest,
  IUpdateFlagsResponse,
} from './types';

export const GetFlags = async (
  bot_name: string,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IGetFlagsResponse>> => {
  const action = `bots/${bot_name}/flags`;
  return json<IGetFlagsResponse>('GET', action, dispatch);
};

export const UpdateFlags = async (
  request: IUpdateFlagsRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IUpdateFlagsResponse>> => {
  const action = `bots/${request.botName}/flags`;
  return json<IUpdateFlagsResponse>('PATCH', action, dispatch, request);
};
