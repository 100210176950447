import React, { ChangeEvent, useEffect, useState } from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { Dialog, Slide, TextField } from '@material-ui/core';

import { useFlow } from 'contexts/Flow/flowContext';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import './styles.css';
import {
  ContainerDialog,
  ContainerHeader,
  Button,
  ButtonContainer,
  ModalTitle,
  SelectedBlocks,
  FieldContainer,
} from './styles';
import { CircularProgress } from '@material-ui/core';
import { Block } from 'contexts/Library/types';
import { newBlockSetValidation } from 'models/NewBlockSet';
import { GetLibrary, SaveLibrary } from 'services/LibraryService';
import { getSelectedNodeId } from '../Flow/utils/NodeContainer';
import GetBlockOnFlowController from 'controllers/library/GetBlockOnFlowController';
import { useApp } from 'contexts/App/appContext';
import useTranslator from 'utils/hooks/Translator';
import EIntentType from 'enums/EIntentType';

const removedBlocks = [
  EIntentType.FlexBlock,
  EIntentType.FlexBlockEntry,
  EIntentType.FlexBlockExit,
];

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      background: '#F2F2F2',
    },

    input: {
      background: '#FFFFFF',
    },
  })
);

export default function ModalSaveSelectedBlocks() {
  const { state, dispatch, toastNotification, clearNodeList } = useFlow();
  const { dispatch: dispatchApp } = useApp();
  const { botName } = state;
  const classes = useStyles();
  const { getTranslation } = useTranslator();

  const [newBlockSet, setNewBlockSet] = useState<Block>({
    name: '',
    description: '',
    blocks: {},
  });
  const [errors, setErrors] = useState<Partial<Block>>({
    name: '',
  });

  useEffect(() => {
    if (state.openModalSaveSelectedBlocks && state.editorFlow) {
      const nodeIds = getSelectedNodeId();
      const controller = new GetBlockOnFlowController(state.editorFlow);
      const blocks = { ...controller.getBlock(nodeIds) };
      for (const block of nodeIds) {
        const currentBlock = blocks[block];
        if (removedBlocks.includes(currentBlock.data.intentType)) delete blocks[block];
      }
      setNewBlockSet((old) => ({ ...old, blocks }));
    }
  }, [state.openModalSaveSelectedBlocks, state.editorFlow]);

  const [load, setLoad] = useState(false);

  function handleClose() {
    dispatch({ type: 'closeModalSaveSelectedBlocks' });
  }

  const handleChangeInput =
    (key: keyof Block) => (e: ChangeEvent<HTMLInputElement>) => {
      setErrors((old) => ({ ...old, [key]: '' }));
      setNewBlockSet((old) => ({ ...old, [key]: e.target.value }));
    };

  const handleCreateBlockSet = async () => {
    if (load || !state.editorFlow) return;
    if (getSelectedBlocks() === '') {
      toastNotification('error', 'Nenhum bloco selecionado!');
      return;
    }

    let blockToSaveList: Block[] = [];
    const resultLib = await GetLibrary(
      { bot_name: botName || '' },
      dispatchApp
    );
    if (resultLib.Success) {
      blockToSaveList = resultLib.Data.data;

      const { isValid, errors } = await newBlockSetValidation(
        newBlockSet,
        blockToSaveList,
        getTranslation('validations.required', {
          field: getTranslation('name'),
        }),
        getTranslation('validations.duplicatedLibrary')
      );
      setErrors(errors);
      if (!isValid) return;
      setLoad(true);

      const result = await SaveLibrary(
        {
          ...newBlockSet,
          bot_name: botName || '',
        },
        dispatchApp
      );

      if (result.Success) {
        toastNotification(
          'success',
          getTranslation('toast.success.blockSaved')
        );
        clearNodeList();
        handleClose();
        setNewBlockSet({
          name: '',
          description: '',
          blocks: {},
        });
        setErrors({});
      } else {
        toastNotification(
          'error',
          result.Message || getTranslation('toast.error.saveBlocks')
        );
      }
    } else {
      toastNotification(
        'error',
        resultLib.Message || getTranslation('toast.error.getBlocks')
      );
    }

    setLoad(false);
  };

  if (!state.openModalSaveSelectedBlocks) return <></>;

  const getSelectedBlocks = () => {
    const keys = Object.keys(newBlockSet.blocks);
    return keys
      .map((key) => newBlockSet.blocks[Number(key)].data.name)
      .join(', ');
  };

  return (
    <Dialog
      open={state.openModalSaveSelectedBlocks}
      TransitionComponent={Transition}
      maxWidth="lg"
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <ContainerDialog className={classes.dialog}>
        <ContainerHeader>
          <ModalTitle style={{ fontWeight: 600 }}>
            {getTranslation('modal.saveSelectedBlocks.title')}
          </ModalTitle>
          <IconButton
            edge="start"
            color="default"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </ContainerHeader>
        <SelectedBlocks>
          <span> {getTranslation('modal.saveSelectedBlocks.subtitle')}</span>
          <strong>{getSelectedBlocks()}</strong>
        </SelectedBlocks>
        <FieldContainer>
          <TextField
            variant="outlined"
            placeholder={getTranslation('modal.saveSelectedBlocks.nameAs')}
            onChange={handleChangeInput('name')}
            value={newBlockSet.name}
            inputProps={{ maxLength: 40 }}
            className={classes.input}
            fullWidth
            error={!!errors.name}
            helperText={errors.name || ''}
          />
        </FieldContainer>
        <FieldContainer>
          <TextField
            variant="outlined"
            placeholder={getTranslation('description')}
            value={newBlockSet.description}
            onChange={handleChangeInput('description')}
            inputProps={{ maxLength: 40 }}
            className={classes.input}
            fullWidth
            error={!!errors.description}
            helperText={errors.description || ''}
          />
        </FieldContainer>
        <ButtonContainer>
          <Button
            style={{ letterSpacing: 2, borderRadius: 8 }}
            variant="contained"
            color="primary"
            onClick={handleCreateBlockSet}
          >
            {getTranslation('save')}
            {load && <CircularProgress color="inherit" size={18} />}
          </Button>
        </ButtonContainer>
      </ContainerDialog>
    </Dialog>
  );
}
