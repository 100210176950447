import { useCallback, useEffect } from 'react';

import { useForm } from 'contexts/Form/formContext';
import DeleteIcon from '@material-ui/icons/Delete';

import { IconButton, TextField, Typography } from '@material-ui/core';

import * as S from './styles';
import { ISentencesProps } from './types';
import useTranslator from 'utils/hooks/Translator';

export default function ABTestOutputsSentences(props: ISentencesProps) {
  const { index, choice, showRemoveButton, updateSum } = props;
  const { state, dispatch } = useForm();
  const { dataForm, formErrors } = state;
  const { getTranslation } = useTranslator();

  const load = useCallback(() => {
    if (dataForm && choice) {
      if (!!choice.userSentences) {
        choice.userSentences = [''];
      }
      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choice, index]);

  useEffect(() => {
    load();
  }, [load]);

  const handleChangeTitle = (newValue: string) => {
    if (dataForm && dataForm.outputs && dataForm.outputs[index]) {
      if (dataForm.outputs[index].title !== 'Outros') {
        choice.title = newValue;
        dataForm.outputs[index] = choice;

        if (dataForm) {
          dispatch({
            type: 'updateForm',
            data: {
              dataForm: {
                ...dataForm,
              },
            },
          });
        }
      }
    }
  };

  const handleDeleteOption = () => {
    if (!!dataForm && dataForm.outputs) {
      updateSum(index, dataForm.outputs[index].value || '0', 'subtract');
      dataForm.outputs.splice(index, 1);

      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
  };

  return (
    <S.Container>
      <S.ContainerOptionHeader>
        <S.OptionIndexAndTip>
          <S.TextContainer>
            <TextField
              fullWidth
              value={choice.title}
              onChange={(e) => handleChangeTitle(e.target.value)}
              label={getTranslation('outputTitle')}
              helperText={formErrors[`outputs[${index}].title`]}
              error={!!formErrors[`outputs[${index}].title`]}
            />
          </S.TextContainer>
          <S.TextContainer style={{ display: 'flex' }}>
            <TextField
              style={{ maxWidth: '98px' }}
              value={choice.value}
              type="number"
              label={getTranslation('outputValue')}
              onChange={(e) => updateSum(index, e.target.value, 'sum')}
              helperText={formErrors[`outputs[${index}].value`]}
              error={!!formErrors[`outputs[${index}].value`]}
            />
            <Typography
              variant="h6"
              children="%"
              style={{ alignSelf: 'center' }}
            />
          </S.TextContainer>
        </S.OptionIndexAndTip>
        {choice.title !== 'Outros' && showRemoveButton ? (
          <IconButton onClick={() => handleDeleteOption()}>
            <DeleteIcon style={{ alignSelf: 'center' }} htmlColor="#6c50a4" />
          </IconButton>
        ) : (
          <></>
        )}
      </S.ContainerOptionHeader>
    </S.Container>
  );
}
