import React from 'react';
// import './';
import { Router } from 'react-router-dom';
import { History } from 'history';
import { AppProvider } from './contexts/App/appContext';
import { FlowProvider } from './contexts/Flow/flowContext';
import ThemeCustom from './themes/ThemeCustom';
import Main from './views/Canvas/Main';
import GlobalStyles from './styles';
import { LibraryProvider } from './contexts/Library/libraryContext';
import './utils/i18n';
import { PermissionsProvider } from 'contexts/Permissions/permissionsContext';

type Props = {
  history: History<unknown>;
};

function App(props: Props) {
  return (
    <>
      <GlobalStyles />
      <AppProvider>
        <PermissionsProvider>
          <ThemeCustom>
            <FlowProvider>
              <LibraryProvider>
                <Router history={props.history}>
                  <Main />
                </Router>
              </LibraryProvider>
            </FlowProvider>
          </ThemeCustom>
        </PermissionsProvider>
      </AppProvider>
    </>
  );
}

export default App;
