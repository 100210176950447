import React, { useState } from 'react';
import {
  IconButton,
  TextField,
  CircularProgress,
  Typography,
  Button,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';

import {
  ContainerDialog,
  ContainerHeader,
  ContainerBody,
  ContainerFooter,
} from './styles';
import { ModalDeleteGroupProps } from './types';
import useTranslator from 'utils/hooks/Translator';

export default function ModalDeleteGroup(props: ModalDeleteGroupProps) {
  const { handleClose, groupName, handleDeleteGroup } = props;

  const [loading, setLoading] = useState(false);
  const [textConfirmation, setTextConfirmation] = useState('');
  const [errorTextConfirmation, setErrorTextConfirmation] = useState('');
  const { getTranslation } = useTranslator();

  const handleVerifyGroupName = () => {
    if (groupName !== textConfirmation) {
      setErrorTextConfirmation(getTranslation('modal.deleteGroup.error'));
    } else {
      setLoading(true);
      handleDeleteGroup();
    }
  };

  const changeTextConfirmation = (e: string) => {
    if (!!errorTextConfirmation) setErrorTextConfirmation('');
    setTextConfirmation(e);
  };

  return (
    <Dialog open fullWidth={true} maxWidth="sm">
      <ContainerDialog>
        <ContainerHeader>
          <Typography
            variant="h6"
            color="primary"
            style={{ fontWeight: 'bold' }}
          >
            {getTranslation('modal.deleteGroup.title')}
          </Typography>
          <IconButton
            disabled={loading}
            style={{ color: '#707070' }}
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </ContainerHeader>
        <ContainerBody>
          <p>
            {getTranslation('modal.deleteGroup.subtitle1')}
            <strong>{groupName}</strong>
            {getTranslation('modal.deleteGroup.subtitle2')}
          </p>
          <TextField
            fullWidth
            hiddenLabel
            variant="filled"
            placeholder={getTranslation('modal.deleteGroup.placeholder')}
            value={textConfirmation}
            error={!!errorTextConfirmation}
            helperText={errorTextConfirmation}
            disabled={loading}
            onChange={(e) => changeTextConfirmation(e.target.value)}
            autoFocus
          />
        </ContainerBody>
        <ContainerFooter>
          <Button
            disabled={!textConfirmation || loading}
            variant="contained"
            size="large"
            color="primary"
            style={{ backgroundColor: '#EA1601', minWidth: 156 }}
            onClick={handleVerifyGroupName}
          >
            {loading ? (
              <CircularProgress
                style={{ color: '#fff', margin: 4 }}
                color="inherit"
                size={18}
              />
            ) : (
              `${getTranslation('modal.deleteGroup.button')}`
            )}
          </Button>
        </ContainerFooter>
      </ContainerDialog>
    </Dialog>
  );
}
