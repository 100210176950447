import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'contexts/Form/formContext';
import AddIcon from '@material-ui/icons/Add';
import * as S from './styles';
import MttButton from 'components/Material/MttButton/MttButton';
import { Output } from 'models/DataFlow';

import {
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';

import OptionsBlockOutputsSentences from './OptionsBlockOutputsSentences';
import useTranslator from 'utils/hooks/Translator';
import { validateVarNames } from 'utils/String';
// import { GetAgentContexts } from 'services/FalaGPTService';
// import { FalaGPTContext } from './OptionsBlockOutputsSentences/types';
import { useApp } from 'contexts/App/appContext';
import { useFlow } from 'contexts/Flow/flowContext';
import { GetEntities } from 'services/EntityService';
import { IEntity } from 'models/Entity';

interface Props {
  isFalaGPT?: boolean;
}

export default function OptionsBlockContent({ isFalaGPT = false }: Props) {
  const { state, dispatch } = useForm();
  const { dispatch: dispatchApp } = useApp();
  const { dataForm, formErrors } = state;
  const [varValue, setVarValue] = useState(
    dataForm?.varValue?.substring(5, dataForm?.varValue?.length) || 'resposta'
  );
  // const [falaGPTContextNames, setFalaGPTContextsNames] = useState<string[]>([]);
  const isFirstRender = useRef(true);
  const { botName } = useFlow().state;
  const [entities, setEntities] = useState<IEntity[]>([]);

  const { getTranslation } = useTranslator();

  const load = useCallback(() => {
    if (dataForm) {
      if (dataForm.outputs && dataForm.outputs?.length === 0) {
        const outputs = [
          { title: isFalaGPT ? 'FalaGPT' : '', isChip: false },
          { title: 'Outros', isChip: false },
        ];

        dataForm.outputs = outputs;
        dispatch({
          type: 'updateForm',
          data: {
            dataForm: {
              ...dataForm,
            },
          },
        });
      }
      if (!dataForm.sendUser) {
        dataForm.sendUser = {
          message: '',
          messageDidNotUnderstood: '',
          url: '',
        };
        dispatch({
          type: 'updateForm',
          data: {
            dataForm: {
              ...dataForm,
            },
          },
        });
      }

      if (!dataForm.varValue) {
        dataForm.varValue = 'vars.resposta';
        dispatch({
          type: 'updateForm',
          data: {
            dataForm: {
              ...dataForm,
            },
          },
        });
      }
    }
  }, [dataForm, isFalaGPT, dispatch]);

  // const loadGPTContexts = useCallback(async () => {
  //   const response = await GetAgentContexts(dispatchApp);

  //   if (response.Success) {
  //     const contexts: FalaGPTContext[] = await response.Data.data;
  //     setFalaGPTContextsNames(contexts.map((context) => context.name));
  //   }
  // }, [dispatchApp]);

  //faz uma requeste para pegar as entidades
  const loadEntities = useCallback(async () => {
    if (botName) {
      const result = await GetEntities({ bot_name: botName }, dispatchApp);

      if (result.Success) {
        if (!!result.Data) {
          const entitiesResult = result.Data.filter(
            (e) => e.entitytype === 'words'
          );
          setEntities([...entitiesResult]);
        }
      }
    }
  }, [botName, dispatchApp]);

  useEffect(() => {
    load();
  }, [load]);

  useEffect(() => {
    loadEntities();
    if (isFalaGPT && isFirstRender.current) {
      isFirstRender.current = false;
    }
  }, [loadEntities, isFalaGPT]);

  const handleChangeDidNotUnderstoodMessage = (newValue: string) => {
    if (!!dataForm && dataForm.sendUser) {
      dataForm.sendUser.messageDidNotUnderstood = newValue;

      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
  };

  const renderChoice = (choice: Output, index: number) => {
    return (
      <div key={index}>
        {choice.title !== 'Outros' && (
          <OptionsBlockOutputsSentences
            index={index}
            choice={choice}
            showRemoveButton={showRemoveButton}
            isFalaGptBlock={isFalaGPT}
            // falaGPTContextsNames={falaGPTContextNames}
            entities={entities}
          />
        )}
      </div>
    );
  };

  const handleAddChoice = () => {
    if (dataForm) {
      if (dataForm.outputs) {
        const other = dataForm.outputs.find(
          (o) => o.title.toLowerCase() === 'outros'
        );
        const newOutputs = dataForm.outputs.filter(
          (o) => o.title.toLowerCase() !== 'outros'
        );
        newOutputs.push({
          title: '',
          userMsg: '',
          userSentences: [''],
          isChip: false,
        });
        if (other) {
          newOutputs.push(other);
        }
        dataForm.outputs = newOutputs;
      } else {
        dataForm.outputs = [{ title: 'Outros' }];
      }

      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
  };

  const handleChangeVarValue = (
    e: React.ChangeEvent<
      | { name?: string | undefined; value: unknown }
      | HTMLTextAreaElement
      | HTMLInputElement
    >
  ) => {
    if (!!dataForm && dataForm.varValue) {
      const validatedVarValue = validateVarNames(e, 'name', true);
      dataForm.varValue = `vars.${validatedVarValue}`;

      setVarValue(validatedVarValue);

      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
  };

  const handleChangeQuestion = (value: string) => {
    if (!!dataForm && dataForm.sendUser) {
      dataForm.sendUser.message = value;
      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
  };

  const handleChangeSaveVarValue = (value: boolean) => {
    if (!!dataForm) {
      dataForm.saveVarValue = value;
      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
  };

  const showRemoveButton =
    !!dataForm && !!dataForm.outputs
      ? dataForm.outputs.length > 2 || isFalaGPT
      : false;

  return (
    <S.GridContainer>
      <S.ContainerButton>
        <S.Container>
          <Typography variant="h6" color="primary">
            {getTranslation('info.messageForUser')}
          </Typography>
          <S.MessageContainer>
            <S.StyledMttTextField
              variant="outlined"
              label={getTranslation('message')}
              fullWidth
              value={dataForm?.sendUser?.message || ''}
              onChange={(e) => handleChangeQuestion(e.target.value)}
              minRows={3}
              maxRows={3}
              multiline
              error={!!formErrors['formMsg']}
              helperText={formErrors['formMsg']}
            />
          </S.MessageContainer>
        </S.Container>
      </S.ContainerButton>
      <S.ContainerButton>
        <MttButton
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddChoice}
        >
          {getTranslation('addOption')}
        </MttButton>
      </S.ContainerButton>

      <S.ContentGrid>
        {dataForm && dataForm.outputs && dataForm.outputs.map(renderChoice)}
      </S.ContentGrid>

      <>
        {!isFalaGPT && (
          <S.SwitchDiv>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={dataForm?.saveVarValue}
                    color="primary"
                    onChange={(e) => {
                      handleChangeSaveVarValue(e.target.checked);
                    }}
                  />
                }
                label={getTranslation('saveSelectedOption')}
                color="primary"
              />
            </FormGroup>
            {dataForm?.saveVarValue && (
              <>
                <Typography variant="subtitle1">
                  {getTranslation('outputVariable')}
                </Typography>
                <TextField value={'vars.'} style={{ maxWidth: '56px' }} />
                <TextField value={varValue} onChange={handleChangeVarValue} />
              </>
            )}
          </S.SwitchDiv>
        )}

        <S.ContentMsgForUserGrid>
          <S.StyledMttTypographyMsgForUser variant="body1" color="primary">
            {isFalaGPT
              ? 'Mensagem quando a resposta do usuário não atender as opções acima'
              : getTranslation('messageDidNotUnderstood')}
          </S.StyledMttTypographyMsgForUser>
          <S.StyledMttTextField
            variant="outlined"
            fullWidth
            label={getTranslation('message')}
            multiline
            rows={3}
            name="choice-input"
            type="text"
            value={dataForm?.sendUser?.messageDidNotUnderstood || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleChangeDidNotUnderstoodMessage(e.target.value);
            }}
          />
        </S.ContentMsgForUserGrid>
      </>
    </S.GridContainer>
  );
}
