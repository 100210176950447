import { Grid } from '@material-ui/core';
import styled from 'styled-components';

export const ButtonContainer = styled(Grid)`
  display: flex;
  justify-content: flex-end;
`;

export const ProjectHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
