import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import MttButton from '../Material/MttButton/MttButton';

export const Container = styled.div`
  position: absolute;
  left: 32%;
  top: 12%;
  border-radius: 10px;

  z-index: 10;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000014;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
  max-width: 624px;
  width: 100%;
  height: 100%;
  max-height: 100px;
`;

export const ContentContainer = styled.div`
  width: 100%;
  max-width: 350px;
  max-height: 90px;
`;

export const ImageContainer = styled.div`
  margin: 0px 15px 0px 10px;
  img {
    max-width: 140px;
    max-height: 50px;
  }
`;

export const TextContainer = styled(Typography).attrs({})`
  display: flex;
  max-width: 355px;
`;

export const ModalTitle = styled(Typography).attrs({
  variant: 'h6',
})`
  color: #6c50a4;
`;

export const ButtonContainer = styled.div`
  padding: 22px 16px;
`;

export const ButtonOk = styled(MttButton)`
  width: 134px;
  height: 35px;
`;
