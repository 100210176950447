import React from 'react';
import { Switch } from 'react-router-dom';
import Entities from '../pages/Entities';
import Flow from '../pages/Flow';
import Login from '../pages/Login';
import PrivateRoute from './PrivateRoute';

const Routes: React.FC = () => (
  <Switch>
    <PrivateRoute path="/" Component={Flow} exact />
    <PrivateRoute path="/login" Component={Login} exact />
    <PrivateRoute path="/entities" Component={Entities} exact />
  </Switch>
);

export default Routes;
