import EIntentType from 'enums/EIntentType';
import { DataFlow, ItemDataFlow, MessageType } from 'models/DataFlow';
import { IEntity } from 'models/Entity';
import { IFlow, IFlowResult } from 'services/BotService/types';

let lastBlockId = 0;

const payloadURL = process.env.REACT_APP_S3_URL || '';
const payloadApiKey = process.env.REACT_APP_S3_API_KEY || '';

const similarityAccuracyLimit = 0.3;
const similarityAPI =
  'https://api-hub.fintalk.io/nlu/similarity?apikey=b1fcea2c-d3a6-11eb-b8bc-0242ac130003';

const compileFlow = (
  flows: any[],
  entitiesResult: IEntity[],
  principalFlow: IFlowResult
) => {
  const flexGroups: any[] = flows.filter((flow) =>
    flow.groupName.startsWith('flex')
  );
  flows.map((item) => preCompile(item, entitiesResult, flexGroups));
};

const preCompile = (data: IFlow, entities: IEntity[], flexGroups: any[]) => {
  const blocks = data.drawflow.drawflow.Home.data;
  const blockCopy = blocks;
  const botName = data.bot;
  const blocksKeys = Object.keys(blocks);
  lastBlockId = Number(blocksKeys[blocksKeys.length - 1]);

  Object.keys(blockCopy).forEach(function (block) {
    const blockObj = blocks[Number(block)];
    const blockData = blockObj.data;
    let outputBlock = 0;
    if (blockData) {
      if (
        [
          EIntentType.SendDocument,
          EIntentType.Carousel,
          EIntentType.SendMessage,
          EIntentType.OptionsBlock,
          EIntentType.SimpleQuestion,
          EIntentType.OpenHTML,
          EIntentType.ABTest,
          EIntentType.NLU,
          EIntentType.FalaGPT,
          EIntentType.FlexBlockExit,
          EIntentType.FlexBlock,
        ].includes(blockData.intentType)
      ) {
        if (blockObj.outputs) {
          const block_output = blockObj.outputs;
          if (block_output.output_1) {
            if (
              block_output.output_1.connections &&
              block_output.output_1.connections[0] &&
              block_output.output_1.connections[0].node
            ) {
              outputBlock = block_output.output_1.connections[0].node;
            }
          }
        }
        blockChanger(
          botName,
          blocks,
          blockObj,
          outputBlock,
          lastBlockId,
          entities,
          flexGroups
        );
      }
    }
  });
};

const blockChanger = (
  botName: string,
  blocks: DataFlow,
  block: ItemDataFlow,
  to_block: number,
  lastBlock: number,
  entities: IEntity[],
  flexGroups: any[]
) => {
  switch (Number(block.data.intentType)) {
    case EIntentType.SimpleQuestion:
      const simpleQuestionFallbackBlock = {} as ItemDataFlow;

      if (block.data && block.data.outputs) {
        simpleQuestionFallbackBlock.name =
          block.data.outputs[block.data?.outputs.length - 1].nameIntent ||
          block.data.name;

        simpleQuestionFallbackBlock.id =
          Number(block.data.outputs[block.data?.outputs.length - 1].outputid) ||
          0;
      }

      const inputBlock = {
        id: lastBlock + 1,
        data: {
          dataBlockly: {
            compiledPayload: '',
            lastVersion: 0,
            payload: '',
            xml: '',
          },
          inputs: { variables: [{ questions: [''] }] },
          intentType: 3,
          groupId: block.data.groupId,
          name: `${block.data.name}-input`,
        },
      } as ItemDataFlow;
      lastBlockId = lastBlock + 1;
      blocks[lastBlockId] = inputBlock;

      const logicBlock = {
        id: lastBlock + 2,
        data: {
          dataBlockly: {
            compiledPayload: '',
            lastVersion: 0,
            payload: '',
            xml: '',
          },
          intentType: 120,
          groupId: block.data.groupId,
          name: `${block.data.name}-logica`,
        },
      } as ItemDataFlow;
      lastBlockId = lastBlock + 2;
      blocks[lastBlockId] = logicBlock;

      if (
        block.data.dataBlockly &&
        block.data.inputs &&
        block.data.inputs.variables[0]
      ) {
        block.data.dataBlockly.payload = ``;
        block.data.dataBlockly.compiledPayload = `event('${block.data.groupId}_${inputBlock.data.name}');\n`;

        const questions = block.data.inputs.variables[0].questions
          .filter((q) => q !== '')
          .map((q) => q.replaceAll('{{', '`${').replaceAll('}}', '}`'));

        const fixedVarValue =
          block.data.varValue
            ?.replaceAll('vars.', '')
            .replaceAll('user.', '') || '';

        if (inputBlock.data.inputs) {
          inputBlock.data.inputs.variables[0] = {
            name: `${fixedVarValue}`,
            questions,
            required: true,
            vartype: '@sys.any',
            defaultValue: '',
            isList: false,
            value: `$${fixedVarValue}`,
          };
        }
      }

      if (logicBlock.data.dataBlockly) {
        const outputBlockId = to_block !== 0 ? to_block : block.id;

        logicBlock.data.dataBlockly.compiledPayload = `				
				var input;\n
				var resp;\n
        vars.contagem = vars.contagem || 0;\n
				`;

        logicBlock.data.dataBlockly.compiledPayload += getCompiledPayload(
          blocks,
          block,
          inputBlock,
          outputBlockId,
          simpleQuestionFallbackBlock.name
        );

        logicBlock.data.dataBlockly.compiledPayload =
          logicBlock.data.dataBlockly.compiledPayload.replaceAll('\t', '');
      }

      inputBlock.data.dataBlockly = {
        lastVersion: 1,
        payload: '',
        xml: '',
        compiledPayload: `event('${block.data.groupId}_${logicBlock.data.name}')`,
      };

      blocks[lastBlockId] = logicBlock;
      break;

    case EIntentType.SendDocument:
      const fallbackBlock = {} as ItemDataFlow;
      const destinationBlock = blocks[to_block];
      const destinationBlockName = destinationBlock
        ? destinationBlock.data.name
        : '';

      const fallBackMessage =
        block.data.sendUser?.messageDidNotUnderstood || '';

      if (block.data && block.data.outputs) {
        fallbackBlock.name =
          block.data.outputs[block.data?.outputs.length - 1].nameIntent ||
          block.data.name;

        fallbackBlock.id =
          Number(block.data.outputs[block.data?.outputs.length - 1].outputid) ||
          0;
      }

      if (block.data.dataBlockly) {
        const inputBlock = {
          id: lastBlock + 1,
          data: {
            dataBlockly: {
              compiledPayload: '',
              lastVersion: 0,
              payload: '',
              xml: '',
            },
            inputs: { variables: [{ questions: [' '] }] },
            intentType: 3,
            groupId: block.data.groupId,
            name: `${block.data.name}-input`,
          },
        } as ItemDataFlow;

        blocks[lastBlock + 1] = inputBlock;
        lastBlockId = lastBlock + 1;

        block.data.dataBlockly = {
          lastVersion: 0,
          payload: '',
          xml: '',
          compiledPayload: `event('${inputBlock.data.groupId}_${inputBlock.data.name}');`,
        };

        if (inputBlock.data.inputs) {
          if (block.data.varValue) {
            const blockVarValue = block.data.varValue || 'vars.url';
            const fixedVarValue =
              blockVarValue?.replaceAll('vars.', '').replaceAll('user.', '') ||
              '';

            inputBlock.data.inputs = {
              variables: [
                {
                  name: `${fixedVarValue}`,
                  questions: [' '],
                  required: true,
                  vartype: '@sys.any',
                  defaultValue: '',
                  isList: false,
                  value: `$${fixedVarValue}`,
                },
              ],
            };

            if (inputBlock.data.dataBlockly) {
              inputBlock.data.dataBlockly.compiledPayload = `
								var pic;
								var url;
								pic = user.input.match("https://");
								vars.contagem = vars.contagem || 0;
								if(pic){
									url = (await request('POST', '${payloadURL}', {
										"bot": '${botName}',
										"userId": user.id,
										"url": user.input
									}, {
										headers: {
											"X-API-Key": "${payloadApiKey}"
										}
									}
									));
									if (url) {
										vars.contagem = 0;
										${blockVarValue} = url.document;
										event('${block.data.groupId}_${destinationBlockName}');
									} else {
										vars.contagem = vars.contagem + 1;
										${getCountCondition(
                      fallBackMessage,
                      block.data.groupId,
                      fallbackBlock.name || ''
                    )}
									}
								} else {
									vars.contagem = vars.contagem + 1;
									${getCountCondition(
                    fallBackMessage,
                    block.data.groupId,
                    fallbackBlock.name || ''
                  )}
								}
							`;

              inputBlock.data.dataBlockly.compiledPayload =
                inputBlock.data.dataBlockly.compiledPayload.replaceAll(
                  '\t',
                  ''
                );
            }
          }
        }
      }
      break;

    case EIntentType.OptionsBlock:
      const optionsBlockInput = {
        id: lastBlock + 1,
        data: {
          dataBlockly: {
            compiledPayload: '',
            lastVersion: 0,
            payload: '',
            xml: '',
          },
          intentType: 4,
          outputs: [{ title: '', nameIntent: '' }],
          groupId: block.data.groupId,
          name: `${block.data.name}-input`,
        },
      } as ItemDataFlow;

      lastBlockId = lastBlock + 1;
      blocks[lastBlock + 1] = optionsBlockInput;

      if (block.data.outputs) {
        const othersOutput = block.data.outputs[block.data.outputs.length - 1];

        let switchString = '';
        const synonymArray: any[] = [];
        block.data.outputs?.forEach((output) => {
          return synonymArray.push(
            output.userSentences?.filter((s) => s !== '')
          );
        });

        synonymArray.forEach((sArr) => {
          if (sArr) {
            sArr.forEach((s: string, index: number) => {
              if (s.substring(0, 1) === '@') {
                const foundEntity = entities.find((e) => `@${e.name}` === s);
                if (foundEntity) {
                  foundEntity.values[0].synonyms.forEach((v) => {
                    sArr.push(v);
                  });
                }
                sArr.splice(index, 1);
              }
            });
          }
        });

        const arr = synonymArray.filter((arr) => {
          if (!!arr) return arr.length > 0;
          else return false;
        });

        let compiledPayload = `
					setLocalCapture(false);
					var resp;
					vars.userInput = \`$raw.resposta\`;
					vars.contagem = (vars.contagem || 0);
					resp = (
							await request(
									'POST',
									'${similarityAPI}',{
											"input": \`\${vars_userInput}\`,
											"list": ${JSON.stringify(arr)},
											"accuracy_limit": ${similarityAccuracyLimit}
									}
							)
					)
			`;
        if (block.data.outputs) {
          let evt = '';

          const outputs = block.data.outputs;
          const saveVarValueString = block.data.saveVarValue
            ? `${block.data.varValue} = resp.option_selected;`
            : '';

          const ifOptionSelectedStatement = saveVarValueString
            ? `if (resp.option_selected) { ${saveVarValueString} }`
            : '';

          switchString = `if (resp) {
							${ifOptionSelectedStatement} 
							switch (resp.option_selected) {
				`;

          outputs.forEach((output) => {
            if (output.title !== '' && output.title !== 'Outros') {
              if (output.nameIntent !== '') {
                evt = `event('${block.data.groupId}_${output.nameIntent}');`;
              }
              switchString += `
							case '${output.title}':
								${evt}
								break;
								`;
            }
            evt = '';
          });
          switchString += `
					default:
					${getCountCondition(
            block.data.sendUser?.messageDidNotUnderstood || '',
            block.data.groupId,
            othersOutput.nameIntent || ''
          )}
					}
				} else {
					msg("Encontrei um erro ao tentar processar sua mensagem, tente novamente em alguns instantes");
					event('${block.data.groupId}_${block.data.name}');
					}
				`;
        }

        compiledPayload += switchString;

        const chipsTitles = block.data.outputs
          ?.map((output) => (output.isChip ? output.title : ''))
          .filter((item) => item !== 'Outros' && item !== '');

        let chipsString = '[';

        chipsTitles?.forEach((chip, index) => {
          chipsString += `'${chip}'`;
          if (index < chipsTitles.length - 1) {
            chipsString += ',';
          }
        });

        chipsString += ']';

        const chipsEvent =
          chipsString !== '[]' ? `msg(${chipsString}, 'chips');\n` : '';

        block.data.dataBlockly = {
          lastVersion: 0,
          compiledPayload: `
				  	setLocalCapture(true, '${block.data.groupId}_${optionsBlockInput.data.name}')
						${chipsEvent}
					`,
          payload: '',
          xml: '',
        };

        optionsBlockInput.data.dataBlockly = {
          lastVersion: 0,
          compiledPayload: compiledPayload.replaceAll('\t', ''),
          payload: '',
          xml: '',
        };
      }
      break;

    case EIntentType.Carousel:
      const carouselBlock1 = {
        id: lastBlock + 1,
        data: {
          dataBlockly: {
            compiledPayload: '',
            lastVersion: 0,
            payload: '',
            xml: '',
          },
          intentType: 120,
          groupId: block.data.groupId,
          name: `${block.data.name}-logica`,
        },
      } as ItemDataFlow;

      lastBlockId = lastBlock + 1;
      blocks[lastBlock + 1] = carouselBlock1;

      const carouselBlock2 = {
        id: lastBlock + 2,
        data: {
          dataBlockly: {
            compiledPayload: '',
            lastVersion: 0,
            payload: '',
            xml: '',
          },
          intentType: 120,
          groupId: block.data.groupId,
          name: `${block.data.name}-input`,
        },
      } as ItemDataFlow;

      lastBlockId = lastBlock + 2;
      blocks[lastBlock + 2] = carouselBlock2;

      const items = block.data.outputs
        ?.map((output) => {
          return {
            title: output.title,
            description: output.description || ' ',
            price: Number(output.value),
            image: output.url,
          };
        })
        .filter((item) => item.title !== 'Outros');

      if (block.data.outputs) {
        const othersOutput = block.data.outputs[block.data.outputs.length - 1];
        const carouselOrList = block.data.listType === 1 ? 'carousel' : 'list';
        const stringifiedItems = JSON.stringify(items);

        if (carouselBlock1.data.dataBlockly)
          carouselBlock1.data.dataBlockly.compiledPayload = `
				items = ${stringifiedItems};
				if (user.appClient == "Web" || user.appClient == "Facebook") {
					msg(items, '${carouselOrList}');
				} else {
					let msgTxt = "";
					let chips = [];

						for (let i = 0; i < items.length; i++) {
							if (!!items[i]) {
								const price = !!items[i].price ? items[i].price.toLocaleString('pt-br', { style: 'currency', currency: 'BRL'}) : " "\n;
								const description = (!!items[i].description && items[i].description.trim() !== "") ?  \`\n\`+ items[i].description + \`\n\` : \`\`;
								msgTxt += !!items[i].title ? '*' + (i+1) + '*. ' + items[i].title + \` \` + price + description + \`\n\` : \`''\`\n;
								if (items[i]) chips.push(items[i].title);    
							}
						}
					 msg(msgTxt, 'text');     
					 msg(chips, 'chips');
				}
				setLocalCapture(true, '${block.data.groupId}_${carouselBlock2.data.name}')
			`;

        const fallBackMessageCarousel =
          block.data.sendUser?.messageDidNotUnderstood || '';

        let switchString = '';
        const synonymArray: any[] = [];
        block.data.outputs?.forEach((output, index) => {
          const foundIndex = output.userSentences?.find(
            (s) => s === (index + 1).toString()
          );

          if (!foundIndex) output.userSentences?.push(`${index + 1}`);

          return synonymArray.push(
            output.userSentences?.filter((s) => s !== '')
          );
        });

        const arr = synonymArray.filter((arr) => {
          if (!!arr) return arr.length > 0;
          else return false;
        });

        let compiledPayload = `
					setLocalCapture(false)
					var resp;
					vars.userInput = \`$raw.resposta\`;
					vars.contagem = (vars.contagem || 0);
					resp = (
							await request(
									'POST',
									'${similarityAPI}',{
											"input": \`\${vars_userInput}\`,
											"list": ${JSON.stringify(arr)},
											"accuracy_limit": ${similarityAccuracyLimit}
									}
							)
						)
			`;
        if (block.data.outputs) {
          let evt = '';

          const outputs = block.data.outputs;
          const saveVarValueString = block.data.saveVarValue
            ? `${block.data.varValue} = resp.option_selected;`
            : '';

          const ifOptionSelectedStatement = saveVarValueString
            ? `if (resp.option_selected) { ${saveVarValueString} }`
            : '';

          switchString = `if (resp) {
							${ifOptionSelectedStatement} 
							switch (resp.option_selected) {
				`;

          outputs.forEach((output) => {
            if (output.title !== '' && output.title !== 'Outros') {
              if (output.nameIntent !== '') {
                evt = `event('${block.data.groupId}_${output.nameIntent}');`;
              }
              switchString += `
							case '${output.title}':
								${evt}
								break;
								`;
            }
            evt = '';
          });
          switchString += `
					default:
					${getCountCondition(
            fallBackMessageCarousel,
            block.data.groupId,
            othersOutput.nameIntent || ''
          )}
					}
				} else {
					msg("Encontrei um erro ao tentar processar sua mensagem, tente novamente em alguns instantes");
					event('${block.data.groupId}_${block.data.name}');
					}
				`;
        }

        compiledPayload += switchString;

        block.data.dataBlockly = {
          lastVersion: 0,
          compiledPayload: `
						event('${block.data.groupId}_${carouselBlock1.data.name}');
					`,
          payload: '',
          xml: '',
        };

        carouselBlock1.data.dataBlockly = {
          lastVersion: 0,
          compiledPayload:
            carouselBlock1.data?.dataBlockly?.compiledPayload.replaceAll(
              '\t',
              ''
            ) || '',
          payload: '',
          xml: '',
        };

        carouselBlock2.data.dataBlockly = {
          lastVersion: 0,
          compiledPayload: compiledPayload.replaceAll('\t', ''),
          payload: '',
          xml: '',
        };
      }
      break;

    case EIntentType.SendMessage:
      if (block.data.dataBlockly) {
        block.data.dataBlockly.payload += `// #PARSER#CONNECTOR#Saída#`;
      }
      break;

    case EIntentType.OpenHTML:
      if (block.data.dataBlockly) {
        const destinationBlock = blocks[to_block];
        const destinationBlockName = destinationBlock
          ? destinationBlock.data.name
          : '';
        block.data.dataBlockly.payload = '';
        block.data.dataBlockly.compiledPayload = `
				if (user.appClient == "Web" || user.appClient == "App"){
					msg({
						"type": "iframe",
						"items": \`${block.data.sendUser?.url || block.data.sendUser?.message}\` ,
						"onClose": '${block.data.groupId}_${destinationBlockName}',
						"userId": user.id
					}, 'payload');
					} else {
						msg(\`${block.data.sendUser?.url || block.data.sendUser?.message}\`);
						event('${block.data.groupId}_${destinationBlockName}');
					}
				`.replaceAll('\t', '');
      }
      break;

    case EIntentType.ABTest:
      if (block.data.dataBlockly && !!block.data.outputs) {
        const outputs = block.data.outputs;
        const allValues = block.data.outputs.map((o) => o.value || '0');

        block.data.dataBlockly.payload = '';

        block.data.dataBlockly.compiledPayload = `
				var random = Math.random() * 100;
				`;

        let switchString = ``;
        let previousValue = 0;

        allValues?.forEach((value, index) => {
          if (outputs[index]) {
            const outputName = outputs[index].nameIntent;
            previousValue = previousValue + Number(value);

            switchString += `
							if (random < ${previousValue}){
								event('${block.data.groupId}_${outputName}');
						} else
						`;

            if (!outputs[index + 1])
              switchString += `
							{
								event('${block.data.groupId}_${block.data.name}');
							}`;
          }
        });

        block.data.dataBlockly.compiledPayload += switchString.replaceAll(
          '\t',
          ''
        );
      }
      break;

    case EIntentType.NLU:
      if (block.data.inputs) {
        block.data.inputs.userSentences = block.data.inputs?.userSentences?.map(
          (sentence) => sentence.replaceAll(/[\r\n\t]/g, '')
        );
      }
      break;

    case EIntentType.FlexBlock:
      const flexBlockRelatedGroup = flexGroups.find(
        (group) => group.groupName === `flex-${block.data.name}`
      );
      if (!flexBlockRelatedGroup) break;
      const flexBlockRelatedGroupBlocks =
        flexBlockRelatedGroup.drawflow.drawflow.Home.data;
      const flexGroupBlocksKeys = Object.keys(flexBlockRelatedGroupBlocks);

      let entryBlockInFlexGroup: ItemDataFlow = {} as ItemDataFlow;
      const exitBlocks: ItemDataFlow[] = [];

      lastBlockId = lastBlock;

      for (const key in flexGroupBlocksKeys) {
        const currentBlock: ItemDataFlow =
          flexBlockRelatedGroupBlocks[flexGroupBlocksKeys[key]];

        if (currentBlock.data.intentType === EIntentType.FlexBlockEntry) {
          entryBlockInFlexGroup = currentBlock;
        }

        if (currentBlock.data.intentType === EIntentType.FlexBlockExit) {
          exitBlocks.push(currentBlock);
        }
      }

      let flexBlockCompiledPayload = '';

      exitBlocks.forEach((exitBlock, index) => {
        if (block.data.outputs && block.data.outputs[index]) {
          exitBlock.data.dataBlockly = {
            ...exitBlock.data.dataBlockly,
            lastVersion: exitBlock.data.dataBlockly?.lastVersion || 0,
            payload: '',
            xml: '',
            compiledPayload: `event('${block.data.groupId}_${block.data.outputs[index].nameIntent}')`,
          };

          flexBlockCompiledPayload += `
					vars.__output${index}_event = '${block.data.groupId}_${block.data.outputs[index].nameIntent}'
					`;
        }
      });
      flexBlockCompiledPayload += `event('${flexBlockRelatedGroup.groupName}_${entryBlockInFlexGroup.data.name}')`;

      block.data.dataBlockly = {
        xml: block.data.dataBlockly?.xml || '',
        payload: '',
        lastVersion: block.data.dataBlockly?.lastVersion || 1,
        compiledPayload: flexBlockCompiledPayload,
      };
      break;

    case EIntentType.FlexBlockExit:
      const flexBlockGroup = flexGroups.find(
        (group) => group.groupName === block.data.groupId
      );
      if (!flexBlockGroup) break;
      const flexBlockRelatedlocks = flexBlockGroup.drawflow.drawflow.Home.data;
      const flexGroupKeys = Object.keys(flexBlockRelatedlocks);

      const exits: ItemDataFlow[] = [];

      for (const key in flexGroupKeys) {
        const currentBlock: ItemDataFlow = flexBlockRelatedlocks[flexGroupKeys[key]];

        if (currentBlock.data.intentType === EIntentType.FlexBlockExit) {
          exits.push(currentBlock);
        }
      }

      exits.forEach((exit, index) => {
        if (exit.data.name === block.data.name) {
          block.data.dataBlockly = {
            xml: block.data.dataBlockly?.xml || '',
            payload: '',
            lastVersion: block.data.dataBlockly?.lastVersion || 1,
            compiledPayload: `event(vars.__output${index}_event)`,
          };
          return;
        }
      });

      break;

    case EIntentType.FalaGPT:
      const groupName = block.data.groupId;

      const falaGptInputBlock = {
        id: lastBlock + 1,
        data: {
          dataBlockly: {
            compiledPayload: '',
            lastVersion: 0,
            payload: '',
            xml: '',
          },
          outputs: block.data.outputs,
          intentType: 211,
          groupId: groupName,
          name: `${block.data.name}-recognition`,
        },
      } as ItemDataFlow;

      const falaGptLogicBlock = {
        id: lastBlock + 2,
        data: {
          dataBlockly: {
            compiledPayload: '',
            lastVersion: 0,
            payload: '',
            xml: '',
          },
          intentType: 120,
          groupId: groupName,
          name: `${block.data.name}-logic`,
        },
      } as ItemDataFlow;

      const falaGptOutputIndex = block.data.outputs!.findIndex(
        (output) => output.title === 'FalaGPT'
      );

      if (falaGptOutputIndex !== -1) {
        falaGptInputBlock.data.outputs![falaGptOutputIndex].nameIntent =
          falaGptLogicBlock.data.name;

        falaGptInputBlock.data.outputs![falaGptOutputIndex].title = 'Outros';
      }

      blocks[lastBlock + 1] = falaGptInputBlock;
      blocks[lastBlock + 2] = falaGptLogicBlock;

      if (block.data.outputs) {
        const synonymArray: any[] = [];
        block.data.outputs?.forEach((output) => {
          return synonymArray.push(
            output.userSentences?.filter((s) => s !== '')
          );
        });

        synonymArray.forEach((sArr) => {
          if (sArr) {
            sArr.forEach((s: string, index: number) => {
              if (s.substring(0, 1) === '@') {
                const foundEntity = entities.find((e) => `@${e.name}` === s);
                if (foundEntity) {
                  foundEntity.values[0].synonyms.forEach((v) => {
                    sArr.push(v);
                  });
                }
                sArr.splice(index, 1);
              }
            });
          }
        });

        const chipsTitles = block.data.outputs
          ?.map((output) => (output.isChip ? output.title : ''))
          .filter((item) => item !== 'Outros' && item !== '');

        let chipsString = '[';

        chipsTitles?.forEach((chip, index) => {
          chipsString += `'${chip}'`;
          if (index < chipsTitles.length - 1) {
            chipsString += ',';
          }
        });

        chipsString += ']';

        const chipsEvent =
          chipsString !== '[]' ? `msg(${chipsString}, 'chips');\n` : '';

        block.data.dataBlockly = {
          lastVersion: 0,
          compiledPayload: `
							${chipsEvent};
							setLocalCapture(true, '${block.data.groupId}_${falaGptInputBlock.data.name}');
						`,
          payload: '',
          xml: '',
        };

        block.data.intentType = 100;

        falaGptInputBlock.data.dataBlockly = {
          lastVersion: 0,
          compiledPayload: `
					  setLocalCapture(false)
        	`,
          payload: '',
          xml: '',
        };

        const logicCompiledPayload = `
				try {
					const response = await chatGPTQuestion(user.id, "${block.data.outputs[0].falaGPTContext}", user.input);					
					if (response) {
						vars.respLonga = response;
						msg(vars.respLonga)
					}
					else {
						msg('Tive um problema para encontrar uma resposta, tente novamente.', 'text');
					} 
					event('${groupName}_${falaGptInputBlock.data.name}')
				}
				catch (e) {
					msg('Tive um problema para encontrar uma resposta, tente novamente.', 'text');
					event('${groupName}_${falaGptInputBlock.data.name}')
				}
				`;

        falaGptLogicBlock.data.dataBlockly = {
          lastVersion: 0,
          compiledPayload: logicCompiledPayload.replaceAll('\t', ''),
          payload: '',
          xml: '',
        };
      }
      break;
  }
};

const getRegex = (type: MessageType) => {
  let regex: RegExp = /.+/gm;

  switch (type) {
    case 'text':
      regex = /.+/gm;
      break;
    case 'anyNumber':
      regex = /\d+/gm;
      break;
    case 'email':
      regex = /[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+[.][a-zA-Z0-9-.]+/gm;
      break;
    case 'url':
      regex =
        // eslint-disable-next-line no-useless-escape
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%.\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%\+.~#?&\/=]*)/gm;
      break;
    case 'phone':
      regex = /\(?\d{2}\)? ?\d{5}-?\d{4}/gm;
      break;
    case 'lettersOnly':
      regex = /[A-Za-z ã á â é ê í õ ó ç]*/gm;
      break;
    case 'cpf':
      regex = /\d{3}\.?\d{3}\.?\d{3}-?\d{2}/gm;
      break;
    case 'cnpj':
      regex =
        // eslint-disable-next-line no-useless-escape
        /[0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2}/gm;
      break;
    case 'cep':
      regex = /\d{5}-?\d{3}/gm;
      break;
    case 'data':
      regex = /\d{2}\/?\d{2}\/?\d{4}/gm;
      break;
    case 'money':
      regex = /[1-9]\d{0,2}(\.\d{3})*,\d{2}/gm;
      break;
    case 'password':
      regex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      break;
    case 'location':
      regex =
        /[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)[|]\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)/gm;
      break;
    case 'uuid':
      regex =
        /[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/gm;
      break;
  }

  return regex;
};

const getCompiledPayload = (
  blocks: DataFlow,
  block: ItemDataFlow,
  inputBlock: ItemDataFlow,
  outputBlockId: number,
  fallBackBlock: string
) => {
  let compiledPayload = '';
  const msgType = block.data.messageType as MessageType;

  switch (msgType) {
    case 'cep':
      compiledPayload += `${getIfCondition(
        block,
        inputBlock.data.name,
        blocks[outputBlockId].data.name,
        fallBackBlock,
        'cep'
      )}`;
      break;

    case 'cpf':
      compiledPayload += `${getIfCondition(
        block,
        inputBlock.data.name,
        blocks[outputBlockId].data.name,
        fallBackBlock,
        'cpf'
      )}`;
      break;

    case 'cnpj':
      compiledPayload += `${getIfCondition(
        block,
        inputBlock.data.name,
        blocks[outputBlockId].data.name,
        fallBackBlock,
        'cnpj'
      )}`;
      break;

    case 'location':
      compiledPayload += `
			${getIfCondition(
        block,
        inputBlock.data.name,
        blocks[outputBlockId].data.name,
        fallBackBlock,
        'location'
      )}`;
      break;

    case 'email':
      compiledPayload += `
			${getIfCondition(
        block,
        inputBlock.data.name,
        blocks[outputBlockId].data.name,
        fallBackBlock,
        'email'
      )}`;
      break;

    case 'text':
      compiledPayload += `
			${getIfCondition(
        block,
        inputBlock.data.name,
        blocks[outputBlockId].data.name,
        fallBackBlock,
        'text'
      )}`;
      break;

    case 'anyNumber':
      compiledPayload += `
			${getIfCondition(
        block,
        inputBlock.data.name,
        blocks[outputBlockId].data.name,
        fallBackBlock,
        'anyNumber'
      )}`;
      break;

    case 'data':
      compiledPayload += `
			${getIfCondition(
        block,
        inputBlock.data.name,
        blocks[outputBlockId].data.name,
        fallBackBlock,
        'data'
      )}`;
      break;

    case 'phone':
      compiledPayload += `
			${getIfCondition(
        block,
        inputBlock.data.name,
        blocks[outputBlockId].data.name,
        fallBackBlock,
        'phone'
      )}`;
      break;

    case 'password':
      compiledPayload += `
			${getIfCondition(
        block,
        inputBlock.data.name,
        blocks[outputBlockId].data.name,
        fallBackBlock,
        'password'
      )}`;
      break;

    case 'money':
      compiledPayload += `
			${getIfCondition(
        block,
        inputBlock.data.name,
        blocks[outputBlockId].data.name,
        fallBackBlock,
        'money'
      )}`;
      break;

    case 'url':
      compiledPayload += `
			${getIfCondition(
        block,
        inputBlock.data.name,
        blocks[outputBlockId].data.name,
        fallBackBlock,
        'url'
      )}`;
      break;

    case 'uuid':
      compiledPayload += `
			${getIfCondition(
        block,
        inputBlock.data.name,
        blocks[outputBlockId].data.name,
        fallBackBlock,
        'uuid'
      )}`;
      break;

    case 'lettersOnly':
      compiledPayload += `
			${getIfCondition(
        block,
        inputBlock.data.name,
        blocks[outputBlockId].data.name,
        fallBackBlock,
        'lettersOnly'
      )}`;
      break;
  }

  return compiledPayload;
};

const getIfCondition = (
  block: ItemDataFlow,
  blockName: string,
  destinationBlockName: string,
  fallBackBlock: string,
  msgType: MessageType
) => {
  const groupName = block.data.groupId;
  const varValue = block.data.varValue || 'vars.resposta';
  const errorMessage = block.data.sendUser?.messageDidNotUnderstood || '';
  let condition = '';
  const regex = getRegex(msgType);

  const validationFuncion =
    msgType === 'cpf'
      ? 'isValidCPF'
      : msgType === 'cnpj'
      ? 'isValidCNPJ'
      : msgType === 'cep'
      ? 'isValidCEP'
      : '';

  if (validationFuncion !== '') {
    condition = `
			const regexResult = user.input.match(${regex});
			input = regexResult;
			${varValue} = regexResult;

			if (input !== null) {
				${msgType} = (await ${validationFuncion}(input.toString()));\n
				if (${msgType}) {
					${varValue} = ${msgType};
					vars.contagem == null;
					event('${groupName}_${destinationBlockName}');
				} else {
					${getCountCondition(errorMessage, groupName, blockName, fallBackBlock)}
				}		
			} else {
				${getCountCondition(errorMessage, groupName, blockName, fallBackBlock)}
			}
		`;
  } else if (msgType === 'location') {
    condition += `
		regexResult = ${regex}.exec(user.input);
		if (regexResult) {
			lat = user.input.substring(0, user.input.indexOf("|"));
			long = user.input.substring(user.input.indexOf("|") + 1, user.input.length);
			ret = await getAddress(long, lat);
			if (ret) {
				${varValue} = ret;
				vars.contagem == null;
				event('${groupName}_${destinationBlockName}');
			}
			else {
				${getCountCondition(
          `Erro ao buscar sua Localização.\n\n ${errorMessage}`,
          groupName,
          blockName,
          fallBackBlock
        )}
			}		
		}
		else {
			${getCountCondition(errorMessage, groupName, blockName, fallBackBlock)}
		}		
		`;
  } else {
    condition += ` 
			regexResult = ${regex}.exec(user.input);
			if (regexResult) {
				${varValue} = regexResult[0].toString();
				vars.contagem == null;
				event('${groupName}_${destinationBlockName}');
			} else {
				${getCountCondition(errorMessage, groupName, blockName, fallBackBlock)}
			}		
		`;
  }

  return condition;
};

const getCountCondition = (
  errorMessage: string,
  groupName: string,
  blockName: string,
  fallBackBlock?: string
) => {
  const fallBackEvent = fallBackBlock
    ? `event('${groupName}_${fallBackBlock}');`
    : blockName !== ''
    ? `event('${groupName}_${blockName}');`
    : '';

  const errorEvent =
    errorMessage !== '' ? `msg(\`${errorMessage}\`, 'text');` : '';

  return `
	if (vars.contagem >= 2) {
		vars.contagem = null;
		${errorEvent}
		event("999-did-not-understood");
	} else {
		vars.contagem = vars.contagem + 1;
		${errorEvent}
		${fallBackEvent}
	}
	`;
};

export default compileFlow;

// const getDefaultErrorMessage = (msgType: MessageType) => {
//   switch (msgType) {
//     case 'text':
//       return 'Digite um texto válido';
//     case 'anyNumber':
//       return 'Digite um número válido';
//     case 'email':
//       return 'Digite um email válido';
//     case 'phone':
//       return 'Digite um telefone válido';
//     case 'password':
//       return 'Digite uma senha válida';
//     case 'money':
//       return 'Digite um valor monetário válido';
//     case 'url':
//       return 'Digite uma url válida';
//     case 'uuid':
//       return 'Digite um uuid válido';
//     case 'lettersOnly':
//       return 'Digite somente letras';
//     case 'cep':
//       return 'Digite um CEP válido';
//     case 'cpf':
//       return 'Digite um CPF válido';
//     case 'cnpj':
//       return 'Digite um CNPJ válido';
//     case 'data':
//       return 'Digite uma data válida';
//     case 'location':
//       return 'Digite uma localização válida';

//     default:
//       return 'Não entendi';
//   }
// };
