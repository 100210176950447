import styled from 'styled-components';
import MttButton from 'components/Material/MttButton/MttButton';
import MttGrid from 'components/Material/MttGrid/MttGrid';

import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';

export const ContainerDialog = styled(MttGrid)<{ colorHeader?: string }>`
  width: 30vw;
  height: 100%;

  /* padding: 8px 8px; */
`;

export const ContainerHeader = styled(MttGrid)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Close = styled(MttGrid)`
  display: flex;
  justify-content: flex-end;
  max-height: 10px;
`;

export const ModalTitle = styled(Typography).attrs({
  variant: 'h6',
})`
  color: #6c50a4;
  padding: 10px;
`;

export const FieldContainer = styled(Grid)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ul {
    list-style-type: none;
  }

  p {
    padding: 5px;
    text-align: center;
  }
`;

export const ButtonContainer = styled(Grid)`
  padding: 30px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  button {
    margin: 5px;
    width: 100%;
    max-width: 150px;
  }
`;

export const Button = styled(MttButton)`
  /* height: 50px; */
  /* margin-top: 10px; */
`;

export const LineContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const NewKeyContainer = styled.div`
  margin-top: 40px;
  width: 100%;

  .MuiInputBase-input {
    outline: 0;
  }

  .MuiInputBase-input::-webkit-input-placeholder {
    opacity: 0.65;
    font-weight: 500;
  }
`;

export const TextContainer = styled.div`
  height: 64px;

  margin-right: 10px;
  width: 100%;
`;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative',
      background: theme.palette.primary.main,
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    dialog: {
      background: '#F2F2F2',
    },

    input: {
      background: '#FFFFFF',
    },
  })
);
