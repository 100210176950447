import { IPermissionsContextActions, IPermissionsContextState } from './types';

export const initialState = {
  isRoot: false,
  companies: [],
} as IPermissionsContextState;

export function permissionsReducer(
  state: IPermissionsContextState,
  action: IPermissionsContextActions
) {
  switch (action.type) {
    case 'isRoot':
      return functionsReducer[action.type](state);

    case 'isNotRoot':
      return functionsReducer[action.type](state);

    case 'updatePermissions':
      return functionsReducer[action.type](state, action.data);

    default:
      return state;
  }
}

const functionsReducer = {
  isRoot: (state: IPermissionsContextState) => {
    return { ...state, isRoot: true };
  },

  isNotRoot: (state: IPermissionsContextState) => {
    return { ...state, isRoot: false };
  },

  updatePermissions: (
    state: IPermissionsContextState,
    data?: Partial<IPermissionsContextState>
  ) => {
    if (data && data.companies) {
      return { ...state, companies: data.companies };
    }
    return { ...state };
  },
};
