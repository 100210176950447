import { Grid, makeStyles, Theme } from '@material-ui/core';
import styled from 'styled-components';
import MttGrid from '../../../../components/Material/MttGrid/MttGrid';

export const ContainerDialog = styled(MttGrid)`
  width: 100%;
  height: 100%;

  position: relative;
  /* padding-top: 54px; */

  overflow: hidden;

  .MuiAppBar-colorPrimary {
    background-color: #6c50a4;
    position: absolute;
  }
`;

export const ContentContainer = styled(Grid)`
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin-top: 54px;
`;

const Headers = styled.div`
  /* padding: 16px; */

  /* margin-top: 14px; */
  width: 100%;
  background-color: #f2f2f2;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const IntentInfo = styled(Headers)`
  /* max-width: 28%; */
`;

export const ClientInfo = styled(Headers)`
  /* max-width: 72%; */
`;

export const MsgFieldContainer = styled.div`
  position: relative;
  left: 30px;

  p {
    color: #6c50a4;
    font-weight: bold;
    font-size: 1.1rem;
  }
`;

export const MessagesContainer = styled.div`
  width: 100%;
  max-height: 74vh;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }
`;

export const GridInfo = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const DateChip = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    background: #6c50a459;
    max-width: 150px;
    padding: 8px 16px;
    margin: 16px;
    border-radius: 8px;
    font-weight: 500;
    /* border: 1px solid #a6a6a6; */
  }
`;

export const useStyles = makeStyles((theme: Theme) => ({
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  userMsg: {
    maxWidth: '50%',
    padding: 8,
    margin: '8px',
    background: '#E5E4E4',
    borderRadius: 8,
    borderTopRightRadius: 0,
  },
  botMsg: {
    maxWidth: '50%',
    margin: 8,
    background: '#f4f4f4',
    padding: 8,
    borderRadius: 8,
    borderTopLeftRadius: 0,
  },
  modal: {
    height: 'inherit',
  },
  msgContainer: {
    height: 'fit-content',
    border: 'none',
  },
  selectedMsg: {
    border: '2px solid #ff14149e',
    background: '#ff00001f',
    margin: 8,
  },
  msg: {
    wordWrap: 'break-word',
    fontWeight: 500,
    margin: 0,
    padding: '0px 10px',
  },
  msgHour: {
    wordWrap: 'break-word',
    fontSize: 10,
    textAlign: 'end',
    fontWeight: 500,
    margin: '0 0 2px',
    padding: '0 7px 0 10px',
  },
  intentGrid: {
    width: '100%',
  },
  intentMsg: {
    width: '180px',
    borderRadius: 8,
    textAlign: 'center',
  },
  regularGrid: {
    // width: '72%',
  },
  loadMessagesButton: {
    background: '#0000000a',
    display: 'flex',
    alignItems: 'center',

    '&:hover': {
      background: '#c3c2c2',
    },
  },
}));
