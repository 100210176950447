import { IEntity } from 'models/Entity';
import { GetKeys, UpdateKeys } from 'services/AccessKeysService';
import { SaveEntities } from 'services/EntityService';
import { emptyFlow } from 'contexts/Flow/staticData';
import {
  SaveGroup,
  UpdateGroup,
  DeleteGroup,
} from 'services/FlowService';
import { Key } from 'services/AccessKeysService/types';
import { IAppContextActions } from 'contexts/App/types';

interface IExportGroup {
  groupName: string;
  blocks: any;
}

async function updatePrincipalGroup(
  botName: string,
  groups: IExportGroup[],
  dispatch: React.Dispatch<IAppContextActions>
) {
  const errors: string[] = [];
  const principal = groups.find((group) => group.groupName === 'principal');

  if (principal) {
    const blocks = principal.blocks;
    const updatePrincipalResponse = await UpdateGroup(
      {
        bot_name: botName || '',
        group_name: principal.groupName,
        blocks,
      },
      dispatch
    );
    if (!updatePrincipalResponse.Success)
      errors.push(
        updatePrincipalResponse.Message || 'Erro ao atualizar o grupo principal'
      );
  } else errors.push('Grupo principal não encontrado.');

  return errors;
}

async function createAndUpdateGroups(
  botName: string,
  groups: IExportGroup[],
  groupsNames: string[],
  dispatch: React.Dispatch<IAppContextActions>
) {
  const errors: string[] = [];

  const groupsToCreate = groups.filter(
    (group) => !groupsNames.map((g) => g).includes(group.groupName)
  );

  const groupsToUpdate = groups.filter(
    (group) => groupsNames.map((g) => g).includes(group.groupName)
  );

  groupsToCreate.map(async (group) => {
    if (group.groupName !== 'principal') {
      const drawflow = emptyFlow;

      const createGroupResult = await SaveGroup(
        {
          bot_name: botName,
          group_name: group.groupName,
          request: {
            groups: [
              {
                drawflow,
                groupId: group.groupName,
                groupName: group.groupName,
                bot: botName,
              },
            ],
          },
        },
        dispatch
      );
      if (!createGroupResult.Success) {
        errors.push(
          createGroupResult.Message ||
            `Erro ao criar o grupo "${group.groupName}".`
        );
      } else {
				const keys = Object.keys(group.blocks);
        const updateGroupResult = await UpdateGroup(
          {
            bot_name: botName,
            group_name: group.groupName,
            blocks: keys.length === 0 ? drawflow : group.blocks,
          },
          dispatch
        );

        if (!updateGroupResult.Success) {
          errors.push(
            updateGroupResult.Message ||
              `Erro ao atualizar o grupo "${group.groupName}".`
          );
        }
      }
    }
  });

  groupsToUpdate.map(async (group) => {
    if (group.groupName !== 'principal') {
      const updateGroupResult = await UpdateGroup(
        {
          bot_name: botName,
          group_name: group.groupName,
          blocks: group.blocks,
        },
        dispatch
      );

      if (!updateGroupResult.Success) {
        errors.push(
          updateGroupResult.Message ||
            `Erro ao atualizar o grupo "${group.groupName}".`
        );
      }
    }
  });

  return errors;
}

async function removeNonExistingGroups(
  botName: string,
  groups: IExportGroup[],
  groupsNames: string[],
  dispatch: React.Dispatch<IAppContextActions>
) {
  const errors: string[] = [];
  const groupsToRemove = groupsNames.filter(
    (group) => !groups.map((g) => g.groupName).includes(group)
  );

  groupsToRemove.map(async (group) => {
    if (group !== 'principal') {
      const deleteGroupResponse = await DeleteGroup(
        {
          bot_name: botName,
          group_name: group,
          group_name_confirmation: group,
        },
        dispatch
      );

      if (!deleteGroupResponse.Success)
        errors.push(
          deleteGroupResponse.Message || 'Erro ao remover os grupos atuais.'
        );
    }
  });

  return errors;
}

async function updateKeys(
  botName: string,
  importKeys: Key[],
  dispatch: React.Dispatch<IAppContextActions>
) {
  const errors = [];
  const getKeysResponse = await GetKeys(botName, dispatch);

  if (getKeysResponse.Success) {
    const keys: Key = {};
    let keysAndValues: Key = {};
    if (getKeysResponse.Data.data) {
      keysAndValues = getKeysResponse.Data.data.keys;
    }
    const currentKeys = Object.keys(keysAndValues);
    if (currentKeys) {
      let currentBotValues: Key[] = [];
      for (const key of currentKeys) {
        const element = keysAndValues[key as any];
        currentBotValues.push({ key, value: element });
      }

      const jsonKeys = Object.keys(importKeys);
      let jsonValues: Key[] = [];
      for (const key of jsonKeys) {
        const element = importKeys[key as any];
        jsonValues.push({ key, value: element });
      }

      jsonValues.map((value) => {
        const importedKeyExists = currentBotValues.find(
          (curr) => curr.key === value.key
        );
        if (!importedKeyExists) {
          currentBotValues.push({ key: value.key, value: '' });
        }
        return jsonValues;
      });

      for (let index = 0; index < currentBotValues.length; index++) {
        const indexKey = currentBotValues[index].key;
        const indexValue = currentBotValues[index].value;
        keys[indexKey] = indexValue;
      }
      const resultUpdateKeys = await UpdateKeys({ botName, keys }, dispatch);
      if (!resultUpdateKeys.Success)
        errors.push(
          resultUpdateKeys.Message || 'Erro ao atualizar as chaves de acesso.'
        );
    }
  } else
    errors.push(
      getKeysResponse.Message || 'Erro ao atualizar as chaves de acesso.'
    );

  return errors;
}

async function updateEntities(
  botName: string,
  entities: IEntity[],
  dispatch: React.Dispatch<IAppContextActions>
) {
  const errors = [];
  const saveEntitiesResponse = await SaveEntities(
    {
      bot_name: botName,
      entities,
    },
    dispatch
  );

  if (!saveEntitiesResponse.Success)
    errors.push(
      saveEntitiesResponse.Message || 'Erro ao atualizar as variávies.'
    );
  return errors;
}

export {
  createAndUpdateGroups,
  removeNonExistingGroups,
  updateKeys,
  updateEntities,
  updatePrincipalGroup,
};
