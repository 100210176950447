import { Grid, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import MttGrid from '../Material/MttGrid/MttGrid';

export const ContainerDialog = styled(MttGrid)<{ colorheader?: string }>`
  width: 100%;
  height: 100%;

  position: relative;
  padding-top: 54px;

  .MuiAppBar-colorPrimary {
    background-color: ${(props) =>
      props.colorheader ? `#${props.colorheader}` : '#6c50a4'};
    position: absolute;
  }
`;

export const ContainerImage = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e9e9e9;
`;

export const ModalTitle = styled(Typography).attrs({
  variant: 'h5',
})`
  color: #656565;
`;

export const ModalFooter = styled(Typography).attrs({})`
  display: flex;
  text-align: center;
`;

export const ContainerFooter = styled.div`
  align-items: center;
  justify-content: center;
  margin: 0 24px 16px 24px;

  .MuiTypography-body1 {
    color: #808080;
  }
`;

export const ContainerMessage = styled.div`
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;

  .MuiTypography-body1 {
    color: #6c50a4;
    font-weight: 500;
  }
`;

export const ModalMessage = styled(Typography).attrs({})`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  .MuiDialog-container {
    .MuiDialog-paper: {
      box-shadow: 10px 5px 5px black;
    }
  }
`;

export const useStyles = makeStyles(() =>
  createStyles({
    grid: {
      alignItems: 'center',
      backgroundColor: '#e9e9e9',
      padding: '24px 24px 0px 24px',
      minWidth: '400px',
    },
    img: {
      maxWidth: 300,
      maxHeight: 350,
      borderRadius: '50%',
      height: 275,
      width: 300,
    },
  })
);
