import styled from 'styled-components';
import MttGrid from 'components/Material/MttGrid/MttGrid';
import { Grid, createStyles, makeStyles } from '@material-ui/core';

export const ContainerDialog = styled(MttGrid)<{ colorHeader?: string }>`
  width: 100%;
  height: 100%;

  padding-top: 64px;

  overflow: hidden;

  .MuiAppBar-colorPrimary {
    background-color: #6c50a4;
    position: absolute;
  }

  button {
    padding: 0;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  overflow-y: auto;
  padding: 16px;

  min-height: 300px;
  overflow-wrap: break-word;
`;

export const GridInfo = styled(Grid)`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  height: 100%;
  margin-top: -24px;

  p {
    margin: 16px;
    text-align: center;
  }

  button {
    letter-spacing: 2px;
  }
`;

export const TokenExistsContainer = styled.div`
  max-width: 600px;
  word-break: break-all;
  flex-direction: column;

  text-align: center;

  p {
    margin: 16px;
  }

  button {
    letter-spacing: 1px;
		display: flex;
		align-items: center;

		svg {
			margin-left: 6px;
		}
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 12px;

  gap: 8px;
`;

export const GeneratingTokenContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;

  gap: 8px;
`;

export const useStyles = makeStyles(() =>
  createStyles({
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    dialog: {
      background: '#F2F2F2',
      overflowY: 'hidden',
    },
    input: {
      background: '#FFFFFF',
    },
  })
);
