import * as yup from 'yup';
import { TFormErrors } from '../contexts/Form/types';
import EIntentType from '../enums/EIntentType';
import { DataFlow, DataForm } from '../models/DataFlow';
import DataFormModel from '../models/DataFormModel';
import { infoValidation } from '../models/InfoForm';
import { toNewIntentionValidation } from '../models/ToNewIntention';

interface TranslationErrors {
  intentNameRequired: string;
  intentNameDuplicated: string;
  outputTitleRequired: string;
  outputTitleDuplicated: string;
  totalSum: string;
  formMessage: string;
  entryName: string;
}

export const schema = yup.object().shape({
  name: yup.string().required().max(40),
});
export const formValidators: {
  [key: string]: (
    form: DataForm,
    errorMsg?: TranslationErrors,
    drawflow?: DataFlow,
    nodeId?: number
  ) => Promise<TFormErrors>;
} = {
  Info: async (
    form: DataForm,
    errorMsg?: TranslationErrors,
    drawflow?: DataFlow,
    nodeId?: number
  ): Promise<TFormErrors> => {
    let result: TFormErrors = {};
    if (form) {
      const resultValidation = await infoValidation(
        { ...form },
        errorMsg?.intentNameRequired || ''
      );
      if (!resultValidation.isValid) {
        result = { ...resultValidation.errors };
      } else {
        if (drawflow) {
          const mainDataModel = new DataFormModel({ ...form });
          if (!mainDataModel.isChild() && !mainDataModel.isOthers()) {
            const keys = Object.keys(drawflow).filter((key) => {
              if (Number(key) === nodeId) return false;
              const { data } = drawflow[Number(key)];
              const currentdataModel = new DataFormModel(data);
              if (!currentdataModel.isChild() && !currentdataModel.isOthers()) {
                return (
                  currentdataModel.name.toLocaleLowerCase() ===
                  mainDataModel.name.toLocaleLowerCase()
                );
              }
              return false;
            });

            if (!!keys.length) {
              result = { name: errorMsg?.intentNameDuplicated || '' };
            }
          }
        }
      }
    }
    return result;
  },

  ToNewIntention: async (
    form: DataForm,
    errorMsg?: TranslationErrors
  ): Promise<TFormErrors> => {
    let result: TFormErrors = {};
    if (form.linked) {
      const resultValidation = await toNewIntentionValidation(form.linked);
      if (!resultValidation.isValid) {
        result = { ...resultValidation.errors };
      }
    }
    return result;
  },

  OutputIntentName: async (
    form: DataForm,
    errorMsg?: TranslationErrors
  ): Promise<TFormErrors> => {
    let result: TFormErrors = {};
    if (form.outputs) {
      const { outputs } = form;
      for (let index = 0; index < outputs.length; index++) {
        const output = outputs[index];
        const amount = outputs.filter(
          (o) =>
            o.title.trim().toLocaleLowerCase() ===
            output.title.trim().toLocaleLowerCase()
        ).length;

        if (amount > 1) {
          result = {
            ...result,
            [`outputs[${index}].title`]: errorMsg?.outputTitleDuplicated || '',
          };
        }
      }
      for (let index = 0; index < outputs.length; index++) {
        if (outputs[index].title.trim() === '') {
          result = {
            ...result,
            [`outputs[${index}].title`]: errorMsg?.outputTitleRequired || '',
          };
        }
      }
    }
    return result;
  },

  RocketChatId: async (
    form: DataForm,
    errorMsg?: TranslationErrors
  ): Promise<TFormErrors> => {
    let result: TFormErrors = {};

    if (form.rocketChat?.rocketID === '') {
      result = { rocketChatId: 'Rocket Chat ID obrigatório.' };
    }
    return result;
  },

  ABTestValidation: async (
    form: DataForm,
    errorMsg?: TranslationErrors
  ): Promise<TFormErrors> => {
    let result: TFormErrors = {};
    const { outputs } = form;

    if (outputs) {
      for (let index = 0; index < outputs.length; index++) {
        if (outputs[index].value?.trim() === '') {
          result = {
            ...result,
            [`outputs[${index}].value`]: '*O valor da saída não pode ser vazio',
          };
        }
      }

      let sum = 0;
      for (let index = 0; index < outputs.length; index++) {
        if (Number(outputs[index].value)) {
          sum += Number(outputs[index].value);
        }
      }

      if (sum < 100) {
        result = {
          ...result,
          sum: errorMsg?.totalSum,
        };
      }
    }
    return result;
  },

  RulesValidation: async (
    form: DataForm,
    errorMsg?: TranslationErrors
  ): Promise<TFormErrors> => {
    let result: TFormErrors = {};
    const { outputs } = form;

    if (outputs) {
      for (let index = 0; index < outputs.length; index++) {
        const { rules } = outputs[index];

        if (rules) {
          for (let jIndex = 0; jIndex < rules.length; jIndex++) {
            if (rules[jIndex].operator?.trim() === '') {
              result = {
                ...result,
                [`outputs[${index}].rules[${jIndex}].operator`]:
                  '*Escolha uma regra de validação',
              };
            }
          }
        }
      }
    }
    return result;
  },

  RequiredMsg: async (
    form: DataForm,
    errorMsg?: TranslationErrors
  ): Promise<TFormErrors> => {
    let result: TFormErrors = {};
    const msg = form.sendUser?.message;
    if (!msg) {
      result = {
        ...result,
        [`formMsg`]: errorMsg?.formMessage,
      };
    }
    return result;
  },
  RequireEntryName: async (
    form: DataForm,
    errorMsg?: TranslationErrors
  ): Promise<TFormErrors> => {
    let result: TFormErrors = {};
    const msg = form.entryName;
    if (!msg) {
      result = {
        ...result,
        [`entryName`]: errorMsg?.entryName,
      };
    }
    return result;
  },
};

export const getValidatorsIntentType = (intentType: EIntentType) => {
  switch (intentType) {
    case EIntentType.InitialFlow:
      return ['Info'];
    case EIntentType.NewGroup:
      return ['Info', 'ToNewIntention'];
    case EIntentType.CarouselParent:
      return ['Info', 'OutputIntentName'];
    case EIntentType.MultipleChoiceParent:
      return ['Info', 'OutputIntentName'];
    case EIntentType.OptionsBlock:
      return ['Info', 'OutputIntentName', 'RequiredMsg'];
    case EIntentType.Carousel:
      return ['Info', 'OutputIntentName'];
    case EIntentType.Rules:
      return ['Info', 'OutputIntentName'];
    case EIntentType.ABTest:
      return ['Info', 'OutputIntentName', 'ABTestValidation'];
    case EIntentType.Livechat:
      return ['Info', 'RocketChatId'];
    case EIntentType.FlexBlock:
      return ['Info', 'RequireEntryName'];
    default:
      return ['Info'];
  }
};
