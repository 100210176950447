import Drawflow from 'drawflow';
import { DataFlow } from 'models/DataFlow';

export default class GetOffsetPositionFlowController {
  protected editorFlow: Drawflow;

  constructor(editorFlow: Drawflow) {
    this.editorFlow = editorFlow;
  }

  getLastPosition() {
    const data: DataFlow = this.editorFlow.drawflow.drawflow.Home.data;
    const keys = Object.keys(data);
    if (!keys.length) return { pos_x: 0, pos_y: 0 };
    const key = keys[keys.length - 1];
    const node = data[Number(key)];
    const { pos_x, pos_y } = node;
    return { pos_x, pos_y };
  }

  getBiggestPositions(pos_x: number[], pos_y: number[]) {
    if (!pos_x.length || !pos_y.length) return this.getLastPosition();
    const biggestPos_x = Math.min(...pos_x);
    const biggestPos_y = Math.max(...pos_y);
    return { pos_x: biggestPos_x - 400, pos_y: biggestPos_y + 260 };
  }

  getRelativePosition(block: DataFlow) {
    const keys = Object.keys(block);
    const positionsX = keys.map((key) => block[Number(key)].pos_x);
    const positionsY = keys.map((key) => block[Number(key)].pos_y);
    return this.getBiggestPositions(positionsX, positionsY);
  }
}
