import React, { useState } from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import { IExportGroup, ModalBotImportErrorProps } from './types';

import { Dialog, Slide } from '@material-ui/core';

import {
  ContainerDialog,
  ContainerHeader,
  Button,
  ButtonContainer,
  ModalTitle,
  FieldContainer,
  Error,
  useStyles,
} from './styles';
import { CircularProgress } from '@material-ui/core';
import { useFlow } from 'contexts/Flow/flowContext';
import { Key } from 'services/AccessKeysService/types';
import { IEntity } from 'models/Entity';
import {
  updatePrincipalGroup,
  removeNonExistingGroups,
  createAndUpdateGroups,
  updateEntities,
  updateKeys,
} from 'utils/ImportBot/ImportBotService';
import { useApp } from 'contexts/App/appContext';
import getTranslator from 'utils/hooks/Translator';
import flexGroupsTreatment from 'components/Flow/utils/FlexGroupsTreatment';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalBotImportError(props: ModalBotImportErrorProps) {
  const classes = useStyles();
  const { dispatch: dispatchApp } = useApp();
  const { state, toastNotification, loadFlow, dispatch } = useFlow();
  const { groupsNames } = state;
  const { handleClose, errors, open, handleOpen, botToRestore } = props;
  const [load /*setLoad*/] = useState(false);
  const { getTranslation } = getTranslator();

  const handleRecoverBot = async () => {
    if (botToRestore) {
      const drawflow = JSON.parse(botToRestore);
      const botName = drawflow.botName;
      const groups: IExportGroup[] = drawflow.groups;
      const entities: IEntity[] = drawflow.entities;
      const importKeys: Key[] = drawflow.accessKeys;
      handleClose();

      errors.splice(0, errors.length);
      dispatch({ type: 'updateForm', data: { loading: true } });

      const promisesResults = await Promise.all([
        await updatePrincipalGroup(botName || '', groups, dispatchApp),
        await removeNonExistingGroups(
          botName || '',
          groups,
          groupsNames,
          dispatchApp
        ),
        await createAndUpdateGroups(
          botName || '',
          groups,
          groupsNames,
          dispatchApp
        ),
        await updateEntities(botName || '', entities, dispatchApp),
        await updateKeys(botName || '', importKeys, dispatchApp),
      ]);

      errors.push(
        promisesResults[0][0],
        promisesResults[1][0],
        promisesResults[2][0],
        promisesResults[3][0],
        promisesResults[4][0]
      );
      const updatedGroupsNames = groups.map((group) => group.groupName);

      dispatch({
        type: 'updateForm',
        data: { groupsNames: updatedGroupsNames },
      });

      dispatch({ type: 'updateForm', data: { loading: false } });

      const requestErrors = errors
        .filter((error) => (typeof error !== undefined ? error : ''))
        .filter((error) => error !== '');

      if (requestErrors.length <= 0) {
        toastNotification(
          'success',
          getTranslation('toast.success.agentRestore')
        );
        await flexGroupsTreatment(botName || '', groups, dispatchApp);

        await loadFlow(state.groupsNames[0], botName || '');
      } else {
        handleOpen();
      }
    }
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      maxWidth="md"
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <ContainerDialog className={classes.dialog}>
        <ContainerHeader>
          <ModalTitle style={{ fontWeight: 600, fontSize: '1.5rem' }}>
            Erro ao importar Bot!
          </ModalTitle>
        </ContainerHeader>
        <FieldContainer>
          <p>
            {errors && (
              <p>
                Ocorreram os seguintes erros ao importar o bot:
                <ul>
                  <Error>
                    {errors.map((error) => {
                      if (error) {
                        return <li>- {error}</li>;
                      } else return <></>;
                    })}
                  </Error>
                </ul>
              </p>
            )}
            O bot não foi importado corretamente. Como gostaraia de prosseguir?
          </p>
        </FieldContainer>

        <ButtonContainer>
          <Button
            style={{ letterSpacing: 2, borderRadius: 8 }}
            variant="contained"
            color="primary"
            onClick={handleRecoverBot}
          >
            Voltar Bot ao Estado Anterior
            {load && <CircularProgress color="inherit" size={18} />}
          </Button>

          <Button
            style={{ letterSpacing: 2, borderRadius: 8 }}
            variant="contained"
            color="primary"
            onClick={handleClose}
          >
            Fechar
            {load && <CircularProgress color="inherit" size={18} />}
          </Button>
        </ButtonContainer>
      </ContainerDialog>
    </Dialog>
  );
}
