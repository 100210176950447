import styled from "styled-components";
import MttTextField from 'components/Material/MttTextField/MttTextFIeld';
import MttTypography from 'components/Material/MttTypography/MttTypography';
import MttGrid from 'components/Material/MttGrid/MttGrid';
import TextField from '@material-ui/core/TextField/TextField';

export const StyledMttTextField = styled(MttTextField)`
`;

export const StyledMttTypographyIdentificacao = styled(MttTypography).attrs({
	children: 'Variável para identificação no texto do usuário',
	variant: 'h6',
	color: 'primary',
})``;

export const StyledFooterGrid = styled(MttGrid)`
  width: calc(100% - 116px);

  margin: 0 42px;
  padding: 16px;
  border-radius: 4px;

  background-color: #f2f2f2;
`;

export const StyledSwitchGrid = styled(MttGrid)`
  width: 210px;
  display: flex;

  justify-content: space-between;
  align-items: center;
`;

export const StyledMttTypographyPerguntas = styled(MttTypography).attrs({
	children: 'Perguntas obrigatórias',
	variant: 'subtitle2',
	color: 'primary',
})``;

export const StyledGridPerguntas = styled(MttGrid) <{ showgrid?: boolean }>`
  padding-top: 16px;
  display: ${(props) => (props.showgrid ? "flex" : "none")};
  flex-direction: column;
  justify-content: space-between;

  min-height: 128px;
  max-height: 192px;

  overflow-x: hidden;
  overflow-y: auto;

  margin-bottom: 16px;

  div {
    margin-bottom: 8px;
    height: 56px;
  }

  div:last-child{
    margin-bottom: 0px;
  }
`;

export const StyledTextFieldPergunta = styled(TextField).attrs({
	label: "Escreva uma pergunta",
	variant: "outlined",
})`
  background-color: #FFFFFF;
  width:50%;
`;

export const StyledInputsGrid = styled(MttGrid)`

  width: 100%;
  
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px;

  .MuiInputBase-input{
    background-color: #FFFFFF;

    &:focus{
      background-color: #FFFFFF;
    }
  }
`;

export const StyledTitleGrid = styled(MttGrid)`
  display:flex;
  justify-content: space-between;
  margin: 16px 42px 8px 42px; 
`;

export const GridIconDelete = styled(MttGrid)`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 8px;
  cursor: pointer;
`;

export const StyledComponent = styled(MttGrid)`
  display: flex;
`;

export const GridStyledManyOptions = styled(MttGrid)`
  margin-bottom: 8px;
`;