import {
  addSeconds,
  fromUnixTime,
  differenceInSeconds,
  isFuture,
} from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import jwt_decode from 'jwt-decode';
import { IAppContextActions } from 'contexts/App/types';
import { LogOut, RefreshUserToken } from '../AuthService';
import { IMethod } from './Headers';
import { IBaseResponse, MessageError } from './Response';

const apiUrl = process.env.REACT_APP_URL;
const campaignApiKey = process.env.REACT_APP_CAMPAIGN_EXECUTE_API_KEY || '';
const headers = new Headers();

const getRequestInit = <TBody>(method: IMethod, body?: TBody): RequestInit => {
  return {
    method,
    headers: headers,
    cache: 'no-cache',
    body: JSON.stringify(body),
  };
};

const sendRequest = (
  url: string,
  requestInit: RequestInit
): Promise<Response> => fetch(apiUrl + url, requestInit);

export const appendHeader = (prop: string, value: string) => {
  if (headers.has(prop)) {
    headers.set(prop, value);
  } else {
    headers.append(prop, value);
  }
};

const handleError = (resultError: MessageError) => {
  if (resultError && resultError.detail && resultError.detail.errors) {
    const { errors } = resultError.detail;
    return !!errors.length ? errors[0].detail : '';
  }
  return '';
};

const SECONDS_TO_REFRESH_TOKEN = 600;

async function getToken() {
  const token = localStorage.getItem('token');
  const refreshToken = localStorage.getItem('refresh_token');
  let authorizedToken: string | boolean = false;

  if (!!refreshToken) {
    const dateToExpiration = getExpirationDateFromToken(refreshToken);
    const now = addSeconds(new Date(), SECONDS_TO_REFRESH_TOKEN);

    if (differenceInSeconds(dateToExpiration, now) < SECONDS_TO_REFRESH_TOKEN) {
      const refreshTokenResponse = await getRefreshToken();
      authorizedToken = refreshTokenResponse?.token ?? false;
    }
  }

  if (!!token) {
    const dateToExpiration = getExpirationDateFromToken(token);

    if (!isFuture(dateToExpiration)) {
      const refreshTokenResponse = await getRefreshToken();
      authorizedToken = refreshTokenResponse?.token ?? false;
    } else {
      authorizedToken = token;
    }
  }

  return authorizedToken;
}

function getExpirationDateFromToken(token: string) {
  const { exp }: any = jwt_decode(token);
  const dateToExpiration = fromUnixTime(exp);

  return zonedTimeToUtc(dateToExpiration, 'America/Sao_Paulo');
}

async function getRefreshToken() {
  const responseRefreshToken = await RefreshUserToken();
  if (!!responseRefreshToken) {
    localStorage.setItem('token', responseRefreshToken.token);
    localStorage.setItem('refresh_token', responseRefreshToken.refresh_token);

    return responseRefreshToken;
  }
}

async function removeSignedToken(dispatch: React.Dispatch<IAppContextActions>) {
  await LogOut();

  dispatch({
    type: 'updateApp',
    data: { signed: false },
  });
}

interface THeaders {
  access_key: string;
  secret_key: string;
}

export const json = async <TResponse, TRequest = unknown>(
  method: IMethod,
  url: string,
  dispatch: React.Dispatch<IAppContextActions>,
  body?: TRequest,
  headers?: THeaders
): Promise<IBaseResponse<TResponse>> => {
  try {
    const token = await getToken();

    if (!token) {
      await removeSignedToken(dispatch);
    }

    if (!!token) {
      appendHeader('Content-Type', 'application/json');
      appendHeader('Authorization', token);
    }

    if (/\/execute/.test(url)) {
      appendHeader('x-api-key', campaignApiKey);
    }

    if (/falazap\/identity-token/.test(url) && headers) {
      appendHeader(`x-access-key`, headers.access_key);
      appendHeader(`x-secret-key`, headers.secret_key);
    }

    const requestInit = getRequestInit<TRequest>(method, body);
    const result = await sendRequest(url, requestInit);

    if (!result.ok) {
      const errors: MessageError = await result.json();
      throw new Error(handleError(errors));
    }

    const Data: TResponse = await result.json();
    return {
      Data,
      StatusCode: result.status,
      Success: true,
      Message: '',
    };
  } catch (error: any) {
    return {
      Data: {} as TResponse,
      StatusCode: 400,
      Success: false,
      Message: error.message,
    };
  }
};
