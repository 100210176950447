import { useForm } from 'contexts/Form/formContext';
import InputCustom from '../../Constructors/Input';
import Select from '../../Constructors/Select';
import Checkbox from 'components/Constructors/Checkbox';
import styles from './styles';
import { propsData } from './types';
import { useCallback, useEffect, useState } from 'react';
import { useFlow } from 'contexts/Flow/flowContext';
import { IEntity } from 'models/Entity';

const {
  Container,
  Content,
  Line,
  Questions,
  QuestionsAside,
  QuestionsContent,
} = styles;

export default function Variables(props: propsData) {
  const { botName } = useFlow().state;
  const { state, dispatch } = useForm();
  const { dataForm } = state;
  const [types, setTypes] = useState<{ id: string; name: string }[]>([]);

  useEffect(() => {
    let allTypes = [
      { id: '@sys.any', name: 'qualquer-input' },
      { id: '@sys.email', name: 'input-de-email' },
      { id: '@sys.number', name: 'input-de-numero' },
      { id: '@sys.date-time', name: 'input-de-data' },
      { id: '@sys.url', name: 'input-de-url' },
    ];

    if (botName) {
      const entitiesJson = localStorage.getItem(`Entities&&${botName}`);
      if (entitiesJson) {
        const entities: IEntity[] = JSON.parse(entitiesJson);

        allTypes = [
          ...allTypes,
          ...entities.map((entity) => ({
            id: entity.name,
            name: entity.name,
          })),
        ].sort(function (a, b) {
          return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
        });

        allTypes.map((type) => {
          if (type.id.substring(0, 1) !== '@') {
            const insertAt = `${type.id.slice(0, 0)}${'@'}${type.id.slice(0)}`;
            let filteredValue = insertAt;
            type.id = filteredValue;
          }
          return allTypes;
        });
      }
    }

    setTypes(allTypes);
  }, [botName]);

  const { showTitle = false, showQuestions = true, index } = props;
  const variable = dataForm?.inputs?.variables[index];

  const load = useCallback(() => {
    if (dataForm && dataForm.inputs && !dataForm.inputs.variables.length) {
      dataForm.inputs = {
        userSentences: ['', '', ''],
        variables: [
          {
            name: '',
            vartype: '',
            questions: ['', '', ''],
            required: false,
            value: '',
            defaultValue: '',
            isList: false,
          },
        ],
      };
      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
  }, [dataForm, dispatch]);

  useEffect(() => {
    load();
  }, [load]);

  const handleChangeInput =
    (key: 'value' | 'name' | 'vartype') =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (dataForm && variable) {
        const value = e.target.value;
        variable[key] = value;
        dispatch({
          type: 'updateForm',
          data: { dataForm },
        });
      }
    };

  const handleQuestion = (value: string, index: number) => {
    if (variable && dataForm) {
      variable.questions[index] = value;
      dispatch({
        type: 'updateForm',
        data: { dataForm: { ...dataForm } },
      });
    }
  };

  const handleChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (dataForm && variable) {
      const checked = e.target.checked;
      variable.required = checked;
      dispatch({
        type: 'updateForm',
        data: { dataForm: { ...dataForm } },
      });
    }
  };

  return (
    <Container>
      {showTitle && <span>Variável para identificar no texto do usuário</span>}
      <Content>
        <Line>
          <InputCustom
            props={{
              name: 'name-input',
              type: 'text',
              value: variable?.name,
              onChange: handleChangeInput('name'),
            }}
          >
            Nome
          </InputCustom>

          <Select
            name={`select-type`}
            options={types}
            value={variable?.vartype}
            onChange={handleChangeInput('vartype')}
          >
            Tipo
          </Select>

          <InputCustom
            props={{
              name: 'value-input',
              type: 'text',
              value: variable?.value,
              onChange: handleChangeInput('value'),
            }}
          >
            Valor
          </InputCustom>
        </Line>

        {showQuestions && (
          <Questions>
            <QuestionsAside>
              <span>Perguntas(caso não identifique)</span>
              <div style={{ alignItems: 'center', justifyContent: 'center' }}>
                <Checkbox
                  color="secondary"
                  onChange={handleChangeCheckbox}
                  checked={variable?.required ? true : false}
                />
                Obrigatória
              </div>
            </QuestionsAside>

            <QuestionsContent>
              {variable?.questions.map((value, index) => {
                return (
                  <label htmlFor={`question-input-${index}`}>
                    <InputCustom
                      props={{
                        name: `question-input-${index}`,
                        type: 'text',
                        value: value,
                        onChange: (e: any) =>
                          handleQuestion(e.target.value, index),
                      }}
                      id={`question-input-${index}`}
                    />
                  </label>
                );
              })}
            </QuestionsContent>
          </Questions>
        )}
      </Content>
    </Container>
  );
}
