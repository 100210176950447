import {
  TextField,
  IconButton,
  AppBar,
  Button,
  Container,
  Dialog,
  Toolbar,
  CircularProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { useCompanies } from 'contexts/Company';
import { ContainerDialog } from '../../styles';
import useTranslator from 'utils/hooks/Translator';
import {
  CreateProject,
  GetAllProjects,
  UpdateProject,
} from 'services/CompaniesService/Projects';
import { useApp } from 'contexts/App/appContext';
import { ManageAgents } from '../../ManageAgents';
import { ButtonContainer } from '../styles';
import * as S from './styles';
import { useFlow } from 'contexts/Flow/flowContext';
import { ManageUsers } from '../../ManageUsers';
import { useEffect, useMemo, useState } from 'react';
import {
  CustomPaper,
  FormsContainer,
} from 'components/ModalManageCompanies/styles';
import { ProjectGroups } from './ProjectGroups';
import { GetCompany } from 'services/CompaniesService/Company';
import { Company } from 'contexts/Company/types';
import { arraysAreEqual } from 'utils/arraysAreEqual';

interface Props {
  handleClose: () => void;
  editProject: boolean;
}
interface Errors {
  name: string;
}

export function ManageProject({ handleClose, editProject }: Props) {
  const { toastNotification } = useFlow();
  const { state, dispatch, setCompany } = useCompanies();
  const { dispatch: dispatchApp } = useApp();
  const { company, project, projectCopy } = state;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Errors>({ name: '' });
  const { getTranslation } = useTranslator();
  const [nameProject, setNameProject] = useState(project.name);
  const [agents, setAgents] = useState(['']);
  const [users, setUsers] = useState(['']);
  const classes = S.useStyles();
  const hasUserChanges = !arraysAreEqual(projectCopy.users, project.users);
  const hasAgentChanges = !arraysAreEqual(projectCopy.agents, project.agents);
  const hasGroupChanges = !arraysAreEqual(projectCopy.groups, project.groups);

  const hasChanges = useMemo(
    () =>
      project.name !== nameProject ||
      hasUserChanges ||
      hasAgentChanges ||
      hasGroupChanges,
    [project, nameProject, hasUserChanges, hasAgentChanges, hasGroupChanges]
  );

  useEffect(() => {
    if (!project._id) {
      dispatch({
        type: 'setProject',
        data: { project: { ...project, company: company.name || '' } },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function handleValidate(): boolean {
    if (!nameProject) {
      setError((prev) => ({
        ...prev,
        name: getTranslation('validations.required', {
          field: getTranslation('name'),
        }),
      }));
      return true;
    }
    return false;
  }
  async function handleFetchAndSetProjects() {
    const response = await GetAllProjects(company.name, dispatchApp);
    if (response.Success) {
      const namesProjects = response.Data.data.map((project) => project.name);
      setCompany(
        {
          ...company,
          projects: namesProjects,
        },
        true
      );
    } else {
      toastNotification(
        'error',
        getTranslation('modal.manageCompany.project.fetchError')
      );
    }
  }

  async function handleCreateOrUpdateProject() {
    if (handleValidate()) return;
    setNameProject(nameProject.trim());
    setLoading(true);
    const response = await getResponseToCreateOrEditProject();

    if (response.Success) {
      const companyProjects = company.projects;
      if (!companyProjects) return;

      await handleFetchAndSetProjects();
      dispatch({
        type: 'setProjectCopy',
        data: { projectCopy: project },
      });
      if (project._id) {
        toastNotification(
          'success',
          getTranslation('modal.manageCompany.project.updateSuccess')
        );
      } else {
        toastNotification(
          'success',
          getTranslation('modal.manageCompany.project.creationSuccess')
        );
      }

      const savedProject = response.Data;
      dispatch({
        type: 'setProject',
        data: { project: { ...savedProject } },
      });
    } else {
      if (project._id) {
        toastNotification(
          'error',
          getTranslation('modal.manageCompany.project.updateError')
        );
      } else {
        toastNotification(
          'error',
          getTranslation('modal.manageCompany.project.creationError')
        );
      }
    }
    setLoading(false);
  }

  async function getResponseToCreateOrEditProject() {
    if (!project._id)
      return await CreateProject(
        { ...project, name: nameProject.trim() },
        dispatchApp
      );
    else
      return await UpdateProject(
        { ...project, name: nameProject.trim() },
        dispatchApp
      );
  }

  async function handleGetCompany() {
    const response = await GetCompany(company.name, dispatchApp);
    if (response.Success) {
      const companyResponse = response.Data as unknown as Company;
      setAgents(companyResponse.agents);
      setUsers(companyResponse.users.concat(companyResponse.admins));
    }
  }
  function handleChangeProjectName(name: string) {
    if (!name.includes('/')) {
      setError({
        name: '',
      });
      setNameProject(name);
    }
  }
  useEffect(() => {
    handleGetCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editProject]);

  return (
    <Dialog
      open
      fullScreen
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <ContainerDialog className={classes.dialog}>
        <AppBar>
          <Toolbar className={classes.toolbar}>
            <S.ModalTitle style={{ fontWeight: 600 }}>
              {project._id ? getTranslation('edit') : getTranslation('create')}{' '}
              {getTranslation('project')}
            </S.ModalTitle>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Container className={classes.container}>
          <FormsContainer onSubmit={(e) => e.preventDefault()}>
            <CustomPaper>
              <TextField
                error={!!error.name}
                helperText={error.name}
                label={getTranslation('name')}
                value={nameProject}
                onChange={(e) => {
                  handleChangeProjectName(e.target.value);
                }}
              />
            </CustomPaper>

            <S.ChildrenContainer>
              <ManageUsers
                currentUsers={project.users || []}
                sourceUsers={users}
                objectToEdit="project"
              />
              <ManageAgents
                objectToEdit="project"
                sourceAgents={agents}
                currentAgents={project.agents}
              />
              <ProjectGroups />
            </S.ChildrenContainer>
          </FormsContainer>
          <ButtonContainer>
            <Button
              disabled={!hasChanges}
              style={{ letterSpacing: 2 }}
              variant="contained"
              color="primary"
              onClick={() => handleCreateOrUpdateProject()}
            >
              {getTranslation('save')}
              {loading && (
                <CircularProgress size={18} style={{ marginLeft: '12px' }} />
              )}
            </Button>
          </ButtonContainer>
        </Container>
      </ContainerDialog>
    </Dialog>
  );
}
