import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CloseIcon from '@material-ui/icons/Close';
import Paper from '@material-ui/core/Paper';
import './styles.css';
import { ModalConversationsProps } from './types';
import { GetConversations } from 'services/ConversationService';
import { useFlow } from 'contexts/Flow/flowContext';
import { Conversation } from 'models/Conversation';
import {
  AppBar,
  // Button,
  CircularProgress,
  Grid,
  IconButton,
  TableFooter,
  TablePagination,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ModalCustomer from '../ModalCustomer';
import { formatDateHour } from 'utils/Date';
import { useApp } from 'contexts/App/appContext';
import useTranslator from 'utils/hooks/Translator';
import * as S from './style';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalConversations(props: ModalConversationsProps) {
  const classes = S.useStyles();
  const { botName } = useFlow().state;
  const { open, handleClose } = props;
  const { dispatch: dispatchApp } = useApp();
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [customer, setCustomer] = useState('');
  const [loading, setLoading] = useState(false);
  const [customerId, setCustomerId] = useState('');
  const [error, setError] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { getTranslation } = useTranslator();

  const filteredConversations =
    customerId !== ''
      ? conversations.filter(
          (conversation) => conversation.customer === customerId
        )
      : conversations;

  useEffect(() => {
    async function load() {
      setLoading(true);
      const bot_name = botName || '';
      const result = await GetConversations({ bot_name }, dispatchApp);

      if (result.Success) {
        setConversations(result.Data.data);
      } else {
        setConversations([]);
      }
      setLoading(false);
    }
    if (open) load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, botName]);

  const handleDetails = (currentCustomer: string) => () => {
    setCustomer(currentCustomer);
    setOpenModal(true);
  };

  const handleChangePage = (
    event: any,
    newPage: React.SetStateAction<number>
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e: {
    target: { value: string | number };
  }) => {
    setRowsPerPage(+e.target.value);
    setPage(0);
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth="md"
      >
        <S.ContainerDialog>
          <AppBar>
            <Toolbar>
              <Typography variant="h6" className={classes.title}>
                {getTranslation('modal.conversationHistory.title')}
              </Typography>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  setPage(0);
                  handleClose();
                }}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>

          {loading ? (
            <Grid
              style={{
                padding: 24,
              }}
            >
              <CircularProgress color="inherit" size={18} />
            </Grid>
          ) : (
            <S.ContentContainer>
              <S.TagsContainer>
                <S.SearchTagContainer>
                  <S.TextContainer>
                    <TextField
                      label={getTranslation('modal.conversationHistory.search')}
                      value={customerId || ''}
                      fullWidth
                      onChange={(e) => setCustomerId(e.target.value)}
                      helperText={error || ''}
                      error={!!error}
                      onBlur={() => setError('')}
                    />
                  </S.TextContainer>
                  {/* <Button
                    variant="contained"
                    color="primary"
                    disabled={customerId === ''}
                  >
                    {getTranslation('search')}
                  </Button> */}
                </S.SearchTagContainer>
              </S.TagsContainer>
              <TableContainer component={Paper}>
                <Table
                  className={classes.table}
                  aria-label="simple table"
                  size="medium"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center"></TableCell>
                      <TableCell>
                        {getTranslation('modal.conversationHistory.client')}
                      </TableCell>
                      <TableCell align="center">
                        {getTranslation('modal.conversationHistory.agent')}
                      </TableCell>
                      <TableCell align="center">
                        {getTranslation('modal.conversationHistory.createdAt')}
                      </TableCell>
                      <TableCell align="center">
                        {getTranslation('modal.conversationHistory.updatedAt')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(rowsPerPage > 0
                      ? filteredConversations.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                      : filteredConversations
                    ).map((row) => (
                      <TableRow key={row.customer}>
                        <TableCell align="center">
                          <IconButton onClick={handleDetails(row.customer)}>
                            <VisibilityIcon />
                          </IconButton>
                        </TableCell>
                        <TableCell scope="row">{row.customer}</TableCell>
                        <TableCell align="center">{row.bot}</TableCell>
                        <TableCell align="center">
                          {formatDateHour(row.createdAt)}
                        </TableCell>
                        <TableCell align="center">
                          {formatDateHour(row.updatedAt)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        count={filteredConversations.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage={getTranslation(
                          'modal.conversationHistory.linesPerPage'
                        )}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </S.ContentContainer>
          )}
        </S.ContainerDialog>
      </Dialog>
      {openModal && (
        <ModalCustomer
          open={openModal}
          bot_name={botName || ''}
          customer={customer}
          handleClose={() => {
            setOpenModal(false);
          }}
        />
      )}
    </>
  );
}
