import Info from 'components/inputs/Material/Info/index';
import MessageForUser from 'components/inputs/Material/MessageForUser/index';
import MultipleChoiceItems from 'components/inputs/Material/MultipleChoiceItems/index';
import FooterButtons from 'components/inputs/Material/FooterButtons/index';
import React from 'react';
import { FormFieldsContainer } from '../Shared/styles';
import MultipleTags from 'components/inputs/Material/MultipleTags';

export default function MultipleChoiceParent() {
  return (
    <>
      <FormFieldsContainer>
        <Info />
        <MultipleTags />
        <MessageForUser />
        <MultipleChoiceItems />
      </FormFieldsContainer>
      <FooterButtons />
    </>
  );
}
