import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'contexts/Form/formContext';
import AddIcon from '@material-ui/icons/Add';
import * as S from './styles';
import MttButton from 'components/Material/MttButton/MttButton';
import { Output } from 'models/DataFlow';
import { Typography } from '@material-ui/core';

import ABTestOutputsSentences from './ABTestOutputsSentences';
import useTranslator from 'utils/hooks/Translator';

export default function ABTestContent() {
  const { state, dispatch } = useForm();
  const { dataForm, formErrors } = state;
  const [sumResults, setSumResults] = useState(
    dataForm?.outputs?.findIndex((o) => !!o.value) !== -1 ? 100 : 0
  );
  const maxValue = 100;
  const { getTranslation } = useTranslator();

  const load = useCallback(() => {
    if (dataForm && dataForm.outputs && dataForm.outputs?.length === 0) {
      dataForm.outputs = [{ title: '', value: '0' }];

      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
  }, [dataForm, dispatch]);

  useEffect(() => {
    load();
  }, [load]);

  useEffect(() => {
    setSumResults(sumResults);
  }, [sumResults]);

  const renderChoice = (choice: Output, index: number) => {
    return (
      <div key={index}>
        <ABTestOutputsSentences
          index={index}
          choice={choice}
          showRemoveButton={showRemoveButton}
          updateSum={updateSum}
        />
      </div>
    );
  };

  const handleAddChoice = () => {
    if (dataForm) {
      if (dataForm.outputs) {
        const other = dataForm.outputs.find(
          (o) => o.title.toLowerCase() === 'outros'
        );
        const newOutputs = dataForm.outputs.filter(
          (o) => o.title.toLowerCase() !== 'outros'
        );
        newOutputs.push({
          title: '',
          value: '0',
        });
        if (other) {
          newOutputs.push(other);
        }
        dataForm.outputs = newOutputs;
      } else {
        dataForm.outputs = [{ title: 'Outros' }];
      }

      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
  };

  const handleChangeValue = (index: number, newValue: string) => {
    if (dataForm && dataForm.outputs && dataForm.outputs[index]) {
      dataForm.outputs[index].value = newValue;

      if (dataForm) {
        dispatch({
          type: 'updateForm',
          data: {
            dataForm: {
              ...dataForm,
            },
          },
        });
      }
    }
  };

  const updateSum = (
    index: number,
    newValue: string,
    operation?: 'sum' | 'subtract'
  ) => {
    if (Number(newValue) < 0) return;
    if (!!dataForm && !!dataForm.outputs) {
      const value = operation === 'sum' ? Number(newValue) : -Number(newValue);
      let currSum = 0;

      if (operation === 'sum') {
        const outputsExceptIndex = dataForm.outputs.filter((o) => {
          if (dataForm.outputs) {
            return o !== dataForm.outputs[index] ? o : null;
          }
          return null;
        });
        currSum = outputsExceptIndex.reduce((a, b) => a + Number(b.value), 0);
      } else {
        currSum = dataForm.outputs.reduce((a, b) => a + Number(b.value), 0);
      }

      if (currSum + value <= maxValue) {
        setSumResults(currSum + value);
        handleChangeValue(index, newValue);
      }
    }
  };

  const showRemoveButton =
    !!dataForm && !!dataForm.outputs
      ? dataForm.outputs.filter((o) => o.title !== 'Outros').length >= 1
      : false;

  return (
    <S.GridContainer>
      <S.ContainerButton>
        <Typography
          variant="h6"
          children={getTranslation('outputs')}
          color="primary"
        />
        <MttButton
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddChoice}
        >
          {getTranslation('addOutput')}
        </MttButton>
      </S.ContainerButton>
      <S.ContentGrid>
        {dataForm?.outputs?.map(renderChoice)}
        <S.SumContainer>
          <div style={{ display: 'flex' }}>
            <Typography
              variant="h6"
              children={getTranslation('outputsSum')}
              color="textPrimary"
            />
            <Typography
              variant="h6"
              children={`${sumResults}%`}
              style={{
                color: `${sumResults < 100 ? 'red' : 'green'}`,
                marginLeft: '4px',
              }}
            />
          </div>
          {!!formErrors[`sum`] && (
            <Typography
              children={formErrors['sum']}
              variant="body2"
              color="error"
              style={{ marginLeft: '8px' }}
            />
          )}
        </S.SumContainer>
      </S.ContentGrid>
    </S.GridContainer>
  );
}
