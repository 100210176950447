import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import React, { useMemo, useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';

import {
  AutoCompleteWrap,
  ContentItemAdd,
  ContentLimit,
  TableContainer,
  TextContainer,
} from '../styles';
import { emailValidation } from 'models/OrganizationValidadion';
import useTranslator from 'utils/hooks/Translator';
import { emptyUser, IPermissionCompanyUser } from 'contexts/Permissions/types';
import { useCompanies } from 'contexts/Company';
import { CompanyUser } from 'contexts/Company/types';
import {
  CustomPaper,
  HoverableRow,
} from 'components/ModalManageCompanies/styles';
import { usePermissions } from 'contexts/Permissions/permissionsContext';

interface Props {
  currentUsers: string[];
  objectToEdit: 'company' | 'group' | 'project';
  sourceUsers?: string[];
}

interface EditingUser extends CompanyUser {
  index: number;
}

export function ManageUsers({
  currentUsers,
  objectToEdit,
  sourceUsers = [],
}: Props) {
  const { state, dispatch } = useCompanies();
  const [errorLimit, setErrorLimit] = useState<string>('');
  const { isRoot } = usePermissions();
  const { company } = state;
  const [selectedCurrentUsers, setSelectedCurrentUsers] = useState<string[]>(
    []
  );
  const [currentUser, setCurrentUser] =
    useState<IPermissionCompanyUser>(emptyUser);
  const { getTranslation } = useTranslator();
  const [editingUser, setEditingUser] = useState<EditingUser>({
    ...emptyUser,
    index: -1,
  });
  const dispatchType = getDispatchType();
  const isCompany = objectToEdit === 'company';
  const [error, setError] = useState<{
    email?: string | undefined;
    usedEmails?: string | undefined;
  }>({
    email: '',
    usedEmails: '',
  });

  const users = useMemo(() => {
    return currentUsers.sort().map((user) => ({
      email: user,
      isAdmin: company.admins.includes(user),
    }));
  }, [company.admins, currentUsers]);

  function getDispatchType() {
    switch (objectToEdit) {
      case 'company':
        return 'setCompany';
      case 'project':
        return 'setProject';
      case 'group':
        return 'setGroup';
    }
  }

  async function addUser() {
    if (!isCompany) {
      handleAdduserMultiple();
      return;
    }
    const { isValid, errors } = await emailValidation(
      currentUser.email,
      users.map((user) => user.email)
    );

    if (!isValid || users.some((user) => user.email === currentUser.email)) {
      setError(errors);
    } else {
      handleFinishEditingUser([...users, currentUser]);
      setCurrentUser(emptyUser);
    }
  }

  function handleAdduserMultiple() {
    const users = [...selectedCurrentUsers, ...currentUsers];
    dispatch({
      type: dispatchType,
      data: {
        [objectToEdit]: {
          ...[objectToEdit],
          users: users,
        },
      },
    });
    setSelectedCurrentUsers([]);
  }

  function removeUser(user: string) {
    const usersNames = [...users].filter((u) => u.email !== user);

    handleFinishEditingUser(usersNames);
  }

  function handleEditUser(index: number) {
    const cloneUsers = [...users];
    const user = cloneUsers[index];
    setEditingUser({ ...user, index });
  }

  function handleChangeUser(e: React.ChangeEvent | any, index: number) {
    if (e.target.id === 'email') {
      users[index] = { ...users[index], email: e.target.value };
      setEditingUser((prev) => ({ ...prev, email: e.target.value, index }));
    } else {
      users[index] = {
        ...users[index],
        isAdmin: e.target.value === getTranslation('yes') ? true : false,
      };

      setEditingUser((prev) => ({
        ...prev,
        isAdmin: e.target.value === getTranslation('yes') ? true : false,
      }));
    }
  }

  function handleFinishEditingUser(cloneUsers = users) {
    if (isCompany) {
      const { users, admins } = cloneUsers.reduce(
        (acc: { users: string[]; admins: string[] }, user) => {
          if (user.isAdmin) acc.admins.push(user.email);
          else acc.users.push(user.email);

          return acc;
        },
        {
          users: [],
          admins: [],
        }
      );
      if (state.company.usersLimit >= users.length) setErrorLimit('');
      dispatch({
        type: dispatchType,
        data: {
          company: {
            ...company,
            users,
            admins,
          },
        },
      });
    } else {
      dispatch({
        type: dispatchType,
        data: {
          [objectToEdit]: {
            ...[objectToEdit],
            users: [...cloneUsers.map((user) => user.email)],
          },
        },
      });
    }

    setEditingUser({ ...emptyUser, index: -1 });
  }

  const differentUsers = useMemo(() => {
    const diff = sourceUsers.filter(
      (sorceUser) => !currentUsers.includes(sorceUser)
    );
    return diff.sort();
  }, [currentUsers, sourceUsers]);

  const handleChangeLimit = (usersLimit: number) => {
    if (users.length <= usersLimit) setErrorLimit('');
    dispatch({
      type: dispatchType,
      data: { [objectToEdit]: { ...[objectToEdit], usersLimit } },
    });
  };
  const handleErrorLimitUseres = () => {
    const error =
      state.company.usersLimit < users.length
        ? getTranslation('modal.manageCompany.user.limitValidationUserCount')
        : '';
    setErrorLimit(error);
  };
  return (
    <CustomPaper addminheight={objectToEdit !== 'company'}>
      <h6>
        {objectToEdit === 'company' &&
          getTranslation('modal.manageCompany.user.manageCompanyUsers')}
        {objectToEdit === 'project' &&
          getTranslation('modal.manageCompany.user.manageProjectUsers')}
        {objectToEdit === 'group' &&
          getTranslation('modal.manageCompany.user.manageGroupUsers')}
      </h6>
      {objectToEdit === 'company' && (
        <ContentLimit>
          <TextField
            style={{ width: '125px' }}
            value={state.company.usersLimit}
            label={getTranslation('modal.manageCompany.user.userLimit')}
            type="number"
            disabled={!isRoot()}
            onChange={(e) => {
              handleChangeLimit(parseInt(e.target.value, 10));
            }}
            inputProps={{
              min: 0,
            }}
            onBlur={handleErrorLimitUseres}
            helperText={errorLimit}
            error={!!errorLimit}
          />
        </ContentLimit>
      )}

      <ContentItemAdd comanyEdit={isCompany}>
        <TextContainer>
          {isCompany ? (
            <TextField
              fullWidth
              label="Email"
              value={currentUser.email}
              onChange={(e) => {
                const email = e.target.value;
                setCurrentUser((prev) => ({ ...prev, email }));
              }}
              helperText={error.email || error.usedEmails || ''}
              error={!!error.email || !!error.usedEmails}
              onBlur={() => setError({ email: '', usedEmails: '' })}
            />
          ) : (
            <AutoCompleteWrap>
              <Autocomplete
                multiple
                value={selectedCurrentUsers}
                options={differentUsers}
                disableCloseOnSelect
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{
                        marginRight: 8,
                        color: selected ? '#6c50a4' : '',
                      }}
                      checked={selected}
                    />
                    {option}
                  </React.Fragment>
                )}
                noOptionsText={getTranslation('noResults')}
                getOptionLabel={(option) => option}
                onChange={(_e: any, selectedOptions) => {
                  setSelectedCurrentUsers(selectedOptions);
                }}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={getTranslation('users')}
                    variant="outlined"
                    fullWidth
                    style={{
                      backgroundColor: '#ffff',
                      zIndex: 9999,
                    }}
                  />
                )}
              />
            </AutoCompleteWrap>
          )}
        </TextContainer>

        {isCompany && (
          <FormControlLabel
            control={
              <Checkbox
                checked={currentUser.isAdmin}
                onChange={(e) =>
                  setCurrentUser((prev) => ({
                    ...prev,
                    isAdmin: e.currentTarget.checked,
                  }))
                }
                name="Admin"
                color="primary"
              />
            }
            label="Admin"
          />
        )}

        <IconButton
          aria-label="adiciona um usuario"
          onClick={addUser}
          disabled={users.length >= state.company.usersLimit}
        >
          <AddIcon />
        </IconButton>
      </ContentItemAdd>
      <TableContainer>
        <Table size="small" aria-label="Lista de usuários">
          <TableHead>
            <TableRow>
              <TableCell style={{ border: 'none' }}>Email</TableCell>
              {isCompany && <TableCell>Admin</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {users?.sort().map((user, index) => (
              <HoverableRow key={index}>
                <TableCell style={{ width: '100%' }}>
                  <TextField
                    id="email"
                    value={
                      editingUser.email === user.email
                        ? editingUser.email
                        : user.email
                    }
                    disabled={!(editingUser.index === index)}
                    fullWidth
                    onChange={(e) => handleChangeUser(e, index)}
                  />
                </TableCell>
                {isCompany && (
                  <TableCell>
                    <Select
                      value={
                        user.isAdmin
                          ? getTranslation('yes')
                          : getTranslation('no')
                      }
                      onChange={(e) => handleChangeUser(e, index)}
                      disabled={!(editingUser.index === index)}
                    >
                      <MenuItem value={getTranslation('yes')}>
                        {getTranslation('yes')}
                      </MenuItem>
                      <MenuItem value={getTranslation('no')}>
                        {getTranslation('no')}
                      </MenuItem>
                    </Select>
                  </TableCell>
                )}
                <TableCell style={{ display: 'flex' }}>
                  <>
                    {isCompany && (
                      <>
                        {!(editingUser.index === index) ? (
                          <IconButton
                            aria-label="edita um usuário"
                            onClick={() => handleEditUser(index)}
                          >
                            <EditIcon htmlColor="#7B88B2" />
                          </IconButton>
                        ) : (
                          <IconButton
                            aria-label="finaliza edição de usuário"
                            onClick={() => handleFinishEditingUser()}
                          >
                            <CheckIcon htmlColor="#2EBC2B" />
                          </IconButton>
                        )}
                      </>
                    )}

                    <IconButton
                      aria-label="remove um usuario"
                      onClick={() => removeUser(user.email)}
                    >
                      <CloseIcon htmlColor="#fc5858" />
                    </IconButton>
                  </>
                </TableCell>
              </HoverableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </CustomPaper>
  );
}
