import styled from 'styled-components';
import MttButton from 'components/Material/MttButton/MttButton';
import MttGrid from 'components/Material/MttGrid/MttGrid';

import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';

export const ContainerDialog = styled(MttGrid)<{ colorHeader?: string }>`
  width: 100%;
  height: 100%;

  position: relative;
  padding-top: 54px;

  .MuiAppBar-colorPrimary {
    background-color: #6c50a4;
    position: absolute;
  }

  /* padding: 8px 8px; */
`;

export const ContainerHeader = styled(MttGrid)`
  display: flex;
  align-items: center;
  justify-content: center;
  /* overflow-y: auto; */
`;

export const Close = styled(MttGrid)`
  display: flex;
  justify-content: flex-end;
  max-height: 10px;
`;

export const ModalTitle = styled(Typography).attrs({
  variant: 'h6',
})`
  color: #6c50a4;
  padding: 10px;
`;

export const FieldContainer = styled(Grid)`
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: 5px;
  max-height: 65vh;

  overflow-y: scroll;

  ul {
    list-style-type: none;
  }

  p {
    padding: 5px;
    text-align: center;
  }
`;

export const ButtonContainer = styled(Grid)`
  padding: 16px 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
`;

export const Button = styled(MttButton)`
  /* height: 50px; */
  margin: 5px;
`;

export const LineContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const NewKeyContainer = styled.div`
  margin-top: 40px;
  width: 100%;

  .MuiInputBase-input {
    outline: 0;
  }

  .MuiInputBase-input::-webkit-input-placeholder {
    opacity: 0.65;
    font-weight: 500;
  }
`;

export const TextContainer = styled.div`
  height: 64px;

  margin-right: 10px;
  width: 100%;
`;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    dialog: {
      background: '#F2F2F2',
      overflowY: 'hidden',
    },

    input: {
      background: '#FFFFFF',
    },
  })
);
