import styled from 'styled-components';
import MttGrid from '../Material/MttGrid/MttGrid';
import MttButton from '../Material/MttButton/MttButton';

import { createStyles, Grid, makeStyles, Typography } from '@material-ui/core';

export const ContainerDialog = styled(MttGrid)<{ colorHeader?: string }>`
  width: 100%;
  height: 100%;

  position: relative;
  padding-top: 54px;

  overflow: hidden;
  min-height: 400px;

  .MuiAppBar-colorPrimary {
    background-color: #6c50a4;
    position: absolute;
		padding: 0 !important;
  }
`;

export const ContainerHeader = styled(MttGrid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 0 4px;
`;

export const ModalTitle = styled(Typography).attrs({
  variant: 'h6',
})`
  color: #6c50a4;
`;

export const FieldContainer = styled(Grid)`
  width: 100%;
  margin-top: 10px;
  display: flex;

  overflow-y: auto;

  align-items: center;

  .MuiInputBase-input {
    outline: 0;
  }

  .MuiInput-underline:before {
    display: none;
  }

  .MuiInputBase-input::-webkit-input-placeholder {
    opacity: 0.65;
    font-weight: 500;
  }

  .MuiIconButton-root {
    padding: 8px;
  }

  .tableHeadCell-Button {
    width: 40px;
    text-align: right;
  }
`;

export const ButtonContainer = styled(Grid)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const Button = styled(MttButton)``;

export const LineContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  padding: 0 4px;
`;

export const NewKeyContainer = styled.div`
  padding: 12px;

  .MuiInputBase-input {
    outline: 0;
  }

  .MuiInputBase-input::-webkit-input-placeholder {
    opacity: 0.65;
    font-weight: 500;
  }
`;

export const TextContainer = styled.div`
  height: 64px;

  margin-right: 10px;
  width: 100%;
`;

export const Container = styled.div`
  max-height: 55vh;
  overflow-y: auto;
	padding: 0;
	
	@media (-webkit-max-device-pixel-ratio: 1.25) {
		padding: 16px;
	}
`;

export const useStyles = makeStyles(() =>
  createStyles({
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    dialog: {
      background: '#F2F2F2',
      overflowY: 'hidden',
    },
    input: {
      background: '#FFFFFF',
    },
		buttonTableCell: {
			padding: 0
		}
  })
);
