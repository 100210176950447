import Drawflow from 'drawflow';
import { NewDrawFlow } from 'contexts/Flow/types';
import EIntentType from 'enums/EIntentType';
import { ItemDataFlow, DataForm, INodePosition } from 'models/DataFlow';
import DataFormModel from 'models/DataFormModel';

export default class IntentController {
  protected AMOUNT_INPUT = 1;

  protected editorFlow: NewDrawFlow;

  constructor(editorFlow: Drawflow) {
    this.editorFlow = editorFlow;
  }

  getLastIntentCreated(): ItemDataFlow | null {
    const keys = Object.keys(this.editorFlow.drawflow.drawflow.Home.data);
    if (!!keys.length) {
      const data =
        this.editorFlow.drawflow.drawflow.Home.data[keys[keys.length - 1]];
      if (data.data.intentType === EIntentType.Others) {
        return this.editorFlow.drawflow.drawflow.Home.data[
          keys[keys.length - 2]
        ];
      }
      return data;
    }
    return null;
  }

  getPositionByNodeId(nodeId: number) {
    const data = this.editorFlow.drawflow.drawflow.Home.data[nodeId];
    return { x: data.pos_x, y: data.pos_y };
  }

  createIntentChild(
    dataFormModel: DataFormModel,
    parentId: number,
    index: number,
    parentPosition: { x: number; y: number }
  ) {
    dataFormModel.outputs =
      dataFormModel.name === 'Outros' ? [] : [{ title: 'Saída' }];
    const amountOutput = dataFormModel.getOutputAmount();
    const dataForm = dataFormModel.toDataForm();
    const lastIntentCreated = this.getLastIntentCreated();
    let offSetX = 0,
      offSetY = 0;
    if (lastIntentCreated) {
      offSetX = parentPosition.x;
      offSetY = lastIntentCreated.pos_y;
    }
    const position = {
      x: offSetX + 400,
      y: !!index ? offSetY + 150 : offSetY - 150,
    };
    if (dataFormModel.isOthers()) {
      position.x = parentPosition.x;
      position.y = parentPosition.y + 200;
    }
    const childId = this.addNode(amountOutput, dataForm, position);
    dataForm.idOutput = String(childId);
    this.addConnection(parentId, childId, index);
    return childId;
  }

  addNode(amountOutput: number, dataForm: DataForm, position: INodePosition) {
    const amount = amountOutput
      ? amountOutput
      : [
          EIntentType.InitialFlow,
          EIntentType.Advanced,
          EIntentType.QuestionsAndAnswers,
          EIntentType.Others,
        ].includes(dataForm?.intentType)
      ? 1
      : amountOutput;

    return this.editorFlow.addNode(
      'dbclick',
      dataForm.intentType === EIntentType.NLU ||
        dataForm.intentType === EIntentType.FlexBlockEntry
        ? 0
        : this.AMOUNT_INPUT,
      amount,
      position.x,
      position.y,
      'dbclick',
      dataForm,
      '',
      false
    );
  }

  addConnection(parentId: number, childId: number, index: number) {
    this.editorFlow.addConnection(
      parentId,
      childId,
      'output_' + (index + 1),
      'input_1'
    );
  }

  updateVersion(currentVersion: number) {
    const { drawflow } = this.editorFlow.drawflow;
    if (!drawflow.Home.version) {
      drawflow.Home.version = currentVersion;
    } else {
      drawflow.Home.version += 1;
    }
  }

  getEditorFlow() {
    return this.editorFlow;
  }
}
