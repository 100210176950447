import React from 'react';

export interface ISVGProps {
  size?: string;
  color?: string;
}

const TrainingIcon: React.FC<ISVGProps> = (props) => {
  const { size, color } = props;

  return (
    <svg
      width={size || '24'}
      height={size || '24'}
      viewBox="0 0 24 24"
      fill={color || '#424242'}
    >
      <g>
        <rect
          xmlns="http://www.w3.org/2000/svg"
          id="Retângulo_1366"
          data-name="Retângulo 1366"
          width="24"
          height="24"
          fill="none"
        />
      </g>
      <g
        xmlns="http://www.w3.org/2000/svg"
        id="Grupo_2826"
        data-name="Grupo 2826"
      >
        <g id="Grupo_2825" data-name="Grupo 2825">
          <path
            id="Caminho_4991"
            data-name="Caminho 4991"
            d="M13,8.57A1.43,1.43,0,1,0,14.43,10,1.43,1.43,0,0,0,13,8.57Z"
            fill="#424242"
          />
          <path
            id="Caminho_4992"
            data-name="Caminho 4992"
            d="M13,3A6.981,6.981,0,0,0,6.02,9.64L4.1,12.2a.5.5,0,0,0,.4.8H6v3a2.006,2.006,0,0,0,2,2H9v3h7V16.32A7,7,0,0,0,13,3Zm3,7c0,.13-.01.26-.02.39l.83.66a.187.187,0,0,1,.05.25l-.8,1.39a.2.2,0,0,1-.24.09l-.99-.4a3,3,0,0,1-.67.39L14,13.83a.2.2,0,0,1-.2.17H12.2a.2.2,0,0,1-.2-.17l-.15-1.06a2.86,2.86,0,0,1-.68-.39l-.99.4a.21.21,0,0,1-.25-.09l-.8-1.39a.189.189,0,0,1,.05-.25l.84-.66c-.01-.13-.02-.26-.02-.39a2.453,2.453,0,0,1,.04-.39l-.85-.66a.2.2,0,0,1-.05-.26l.8-1.38a.2.2,0,0,1,.24-.09l1,.4a3.175,3.175,0,0,1,.67-.39L12,6.17A.2.2,0,0,1,12.2,6h1.6a.2.2,0,0,1,.2.17l.15,1.06a3,3,0,0,1,.67.39l1-.4a.193.193,0,0,1,.24.09l.8,1.38a.2.2,0,0,1-.05.26l-.85.66A1.611,1.611,0,0,1,16,10Z"
            fill="#424242"
          />
        </g>
      </g>
    </svg>
  );
};

export default TrainingIcon;
