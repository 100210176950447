import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import React, { useCallback, useEffect, useState } from 'react';
import ForumIcon from '@material-ui/icons/Forum';
import {
  StyledFooterGrid,
  StyledGridPerguntas,
  StyledInputsGrid,
  StyledMttGridMain,
  StyledMttTextField,
  StyledMttTypographyIdentificacao,
  StyledTextFieldPergunta,
} from './styles';

import { useForm } from 'contexts/Form/formContext';
import { propsData } from './types';

import { GridIconDelete, ErrorWarning } from './styles';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import CircularDeleteButton from 'components/CircularDeleteButton/index';
import { useFlow } from 'contexts/Flow/flowContext';
import SpeedDialWithMenuList from 'components/SpeedDialWithMenuList';
import { IOptionMenuList } from 'components/IconButtonWithMenuList/types';
import ModalVariableAdvanced from './ModalVariableAdvanced';
import { GetEntities } from 'services/EntityService';
import { validateVarNames } from 'utils/String';
import { useApp } from 'contexts/App/appContext';

export default function UserQuestions(props: propsData) {
  const { botName } = useFlow().state;
  const { state, dispatch } = useForm();
  const { dispatch: dispatchApp } = useApp();
  const { dataForm, formErrors } = state;

  const { index, showDelete, showTitle } = props;
  const variables = dataForm?.inputs?.variables || [];
  const variable = dataForm?.inputs?.variables[index];

  const [types, setTypes] = useState<{ id: string; name: string }[]>([]);
  const [openAdvancedModal, setOpenAdvancedModal] = useState<boolean>(false);

  useEffect(() => {
    async function load() {
      let allTypes = [
        { id: '@sys.any', name: 'qualquer-input' },
        { id: '@sys.email', name: 'input-de-email' },
        { id: '@sys.number', name: 'input-de-numero' },
        { id: '@sys.date-time', name: 'input-de-data' },
        { id: '@sys.url', name: 'input-de-url' },
      ];

      if (botName) {
        let result = await GetEntities(
          { bot_name: botName || '' },
          dispatchApp
        );
        if (result.Success) {
          if (!!result.Data) {
            const entities = result.Data;
            allTypes = [
              ...allTypes,
              ...entities.map((entity) => ({
                id: entity.name,
                name: entity.name,
              })),
            ].sort(function (a, b) {
              return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
            });

            allTypes.map((type) => {
              if (type.id.substring(0, 1) !== '@') {
                const insertAt = `${type.id.slice(0, 0)}${'@'}${type.id.slice(
                  0
                )}`;
                let filteredValue = insertAt;
                type.id = filteredValue;
              }
              return allTypes;
            });
          }
        }
      }
      setTypes(allTypes);
    }
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botName]);

  const load = useCallback(() => {
    if (dataForm && dataForm.inputs && !dataForm.inputs.variables.length) {
      dataForm.inputs = {
        variables: [
          {
            name: '',
            vartype: '',
            questions: ['', '', ''],
            required: false,
            defaultValue: '',
            isList: false,
            value: '',
          },
        ],
      };
      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
  }, [dataForm, dispatch]);

  useEffect(() => {
    load();
  }, [load]);

  const handleChangeInput =
    (key: 'value' | 'name' | 'vartype') =>
    (e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
      if (dataForm && variable) {
        const value = validateVarNames(e, key, true);

        if (dataForm.dataBlockly) {
          dataForm.dataBlockly.payload = dataForm.dataBlockly?.payload.replaceAll(
            `$${variable['name']}`,
            `$${value}`
          );
        }

        if (key === 'name' && !variable.isValueEdited) {
          variable['value'] = '$' + value;
        }
        variable[key] = value;

        dispatch({
          type: 'updateForm',
          data: { dataForm },
        });
      }
    };

  const handleQuestion = (value: string, index: number) => {
    if (variable && dataForm) {
      variable.questions[index] = value;
      dispatch({
        type: 'updateForm',
        data: { dataForm },
      });
    }
  };

  const handleRemoveVariable = (index: number) => {
    variables.splice(index, 1);
    dispatch({ type: 'updateForm', data: { dataForm } });
  };

  const options: IOptionMenuList[] = [
    {
      label: 'Avançado',
      iconName: 'Settings',
      action: () => {
        setOpenAdvancedModal(true);
      },
    },
    {
      label: 'Cancelar',
      iconName: 'Cancel',
      action: () => {
        setOpenAdvancedModal(false);
      },
    },
  ];

  const renderQuestions = () => {
    return (
      <>
        <StyledGridPerguntas showgrid={true}>
          {variable?.questions.map((perg, index) => {
            return (
              <StyledTextFieldPergunta
                key={index}
                onChange={(e) => handleQuestion(e.target.value, index)}
                value={perg}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ForumIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
              />
            );
          })}
        </StyledGridPerguntas>
      </>
    );
  };

  return (
    <StyledMttGridMain>
      {!!showTitle && <StyledMttTypographyIdentificacao />}
      <StyledFooterGrid>
        <StyledInputsGrid>
          <StyledMttTextField
            variant="outlined"
            label="Nome"
            value={variable?.name || ''}
            onChange={handleChangeInput('name')}
            fullWidth
          />

          <FormControl variant="outlined" fullWidth>
            <InputLabel id="groups-simple-select-outlined-label">
              Tipos
            </InputLabel>
            <ErrorWarning>
              <Select
                labelId="groups-simple-select-outlined-label"
                id="groups-simple-select-outlined"
                value={variable?.vartype || ''}
                onChange={handleChangeInput('vartype')}
                label="Tipos"
                fullWidth
                required
                error={
                  variable?.name !== '' &&
                  variable?.vartype === '' &&
                  !!formErrors['varType']
                }
              >
                <MenuItem value="">
                  <em>Selecione um Tipo</em>
                </MenuItem>
                {types.map((type, index) => (
                  <MenuItem
                    key={index}
                    value={type.id}
                    disabled={!variable?.name}
                  >
                    {type.name}
                  </MenuItem>
                ))}
              </Select>
              {variable?.name && variable?.vartype === '' && (
                <FormHelperText error>{formErrors['varType']}</FormHelperText>
              )}
            </ErrorWarning>
          </FormControl>
          <SpeedDialWithMenuList iconName="MoreVert" options={options} />
          {!!showDelete && (
            <GridIconDelete key={`variable-${index}`}>
              <CircularDeleteButton
                handleDeleteItem={() => handleRemoveVariable(index)}
              />
            </GridIconDelete>
          )}
        </StyledInputsGrid>
        {renderQuestions()}
      </StyledFooterGrid>
      <ModalVariableAdvanced
        index={index}
        open={openAdvancedModal}
        handleCloseModal={() => setOpenAdvancedModal(false)}
      />
    </StyledMttGridMain>
  );
}
