import styled from 'styled-components';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import MttGrid from '../Material/MttGrid/MttGrid';
import MttButton from '../Material/MttButton/MttButton';

import { Grid, Paper, TableRow, Typography } from '@material-ui/core';

export const HoverableRow = styled(TableRow)<{
  white?: boolean;
}>`
  &:hover {
    background-color: ${(props) =>
      props.white
        ? '#ffff'
        : '#f2f2f2'}; /* Ou qualquer outra cor que você desejar */
    transition: background-color 0.2s ease-in-out; /* Adiciona uma transição suave para a mudança de cor de fundo */
    transform-origin: center; /* Define o ponto de origem da transformação */
  }
`;
export const ContainerDialog = styled(MttGrid)<{ colorHeader?: string }>`
  width: 100%;
  height: 100%;

  position: relative;
  padding-top: 54px;

  overflow: hidden;
  min-height: 400px;

  .MuiAppBar-colorPrimary {
    background-color: #6c50a4;
    position: absolute;
  }
`;

export const Container = styled.div`
  overflow-y: auto;
  padding: 12px;
`;

export const ContainerHeader = styled(MttGrid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ModalTitle = styled(Typography).attrs({
  variant: 'h6',
})`
  color: #fff;
`;

export const FieldContainer = styled(Grid)`
  width: 100%;
  margin-top: 10px;
  display: flex;

  overflow-y: auto;

  align-items: center;

  .MuiInputBase-input {
    outline: 0;
  }

  .MuiInput-underline:before {
    display: none;
  }

  .MuiInputBase-input::-webkit-input-placeholder {
    opacity: 0.65;
    font-weight: 500;
  }

  .MuiIconButton-root {
    padding: 8px;
  }

  .tableHeadCell-Button {
    width: 40px;
    text-align: right;
  }
`;

export const ButtonContainer = styled(Grid)`
  padding: 24px;
  display: flex;
  justify-content: flex-end;

  .MuiCircularProgress-colorPrimary {
    color: #f8f8f24f;
    margin-left: 8px;
  }
`;

export const Button = styled(MttButton)`
  height: 50px;
`;

export const CustomPaper = styled(Paper)<{ addminheight?: boolean }>`
  margin-top: 10px;
  padding: 20px;
  flex: 1;

  min-height: ${(props) => (props.addminheight ? '62vh' : 'none')};
`;

export const FormsContainer = styled.form`
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 54px);
  overflow-y: auto;

  padding: 4px;
`;

export const useStyles = makeStyles(() =>
  createStyles({
    dialog: {
      background: '#F2F2F2',
      overflowY: 'hidden',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
    },

    input: {
      background: '#FFFFFF',
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    loginWithAdInfo: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    infoIcon: {
      color: '#c2c2c2',
      cursor: 'pointer',
    },
  })
);
