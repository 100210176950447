import styled from 'styled-components';
import MttGrid from 'components/Material/MttGrid/MttGrid';
import MttTypography from 'components/Material/MttTypography/MttTypography';

export const Container = styled(MttGrid)`
  margin: 16px;
  display: flex;
  align-items: center;

  .MuiOutlinedInput-root {
    margin-left: 32px;
  }
`;

export const TitleTypography = styled(MttTypography)``;
