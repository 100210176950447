import React, { useEffect, useState } from 'react';
import {
  Slide,
  Dialog,
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  TextField,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import CloseIcon from '@material-ui/icons/Close';

import * as SS from '../styles';

import useTranslator from 'utils/hooks/Translator';
import MttButton from 'components/Material/MttButton/MttButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import { CircularProgress } from '@material-ui/core';

import { formatDateHour } from 'utils/Date';
import { CreateOrEditCampaign } from './CreateOrEditCampaign';
import {
  emptyCampaign,
  ICampaign,
  ICampaignStatus,
} from 'services/CampaignService/CampaignsService/types';
import {
  DeleteCampaign,
  GetCampaigns,
} from 'services/CampaignService/CampaignsService';
import { useApp } from 'contexts/App/appContext';
import { useFlow } from 'contexts/Flow/flowContext';
import { GetActionings } from 'services/CampaignService/ActioningsService';
import TemplateIcon from 'components/Icons/TemplateIcon';
import ActioningIcon from 'components/Icons/ActioningIcon';
import { isBefore } from 'date-fns';
import { usePermissions } from 'contexts/Permissions/permissionsContext';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface ModalCampaignsProps {
  open: boolean;
  close: () => void;
}

export function ModalCampaigns({ open, close }: ModalCampaignsProps) {
  const classes = SS.useStyles();
  const { hasPermissionToAction } = usePermissions();
  const { getTranslation } = useTranslator();
  const { dispatch: dispatchApp } = useApp();
  const { toastNotification, dispatch, state } = useFlow();
  const { botName } = state;
  const bot_name = botName || '';

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openModalCreateOrEditCampaign, setOpenModalCreateOrEditCampaign] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [currentCampaign, setCurrentCampaign] =
    useState<ICampaign>(emptyCampaign);
  const [campaigns, setCampaigns] = useState<ICampaign[]>([]);
  const [tooltipTitle, setTooltipTitle] = useState(getTranslation('copy'));
  const isCommunicationGuidelineWrite = hasPermissionToAction({
    company: state.companyName!,
    agent: state.botName!,
    action: ['communication_guideline:write'],
  });

  const filteredCampaigns =
    search.length > 0
      ? campaigns.filter((t) => t.name.includes(search))
      : campaigns;

  useEffect(() => {
    if (!openModalCreateOrEditCampaign) loadCampaigns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, openModalCreateOrEditCampaign]);

  async function loadCampaigns() {
    setLoading(true);

    try {
      // const listCampaigns: ICampaign[] = [];

      // retirar organization
      const response = await GetCampaigns(
        {
          bot_name,
        },
        dispatchApp
      );

      if (response.Success) {
        const allCampaigns = response.Data.data;

        setCampaigns(
          allCampaigns.sort((a, b) => {
            if (
              isBefore(
                new Date(a.creation_date).getTime(),
                new Date(b.creation_date).getTime()
              )
            )
              return 1;
            else return -1;
          })
        );
      } else throw new Error('erro');

      // userOrganizations.forEach(async (organization) => {
      //   const items = await GetCampaigns(
      //     { bot_name, organization: organization.name },
      //     dispatchApp
      //   );

      //   const foundCampaigns = items.Data.data;

      //   if (foundCampaigns) {
      //     foundCampaigns.forEach((campaign) => {
      //       listCampaigns.push(campaign);
      //     });
      //   }

      //   const allCampaigns = [...campaigns, ...listCampaigns];

      //   setCampaigns(
      //     allCampaigns.sort((a, b) => {
      //       if (
      //         isBefore(
      //           new Date(a.creation_date).getTime(),
      //           new Date(b.creation_date).getTime()
      //         )
      //       )
      //         return 1;
      //       else return -1;
      //     })
      //   );
      // });
    } catch (error: any) {
      toastNotification('error', getTranslation('toast.error.searchCampaigns'));
    }

    setLoading(false);
  }

  function handleClose() {
    setPage(0);
    close();
  }

  function handleNewCampaign() {
    setCurrentCampaign(emptyCampaign);
    setOpenModalCreateOrEditCampaign(true);
  }

  function handleCloseNewCampaign() {
    setCampaigns([]);
    setOpenModalCreateOrEditCampaign(false);
    setSearch('');
  }

  function handleChangeRowsPerPage(e: { target: { value: string | number } }) {
    setRowsPerPage(+e.target.value);
    setPage(0);
  }

  function handleChangePage(event: any, newPage: React.SetStateAction<number>) {
    setPage(newPage);
  }

  async function campaignIsUsed(campaign: ICampaign): Promise<boolean> {
    const response = await GetActionings({
      bot_name,
      organization: ''
    }, dispatchApp);
    let campaignUsed = true;

    if (response.Success) {
      const actionings = response.Data.data;

      const foundCampaign = actionings.find(
        (actioning) => actioning.campaign_name === campaign.name
      );

      if (foundCampaign) campaignUsed = true;
      else campaignUsed = false;
    }

    return campaignUsed;
  }

  async function handleRemoveCampaign(campaign: ICampaign) {
    const campaignUsed = await campaignIsUsed(campaign);

    if (!campaignUsed) {
      const response = await DeleteCampaign(
        {
          ...campaign,
          bot_name,
        },
        dispatchApp
      );

      if (response.Success) {
        toastNotification(
          'success',
          getTranslation('toast.success.campaignDeleted')
        );
        setCampaigns(campaigns.filter((camp) => camp._id !== campaign._id));
      } else {
        toastNotification(
          'error',
          getTranslation('toast.error.campaignDeleted')
        );
      }
    } else
      toastNotification('error', getTranslation('toast.error.usedCampaign'));
  }

  function handleEditCampaign(campaign: ICampaign) {
    setCurrentCampaign(campaign);
    setOpenModalCreateOrEditCampaign(true);
  }

  function getPrettyStatus(status: ICampaignStatus) {
    switch (status) {
      case 'active':
        return getTranslation('active');
      case 'inactive':
      default:
        return getTranslation('inactive');
    }
  }

  async function handleCopyId(id: string) {
    await navigator.clipboard.writeText(id);
    setTooltipTitle(getTranslation('copied'));
  }

  const renderCampaignsList = () => (
    <SS.ContainerList>
      <SS.SearchAndTableContainer>
        <SS.ContainerHeader>
          <Typography variant="h6" color="primary">
            {getTranslation('campaignsList')}
          </Typography>
          <SS.HeaderButtonsContainer>
            {isCommunicationGuidelineWrite && (
              <MttButton
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => handleNewCampaign()}
              >
                {getTranslation('modal.campaigns.campaign.newButton')}
              </MttButton>
            )}
          </SS.HeaderButtonsContainer>
        </SS.ContainerHeader>
        <SS.GridSearch>
          <Autocomplete
            options={campaigns.map((option) => option.name)}
            renderInput={(params) => (
              <TextField
                {...params}
                value={search}
                label={getTranslation('search')}
                variant="outlined"
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </SS.GridSearch>
        {loading ? (
          <div className={classes.loadingContainer}>
            <CircularProgress color="inherit" size={20} />
          </div>
        ) : campaigns.length > 0 ? (
          <div>
            <TableContainer
              component={Paper}
              className={classes.tableContainer}
            >
              <Table
                className={classes.table}
                aria-label="simple table"
                size="medium"
                stickyHeader
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Id</TableCell>
                    <TableCell align="center">
                      {getTranslation('name')}
                    </TableCell>
                    <TableCell align="center">Template</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="center">
                      {getTranslation('creationDate')}
                    </TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? filteredCampaigns.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : filteredCampaigns
                  ).map((campaign) => (
                    <TableRow key={campaign._id}>
                      <TableCell
                        scope="campaign"
                        align="center"
                        onClick={() => handleCopyId(campaign._id || '')}
                      >
                        <Tooltip title={tooltipTitle} placement="right">
                          <span
                            onMouseLeave={() =>
                              setTooltipTitle(getTranslation('copy'))
                            }
                          >
                            {campaign._id}
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell scope="campaign" align="center">
                        {campaign.name}
                      </TableCell>
                      <TableCell align="center">{campaign.template}</TableCell>
                      <TableCell align="center">
                        {getPrettyStatus(campaign.status)}
                      </TableCell>
                      <TableCell align="center">
                        {formatDateHour(campaign.creation_date)}
                      </TableCell>
                      <TableCell align="center">
                        {isCommunicationGuidelineWrite && (
                          <>
                            <Tooltip title={getTranslation('edit')}>
                              <IconButton
                                onClick={() => handleEditCampaign(campaign)}
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={getTranslation('delete')}>
                              <IconButton
                                onClick={() => handleRemoveCampaign(campaign)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : (
          <p style={{ marginTop: 24 }}>{getTranslation('noCampaignsResult')}</p>
        )}
      </SS.SearchAndTableContainer>
      <div className={classes.tableFooter}>
        <div>
          <Tooltip title={'Templates'}>
            <IconButton
              onClick={() => {
                dispatch({ type: 'closeModalCampaignCampaign' });
                dispatch({ type: 'openModalCampaignTemplates' });
              }}
            >
              <TemplateIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={getTranslation('actionings')}>
            <IconButton
              onClick={() => {
                dispatch({ type: 'closeModalCampaignCampaign' });
                dispatch({ type: 'openModalCampaignActioning' });
              }}
            >
              <ActioningIcon />
            </IconButton>
          </Tooltip>
        </div>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              count={filteredCampaigns.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={getTranslation(
                'modal.conversationHistory.linesPerPage'
              )}
            />
          </TableRow>
        </TableFooter>
      </div>
    </SS.ContainerList>
  );

  return (
    <>
      <Dialog open={open} TransitionComponent={Transition} fullScreen>
        <AppBar>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              {getTranslation('campaign')}
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={
                openModalCreateOrEditCampaign
                  ? () => handleCloseNewCampaign()
                  : () => handleClose()
              }
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <SS.ContainerDialog>
          {openModalCreateOrEditCampaign ? (
            <CreateOrEditCampaign
              handleClose={handleCloseNewCampaign}
              currentCampaign={{
                ...currentCampaign,
                bot_name,
              }}
            />
          ) : (
            renderCampaignsList()
          )}
        </SS.ContainerDialog>
      </Dialog>
    </>
  );
}
