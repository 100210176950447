import FooterButtons from 'components/inputs/Material/FooterButtons';
import Info from 'components/inputs/Material/Info';
import Sentences from 'components/inputs/Material/Sentences';
import { FormFieldsContainer } from '../Shared/styles';
import MultipleTags from 'components/inputs/Material/MultipleTags';

export default function NLU() {
  return (
    <>
      <FormFieldsContainer>
        <Info />
        <MultipleTags />
        <Sentences />
      </FormFieldsContainer>
      <FooterButtons />
    </>
  );
}
