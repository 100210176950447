import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import styled from 'styled-components';
import MttGrid from 'components/Material/MttGrid/MttGrid';

export const ContainerDialog = styled(MttGrid)`
  width: 100%;
  height: 100%;

  position: relative;
  padding-top: 54px;

  overflow: hidden;

  .MuiAppBar-colorPrimary {
    background-color: #6c50a4;
    position: absolute;
  }
`;

export const AddTagContainer = styled(Grid)`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 35px;
  max-height: 50px;

  button {
    margin: 5px;
    padding: 10px;
  }
`;

export const TagsContainer = styled.div`
  width: 100%;
  overflow: auto;
  /* overflow-x: hidden; */
  margin-bottom: 20px;
`;

export const TextContainer = styled.div`
  height: 64px;
  padding: 7px;
  width: 100%;
  max-width: 200px;
  margin: 0px 2px 5px 10px;
`;

export const TagsList = styled(Grid)`
  display: flex;
  margin: 10px 10px;
  align-items: center;
  max-width: 100%;
  flex-wrap: wrap;
`;

export const ChipContainer = styled.div`
  div {
    margin: 3px;
  }
`;

export const ContentContainer = styled(Grid)`
  overflow: hidden;
  height: 100%;
`;

export const ContainerImage = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffff;
`;

export const ModalTitle = styled(Typography).attrs({
  variant: 'h6',
})``;

export const ModalFooter = styled(Typography).attrs({})`
  display: flex;
  text-align: center;
`;

export const ContainerFooter = styled.div`
  align-items: center;
  justify-content: center;
  margin: 0 24px 16px 24px;

  .MuiTypography-body1 {
    color: #808080;
  }
`;

export const ContainerMessage = styled.div`
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;

  .MuiTypography-body1 {
    color: #6c50a4;
    font-weight: 500;
  }
`;

export const ModalMessage = styled(Typography).attrs({})`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
`;

export const ClientInfo = styled.div`
  padding-top: 8px;

  margin-bottom: 4px;

  width: 100%;
  /* max-width: 444px; */
  background-color: #f2f2f2;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .MuiIconButton-root {
    /* color: #34ac26; */
  }
`;

export const MsgFieldContainer = styled.div`
  p {
    margin-left: 28px;
    color: #6c50a4;
    font-weight: bold;
    font-size: 1.1rem;
  }
`;

export const PopoverContainer = styled.div`
  padding: 16px;
`;

export const MessagesContainer = styled.div`
  overflow-y: scroll;
  max-height: calc(100% - 74px);

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }
`;

export const DateChip = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    background: #6c50a459;
    max-width: 150px;
    padding: 8px 16px;
    margin: 16px;
    border-radius: 8px;
    font-weight: 500;
    /* border: 1px solid #a6a6a6; */
  }
`;

export const useStyles = makeStyles((theme: Theme) => ({
  table: {
    minWidth: 650,
  },
  appBar: {
    position: 'relative',
    background: theme.palette.primary.main,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    maxWidth: '50%',
    margin: 16,
    padding: 16,
  },
  userMsg: {
    maxWidth: '50%',
    margin: 8,
    padding: 8,
    background: '#E5E4E4',
    borderRadius: 8,
    borderBottomRightRadius: 0,
  },
  botMsg: {
    maxWidth: '50%',
    margin: 8,
    background: '#f4f4f4',
    padding: 8,
    borderRadius: 8,
    borderTopLeftRadius: 0,
  },
  modal: {
    height: 'inherit',
  },
  msgContainer: {
    margin: '10px 10px 0',
    border: 'none',
  },
  selectedMsg: {
    border: '2px solid #ff14149e',
    background: '#ff00001f',
  },
  msg: {
    wordWrap: 'break-word',
    fontWeight: 500,
    margin: 0,
    padding: '0px 10px',
  },
  msgHour: {
    wordWrap: 'break-word',
    fontSize: 10,
    textAlign: 'end',
    fontWeight: 500,
    margin: '0 0 2px',
    padding: '0 7px 0 10px',
  },
}));
