import { Grid } from '@material-ui/core';
import styled from 'styled-components';

export const ContainerDialog = styled(Grid)`
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 54px;

  overflow-y: hidden;

  .MuiAppBar-colorPrimary {
    background-color: #6c50a4;
    position: absolute;
  }

  .MuiOutlinedInput-input {
    background-color: #fff;
  }

  .MuiFormControl-root {
    background-color: #fff;
  }

  .MuiToolbar-root {
    justify-content: space-between;
  }
`;

export const ContentContainer = styled(Grid)`
  overflow-y: auto;
  padding: 20px;
  margin-top: 16px;
`;

export const PhrasesContainer = styled(Grid).attrs({
  className: 'customScroll'
})`
  height: 60vh;
  overflow-y: auto;
  background: #f5f5f5;
  border-radius: 4px;

	max-height: 60vh;
`;

export const AutoCompleteOption = styled.span`
  flex: 1;
  margin: 3px 0;
`;

export const GridInfo = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
