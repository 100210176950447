import styled from 'styled-components';
import MttGrid from 'components/Material/MttGrid/MttGrid';

export const ContainerDialog = styled(MttGrid)`
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 54px;

  overflow-y: hidden;
  .MuiToolbar-root {
    justify-content: space-between;
  }

  .MuiAppBar-colorPrimary {
    background-color: #6c50a4;
    position: absolute;
  }

  .MuiOutlinedInput-input {
    background-color: #fff;
  }

  .MuiFormControl-root {
    background-color: #fff;
  }
`;
export const Content = styled.div`
  padding: 16px;
`;

export const Message = styled.div`
  padding: 16px 4px;
`;

export const FooterButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
