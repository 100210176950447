import React, { useMemo } from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import { useFlow } from 'contexts/Flow/flowContext';
import { useForm } from 'contexts/Form/formContext';
import { InfoForm, infoFormMask } from 'models/InfoForm';
import * as S from './style';
import { IInfoProps, TFielInfo } from './types';
import useTranslator from 'utils/hooks/Translator';

export default function Info(props: IInfoProps) {
  const { fieldsLock = [] } = props;

  const { groupsNames } = useFlow().state;
  const { state, dispatch } = useForm();

  const { dataForm, formErrors } = state;
  const { getTranslation } = useTranslator();

  const selectedGroup = useMemo(
    () =>
      groupsNames.find((group) => group === (state.dataForm?.groupId || '')),
    [groupsNames, state.dataForm?.groupId]
  );

  const handleChangeInput =
    (key: keyof InfoForm) => (e: React.ChangeEvent<HTMLInputElement>) => {
      if (dataForm) {
        const { value } = e.target;
        const valueWithMask = infoFormMask[key](value);
        delete formErrors[key];
        dispatch({
          type: 'updateForm',
          data: {
            dataForm: { ...dataForm, [key]: valueWithMask },
            formErrors,
          },
        });
      }
    };

  const isLocked = (field: TFielInfo) => {
    return fieldsLock.includes(field);
  };

  return (
    <S.HeaderContent>
      <S.HeaderOptions>
        <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor="outlined-adornment-name">
            {getTranslation('info.name')}
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-name"
            value={dataForm?.name || ''}
            startAdornment={isLocked('name') && <LockIcon />}
            disabled={isLocked('name')}
            inputProps={{
              style: { background: isLocked('name') ? '#f2f2f2' : undefined },
              maxLength: 20,
            }}
            labelWidth={120}
            error={!!formErrors['name']}
            onChange={handleChangeInput('name')}
          />
          <FormHelperText error>{formErrors['name']}</FormHelperText>
        </FormControl>

        <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor="outlined-adornment-group">
            {' '}
            {getTranslation('info.group')}
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-group"
            value={selectedGroup || ''}
            startAdornment={<LockIcon />}
            disabled
            labelWidth={60}
            inputProps={{ style: { background: '#f2f2f2' } }}
          />
        </FormControl>
      </S.HeaderOptions>
      <S.GridDescriptionText disabled={isLocked('description')}>
        <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor="outlined-adornment-description">
            {getTranslation('info.description')}
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-description"
            value={dataForm?.description || ''}
            startAdornment={isLocked('description') && <LockIcon />}
            disabled={isLocked('description')}
            inputProps={{
              maxLength: 255,
              style: {
                background: isLocked('description') ? '#f2f2f2' : undefined,
              },
            }}
            labelWidth={70}
            onChange={handleChangeInput('description')}
            multiline
          />
        </FormControl>
        {/* <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor="outlined-adornment-tag">Tag</InputLabel>
          <OutlinedInput
            id="outlined-adornment-tag"
            value={dataForm?.tag || ''}
            inputProps={{
              maxLength: 20,
            }}
            labelWidth={120}
            onChange={handleChangeInput('tag')}
          />
        </FormControl> */}
      </S.GridDescriptionText>
    </S.HeaderContent>
  );
}
