import { Button, TextField, Tooltip } from '@material-ui/core';
import { useState } from 'react';
import { useApp } from 'contexts/App/appContext';
import { UpdateFlags } from 'services/FlagsService';
import * as S from './styles';
import { IFlagsContainerProps, defaultFlags } from './types';
import { IFlag } from 'models/DataFlow';

export default function FlagsContainer(props: IFlagsContainerProps) {
  const { state, form, node, toast } = props;
  const { dispatch: dispatchApp } = useApp();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [currFlag, setCurrFlag] = useState(
    form.flag || {
      color: 'transparent',
      title: '',
    }
  );

  const [flagOptions, setFlagOptions] = useState(state.flags || defaultFlags);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickFlagIcon = (event: React.MouseEvent<HTMLButtonElement>) => {
    const bool = state.editorFlow?.editor_mode === 'fixed';
    if (!bool) {
      if (state) {
        setAnchorEl(event.currentTarget);
      }
    }
  };

  const handleChangeFlagTitle = (newTitle: string, index: number) => {
    flagOptions[index].title = newTitle;
    setFlagOptions([...flagOptions]);
  };

  const handleChangeFlag = (flag: IFlag) => {
    setCurrFlag(flag);

    if (state.editorFlow) {
      state.editorFlow.drawflow.drawflow.Home.data[node].data.flag = flag;
    }
  };

  const handleSaveFlags = async () => {
    const response = await UpdateFlags(
      {
        botName: state.botName || '',
        flags: flagOptions,
      },
      dispatchApp
    );

    if (response.Success) {
      toast('success', 'Flags atualizados com sucesso', 3000);
    } else {
      toast(
        'error',
        response.Message ||
          'Ocorreu algum erro ao atualizar a flag, tente novamente.',
        3000
      );
    }

		handleClose();
  };

  return (
    <>
      <Tooltip title={currFlag.title} arrow placement="top">
        <S.FlagIcon onClick={handleClickFlagIcon} color={currFlag.color} />
      </Tooltip>
      <S.PopoverContainer
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            width: '200px',
            padding: '12px',
            background: '#ddd',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          },
        }}
      >
        {flagOptions.map((flag, index) => (
          <S.FlagContainer key={index}>
            <S.Flag color={flag.color} onClick={() => handleChangeFlag(flag)} />
            {index !== 0 && (
              <TextField
                variant="outlined"
                value={flag.title || ''}
                onChange={(e) => handleChangeFlagTitle(e.target.value, index)}
              />
            )}
          </S.FlagContainer>
        ))}
        <Button onClick={handleSaveFlags}>Salvar</Button>
      </S.PopoverContainer>
    </>
  );
}
