import { DataFlowObject } from 'contexts/Flow/types';
import drawflow from 'drawflow';
import EIntentType from 'enums/EIntentType';
import { DataFlow, DataForm, ItemDataFlow, Output } from 'models/DataFlow';
import DataFormModel from 'models/DataFormModel';

export const mountNode = (dataForm: DataFormModel) => {
  const container = document.createElement('div');
  container.setAttribute('className', 'card-container');

  const titleBox = document.createElement('div');
  const box = document.createElement('div');
  const groupAndBlock = document.createElement('div');

  if (dataForm.isNewGroup()) {
    groupAndBlock.innerHTML = `Bloco: ${dataForm?.linked?.intentId}`;
    const intentName = document.createElement('div');
    intentName.innerHTML += `Grupo: ${dataForm?.linked?.groupId}`;
    groupAndBlock.appendChild(intentName);
  } else if (dataForm.intentType === EIntentType.SimpleQuestion) {
    const validQuestions = dataForm?.inputs?.variables[0].questions.filter(
      (q) => q !== ''
    );

    if (validQuestions) {
      const validQuestionText =
        validQuestions[Math.floor(Math.random() * validQuestions.length)];
      titleBox.innerHTML = validQuestionText || '';
    }
  } else {
    titleBox.innerHTML = dataForm?.description || '';
  }

  if (dataForm.intentType === EIntentType.SendMessage) {
    titleBox.innerHTML = `${dataForm?.sendUser?.message || ''}`;
  }

  box.setAttribute('class', 'box dbclickbox text');

  box.innerHTML = dataForm.getMessage();

  if (dataForm.hasVariables()) {
    if (dataForm.messageType)
      box.innerHTML = `Tipo: ${dataForm.getMessageType()}`;

    if (dataForm.varValue) {
      const varValue = document.createElement('div');
      varValue.innerHTML = `Variável: ${dataForm.varValue}`;
      box.appendChild(varValue);
    }

    box.setAttribute('style', 'padding: 8px 0');
  }

  titleBox.setAttribute('class', 'box dbclickbox text');
  box.appendChild(groupAndBlock);
  container.appendChild(titleBox);
  container.appendChild(box);

  return container;
};

export const blockRightClickEvent = (element: Element | null) => {
  if (!!element) {
    element.addEventListener(
      'contextmenu',
      function (e) {
        e.preventDefault();
        e.stopPropagation();
      },
      false
    );
  }
};

export const blockDragEvent = (element: Element | null) => {
  if (!!element) {
    const blockFuncion = function (e: Event) {
      e.preventDefault();
      e.stopPropagation();
    };
    element.addEventListener('mouseup', blockFuncion, false);
    element.addEventListener('mousemove', blockFuncion, false);
    element.addEventListener('mousedown', blockFuncion, false);
    element.addEventListener('touchend', blockFuncion, false);
    element.addEventListener('touchmove', blockFuncion, false);
    element.addEventListener('touchstart', blockFuncion, false);
  }
};

export const addNodeColor = (nodeId: Number, intentType: EIntentType) => {
  const nodeContainer = document.getElementById(`header-${nodeId}`);

  if (nodeContainer) {
    switch (intentType) {
      case EIntentType.CarouselParent:
        nodeContainer.style.backgroundColor = '#D3E8E8';
        break;
      case EIntentType.CarouselChild:
        nodeContainer.style.backgroundColor = '#D3E8E8';
        break;
      case EIntentType.MultipleChoiceParent:
        nodeContainer.style.backgroundColor = '#D3E8E8';
        break;
      case EIntentType.MultipleChoiceChild:
        nodeContainer.style.backgroundColor = '#D3E8E8';
        break;
      case EIntentType.InitialFlow:
        nodeContainer.style.backgroundColor = '#D3E8D3';
        break;
      case EIntentType.Advanced:
        nodeContainer.style.backgroundColor = '#F8DABF';
        break;
      case EIntentType.QuestionsAndAnswers:
        nodeContainer.style.backgroundColor = '#BFD5F8';
        break;
      case EIntentType.NewGroup:
        nodeContainer.style.backgroundColor = '#DAD3E8';
        break;
      case EIntentType.Welcome:
        nodeContainer.style.backgroundColor = '#9AD37A';
        break;
      case EIntentType.Fallback:
        nodeContainer.style.backgroundColor = '#FFDC5E';
        break;
      case EIntentType.Cancel:
        nodeContainer.style.backgroundColor = '#F77464';
        break;
      case EIntentType.Others:
        nodeContainer.style.backgroundColor = '#FFE78B';
        break;
      case EIntentType.Livechat:
        nodeContainer.style.backgroundColor = '#FFE78B';
        break;
      case EIntentType.SaveTable:
        nodeContainer.style.backgroundColor = '#FFE78B';
        break;
      case EIntentType.SendMessage:
        nodeContainer.style.backgroundColor = '#7A81FF';
        break;
      case EIntentType.SimpleQuestion:
        nodeContainer.style.backgroundColor = '#4F8F00';
        break;
      case EIntentType.SendDocument:
        nodeContainer.style.backgroundColor = '#016D00';
        break;
      case EIntentType.Logic:
        nodeContainer.style.backgroundColor = '#FEAE01';
        break;
      case EIntentType.FlexBlock:
        nodeContainer.style.backgroundColor = '#ff40ff';
        break;
      default:
        break;
    }
  }
};

export const mountOutputsName = (
  nodeId: number,
  outputs: Output[],
  editorFlow: drawflow | null
) => {
  const nodeContainer = document.getElementById(`node-${nodeId}`);
  if (nodeContainer) {
    const outpustCollection = nodeContainer.getElementsByClassName('output');
    for (let index = 0; index < outpustCollection.length; index++) {
      const outputElement = outpustCollection[index];
      const output = outputs[index];
      if (outputElement && output) {
        outputElement.setAttribute('style', `--o:"${output.title}"`);

        if (editorFlow) {
          const dataForm: DataForm =
            editorFlow.drawflow.drawflow.Home.data[nodeId].data;
          const form = new DataFormModel(dataForm);

          const isParent = form.isParent();

          if (output.outputid !== null && !isParent)
            outputElement.setAttribute('id', `node-child-${output.outputid}`);
        }
      }
    }
  }
};

export const getSelectedNodeId = (idsExclude: number[] = []) => {
  const selecteds = document.getElementsByClassName('node-selected');
  const amount = selecteds.length;
  const ids: number[] = [];
  for (let index = 0; index < amount; index++) {
    const element = selecteds[index];
    const id = Number(element.id.replace(/[^0-9]*/, ''));
    if (!idsExclude.includes(id)) {
      ids.push(id);
    }
  }
  return ids;
};

export const getAllNodes = () => {
  const selecteds = document.getElementsByClassName('drawflow-node');
  const amount = selecteds.length;
  const ids: number[] = [];
  for (let index = 0; index < amount; index++) {
    const element = selecteds[index];
    const id = Number(element.id.replace(/[^0-9]*/, ''));
    ids.push(id);
  }
  return ids;
};

export const selectAllNodes = () => {
  const nodes = getAllNodes();

  for (const id of nodes) {
    const nodeContainer = document.getElementById(`node-${id}`);
    nodeContainer?.classList.remove('node-selected');
    nodeContainer?.classList.add('node-selected');
  }
};

export const removeDeleteButton = () => {
  if (document.getElementsByClassName('drawflow-delete-parent').length) {
    document.getElementsByClassName('drawflow-delete-parent')[0].remove();
  }
};

export const isSelectedNode = (nodeId: number) => {
  const nodeContainer = document.getElementById(`node-${nodeId}`);
  return !!nodeContainer?.classList.contains('node-selected');
};

export const markSelectNodes = (ids: number[]) => {
  for (const id of ids) {
    const nodeContainer = document.getElementById(`node-${id}`);
    const nodeHeader = document.getElementById(`header-${id}`);
    nodeContainer?.classList.remove('node-selected');
    nodeContainer?.classList.add('node-selected');
    nodeHeader?.classList.remove('.header-node-selected');
    nodeHeader?.classList.add('.header-node-selected');
  }
};

export const uncheckSelectNodes = () => {
  const ids = getSelectedNodeId();
  for (const id of ids) {
    const nodeContainer = document.getElementById(`node-${id}`);
    nodeContainer?.classList.remove('node-selected');
  }
};

export const getNodePosition = (nodeId: number) => {
  const nodeContainer = document.getElementById(`node-${nodeId}`);
  const y = Number(nodeContainer?.style.top.replace('px', ''));
  const x = Number(nodeContainer?.style.left.replace('px', ''));
  return { x, y };
};

export const putNodeUp = (nodeId: number) => {
  const nodeContainer = document.getElementById(`node-${nodeId}`);
  if (nodeContainer) {
    nodeContainer.style.zIndex = '99999';
  }
};

export const putNodeDown = (nodeId: number) => {
  const nodeContainer = document.getElementById(`node-${nodeId}`);
  if (nodeContainer) {
    nodeContainer.style.zIndex = '';
  }
};

export const markSearch = (keys: string[]) => {
  umarkSearch();
  for (const key of keys) {
    const nodeContainer = document.getElementById(`node-${key}`);
    nodeContainer?.classList.add('node-search');
  }
};

export const umarkSearch = () => {
  var elements = document.getElementsByClassName('node-search');

  while (elements[0]) {
    elements[0].classList.remove('node-search');
  }
};

export const markHighlighted = (key: string) => {
  unmarkHighlighted();
  const nodeContainer = document.getElementById(`node-${key}`);
  nodeContainer?.classList.add('node-highlighted');
};

export const unmarkHighlighted = () => {
  var elements = document.getElementsByClassName('node-highlighted');

  while (elements[0]) {
    elements[0].classList.remove('node-highlighted');
  }
};

export const markCreated = (key: string) => {
  const nodeContainer = document.getElementById(`node-${key}`);
  nodeContainer?.classList.add('node-created');

  setTimeout(() => {
    unmarkCreated(key);
  }, 5000);
};

export const unmarkCreated = (key: string) => {
  const nodeContainer = document.getElementById(`node-${key}`);
  nodeContainer?.classList.remove('node-created');
};

export const markSelected = (key: string) => {
  const nodeContainer = document.getElementById(`node-${key}`);
  nodeContainer?.classList.add('node-selected');
};

export const unmarkSelected = (key: string) => {
  const nodeContainer = document.getElementById(`node-${key}`);
  const included = !!nodeContainer?.classList.contains('node-selected');
  if (!included) nodeContainer?.classList.remove('node-selected');
};

export function overrideDrag(editorFlow: any) {
  editorFlow.__proto__.dragEnd = function (e: any) {
    let e_pos_x: any;
    let e_pos_y: any;
    var ele_last: any;

    if (e.type === 'touchend') {
      e_pos_x = this.mouse_x;
      e_pos_y = this.mouse_y;
      ele_last = document.elementFromPoint(e_pos_x, e_pos_y);
    } else {
      e_pos_x = e.clientX;
      e_pos_y = e.clientY;
      ele_last = e.target;
    }

    if (this.drag) {
      // eslint-disable-next-line eqeqeq
      if (this.pos_x_start != e_pos_x || this.pos_y_start != e_pos_y) {
        this.dispatch('nodeMoved', this.ele_selected.id.slice(5));
      }
    }

    if (this.drag_point) {
      this.ele_selected.classList.remove('selected');
      // eslint-disable-next-line eqeqeq
      if (this.pos_x_start != e_pos_x || this.pos_y_start != e_pos_y) {
        this.dispatch(
          'rerouteMoved',
          this.ele_selected.parentElement.classList[2].slice(14)
        );
      }
    }

    if (this.editor_selected) {
      this.canvas_x = this.canvas_x + -(this.pos_x - e_pos_x);
      this.canvas_y = this.canvas_y + -(this.pos_y - e_pos_y);
      this.editor_selected = false;
    }
    if (this.connection === true) {
      if (
        ele_last?.classList[0] === 'input' ||
        (this.force_first_input &&
          (ele_last?.closest('.drawflow_content_node') != null ||
            ele_last?.classList[0] === 'drawflow-node'))
      ) {
        let input_id: any;
        let input_class: any;

        if (
          this.force_first_input &&
          (ele_last?.closest('.drawflow_content_node') != null ||
            ele_last?.classList[0] === 'drawflow-node')
        ) {
          if (ele_last?.closest('.drawflow_content_node') != null) {
            input_id = ele_last?.closest('.drawflow_content_node').parentElement
              .id;
          } else {
            input_id = ele_last?.id;
          }
          if (
            Object.keys(this.getNodeFromId(input_id.slice(5)).inputs).length ===
            0
          ) {
            input_class = false;
          } else {
            input_class = 'input_1';
          }
        } else {
          // Fix connection;
          input_id = ele_last?.parentElement?.parentElement?.id;
          input_class = ele_last?.classList[1];
        }
        var output_id = this.ele_selected.parentElement.parentElement.id;
        var output_class = this.ele_selected.classList[1];

        if (input_class !== false) {
          if (
            this.container.querySelectorAll(
              '.connection.node_in_' +
                input_id +
                '.node_out_' +
                output_id +
                '.' +
                output_class +
                '.' +
                input_class
            ).length === 0
          ) {
            // Conection no exist save connection

            this.connection_ele.classList.add('node_in_' + input_id);
            this.connection_ele.classList.add('node_out_' + output_id);
            this.connection_ele.classList.add(output_class);
            this.connection_ele.classList.add(input_class);
            var id_input = input_id.slice(5);
            var id_output = output_id.slice(5);

            this.drawflow.drawflow[this.module].data[id_output].outputs[
              output_class
            ].connections.push({ node: id_input, output: input_class });
            this.drawflow.drawflow[this.module].data[id_input].inputs[
              input_class
            ].connections.push({ node: id_output, input: output_class });
            this.updateConnectionNodes('node-' + id_output);
            this.updateConnectionNodes('node-' + id_input);
            this.dispatch('connectionCreated', {
              output_id: id_output,
              input_id: id_input,
              output_class: output_class,
              input_class: input_class,
            });
          } else {
            this.dispatch('connectionCancel', true);
            this.connection_ele.remove();
          }

          this.connection_ele = null;
        } else {
          // Connection exists Remove Connection;
          this.dispatch('connectionCancel', true);
          this.connection_ele.remove();
          this.connection_ele = null;
        }
      } else {
        // Remove Connection;
        this.dispatch('connectionCancel', true);
        this.connection_ele.remove();
        this.connection_ele = null;
      }
    }

    this.drag = false;
    this.drag_point = false;
    this.connection = false;
    this.ele_selected = null;
    this.editor_selected = false;
  };
}

export const fixInputs = (block: ItemDataFlow, allBlocks: DataFlow) => {
  const outputKeys = Object.keys(block.outputs);
  for (const key in outputKeys) {
    const output = block.outputs[outputKeys[key]];

    if (output && output.connections) {
      output.connections.forEach((connection) => {
        const destinationBlockId = connection.node;

        if (allBlocks[destinationBlockId]) {
          const destinationBlock = allBlocks[destinationBlockId];
          const destinationBlockInputsConnections =
            destinationBlock.inputs.input_1.connections;
          const isInputsOk = destinationBlockInputsConnections.find(
            (con) =>
              String(con.node) === String(block.id) &&
              outputKeys[key] === con.input
          );

          if (isInputsOk) return;
          console.log('fez correção de input', block);

          destinationBlock.inputs.input_1.connections.push({
            node: block.id,
            input: outputKeys[key],
          });
        }
      });
    }
  }
};

export const fixOutputs = (block: ItemDataFlow, allBlocks: DataFlow) => {
  const inputsKeys = Object.keys(block.inputs);
  // try {
  for (const key in inputsKeys) {
    const input = block.inputs[inputsKeys[key]];

    if (input && input.connections) {
      input.connections.forEach((connection) => {
        const originBlockId = connection.node;

        if (allBlocks[originBlockId]) {
          const originBlock = allBlocks[originBlockId];
          if (Object.keys(originBlock.inputs).length === 0) return;
          if (
            Object.keys(originBlock.outputs).length === 0 ||
            !originBlock.outputs[String(connection.input)]
          ) {
            const brokenConnectionIndex = block.inputs[
              'input_1'
            ].connections.findIndex(
              (con) => con.input === String(connection.input)
            );

            delete block.inputs['input_1'].connections[brokenConnectionIndex];

            return;
          }

          const originBlockOutputsConnections =
            originBlock.outputs[String(connection.input)].connections;
          const isOutputsOk =
            originBlockOutputsConnections.length > 0 ||
            originBlockOutputsConnections.some(
              (con) => String(con.node) === String(block.id)
            );

          if (isOutputsOk) return;

          originBlock.outputs[String(connection.input)].connections.push({
            node: block.id,
            output: inputsKeys[key],
          });

          console.log('fez correção de output', originBlock);
        }
      });
    }
  }
  // } catch {
  //   console.log(block);
  // }
};

export const fixInputsAndOutputs = (drawflow: DataFlowObject) => {
  const drawflowNodes = drawflow.Home.data;
  const allNodesKeys = Object.keys(drawflowNodes);

  for (const key of allNodesKeys) {
    if (drawflowNodes[Number(key)]) {
      const inputs = drawflowNodes[Number(key)].inputs;
      const outputs = drawflowNodes[Number(key)].outputs;

      if (inputs && inputs.input_1) {
        const inputConnections = inputs.input_1.connections;

        if (inputConnections) {
          inputs.input_1.connections = inputConnections.filter(
            (connection) => allNodesKeys.indexOf(String(connection.node)) !== -1
          );
        }
      }

      fixInputs(drawflowNodes[Number(key)], drawflowNodes);
      fixOutputs(drawflowNodes[Number(key)], drawflowNodes);

      if (outputs && typeof outputs === 'object') {
        const outputsKeys = Object.keys(outputs);

        for (const output in outputsKeys) {
          const connectionsKeys = Object.keys(
            outputs[outputsKeys[Number(output)]].connections
          );
          for (const key of connectionsKeys) {
            if (
              allNodesKeys.indexOf(
                String(
                  outputs[outputsKeys[Number(output)]].connections[Number(key)]
                    .node
                )
              ) === -1
            ) {
              outputs[outputsKeys[Number(output)]].connections.splice(
                Number(key),
                1
              );
            }
          }
        }
      }
    }
  }
  return drawflow;
};
