import React from 'react';

export interface ISVGProps {
  size?: string;
  color?: string;
}

const AdminIcon: React.FC<ISVGProps> = (props) => {
  const { size, color } = props;

  return (
    <svg
      width={size || '24'}
      height={size || '24'}
      viewBox="0 0 24 24"
      fill={color || '#0000008a'}
    >
      <g id="Grupo_2821" data-name="Grupo 2821">
        <path
          id="Caminho_4988"
          data-name="Caminho 4988"
          d="M0,0H24V24H0Z"
          fill="none"
        />
      </g>
      <g id="Grupo_2823" data-name="Grupo 2823">
        <g id="Grupo_2822" data-name="Grupo 2822">
          <circle
            id="Elipse_56"
            data-name="Elipse 56"
            cx="4"
            cy="4"
            r="4"
            transform="translate(6 4)"
            fill="#424242"
          />
          <path
            id="Caminho_4989"
            data-name="Caminho 4989"
            d="M10.67,13.02c-.22-.01-.44-.02-.67-.02a12.876,12.876,0,0,0-6.61,1.82A2.922,2.922,0,0,0,2,17.35V20h9.26A6.963,6.963,0,0,1,10,16,7.072,7.072,0,0,1,10.67,13.02Z"
            fill="#424242"
          />
          <path
            id="Caminho_4990"
            data-name="Caminho 4990"
            d="M20.75,16a4.338,4.338,0,0,0-.06-.63l1.14-1.01-1-1.73-1.45.49a3.647,3.647,0,0,0-1.08-.63L18,11H16l-.3,1.49a3.647,3.647,0,0,0-1.08.63l-1.45-.49-1,1.73,1.14,1.01a4.338,4.338,0,0,0-.06.63,4.338,4.338,0,0,0,.06.63l-1.14,1.01,1,1.73,1.45-.49a3.647,3.647,0,0,0,1.08.63L16,21h2l.3-1.49a3.647,3.647,0,0,0,1.08-.63l1.45.49,1-1.73-1.14-1.01A4.338,4.338,0,0,0,20.75,16ZM17,18a2,2,0,1,1,2-2A2.006,2.006,0,0,1,17,18Z"
            fill="#424242"
          />
        </g>
      </g>
    </svg>
  );
};

export default AdminIcon;
