import React from 'react';

import FlowComponent from 'components/Flow';
import ModalForms from 'components/ModalForms';
import ModalNewGroup from 'components/ModalNewGroup';
import ModalSavedBlocks from 'components/ModalSavedBlocks';
import ModalSaveSelectedBlocks from 'components/ModalSaveSelectedBlocks';
import ModalStandardTemplates from 'components/ModalStandardTemplates';
import ModalTemplateUpload from 'components/ModalTemplateUpload';
import ModalInactiveWarning from 'components/ModalInactiveWarning';
import ModalUsedGroup from 'components/ModalUsedGroup';
import ModalAccessKeys from 'components/ModalAccessKeys';
import ModalManageAccount from 'components/ModalManageAccount';
// import ModalManageOrganization from 'components/ModalManageOrganization';
import ModalManageCompanies from 'components/ModalManageCompanies';
import ModalManagePermission from 'components/ModalManagePermission';
import ModalPhrasesOfIntentManager from 'components/ModalPhrasesOfIntentManager';
import ModalCorrectSentences from 'components/ModalCorrectSentences';
import ModalBlockedGroup from 'components/ModalBlockedGroup';
import { CompanyProvider } from 'contexts/Company';
import ModalFlexBlocks from 'components/ModalFlexBlocks';

const Flow: React.FC = () => {
  return (
    <>
      <FlowComponent />
      <ModalForms />
      <ModalNewGroup />
      <ModalStandardTemplates />
      <ModalFlexBlocks />
      <ModalSavedBlocks />
      <ModalSaveSelectedBlocks />
      <ModalTemplateUpload />
      <ModalInactiveWarning />
      <ModalUsedGroup />
      <ModalAccessKeys />
      <ModalManageAccount />
      <CompanyProvider>
        <ModalManageCompanies />
      </CompanyProvider>
      <ModalManagePermission />
      <ModalPhrasesOfIntentManager />
      <ModalCorrectSentences />
      <ModalBlockedGroup />
    </>
  );
};

export default Flow;
