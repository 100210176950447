import { useCallback, useEffect, useState } from 'react';

import { useForm } from 'contexts/Form/formContext';
import CloseIcon from '@material-ui/icons/CloseRounded';
import DeleteIcon from '@material-ui/icons/Delete';

import { IconButton, TextField } from '@material-ui/core';

import * as S from './styles';
import { ISentencesProps } from './types';
import useTranslator from 'utils/hooks/Translator';

export default function NewCarouselOutputsSentences(props: ISentencesProps) {
  const { index, choice, showRemoveButton } = props;
  const { state, dispatch } = useForm();
  const { dataForm, formErrors } = state;
  const [inputValue, setInputValue] = useState<string>('');
  const [values /*,setValues*/] = useState(choice.userSentences || ['']);
  const { getTranslation } = useTranslator();

  const load = useCallback(() => {
    if (dataForm && choice) {
      if (!choice.userSentences) {
        choice.userSentences = [''];
      }

      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choice, index]);

  useEffect(() => {
    load();
  }, [load]);

  const handleChangeTitle = (newTitle: string) => {
    if (dataForm && dataForm.outputs && dataForm.outputs[index]) {
      if (choice.title !== 'Outros') {
        values[0] = newTitle;
        choice.title = newTitle;
        dataForm.outputs[index] = choice;

        if (choice.userSentences) choice.userSentences[0] = newTitle;

        if (dataForm) {
          dispatch({
            type: 'updateForm',
            data: {
              dataForm: {
                ...dataForm,
              },
            },
          });
        }
      }
    }
  };

  const handleChangeField = (
    field: 'url' | 'value' | 'description',
    newValue: string
  ) => {
    if (dataForm && dataForm.outputs && dataForm.outputs[index]) {
      if (choice.title !== 'Outros') {
        choice[field] = newValue;
        dataForm.outputs[index] = choice;

        if (dataForm) {
          dispatch({
            type: 'updateForm',
            data: {
              dataForm: {
                ...dataForm,
              },
            },
          });
        }
      }
    }
  };

  const handleDeleteOption = () => {
    if (!!dataForm && dataForm.outputs) {
      dataForm.outputs.splice(
        dataForm.outputs.findIndex((c) => c.title === choice.title),
        1
      );

      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
  };

  const handleDeleteSynonym = (phraseIndex: number) => {
    if (!!dataForm && dataForm.outputs) {
      values.splice(phraseIndex, 1);
      dataForm.outputs[index].userSentences = values;

      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
  };

  const handleInsertValue = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (choice.title !== 'Outros') {
      if (event.keyCode === 13 || event.keyCode === 9) {
        if (inputValue !== '') {
          values.push(inputValue);

          if (!!dataForm && dataForm.outputs) {
            dataForm.outputs[index].userSentences = values;
            dispatch({
              type: 'updateForm',
              data: {
                dataForm: {
                  ...dataForm,
                },
              },
            });
          }
        }
        setInputValue('');
      }
    }
  };

  const handleShowSynonyms = (item: string, index: number) => {
    if (item !== '') {
      return (
        <S.GridSynonym key={index}>
          {item}
          {index !== 0 && (
            <IconButton onClick={() => handleDeleteSynonym(index)}>
              <CloseIcon />
            </IconButton>
          )}
        </S.GridSynonym>
      );
    } else return null;
  };

  return (
    <S.Container>
      {choice.title !== 'Outros' && showRemoveButton ? (
        <IconButton
          onClick={() => handleDeleteOption()}
          style={{ alignSelf: 'flex-end' }}
        >
          <DeleteIcon style={{ alignSelf: 'center' }} htmlColor="#6c50a4" />
        </IconButton>
      ) : (
        <></>
      )}
      <S.ContainerOptionHeader>
        <S.OptionIndexAndTip>
          <S.TextContainer>
            <TextField
              fullWidth
              variant="outlined"
              value={choice.title}
              onChange={(e) => handleChangeTitle(e.target.value)}
              label={getTranslation('outputTitle')}
              helperText={formErrors[`outputs[${index}].title`]}
              error={!!formErrors[`outputs[${index}].title`]}
              inputProps={{ maxLength: 40 }}
            />
          </S.TextContainer>
          <S.TextContainer>
            <TextField
              value={choice.url}
              variant="outlined"
              label={getTranslation('imageUrl')}
              fullWidth
              onChange={(e) => handleChangeField('url', e.target.value)}
            />
          </S.TextContainer>
          <S.TextContainer>
            <TextField
              value={choice.value}
              type="number"
              variant="outlined"
              label={getTranslation('optionalValue')}
              fullWidth
              onChange={(e) => handleChangeField('value', e.target.value)}
            />
          </S.TextContainer>
        </S.OptionIndexAndTip>
      </S.ContainerOptionHeader>
      <S.ContainerOptionContent>
        <S.TextContainer>
          <TextField
            value={choice.description}
            variant="outlined"
            label={getTranslation('description')}
            fullWidth
            inputProps={{ maxLength: 140 }}
            onChange={(e) => handleChangeField('description', e.target.value)}
          />
        </S.TextContainer>
      </S.ContainerOptionContent>
      <S.ContainerOptionContent>
        <S.GridInputDefinition>
          <TextField
            onChange={(e) => setInputValue(e.target.value)}
            variant="standard"
            disabled={choice.title === ''}
            fullWidth
            value={choice.title === 'Outros' ? choice.title : inputValue}
            placeholder={`${getTranslation('variables.typeSynonym')} "${
              choice.title || ''
            }"`}
            onKeyDown={(event) => handleInsertValue(event)}
          />
        </S.GridInputDefinition>
        <S.ContentSynonyms>
          {values.map((item, index) => handleShowSynonyms(item, index))}
        </S.ContentSynonyms>
      </S.ContainerOptionContent>
    </S.Container>
  );
}
