import React, { useState } from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import { ModalImportExportBotProps } from './types';

import { AppBar, Dialog, Slide, Toolbar, Typography } from '@material-ui/core';

import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import RestoreIcon from '@material-ui/icons/Restore';

import ModalRestoreBot from './ModalRestoreBot'

import {
  ContainerDialog,
  Button,
  ButtonContainer,
  useStyles
} from './styles';

import { CircularProgress } from '@material-ui/core';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalImportExportBot(props: ModalImportExportBotProps) {
  const classes = useStyles();
  const [openModalRestore, setOpenModalRestore] = useState(false);
  const { open, handleImportBot, handleExportBot, handleClose } =
    props;
  const [load /*setLoad*/] = useState(false);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      maxWidth="md"
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      onClose={handleClose}

    >
      <ContainerDialog >
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <Typography variant="h6" style={{ fontSize: '1.5rem' }}>
              Backup de Bot
            </Typography>
          </Toolbar>
        </AppBar>
        <ButtonContainer>
          <Button
            style={{ letterSpacing: 2, borderRadius: 8 }}
            variant="contained"
            color="primary"
            onClick={handleImportBot}
            startIcon={<PublishIcon />}
          >
            Importar
            {load && <CircularProgress color="inherit" size={18} />}
          </Button>

          <Button
            style={{ letterSpacing: 2, borderRadius: 8 }}
            variant="contained"
            color="primary"
            onClick={handleExportBot}
            startIcon={<GetAppIcon />}
          >
            Exportar
            {load && <CircularProgress color="inherit" size={18} />}
          </Button>

          <Button
            style={{ letterSpacing: 2, borderRadius: 8 }}
            variant="contained"
            color="primary"
            onClick={() => setOpenModalRestore(true)}
            startIcon={<RestoreIcon />}
          >
            Restaurar
            {load && <CircularProgress color="inherit" size={18} />}
          </Button>
        </ButtonContainer>
      </ContainerDialog>
      <ModalRestoreBot open={openModalRestore} handleClose={() => setOpenModalRestore(false)} />
    </Dialog>
  );
}
