import { IGetLoginRequest } from './types';
import { IModifyPassword } from './types';

const apiUrl = process.env.REACT_APP_URL;

export const AuthorizeUser = async (request: IGetLoginRequest) => {
  const action = 'login';

  const resp = await fetch(apiUrl + action, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(request),
  });

  return await resp.json();
};

export const RecoverEmail = async (cpf: string) => {
  const url = `${apiUrl}Auth/RecoveryEmail/${cpf}`;
  const resp = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  });

  return await resp.json();
};

export const SendVerifyCode = async (email: string) => {
  const body = { email };
  const url = `${apiUrl}user/forget_password`;
  const resp = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  });

  return await resp.json();
};

export const ModifyPassword = async (body: IModifyPassword) => {
  const url = `${apiUrl}user/change_password`;
  const resp = await fetch(url, {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  });

  return await resp.json();
};

export const RefreshUserToken = async (): Promise<{
  token: string;
  refresh_token: string;
} | void> => {
  const action = 'user/refresh';
  const refreshToken = localStorage.getItem('refresh_token');
  const token = localStorage.getItem('token');

  if (!!refreshToken) {
    const resp = await fetch(apiUrl + action, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Refresh: refreshToken,
        Authorization: token ?? '',
      },
    });

    return await resp.json();
  }
};

export const LogOut = async (): Promise<void> => {
  const action = 'logout';
  const refreshToken = localStorage.getItem('refresh_token');
  const token = localStorage.getItem('token');

  if (!!refreshToken && !!token) {
    await fetch(apiUrl + action, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Refresh: refreshToken,
        Authorization: token,
      },
    });
  }

  localStorage.removeItem('token');
  localStorage.removeItem('refresh_token');
};

export const CognitoLogin = async (request: IGetLoginRequest) => {
  const action = 'user/login';

  const resp = await fetch(apiUrl + action, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(request),
  });

  return await resp.json();
};


export const CognitoLogOut = async (): Promise<void> => {
  const action = 'user/logout';

  const refreshToken = localStorage.getItem('refresh_token');
  const token = localStorage.getItem('token');

  if (!!refreshToken && !!token) {
    await fetch(apiUrl + action, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Refresh: refreshToken,
        Authorization: token,
      },
    });
  }

  localStorage.removeItem('token');
  localStorage.removeItem('refresh_token');
};