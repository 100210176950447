/* eslint-disable array-callback-return */
import React, { useCallback, useEffect, useState } from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import {
  AppBar,
  Dialog,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';

import { useFlow } from 'contexts/Flow/flowContext';
import { GetKeys, UpdateKeys } from 'services/AccessKeysService';
import { Key } from 'services/AccessKeysService/types';
import { newAccessKeyValidation } from 'models/NewAccessKey';

import * as S from './styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { CircularProgress } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { validateVarNames } from 'utils/String';
import { useApp } from 'contexts/App/appContext';
import useTranslator from 'utils/hooks/Translator';
import { usePermissions } from 'contexts/Permissions/permissionsContext';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalAccessKeys() {
  const { state, dispatch, toastNotification } = useFlow();
  const { dispatch: appDispatch } = useApp();
  const { botName } = state;
  const { hasPermissionToAction } = usePermissions();
  const classes = S.useStyles();

  const [accessKeys, setAccessKeys] = useState<Key[]>([]);
  const [newAccessKey, setNewAccessKey] = useState<Key>([{ '': '' }]);
  const [editingKey, setEditingKey] = useState<string | number>('');
  const [editingKeyNewValues /*setEditingKeyNewValues*/] = useState({
    key: '',
    value: '',
  });

  const [errors, setErrors] = useState<any>({
    key: '',
    value: '',
    existingKeys: accessKeys,
  });

  const isAccessKeyWrite = hasPermissionToAction({
    company: state.companyName!,
    agent: state.botName!,
    action: ['access_key:write'],
  });

  const [load /*setLoad*/] = useState(false);

  const { getTranslation } = useTranslator();

  const updateStateKeys = useCallback(
    (keys: Key[]) => {
      const urlFalazap = keys.find((resKey) => {
        if (resKey.key === 'url-falazap') return resKey.value;
      });
      const urlFacebook = keys.find((resKey) => {
        if (resKey.key === 'url-facebook') return resKey.value;
      });
      const urlTelegram = keys.find((resKey) => {
        if (resKey.key === 'url-telegram') return resKey.value;
      });
      const urlWhatsApp = keys.find((resKey) => {
        if (resKey.key === 'url-whatsapp') return resKey.value;
      });
      const urlTwitter = keys.find((resKey) => {
        if (resKey.key === 'url-twitter') return resKey.value;
      });

      const botsUrls = {
        urlFalazap: urlFalazap?.value || '',
        urlFacebook: urlFacebook?.value || '',
        urlTelegram: urlTelegram?.value || '',
        urlTwitter: urlTwitter?.value || '',
        urlWhatsApp: urlWhatsApp?.value || '',
      };

      dispatch({ type: 'updateForm', data: { botsUrls } });
    },
    [dispatch]
  );

  useEffect(() => {
    const getKeys = async () => {
      if (botName) {
        const result = await GetKeys(botName, appDispatch);
        if (result.Success) {
          if (result.Data.data) {
            const keys = Object.keys(result.Data.data.keys).sort((a, b) =>
              a.localeCompare(b, undefined, { sensitivity: 'base' })
            );
            if (keys) {
              let values: React.SetStateAction<Key[]> = [];
              for (const key of keys) {
                const element = result.Data.data.keys[key as any];
                values.push({ key, value: element });
              }
              setAccessKeys(values);
              updateStateKeys(values);
            }
          } else {
            setAccessKeys([]);
          }
        }
      }
    };
    getKeys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botName, state.openModalAccessKeys, updateStateKeys]);

  function handleClose() {
    setEditingKey('');
    setNewAccessKey({ '': '' });
    setErrors({ key: '', value: '' });
    dispatch({ type: 'closeModalAccessKeys' });
  }

  const handleAddKey = async () => {
    const { isValid, errors } = await newAccessKeyValidation(
      newAccessKey.key,
      newAccessKey.value,
      accessKeys,
      {
        duplicatedKey: getTranslation('validations.duplicatedKey'),
        requiredKey: getTranslation('validations.requiredKey'),
        requiredValue: getTranslation('validations.requiredValue'),
      }
    );
    setErrors(errors);
    if (!isValid) return { isValid, errors };

    setAccessKeys([...accessKeys, newAccessKey]);

    setNewAccessKey({ '': '' });

    setEditingKey('');

    return { isValid, newAccessKey };
  };

  const handleChangeKey = (
    e: React.ChangeEvent | any,
    keyChanged: string,
    valueChanged: string,
    index: number
  ) => {
    if (e.target.id === 'key') {
      accessKeys[index] = { key: e.target.value, value: valueChanged };
    } else {
      accessKeys[index] = { key: keyChanged, value: e.target.value };
    }

    setAccessKeys([...accessKeys]);
  };

  const handleRemoveKey = (keyToRemove: string | number) => {
    const key = accessKeys.findIndex((key) => {
      return key.key === keyToRemove;
    });

    if (key >= 0) accessKeys.splice(key, 1);

    setAccessKeys([...accessKeys]);
  };

  const handleSaveKeys = async () => {
    if (botName) {
      let keys: { [key: string]: string } = {};
      let error = false;
      if (
        !(newAccessKey.key === undefined || newAccessKey.value === undefined)
      ) {
        const resp = await handleAddKey();
        if (resp?.isValid) {
          keys = objectKeys([...accessKeys, resp.newAccessKey]);
        } else error = true;
      }

      if (!error) {
        keys = Object.keys(keys).length === 0 ? objectKeys(accessKeys) : keys;
        const result = await UpdateKeys({ botName, keys }, appDispatch);

        if (result.Success) {
          toastNotification(
            'success',
            getTranslation('toast.success.accessKeys')
          );
        } else
          toastNotification('error', getTranslation('toast.error.accessKeys'));
        setEditingKey('');
        setNewAccessKey({ '': '' });
      }
    }
  };

  const objectKeys = (accKeys: Key[]) => {
    const keys: Key = {};

    for (let index = 0; index < accKeys.length; index++) {
      const indexKey = accKeys[index].key;
      const indexValue = accKeys[index].value;
      keys[indexKey] = indexValue;
    }

    return keys;
  };

  if (!state.openModalAccessKeys) return <></>;

  return (
    <Dialog
      open={state.openModalAccessKeys}
      TransitionComponent={Transition}
      maxWidth="sm"
      fullWidth
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <S.ContainerDialog>
        <AppBar>
          <Toolbar className={classes.toolbar}>
            <Typography variant="h6">{getTranslation('accessKeys')}</Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <S.Container>
          <S.FieldContainer>
            <Table size="small" style={{ width: '100%' }}>
              <TableHead>
                <TableRow>
                  <TableCell>{getTranslation('key')}</TableCell>
                  <TableCell>{getTranslation('value')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {accessKeys.map((key, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="key">
                      <TextField
                        value={
                          editingKey === key.key &&
                          editingKeyNewValues.key !== ''
                            ? editingKeyNewValues.key
                            : key.key
                        }
                        id={key.key}
                        onChange={(e) =>
                          handleChangeKey(e, key.key, key.value, index)
                        }
                        disabled={!(editingKey === index)}
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={
                          editingKey === key.key &&
                          editingKeyNewValues.value !== ''
                            ? editingKeyNewValues.value
                            : key.value
                        }
                        id={key.value}
                        onChange={(e) =>
                          handleChangeKey(e, key.key, key.value, index)
                        }
                        disabled={!(editingKey === index)}
                        fullWidth
                      />
                    </TableCell>
                    {isAccessKeyWrite && (
                      <>
                        <TableCell className={classes.buttonTableCell}>
                          <IconButton
                            edge="end"
                            color="default"
                            onClick={() => setEditingKey(index)}
                          >
                            <EditIcon />
                          </IconButton>
                        </TableCell>
                        <TableCell className={classes.buttonTableCell}>
                          <IconButton
                            edge="end"
                            color="default"
                            onClick={() => handleRemoveKey(key.key)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </S.FieldContainer>
        </S.Container>
        {isAccessKeyWrite && (
          <S.NewKeyContainer>
            <S.ContainerHeader>
              <S.ModalTitle style={{ fontWeight: 600 }}>
                {getTranslation('addKey')}
              </S.ModalTitle>
            </S.ContainerHeader>
            <S.LineContainer>
              <S.TextContainer>
                <TextField
                  required
                  id="key"
                  label={getTranslation('key')}
                  fullWidth
                  value={newAccessKey?.key || ''}
                  error={!!errors.key}
                  helperText={errors.key || ''}
                  onBlur={() => setErrors({ key: '', value: '' })}
                  onChange={(e) => {
                    const validatedStringForKey = validateVarNames(e, 'name');

                    setNewAccessKey({
                      key: validatedStringForKey,
                      value: newAccessKey?.value,
                    });
                  }}
                />
              </S.TextContainer>
              <S.TextContainer>
                <TextField
                  required
                  id="value"
                  label={getTranslation('value')}
                  fullWidth
                  value={newAccessKey?.value || ''}
                  error={!!errors.value}
                  helperText={errors.value || ''}
                  onBlur={() => setErrors({ key: '', value: '' })}
                  onChange={(e) => {
                    setNewAccessKey({
                      key: newAccessKey?.key,
                      value: e.target.value,
                    });
                  }}
                />
              </S.TextContainer>
              <IconButton edge="start" color="default" onClick={handleAddKey}>
                <AddIcon />
              </IconButton>
            </S.LineContainer>
            <S.ButtonContainer>
              <S.Button
                style={{ letterSpacing: 2, borderRadius: 8 }}
                variant="contained"
                color="primary"
                onClick={handleSaveKeys}
              >
                {getTranslation('save')}
                {load && <CircularProgress color="inherit" size={18} />}
              </S.Button>
            </S.ButtonContainer>
          </S.NewKeyContainer>
        )}
      </S.ContainerDialog>
    </Dialog>
  );
}
