const payloadURL = process.env.REACT_APP_S3_URL_PUBLIC || '';
const payloadApiKey = process.env.REACT_APP_S3_API_KEY || '';
const payloadBucket = process.env.REACT_APP_S3_BUCKET || '';

export async function getFileFromUrl(url: string, defaultType = 'image/jpeg') {
  const name = 'headerImage';
  const response = await fetch(url);
  const data = await response.blob();
  return new File([data], name, {
    type: data.type || defaultType,
  });
}

export function getFormData(file: File) {
  const formData = new FormData();

  formData.append('file', file);
  formData.append('bucket', payloadBucket);
  return formData;
}

export async function handleFileUpload(file: File) {
  const formData = getFormData(file);

  const payloadResponse = await fetch(payloadURL, {
    method: 'POST',

    headers: {
      'X-API-Key': payloadApiKey,
    },

    body: formData,
  });

  return await payloadResponse.json();
}
