import { useMemo, useState } from 'react';

import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import {
  ContentItemAdd,
  AutoCompleteWrap,
  TextContainer,
  ContentLimit,
  TableContainer,
} from '../styles';
import Checkbox from '@material-ui/core/Checkbox';
import { Autocomplete } from '@material-ui/lab';
import useTranslator from 'utils/hooks/Translator';
import { useCompanies } from 'contexts/Company';
import {
  CustomPaper,
  HoverableRow,
} from 'components/ModalManageCompanies/styles';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import React from 'react';
import { usePermissions } from 'contexts/Permissions/permissionsContext';

type Props = {
  sourceAgents: string[];
  currentAgents: string[];
  objectToEdit: 'company' | 'project' | 'group';
};

export function ManageAgents({
  objectToEdit,
  sourceAgents,
  currentAgents,
}: Props) {
  const { dispatch, state } = useCompanies();
  const [errorLimit, setErrorLimit] = useState<string>('');
  const { isRoot } = usePermissions();
  const [selectedCurrentAgents, setSelectedCurrentAgents] = useState<string[]>(
    []
  );
  const { getTranslation } = useTranslator();
  const dispatchType = getDispatchType();

  const disabledAddAgent =
    selectedCurrentAgents.length === 0 ||
    (selectedCurrentAgents.concat(currentAgents).length >
      state.company.agentsLimit &&
      objectToEdit === 'company');

  function getDispatchType() {
    switch (objectToEdit) {
      case 'company':
        return 'setCompany';
      case 'project':
        return 'setProject';
      case 'group':
        return 'setGroup';
    }
  }

  function addAgent(agentToAdd: string[]) {
    const cloneAgents = [...currentAgents];
    agentToAdd.forEach((agent) => {
      if (!cloneAgents.includes(agent)) {
        cloneAgents.push(agent);
      }
    });

    dispatch({
      type: dispatchType,
      data: { [objectToEdit]: { agents: cloneAgents } },
    });

    setSelectedCurrentAgents([]);
  }

  function removeAgent(agentName: string) {
    const cloneAgents = [...currentAgents];

    const indexToRemove = cloneAgents.findIndex((agent) => agent === agentName);

    if (indexToRemove !== -1) {
      cloneAgents.splice(indexToRemove, 1);
      if (state.company.agentsLimit >= cloneAgents.length) setErrorLimit('');
      dispatch({
        type: dispatchType,
        data: { [objectToEdit]: { ...[objectToEdit], agents: cloneAgents } },
      });
    }
  }

  const differentAgents = useMemo(() => {
    const differentBots =
      sourceAgents.filter(
        (sourceAgent) => !currentAgents?.includes(sourceAgent)
      ) || [];

    return differentBots.sort();
  }, [currentAgents, sourceAgents]);

  const handleChangeLimit = (agentsLimit: number) => {
    if (agentsLimit >= currentAgents.length) setErrorLimit('');

    dispatch({
      type: dispatchType,
      data: { [objectToEdit]: { ...[objectToEdit], agentsLimit } },
    });
  };

  const handleErrorLimitAgents = () => {
    const error =
      state.company.agentsLimit < currentAgents.length
        ? getTranslation('modal.manageCompany.agent.limitValidationAgentCount')
        : '';
    setErrorLimit(error);
  };

  return (
    <CustomPaper addminheight={objectToEdit !== 'company'}>
      <h6>
        {objectToEdit === 'company' &&
          getTranslation('modal.manageCompany.agent.manageCompanyAgents')}
        {objectToEdit === 'project' &&
          getTranslation('modal.manageCompany.agent.manageProjectAgents')}
        {objectToEdit === 'group' &&
          getTranslation('modal.manageCompany.agent.manageGroupAgents')}
      </h6>
      {objectToEdit === 'company' && (
        <ContentLimit>
          <TextField
            value={state.company.agentsLimit}
            label={getTranslation('modal.manageCompany.agent.agentLimit')}
            style={{ width: '150px' }}
            type="number"
            disabled={!isRoot()}
            inputProps={{
              min: 0,
            }}
            onChange={(e) => {
              handleChangeLimit(parseInt(e.target.value, 10));
            }}
            onBlur={handleErrorLimitAgents}
            helperText={errorLimit}
            error={!!errorLimit}
          />
        </ContentLimit>
      )}

      <ContentItemAdd>
        <TextContainer>
          <AutoCompleteWrap>
            <Autocomplete
              multiple
              value={selectedCurrentAgents}
              options={differentAgents}
              disableCloseOnSelect
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8, color: selected ? '#6c50a4' : '' }}
                    checked={selected}
                  />
                  {option}
                </React.Fragment>
              )}
              noOptionsText={getTranslation('noResults')}
              getOptionLabel={(option) => option}
              onChange={(_e: any, selectedOptions) => {
                setSelectedCurrentAgents(selectedOptions);
              }}
              fullWidth
              renderInput={(params) => (
                <TextField
                  variant="outlined"
                  {...params}
                  label={getTranslation('agents')}
                  style={{ backgroundColor: '#ffff' }}
                />
              )}
            />
          </AutoCompleteWrap>
        </TextContainer>

        <IconButton
          aria-label="adiciona um agente"
          onClick={() => addAgent(selectedCurrentAgents)}
          disabled={disabledAddAgent}
        >
          <AddIcon />
        </IconButton>
      </ContentItemAdd>
      <TableContainer>
        <Table
          size="small"
          aria-label="Lista de agents"
          style={{ height: 'auto' }}
        >
          <TableHead>
            <TableRow>
              <TableCell style={{ border: 'none' }}>
                {getTranslation('name')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentAgents?.sort().map((agent) => (
              <HoverableRow key={agent}>
                <TableCell>{agent}</TableCell>
                <TableCell style={{ textAlign: 'right', padding: '6px 0px' }}>
                  <IconButton
                    aria-label="remove um agente"
                    onClick={() => removeAgent(agent || '')}
                  >
                    <CloseIcon htmlColor="#fc5858" />
                  </IconButton>
                </TableCell>
              </HoverableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </CustomPaper>
  );
}
