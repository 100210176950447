import { useMemo } from 'react';
import { useFlow } from 'contexts/Flow/flowContext';
import { usePermissions } from 'contexts/Permissions/permissionsContext';
import { Actions } from 'contexts/Permissions/types';

export interface HasAccessProps {
  children: React.ReactChild;
  action: Actions[];
}

const WithHasAccess = ({ children, action }: HasAccessProps) => {
  const { hasPermissionToAction, isCompanyAdmin, isRoot } = usePermissions();
  const { botName: agent, companyName: company } = useFlow().state;

  const verifyAccess = useMemo(() => {
    if (isRoot()) return true;
    if (company && isCompanyAdmin(company)) return true;
    if (company && agent)
      return hasPermissionToAction({ company, agent, action });
  }, [action, agent, company, hasPermissionToAction, isCompanyAdmin, isRoot]);

  return !!verifyAccess ? <>{children}</> : null;
};

export default WithHasAccess;
