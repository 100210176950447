export const validateBlockedIntents = (intentType: EIntentType) => {
  const blocked = [8, 9, 10];
  return blocked.includes(intentType);
};

enum EIntentType {
  InitialFlow,
  MultipleChoiceParent,
  MultipleChoiceChild,
  QuestionsAndAnswers,
  Advanced,
  CarouselParent,
  CarouselChild,
  NewGroup,
  Welcome,
  Fallback,
  Cancel,
  Others,
  SaveTable,
  Livechat,
  OptionsBlock = 130,
  Carousel = 131,
  SendMessage = 100,
  SimpleQuestion = 110,
  SendDocument = 111,
  Logic = 120,
  OpenHTML = 150,
  Rules = 160,
  ABTest = 170,
  NLU = 200,
  FalaGPT = 210,
  IntentRecognition = 211,
  FlexBlock = 300,
  FlexBlockExit = 301,
  FlexBlockEntry = 302,
  VirtualFlexBlock = 303,
}

export default EIntentType;
