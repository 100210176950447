import styled from "styled-components";
import MttGrid from "../Material/MttGrid/MttGrid";
import MttButton from "../Material/MttButton/MttButton";

import { Grid, Typography } from "@material-ui/core";

export const ContainerDialog = styled(MttGrid)<{colorHeader?: string}>`
  width: 55vw;
  height: 100%;

  padding: 8px 16px;
`;

export const ContainerHeader = styled(MttGrid)`
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const ModalTitle = styled(Typography).attrs({
  variant:'h6'
})`
  color:#6c50a4;
`;

export const FieldContainer = styled(Grid)`
  padding: 5px 0;

  .MuiInputBase-input {
    padding: 16px;
    border: solid 1px rgba(0, 0, 0, 0.1);
  }

  .MuiInputBase-input::-webkit-input-placeholder {
    opacity: 0.65;
    font-weight: 500;
  }

  .MuiInput-underline:before{
    display: none;
  }

  .MuiInputBase-multiline{
    padding: 0;
  }

`;

export const SelectFileContainer = styled(Grid)`
  padding: 5px 0;
  margin-top: 5px;
  height: 7rem;

  background: #FFFFFF;
  border-radius: 8px;
  
  display: flex;
  align-items: center;
  justify-content: center;

  strong{
      font-size: 1.2rem;
      opacity: 0.6;
      padding-right: 16px;
  }
`;

export const ButtonContainer = styled(Grid)`
  padding: 16px 0;
  width:100%;  
  display:flex;
  justify-content: flex-end;
`;

export const Button = styled(MttButton)`
  height: 50px;
`;

