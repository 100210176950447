import { Divider, IconButton, Popover, Tooltip } from '@material-ui/core';
import List from '@material-ui/core/List/List';
import ListItem from '@material-ui/core/ListItem/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import IconLLego from 'components/Icons/LLego';
import { useApp } from 'contexts/App/appContext';
import { useFlow } from 'contexts/Flow/flowContext';
import Routes from 'routes';
import LogoutIcon from 'components/Icons/LogoutIcon';
import FlowsUrls from 'components/Menus/FlowsUrls';
import FalazapIframe from 'components/Menus/FlowsUrls/FalazapIframe';

import {
  Container,
  GridHeader,
  GridInputsHeader,
  GridListMenu,
  GridMenu,
  LogoContainer,
  TooltipContainer,
  GridListMenuChild,
} from './styles';
import { IOptions } from './types';
import ModalPublishBot from 'components/ModalPublishBot';
import ModalConversations from './ModalConversations';
import ToastNotification from './ToastNotification';
import {
  UpdateLastAccess,
  DeleteGroup,
  ReleaseGroupAccess,
  UpdateGroup,
} from 'services/FlowService';
import ModalDeleteGroup from './ModalDeleteGroup';
import ModalBotImportError from './ModalBotImportError';
import ModalImportExportBot from './ModalImportExportBot';
import { Group } from 'contexts/Flow/types';
import { IDeleteGroupRequest } from 'services/FlowService/types';

import { getSelectedNodeId } from 'components/Flow/utils/NodeContainer';
import { IEntity } from 'models/Entity';
import { Key } from 'services/AccessKeysService/types';
import exportBot from 'utils/ExportBot';
import {
  createAndUpdateGroups,
  removeNonExistingGroups,
  updateEntities,
  updateKeys,
  updatePrincipalGroup,
} from 'utils/ImportBot/ImportBotService';
import createBotObject from 'utils/CreateBotObject';
import { CognitoLogOut } from 'services/AuthService';
import { localStore } from 'utils/Stores';

import { isValidPrincipalGroup } from 'utils/Helpers';
import ModalUsedTab from 'components/ModalUsedTab';

import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import ModalRestoreBot from './ModalImportExportBot/ModalRestoreBot';

import useTranslator from 'utils/hooks/Translator';
import { Debug } from 'components/Debug';
import BugReportIcon from '@material-ui/icons/BugReport';
import { ModalTemplates } from './Campaigns/ModalTemplates';
import { ModalCampaigns } from './Campaigns/ModalCampaigns';
import { ModalActioning } from './Campaigns/ModalActioning';

import WithHasAccess from 'utils/WithHasAccess';
import useSidebarItems, { slugsListOptions } from 'utils/hooks/useSidebarItems';
import { PublishButton } from './PublishButton';
import useAgents from 'utils/hooks/useAgents';

import { ModalFalazapToken } from 'components/FalazapConfiguration/ModalFalazapToken';
import RenderBots from './RenderBots';
import { DeleteFlexBlockGroup } from 'services/FlexBlocksService';
import flexGroupsTreatment from 'components/Flow/utils/FlexGroupsTreatment';

interface IExportGroup {
  groupName: string;
  blocks: any;
}

export default function Main() {
  const { push } = useHistory();
  const location = useLocation();
  const {
    dispatch,
    saveFlow,
    toastNotification,
    state,
    loadFlow,
    stopEditing,
  } = useFlow();
  const { state: appState } = useApp();
  const { signed, load } = appState;

  const { isAgentFreeToUse } = useAgents();
  const [openModalConversations, setModalConversations] = useState(false);
  const [deleteGroupSelected, setDeleteGroupSelected] = useState<Group | null>(
    null
  );
  const dispatchApp = useApp().dispatch;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { botName, groupsNames, isEditing } = state;

  const [openLibrary, setOpenLibrary] = useState(false);
  const [openAdmin, setOpenAdmin] = useState(false);
  const [openModalImportError, setModalImportError] = useState(false);
  const [openModalImportExportBot, setOpenModalImportExportBot] =
    useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [isClearingFieldsRenderBots, setIsClearingFieldsRenderBots] =
    useState<boolean>(false);
  const [botToRestore, setBotToRestore] = useState<string>();
  const [openFlowTest, setOpenFlowTest] = useState(false);
  const [anchorElFlow, setAnchorElFlow] = useState(null);
  const [openTraining, setOpenTraining] = useState(false);
  const [openCampaign, setOpenCampaign] = useState(false);
  const [openFalazapConfig, setOpenFalazapConfig] = useState(false);
  const [openImportExportBot, setImportExportBot] = useState(false);
  const [openModalRestoreBot, setOpenModalRestoreBot] = useState(false);
  const [openFalazap, setOpenFalazap] = useState(false);
  const [openDebug, setOpenDebug] = useState(false);

  const { getTranslation } = useTranslator();

  const importBot = async () => {
    const botLiberated = await isAgentFreeToUse();

    if (!botLiberated) return;

    const documentHtml = document.getElementById('drawflow');
    const uploadInput = document.createElement('input');
    uploadInput.type = 'file';
    uploadInput.accept = 'application/json';
    setOpenModalImportExportBot(false);

    const currentBot = await createBotObject(botName || '', dispatchApp);
    const currentBotStringified = JSON.stringify(currentBot);

    setBotToRestore(currentBotStringified);

    uploadInput.onchange = async (e: any) => {
      const file = e.target.files?.item(0);
      const fileText = await file.text();
      const drawflow = JSON.parse(fileText);

      const groups: IExportGroup[] = drawflow.groups;
      const entities: IEntity[] = drawflow.entities;
      const importKeys: Key[] = drawflow.accessKeys;

      let requestErrors = [];
      setErrors([]);

      if (!groups || !entities) {
        requestErrors.push(
          getTranslation('validations.invalidJsonFormatImportAgent')
        );
      }

      if (requestErrors.length <= 0) {
        const principalGroup = groups.find((g) => g.groupName === 'principal');
        const principalGroupData = Object.values(
          principalGroup?.blocks.drawflow.Home.data
        );

        if (!isValidPrincipalGroup(principalGroupData)) {
          setErrors([getTranslation('validations.principalWithoutMainBlocks')]);
          setModalImportError(true);
          return;
        }
        dispatch({ type: 'updateForm', data: { loading: true } });


				const promisesResults = await Promise.all([
					 await updatePrincipalGroup(
						botName || '',
						groups,
						dispatchApp
					),
					 await removeNonExistingGroups(
						botName || '',
						groups,
						groupsNames,
						dispatchApp
					),
					 await createAndUpdateGroups(
						botName || '',
						groups,
						groupsNames,
						dispatchApp
					),
					 await updateEntities(
						botName || '',
						entities,
						dispatchApp
					),
					 await updateKeys(
						botName || '',
						importKeys,
						dispatchApp
					),
				])


        requestErrors.push(
          promisesResults[0][0],
          promisesResults[1][0],
          promisesResults[2][0],
          promisesResults[3][0],
          promisesResults[4][0]
        );

        const updatedGroupsNames = groups.map((group) => group.groupName);

        dispatch({
          type: 'updateForm',
          data: { groupsNames: updatedGroupsNames },
        });

        dispatch({ type: 'updateForm', data: { loading: false } });
      }

      requestErrors = requestErrors
        .filter((error) => (typeof error !== undefined ? error : ''))
        .filter((error) => error !== '');
      setErrors(requestErrors);

      if (requestErrors.length > 0) {
        setModalImportError(true);
      } else {
        toastNotification(
          'success',
          getTranslation('toast.success.importAgent')
        );
				await flexGroupsTreatment(botName ||'' , groups, dispatchApp);

        await loadFlow(state.groupsNames[0], botName || '');
        dispatch({
          type: 'setIdGroup',
          data: { idGroup: state.groupsNames[0] },
        });
      }
    };

    documentHtml?.appendChild(uploadInput);
    uploadInput.click();
    uploadInput.parentNode?.removeChild(uploadInput);
  };

  const handleModalLibrary =
    (index: number) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (index === 0) {
        dispatch({ type: 'openModalStandardTemplates' });
      } else if (index === 1) {
        dispatch({ type: 'openModalSavedBlocks' });
      } else if (index === 2) {
        if (getSelectedNodeId().length !== 0)
          dispatch({ type: 'openModalSaveSelectedBlocks' });
        else
          toastNotification(
            'error',
            getTranslation('toast.error.noBlocksSelected')
          );
      }
      handlePopoverClose(e);
    };

  const handleModalImportExportBot =
    (index: number) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (index === 0) {
        importBot();
      } else if (index === 1) {
        if (botName) exportBot(botName, dispatchApp);
      }
      handlePopoverClose(e);
    };

  const handleModalAccessKeys =
    (slug: string) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      switch (slug) {
        case slugsListOptions.MANAGE_ACCOUNT:
          dispatch({ type: 'openModalManageAccount' });
          break;

        case slugsListOptions.MANAGE_COMPANY:
          // setOpenModalManageCompany(true);
          dispatch({ type: 'openModalManageCompany' });
          break;

        case slugsListOptions.MANAGE_PERMISSIONS:
          dispatch({ type: 'openModalManagePermission' });
          break;

        case slugsListOptions.MODAL_PHRASES_OF_INTENT: {
          dispatch({ type: 'openModalPhrasesOfIntentManager' });
          break;
        }
        case slugsListOptions.MANAGE_CORRECT_SENTENCES: {
          dispatch({ type: 'openModalManageCorrectSentences' });
          break;
        }
        case slugsListOptions.OPEN_CAMPAIGN_TEMPLATES: {
          dispatch({
            type: 'openModalCampaignTemplates',
          });
          break;
        }
        case slugsListOptions.OPEN_CAMPAIGN_CAMPAIGN: {
          dispatch({
            type: 'openModalCampaignCampaign',
          });
          break;
        }
        case slugsListOptions.OPEN_CAMPAIGN_ACTIONING: {
          dispatch({
            type: 'openModalCampaignActioning',
          });
          break;
        }
        case slugsListOptions.OPEN_FALAZAP_THEME: {
          dispatch({
            type: 'openModalFalazapTheme',
          });
          break;
        }
        case slugsListOptions.OPEN_FALAZAP_TOKEN: {
          dispatch({
            type: 'openModalFalazapToken',
          });
          break;
        }
      }

      handlePopoverClose(e);
    };

  const handleHistoryConversation = () => {
    setModalConversations(true);
  };

  const sidebarListItem = useSidebarItems({
    handleHistoryConversation,
  });

  async function handleClickSelect(text: IOptions) {
    switch (text.slug) {
      case slugsListOptions.EDIT_ACCESS_KEYS:
        if (!!botName) dispatch({ type: 'openModalAccessKeys' });
        return;
      case slugsListOptions.BOT_HISTORY:
        if (!!botName) setOpenModalRestoreBot(true);
        return;
      case slugsListOptions.OPEN_CAMPAIGN: {
        await saveDataBeforeRedirect(state.botName || '', state.idGroup || '');
        if (text.path) push(text.path);
        return;
      }
    }

    if (!botName || (botName && text.name === getTranslation('menu.library')))
      return;
    if (text.path === location.pathname) return;

    if (text.action) {
      text.action();
      return;
    }

    if (text.path === '/') {
      if (!!state.editorFlow) {
        state.editorFlow.clear();
      }
      dispatch({ type: 'updateForm', data: { loading: false, idGroup: '' } });
    } else if (
      text.path !== '/' &&
      location.pathname === '/' &&
      text.path !== '/entities'
    ) {
      await updateFlow();
    } else if (text.path === '/entities' || text.path === '/campaigns') {
      if (state.botName && state.idGroup) {
        await saveDataBeforeRedirect(state.botName, state.idGroup);
      }
    }
    if (text.path) push(text.path, { from: location.pathname });
  }

  async function handleClickSelectAdminStuff(text: IOptions) {
    switch (text.slug) {
      case slugsListOptions.OPEN_CAMPAIGN: {
        await saveDataBeforeRedirect(state.botName || '', state.idGroup || '');
        if (text.path) push(text.path);
        return;
      }
    }

    if (text.path === location.pathname) return;

    if (text.path === '/') {
      if (!!state.editorFlow) {
        state.editorFlow.clear();
      }
      dispatch({ type: 'updateForm', data: { loading: false, idGroup: '' } });
    } else if (
      text.path !== '/' &&
      location.pathname === '/' &&
      text.path !== '/entities'
    ) {
      await updateFlow();
    } else if (text.path === '/campaigns') {
      if (state.botName && state.idGroup) {
        await saveDataBeforeRedirect(state.botName, state.idGroup);
      }
    }
    if (text.path) push(text.path);
  }

  async function saveDataBeforeRedirect(botName: string, groupName: string) {
    if (state.isEditing) {
      stopEditing();

      await ReleaseGroupAccess(
        {
          bot_name: botName,
          groupName: groupName,
        },
        dispatchApp
      );
      if (state.editorFlow) {
        await UpdateGroup(
          {
            bot_name: botName,
            group_name: groupName,
            blocks: {
              drawflow: state.editorFlow.drawflow.drawflow,
            },
          },
          dispatchApp
        );
      }
    }
  }

  const changeGroupFlow = async (group_id: string, deleteGroup = false) => {
    dispatch({ type: 'updateForm', data: { loading: true } });

    !deleteGroup && (await updateFlow());

    if (state.isEditing) {
      await ReleaseGroupAccess(
        {
          bot_name: botName || '',
          groupName: state.idGroup || '',
        },
        dispatchApp
      );
    }

    await loadFlow(group_id, botName || '', false, deleteGroup);
    dispatch({
      type: 'updateForm',
      data: { idGroup: group_id, loading: false },
    });
  };

  const updateFlow = async () => {
    const groupLastVersion = localStore.get('groupLastVersion') || '';

    if (
      !!state.drawFlowRef &&
      !!state.editorFlow &&
      state.isEditing &&
      JSON.stringify(state.editorFlow.drawflow) !== groupLastVersion
    ) {
      const sBotAndGroup = localStore.get('current_bot_group');

      if (sBotAndGroup) {
        const { botName, groupName } = JSON.parse(sBotAndGroup);

        if (botName && groupName) {
          await UpdateLastAccess(
            {
              bot_name: botName,
              groupName,
            },
            dispatchApp
          );
        }
      }
      await saveFlow();
    }
  };

  async function handleLogout() {
    try {
      if (state.idGroup && state.botName && state.isEditing) {
        await ReleaseGroupAccess(
          {
            bot_name: state.botName,
            groupName: state.idGroup,
          },
          dispatchApp
        );
      }
      setIsClearingFieldsRenderBots(true);
      localStore.remove('userPermissions');
      localStore.remove('lastFields');
      dispatch({ type: 'closeModalWarning' });
      dispatch({ type: 'closeModalUsedGroup' });
      dispatch({
        type: 'updateForm',
        data: {
          userPermissions: [],
          botName: '',
          companyName: '',
          userOrganizations: [],
          idGroup: '',
        },
      });

      await CognitoLogOut();

      dispatchApp({ type: 'updateApp', data: { signed: false } });
    } catch (error) {}
  }

  const handleDeleteGroup = async () => {
    if (!deleteGroupSelected) {
      toastNotification('error', 'Nenhum grupo selecionado');
      return false;
    }

    if (!botName) {
      toastNotification('error', 'Nenhum bot selecionado');
      return false;
    }

    const request: IDeleteGroupRequest = {
      bot_name: botName,
      group_name: deleteGroupSelected.id,
      group_name_confirmation: deleteGroupSelected.id,
    };
    const result = await DeleteGroup(request, dispatchApp);

    if (result.Success) {
      const newGroupList = groupsNames.filter(
        (item) => item !== deleteGroupSelected.id
      );

      if (deleteGroupSelected.id === state.idGroup) {
        const principalGroup = groupsNames.find((item) => item === 'principal');
        const groupSelected = principalGroup ? principalGroup : newGroupList[0];
        await changeGroupFlow(groupSelected, true);
      }

      dispatch({ type: 'updateForm', data: { groupsNames: newGroupList } });

      toastNotification('success', getTranslation('toast.success.deleteGroup'));

      const flexBlockName = deleteGroupSelected.name.substring(
        5,
        deleteGroupSelected.name.length
      );

      await DeleteFlexBlockGroup(
        { bot_name: botName, block_name: flexBlockName, groups_used: [] },
        dispatchApp
      );
    } else {
      toastNotification('error', getTranslation('toast.error.deleteGroup'));
    }
    setDeleteGroupSelected(null);
  };

  const isSelectedPage = (item: IOptions) => {
    if (item.name === getTranslation('menu.library')) return openLibrary;
    if (item.name === getTranslation('menu.admin')) return openAdmin;
    if (item.name === getTranslation('menu.uploadDownloadAgent'))
      return openImportExportBot;
    return !!item.path && location.pathname === item.path && !!botName;
  };

  if (load) return <></>;

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    item: IOptions
  ) => {
    setAnchorEl(event.currentTarget);

    switch (item.name) {
      case getTranslation('menu.library'):
        setOpenLibrary(true);
        break;

      case getTranslation('menu.admin'):
        setOpenAdmin(true);
        break;

      case getTranslation('menu.training'):
        setOpenTraining(true);
        break;

      case getTranslation('menu.communicationRuler'):
        setOpenCampaign(true);
        break;

      case getTranslation('menu.uploadDownloadAgent'):
        setImportExportBot(true);
        break;

      case getTranslation('menu.falazapConfig.title'):
        setOpenFalazapConfig(true);
        break;

      default:
        break;
    }
  };

  const handlePopoverClose = (event?: any) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (anchorEl) setAnchorEl(null);
    if (openLibrary) setOpenLibrary(false);
    if (openAdmin) setOpenAdmin(false);
    if (openTraining) setOpenTraining(false);
    if (openCampaign) setOpenCampaign(false);
    if (openFalazapConfig) setOpenFalazapConfig(false);
    if (openImportExportBot) setImportExportBot(false);
  };

  function handleOpenUrlsTests(event: any) {
    setAnchorElFlow(event.currentTarget);
    setOpenFlowTest(true);
  }

  function handleCloseUrlsTests() {
    setAnchorElFlow(null);
    setOpenFlowTest(false);
  }

  function handleCloseDebug() {
    setOpenDebug(false);
  }

  function getOpenMenuOptions(item: { name: any }) {
    switch (item.name) {
      case getTranslation('menu.admin'):
        return openAdmin;
      case getTranslation('menu.training'):
        return openTraining;
      case getTranslation('menu.communicationRuler'):
        return openCampaign;
      case getTranslation('menu.falazapConfig.title'):
        return openFalazapConfig;
      default:
        return false;
    }
  }

  const renderBody = () => {
    return (
      <>
        <GridHeader>
          <LogoContainer>
            <IconLLego />
          </LogoContainer>
          <GridInputsHeader>
            <RenderBots
              isClearFields={isClearingFieldsRenderBots}
              setDeleteGroupSelected={setDeleteGroupSelected}
            ></RenderBots>
          </GridInputsHeader>
          <TooltipContainer>
            {botName && (
              <Tooltip title={'Debug'} placement="left">
                <IconButton onClick={() => setOpenDebug(true)}>
                  <BugReportIcon fontSize="medium" />
                </IconButton>
              </Tooltip>
            )}

            {openFlowTest && (
              <FlowsUrls
                handleCloseUrlsTests={handleCloseUrlsTests}
                anchorElFlow={anchorElFlow}
                openFlowTest={openFlowTest}
                openFalazap={() => setOpenFalazap(true)}
              />
            )}

            {botName && (
              <Tooltip
                title={getTranslation('tooltip.executeFlow')}
                placement="bottom"
              >
                <IconButton
                  aria-label="execute-flow"
                  id="long-button"
                  aria-controls="long-menu"
                  aria-expanded={openFlowTest ? 'true' : undefined}
                  aria-haspopup="true"
                  onClick={handleOpenUrlsTests}
                  size={'medium'}
                >
                  <PlayCircleFilledIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            )}
          </TooltipContainer>
        </GridHeader>
        <GridMenu>
          <List>
            {sidebarListItem.listMenuOptions.map((item, index) => (
              <GridListMenu
                key={index}
                selected={isSelectedPage(item)}
                onClick={() => handleClickSelect(item)}
              >
                <WithHasAccess action={item.canBeUsed || []}>
                  <ListItem
                    id={item.name}
                    aria-describedby={item.name}
                    button
                    key={index}
                    aria-haspopup="true"
                    onClick={(e) => handlePopoverOpen(e, item)}
                    disabled={
                      item.name === getTranslation('menu.library')
                        ? !isEditing || !botName
                        : !botName
                    }
                  >
                    <Tooltip title={item.name} placement="right">
                      <ListItemIcon>{item.icon}</ListItemIcon>
                    </Tooltip>

                    {item.children && (
                      <Popover
                        id={item.name}
                        open={
                          item.name === getTranslation('menu.library')
                            ? openLibrary
                            : openImportExportBot
                        }
                        anchorEl={anchorEl}
                        anchorOrigin={{
                          vertical: 'center',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        onClose={handlePopoverClose}
                        style={{ zIndex: 9999 }}
                      >
                        <div>
                          {item.children.map(
                            (
                              opt: {
                                icon: JSX.Element;
                                name: string;
                                slug: string;
                              },
                              index: number
                            ) => (
                              <GridListMenuChild
                                key={index}
                                selected={isSelectedPage(item)}
                                onClick={
                                  item.name === getTranslation('menu.library')
                                    ? handleModalLibrary(index)
                                    : handleModalImportExportBot(index)
                                }
                              >
                                <ListItem
                                  button
                                  key={index}
                                  disabled={
                                    opt.name ===
                                      getTranslation(
                                        'menu.libraryChildren.saveSelectedBlocks'
                                      ) && getSelectedNodeId().length === 0
                                  }
                                >
                                  <ListItemIcon>{opt.icon}</ListItemIcon>
                                  <ListItemText primary={opt.name} />
                                </ListItem>
                                <Divider variant="middle" />
                              </GridListMenuChild>
                            )
                          )}
                        </div>
                      </Popover>
                    )}
                  </ListItem>
                </WithHasAccess>
              </GridListMenu>
            ))}
            <Divider />
            {sidebarListItem.listAdminStuff.map((item, index) => (
              <GridListMenu
                key={index}
                selected={isSelectedPage(item)}
                onClick={() => handleClickSelectAdminStuff(item)}
              >
                <WithHasAccess action={item.canBeUsed || []}>
                  <ListItem
                    aria-describedby={item.name}
                    id={item.name}
                    button
                    key={index}
                    aria-haspopup="true"
                    onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) =>
                      handlePopoverOpen(e, item)
                    }
                    disabled={
                      item.name === getTranslation('menu.communicationRuler') ||
                      item.name === getTranslation('menu.falazapConfig.title')
                        ? !botName
                        : false
                    }
                  >
                    <Tooltip title={item.name} placement="right">
                      <ListItemIcon>{item.icon}</ListItemIcon>
                    </Tooltip>
                    {item.children && (
                      <Popover
                        id={item.name}
                        open={getOpenMenuOptions(item)}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        onClose={handlePopoverClose}
                        style={{ zIndex: 9999 }}
                      >
                        <div>
                          {item.children.map(
                            (
                              opt: {
                                icon: JSX.Element;
                                name: string;
                                slug: string;
                                disabled: boolean;
                              },
                              index: number
                            ) => (
                              <GridListMenuChild
                                key={index}
                                selected={isSelectedPage(item)}
                                onClick={handleModalAccessKeys(opt.slug ?? '')}
                              >
                                <ListItem
                                  button
                                  key={index}
                                  disabled={opt.disabled}
                                >
                                  <ListItemIcon>{opt.icon}</ListItemIcon>
                                  <ListItemText primary={opt.name} />
                                </ListItem>
                                <Divider />
                              </GridListMenuChild>
                            )
                          )}
                        </div>
                      </Popover>
                    )}
                  </ListItem>
                </WithHasAccess>
              </GridListMenu>
            ))}
            <GridListMenu>
              <Tooltip title="Logout" placement="right">
                <ListItem button aria-haspopup="true" onClick={handleLogout}>
                  <ListItemIcon>
                    <LogoutIcon />
                  </ListItemIcon>
                </ListItem>
              </Tooltip>
            </GridListMenu>
          </List>

          {botName && (
            <WithHasAccess action={['flow:publish']}>
              <PublishButton />
            </WithHasAccess>
          )}
        </GridMenu>
      </>
    );
  };

  return (
    <Container>
      {signed && renderBody()}

      <Routes />
      <ToastNotification />

      {state.openModalPublishBot && <ModalPublishBot />}

      {!!deleteGroupSelected && (
        <ModalDeleteGroup
          handleClose={() => setDeleteGroupSelected(null)}
          groupName={deleteGroupSelected.name || ''}
          handleDeleteGroup={handleDeleteGroup}
        />
      )}

      {openModalConversations && (
        <ModalConversations
          open={openModalConversations}
          handleClose={() => setModalConversations(false)}
        />
      )}

      {appState.renderModalUsedTab && <ModalUsedTab />}

      {openModalImportExportBot && (
        <ModalImportExportBot
          open={openModalImportExportBot}
          handleExportBot={async () => {
            exportBot(botName || '', dispatchApp);
          }}
          handleImportBot={importBot}
          handleClose={() => setOpenModalImportExportBot(false)}
        />
      )}

      {openModalImportError && (
        <ModalBotImportError
          open={openModalImportError}
          errors={errors}
          botToRestore={botToRestore}
          handleClose={() => setModalImportError(false)}
          handleOpen={() => setModalImportError(true)}
        />
      )}

      {/* {openModalManageCompany && (
        <ModalManageCompany
          open={openModalManageCompany}
          handleClose={() => setOpenModalManageCompany(false)}
        />
      )} */}

      {openModalRestoreBot && (
        <ModalRestoreBot
          open={openModalRestoreBot}
          handleClose={() => setOpenModalRestoreBot(false)}
        />
      )}

      {openFalazap && (
        <FalazapIframe
          url={state.botsUrls.urlFalazap || ''}
          closeFalazap={() => setOpenFalazap(false)}
        />
      )}

      {openDebug && (
        <Debug open={openDebug} handleCloseDebug={handleCloseDebug} />
      )}

      {state.openModalCampaignTemplates && (
        <ModalTemplates
          open={state.openModalCampaignTemplates}
          close={() =>
            dispatch({
              type: 'closeModalCampaignTemplates',
            })
          }
        />
      )}

      {state.openModalCampaignCampaign && (
        <ModalCampaigns
          open={state.openModalCampaignCampaign}
          close={() =>
            dispatch({
              type: 'closeModalCampaignCampaign',
            })
          }
        />
      )}
      {state.openModalCampaignActioning && (
        <ModalActioning
          open={state.openModalCampaignActioning}
          close={() =>
            dispatch({
              type: 'closeModalCampaignActioning',
            })
          }
        />
      )}
      {state.openModalFalazapToken && <ModalFalazapToken />}
    </Container>
  );
}
