import { DataFlowObject } from 'contexts/Flow/types';
import { DataForm, ItemDataFlow, Output } from 'models/DataFlow';
import DataFormModel from 'models/DataFormModel';
import IntentController from './IntentController';
import { slugify } from 'utils/String';
import {
  array_connectors,
  create_array_connectors,
} from 'components/BlocklyConstructor/compiler';
import EIntentType from 'enums/EIntentType';

export default class UpdateIntentController extends IntentController {
  updateIntent(dataForm: DataForm, nodeId: number) {
    const dataFormModel = new DataFormModel(dataForm);

    if (dataFormModel.isParent()) {
      this.updateIntentParent(dataFormModel, nodeId);
    } else if (dataFormModel.isChild()) {
      this.updateIntentChild(dataFormModel, nodeId);
    } else {
      this.updateIntentConventional(dataFormModel, nodeId);
    }
  }

  private updateIntentParent(dataFormModel: DataFormModel, nodeId: number) {
    const nodes: DataFlowObject = this.editorFlow.drawflow.drawflow;
    const { outputs } = nodes.Home.data[nodeId].data;
    if (outputs) {
      for (let index = 0; index < outputs.length; index++) {
        const output = outputs[index];
        output.nameIntent = dataFormModel.name + '-' + slugify(output.title);
      }
    }

    if (!dataFormModel.isOutputEqual(outputs)) {
      const idsToRemove = dataFormModel.getNodeIdsOutputRemoved(outputs);
      this.removeNodesChildren(idsToRemove);
      const outputsToUpdate = dataFormModel.getOutputToUpdate();
      this.updateNodesChildren(outputsToUpdate, dataFormModel.name);
      const outputsToAdd = dataFormModel.getOutputToAdd();
      const position = this.getPositionByNodeId(nodeId);

      const outputsAdded = this.addNodesChildren(
        outputsToAdd,
        dataFormModel,
        nodeId,
        outputsToUpdate.length,
        position
      );
      nodes.Home.data[nodeId].data.outputs = [
        ...outputsToUpdate,
        ...outputsAdded,
      ];
    } else {
      this.updateNodesChildren(dataFormModel.getOutputs(), dataFormModel.name);
    }
    this.updateIntentConventional(dataFormModel, nodeId);
    this.updateConnections(nodeId);
  }

  updateConnections(nodeId: number) {
    const node: ItemDataFlow =
      this.editorFlow.drawflow.drawflow.Home.data[nodeId];

    const outputs = { ...node.outputs };
    for (const key in outputs) {
      const connections = [...outputs[key].connections];
      for (const conn of connections) {
        this.editorFlow.removeSingleConnection(
          nodeId,
          Number(conn.node),
          key,
          conn.output || ''
        );
      }
    }

    for (const key in outputs) {
      const outputsData = node.data.outputs || [];
      const index = Number(key.replace(/[^0-9]/g, '')) - 1;
      const outData = outputsData.find((_, i) => i === index);
      if (outData && outData.outputid) {
        this.editorFlow.addConnection(nodeId, outData.outputid, key, 'input_1');
      }
    }
  }

  private updateIntentChild(dataFormModel: DataFormModel, nodeId: number) {
    this.updateIntentConventional(dataFormModel, nodeId);
    this.updateOutputParent(dataFormModel);
  }

  private updateOutputParent(dataFormModel: DataFormModel) {
    const parentId = dataFormModel.getParentId();
    const nodes: DataFlowObject = this.editorFlow.drawflow.drawflow;
    const outputChild = nodes.Home.data[parentId].data.outputs?.find(
      (child) => child.outputid === dataFormModel.idOutput
    );
    if (!!outputChild) {
      outputChild.nameIntent = dataFormModel.name;
      outputChild.description = dataFormModel.description;
    }
  }

  private updateIntentConventional(
    dataFormModel: DataFormModel,
    nodeId: number
  ) {
    const nodes: DataFlowObject = this.editorFlow.drawflow.drawflow;
    const amountOutputsFlow = Object.keys(
      nodes.Home.data[nodeId].outputs
    ).length;

    if (dataFormModel.intentType === 0) {
      const regExp = new RegExp('&nbsp;', 'gi');

      if (dataFormModel.inputs && dataFormModel.inputs.userSentences) {
        dataFormModel.inputs.userSentences =
          dataFormModel.inputs.userSentences.map((sentence) => {
            sentence = sentence.replace(regExp, ' ');
            return sentence;
          });
      }
    }

    if (dataFormModel.dataBlockly?.payload) {
      create_array_connectors(dataFormModel.dataBlockly?.payload);

      if (
        array_connectors &&
        array_connectors.length !== dataFormModel.getOutputAmount() &&
        ![
          EIntentType.CarouselParent,
          EIntentType.MultipleChoiceParent,
          EIntentType.OptionsBlock,
          EIntentType.FlexBlockExit,
        ].includes(dataFormModel.intentType)
      ) {
        let auxArray = [];
        if (![EIntentType.NewGroup].includes(dataFormModel.intentType)) {
          auxArray = !!array_connectors.length ? array_connectors : [];
        }

        const outputs = [];
        for (let index = 0; index < auxArray.length; index++) {
          outputs.push({
            title: auxArray[index],
          });
        }
        dataFormModel.outputs = outputs;
      }
    }

    const amountOutputsData = dataFormModel.getOutputAmount();
    const differenceAmount = amountOutputsFlow - amountOutputsData;

    if (differenceAmount > 0) {
      this.removeNodeOutputs(nodeId, differenceAmount, amountOutputsFlow);
    } else if (differenceAmount < 0) {
      this.addNodeOutputs(nodeId, differenceAmount);
    }

    nodes.Home.data[nodeId].data = dataFormModel.toDataForm();
  }

  private addNodeOutputs(nodeId: number, differenceAmount: number) {
    let amountOutput = differenceAmount;
    while (amountOutput < 0) {
      this.editorFlow.addNodeOutput(nodeId);
      amountOutput++;
    }
  }

  private removeNodeOutputs(
    nodeId: number,
    differenceAmount: number,
    amountOutputsFlow: number
  ): void {
    for (let index = 0; index < differenceAmount; index++) {
      this.editorFlow.removeNodeOutput(
        nodeId,
        `output_${amountOutputsFlow - index}`
      );
    }
  }

  private removeNodesChildren(nodeIds: number[]) {
    for (let index = 0; index < nodeIds.length; index++) {
      const nodeId = nodeIds[index];
      const node = this.editorFlow.getNodeFromId(nodeId);
      this.editorFlow.removeNodeId('node-' + nodeId);
      const nodeConnection = node.inputs['input_1'].connections.find(
        (connection) => String(connection.node) === String(node.data.parentId)
      );
      if (!!nodeConnection) {
        this.editorFlow.removeNodeOutput(
          Number(nodeConnection.node),
          nodeConnection.input
        );
      }
    }
  }

  private removeCrashedEmoticons(str: string) {
    return str.replace(/✔️/g, '');
  }

  private removeLastTrace(str: string) {
    return str.replace(/-{0,}$/, '');
  }

  private updateNodesChildren(outputs: Output[], parentName: string) {
    for (let index = 0; index < outputs.length; index++) {
      const output = outputs[index];
      const nodes: DataFlowObject = this.editorFlow.drawflow.drawflow;
      const formData = nodes.Home.data[Number(output.outputid)].data;
      const tile = this.removeCrashedEmoticons(
        output.title.toLowerCase().trim()
      );
      formData.name = parentName + '-' + slugify(tile);
      formData.name = this.removeLastTrace(formData.name);
      formData.description = output.description || '';
    }
  }

  private addNodesChildren(
    outputs: Output[],
    dataForm: DataFormModel,
    parentId: number,
    offset: number,
    parentPosition: { x: number; y: number }
  ) {
    const outputResult = [...outputs];

    for (let index = 0; index < outputResult.length; index++) {
      this.editorFlow.addNodeOutput(parentId);

      const output = outputResult[index];
      const tile = this.removeCrashedEmoticons(outputResult[index].title);
      let name = dataForm.name + '-' + slugify(tile);
      name = this.removeLastTrace(name);

      const description = output.description || '';
      const intentType = dataForm.getChildType();
      const groupId = dataForm.groupId;

      const dataFormChild = new DataFormModel({
        parentId,
        name,
        description,
        intentType,
        groupId,
      });

      output.outputid = this.createIntentChild(
        dataFormChild,
        parentId,
        index + offset,
        parentPosition
      ).toString();
    }
    return outputResult;
  }
}
