import React, { useEffect, useState } from 'react';

import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-dracula';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/webpack-resolver';

import { createStyles, Theme } from '@material-ui/core';

import { TransitionProps } from '@material-ui/core/transitions';
import {
  Dialog,
  IconButton,
  Slide,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { ICodeEditorProps } from './types';
import { useStyles } from './styles';

import { js_beautify } from 'js-beautify';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose?: () => void;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    footer: {
      margin: 0,
      padding: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      background: '#282a36',
      borderTop: '1px solid #44475a',
    },
  });

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogFooter = withStyles(styles)((props: DialogTitleProps) => {
  const { classes, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.footer}
      {...other}
    ></MuiDialogTitle>
  );
});

const TextEditor = (props: ICodeEditorProps) => {
  const { showEditor, code, handleClose } = props;
  const [newValue, setNewValue] = useState(code);
  const classes = useStyles();

  useEffect(() => {
    setNewValue(code);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showEditor]);

  return (
    <Dialog
      open={showEditor}
      TransitionComponent={Transition}
      fullWidth
      maxWidth="md"
      aria-describedby="alert-dialog-slide-description"
      aria-labelledby="customized-dialog-title"
      style={{ zIndex: 99999 }}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={() => handleClose(code)}
      >
        Editor de Texto
      </DialogTitle>
      <AceEditor
        mode="javascript"
        theme="dracula"
        name="editor"
        height={'80vh'}
        width={'100%'}
        value={newValue}
        readOnly={false}
        onChange={(value) => setNewValue(value)}
      />
      <DialogFooter id="customized-dialog-title">
        <IconButton
          onClick={() => {
            setNewValue(js_beautify(newValue, { indent_size: 2 }));
          }}
          className={classes.footerFormatCodeButton}
        >
          Formatar Código
        </IconButton>
        <IconButton
          onClick={() => handleClose(newValue)}
          className={classes.footerSaveButton}
        >
          Salvar
        </IconButton>
        <IconButton
          onClick={() => handleClose(code)}
          className={classes.footerCloseButton}
        >
          Sair
        </IconButton>
      </DialogFooter>
    </Dialog>
  );
};

export default TextEditor;
