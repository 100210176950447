import React, { useEffect, useState } from 'react';
import {
  Slide,
  Dialog,
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  TextField,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import CloseIcon from '@material-ui/icons/Close';

import * as S from './styles';
import * as SS from '../styles';

import useTranslator from 'utils/hooks/Translator';
import MttButton from 'components/Material/MttButton/MttButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import {
  // formatDateHour,
  hour,
} from 'utils/Date';
import { CreateOrEditActioning } from './CreateOrEditActioning';
import {
  emptyActioning,
  IActioning,
  IActioningStatus,
} from 'services/CampaignService/ActioningsService/types';
import {
  DeleteActioning,
  GetActionings,
} from 'services/CampaignService/ActioningsService';

import { useApp } from 'contexts/App/appContext';
import { useFlow } from 'contexts/Flow/flowContext';
import { CircularProgress } from '@material-ui/core';
import CampaignIcon from 'components/Icons/CampaignIcon';
import TemplateIcon from 'components/Icons/TemplateIcon';
import {
  // CloudDownload,
  Refresh,
  Visibility,
} from '@material-ui/icons';
import { isBefore } from 'date-fns';
import { usePermissions } from 'contexts/Permissions/permissionsContext';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface ModalTemplatesProps {
  open: boolean;
  close: () => void;
}

export function ModalActioning({ open, close }: ModalTemplatesProps) {
  const classes = SS.useStyles();

  const { dispatch: dispatchApp } = useApp();
  const { toastNotification, dispatch, state } = useFlow();
  const { hasPermissionToAction } = usePermissions();
  const { botName } = state;

  const bot_name = botName || '';
  const isCommunicationGuidelineWrite = hasPermissionToAction({
    company: state.companyName!,
    agent: state.botName!,
    action: ['communication_guideline:write'],
  });
  const { getTranslation } = useTranslator();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openModalCreateOrEditActioning, setOpenModalCreateOrEditActioning] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');

  const [actionings, setActionings] = useState<IActioning[]>([]);
  const [currentActioning, setCurrentActioning] =
    useState<IActioning>(emptyActioning);

  const filteredActionings =
    search.length > 0
      ? actionings.filter((t) => t.bot_name.includes(search))
      : actionings;

  useEffect(() => {
    if (!openModalCreateOrEditActioning) loadActionings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, openModalCreateOrEditActioning]);

  async function loadActionings(refresh?: boolean) {
    setLoading(true);

    // const listActionings: IActioning[] = [];
    try {
      const response = await GetActionings(
        { bot_name, organization: '' },
        dispatchApp
      );

      if (response.Success) {
        const allActionings = response.Data.data;

        setActionings(
          allActionings.sort((a, b) => {
            if (
              isBefore(
                new Date(a.creation_date).getTime(),
                new Date(b.creation_date).getTime()
              )
            )
              return 1;
            else return -1;
          })
        );
      } else throw new Error('erro');

      // userOrganizations.forEach(async (organization) => {
      //   const items = await GetActionings(
      //     { bot_name, organization: organization.name },
      //     dispatchApp
      //   );

      //   const foundActionings = items.Data.data;

      //   if (foundActionings) {
      //     foundActionings.forEach((actioning) => {
      //       listActionings.push(actioning);
      //     });
      //   }

      //   const updatedActionings = refresh
      //     ? [...listActionings]
      //     : [...actionings, ...listActionings];

      //   setActionings(
      //     updatedActionings.sort((a, b) => {
      //       if (
      //         isBefore(
      //           new Date(a.scheduled_date).getTime(),
      //           new Date(b.scheduled_date).getTime()
      //         )
      //       )
      //         return 1;
      //       else return -1;
      //     })
      //   );
      // });
    } catch (error: any) {
      toastNotification(
        'error',
        getTranslation('toast.error.searchActionings')
      );
    }

    setLoading(false);
  }

  function handleClose() {
    setPage(0);
    close();
  }

  function handleCloseNewActioning() {
    setActionings([]);
    setOpenModalCreateOrEditActioning(false);
  }

  function handleNewActioning() {
    setCurrentActioning(emptyActioning);
    setOpenModalCreateOrEditActioning(true);
  }

  function handleChangeRowsPerPage(e: { target: { value: string | number } }) {
    setRowsPerPage(+e.target.value);
    setPage(0);
  }

  function handleChangePage(event: any, newPage: React.SetStateAction<number>) {
    setPage(newPage);
  }

  function getColor(status: IActioningStatus) {
    switch (status) {
      case 'concluded':
        return '#79e16d';
      case 'in_progress':
        return '#ffb84d';
      case 'not_started':
        return '#6dc1e1';
      case 'error':
      default:
        return '#cd5334';
    }
  }

  async function handleRemoveActioning(actioning: IActioning) {
    const response = await DeleteActioning(
      {
        ...actioning,
        bot_name,
      },
      dispatchApp
    );

    if (response.Success) {
      toastNotification(
        'success',
        getTranslation('toast.success.actioningDeleted')
      );
      setActionings(actionings.filter((act) => actioning._id !== act._id));
    } else {
      toastNotification(
        'error',
        getTranslation('toast.error.actioningDeleted')
      );
    }
  }

  function handleEditActioning(actioning: IActioning) {
    setCurrentActioning(actioning);
    setOpenModalCreateOrEditActioning(true);
  }

  function getPrettyStatus(status: IActioningStatus) {
    switch (status) {
      case 'concluded':
        return getTranslation('concluded');
      case 'in_progress':
        return getTranslation('inProgress');
      case 'error':
        return getTranslation('error');
      case 'not_started':
      default:
        return getTranslation('notStarted');
    }
  }

  // function downloadLogFile(uri: string, name: string) {
  //   const link = document.createElement('a');
  //   link.download = name;
  //   link.href = uri;
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // }

  const renderTemplatesList = () => (
    <SS.ContainerList>
      <SS.SearchAndTableContainer>
        <SS.ContainerHeader>
          <Typography variant="h6" color="primary">
            {getTranslation('actioningsList')}
          </Typography>
          <SS.HeaderButtonsContainer>
            <Tooltip title={getTranslation('refresh')}>
              <IconButton onClick={() => loadActionings(true)}>
                <Refresh />
              </IconButton>
            </Tooltip>
            {isCommunicationGuidelineWrite && (
              <MttButton
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => handleNewActioning()}
              >
                {getTranslation('modal.campaigns.actioning.newButton')}
              </MttButton>
            )}
          </SS.HeaderButtonsContainer>
        </SS.ContainerHeader>
        <SS.GridSearch>
          <Autocomplete
            options={filteredActionings.map((option) => option.name)}
            renderInput={(params) => (
              <TextField
                {...params}
                value={search}
                label={getTranslation('search')}
                variant="outlined"
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </SS.GridSearch>
        {loading ? (
          <div className={classes.loadingContainer}>
            <CircularProgress color="inherit" size={20} />
          </div>
        ) : actionings.length > 0 ? (
          <div>
            <TableContainer
              component={Paper}
              className={classes.tableContainer}
            >
              <Table
                className={classes.table}
                aria-label="simple table"
                size="medium"
                stickyHeader
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      {getTranslation('scheduledDate')}
                    </TableCell>
                    <TableCell align="center">
                      {getTranslation('name')}
                    </TableCell>
                    <TableCell align="center">
                      {getTranslation('campaign')}
                    </TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="center">
                      {getTranslation('creationDate')}
                    </TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? filteredActionings.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : filteredActionings
                  ).map((actioning, index) => {
                    const actioningEditable =
                      actioning.status === 'not_started';

                    const viewOrEditTooltip =
                      actioning.status !== 'not_started'
                        ? getTranslation('view')
                        : getTranslation('edit');

                    // const actioningConcludedOrErrored =
                    //   actioning.status === 'concluded' ||
                    //   actioning.status === 'error';

                    return (
                      <TableRow key={index}>
                        <TableCell scope="actioning" align="center">
                          {`${new Date(
                            `${actioning.scheduled_date}`
                          ).toLocaleDateString()} 
													${new Date(actioning.scheduled_date).toLocaleTimeString()}`}
                        </TableCell>
                        <TableCell align="center">{actioning.name}</TableCell>
                        <TableCell align="center">
                          {actioning.campaign_name}
                        </TableCell>
                        <TableCell align="center">
                          <S.StatusDiv>
                            <S.Status bgColor={getColor(actioning.status)} />
                            {getPrettyStatus(actioning.status)}
                          </S.StatusDiv>
                        </TableCell>
                        <TableCell align="center">
                          {`${new Date(
                            `${actioning.creation_date}`
                          ).toLocaleDateString()} 
													${hour(new Date(actioning.creation_date))}`}
                        </TableCell>
                        <TableCell align="center">
                          {/* {actioningConcludedOrErrored && (
                            <Tooltip title="Logs">
                              <IconButton
                                onClick={() => {
                                  if (actioning.log_file)
                                    downloadLogFile(
                                      actioning.log_file,
                                      `log-file-${
                                        actioning.name
                                      }-${formatDateHour(
                                        actioning.creation_date
                                      )}`
                                    );
                                }}
                              >
                                <CloudDownload />
                              </IconButton>
                            </Tooltip>
                          )} */}
                          <Tooltip title={viewOrEditTooltip}>
                            <IconButton
                              onClick={() => handleEditActioning(actioning)}
                            >
                              {actioningEditable ? (
                                <EditIcon />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </Tooltip>

                          {actioning.status === 'not_started' && (
                            <Tooltip title={getTranslation('delete')}>
                              <IconButton
                                onClick={() => handleRemoveActioning(actioning)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : (
          <p style={{ marginTop: 24 }}>
            {getTranslation('noActioningsResult')}
          </p>
        )}
      </SS.SearchAndTableContainer>
      <div className={classes.tableFooter}>
        <div>
          <Tooltip title={getTranslation('campaign')}>
            <IconButton
              onClick={() => {
                dispatch({ type: 'closeModalCampaignActioning' });
                dispatch({ type: 'openModalCampaignCampaign' });
              }}
            >
              <CampaignIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={'Templates'}>
            <IconButton
              onClick={() => {
                dispatch({ type: 'closeModalCampaignActioning' });
                dispatch({ type: 'openModalCampaignTemplates' });
              }}
            >
              <TemplateIcon />
            </IconButton>
          </Tooltip>
        </div>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              count={filteredActionings.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={getTranslation(
                'modal.conversationHistory.linesPerPage'
              )}
            />
          </TableRow>
        </TableFooter>
      </div>
    </SS.ContainerList>
  );

  return (
    <>
      <Dialog open={open} TransitionComponent={Transition} fullScreen>
        <AppBar>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              {getTranslation('actionings')}
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={
                openModalCreateOrEditActioning
                  ? () => handleCloseNewActioning()
                  : () => handleClose()
              }
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <SS.ContainerDialog>
          {openModalCreateOrEditActioning ? (
            <CreateOrEditActioning
              handleClose={handleCloseNewActioning}
              currentActioning={{
                ...currentActioning,
                bot_name,
                scheduled_date: currentActioning._id
                  ? currentActioning.scheduled_date
                  : new Date(),
              }}
            />
          ) : (
            renderTemplatesList()
          )}
        </SS.ContainerDialog>
      </Dialog>
    </>
  );
}
