import React, { useState } from 'react';

import {
  CircularProgress,
  Dialog,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';

import { IModifyPassword } from 'services/AuthService/types';

import * as authService from 'services/AuthService';

import * as S from './styles';
import {
  IForgotPasswordProps,
  PasswordErrors,
  RegistrationError,
  RegistrationInfo,
} from './types';
import { resetPasswordValidation } from './validations';

import { useFlow } from 'contexts/Flow/flowContext';

import MttButton from 'components/Material/MttButton/MttButton';

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import SyncIcon from '@material-ui/icons/Sync';
import CloseIcon from '@material-ui/icons/Close';
import PersonSearchIcon from 'components/Icons/PersonSearchIcon';

import useTranslator from 'utils/hooks/Translator';

const ModalResetPassword: React.FC<IForgotPasswordProps> = (
  props: IForgotPasswordProps
) => {
  const { getTranslation } = useTranslator();
  const { open, firstAccess, temporaryPassword, email, onClose, dispatchUser } =
    props;
  const { toastNotification } = useFlow();
  const [loading, setLoading] = useState(false);
  const [newPasswordMode, setNewPasswordMode] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const classes = S.useStyles();
  const [registration, setRegistration] = useState<RegistrationInfo>(
    new RegistrationInfo(email, temporaryPassword)
  );

  const passwordErrors: PasswordErrors = {
    differentPasswords: getTranslation(
      'validations.resetPassword.differentPasswords'
    ),
    min6digitVerificationCode: getTranslation(
      'validations.resetPassword.min6digitVerificationCode'
    ),
    min8digitPassword: getTranslation(
      'validations.resetPassword.min8digitPassword'
    ),
    min1digitSpecial: getTranslation(
      'validations.resetPassword.min1digitSpecial'
    ),
    min1upperCase: getTranslation('validations.resetPassword.min1upperCase'),
  };

  const handleClose = () => {
    setNewPasswordMode(false);
    setRegistration(new RegistrationInfo(email));
    onClose();
  };

  const handleReturn = () => {
    setRegistration({
      email: registration.email,
      password: '',
      passwordConfirmation: '',
      recoveryCode: '',
      error: new RegistrationError(),
      temporaryPassword: '',
    });
    handleClose();
  };

  const handleSendVerifyCode = async () => {
    setLoading(true);
    try {
      let countError = 0;

      // const validation = await emailValidation(registration.email);

      // if (typeof validation !== 'boolean') {
      //   countError++;
      // }

      if (countError > 0) {
        setLoading(false);
        setRegistration({
          ...registration,
          // error: validation as RegistrationError,
        });
        return;
      } else
        setRegistration({ ...registration, error: new RegistrationError() });

      const response = await authService.SendVerifyCode(registration.email);
      if (
        response.details ===
        'Email com código de verificação enviado para o email informado'
      ) {
        toastNotification(
          'success',
          response.Message ||
            getTranslation('toast.success.verificationCodeSent')
        );

        setNewPasswordMode(true);
      } else {
        const errors = { ...registration.error };
        if (response.Errors) {
          response.Errors.forEach((error: any) => {
            if (error.Property === 'Email') {
              errors.email = error.Message[0];
            }
          });
        }
        setRegistration({ ...registration, error: errors });
        toastNotification(
          'error',
          response.detail.message ||
            getTranslation('toast.error.verificationCodeError')
        );
      }
    } catch (e: any) {
      toastNotification(
        'error',
        e.detail.message || getTranslation('toast.error.verificationCodeError')
      );
    }
    setLoading(false);
  };

  const handleConfirmResetPassword = async () => {
    setLoading(true);
    try {
      let countError = 0;

      const validation = await resetPasswordValidation(
        registration,
        passwordErrors,
        firstAccess
      );

      if (typeof validation !== 'boolean') {
        countError++;
      }

      if (countError > 0) {
        setLoading(false);
        setRegistration({
          ...registration,
          error: validation as RegistrationError,
        });
        return;
      } else
        setRegistration({ ...registration, error: new RegistrationError() });

      const req: IModifyPassword = {
        user: registration.email,
        new_password: registration.password,
        temporary_password_or_code: firstAccess
          ? temporaryPassword
          : registration.recoveryCode,
      };

      const response = await authService.ModifyPassword(req);
      if (response && response.details === 'Senha alterado com sucesso') {
        toastNotification(
          'success',
          response.Message || getTranslation('toast.success.newPasswordSet'),
          3000
        );

        handleClose();

        const responseLogin = await authService.CognitoLogin({
          user: registration.email,
          password: registration.password,
        });
        if (!!responseLogin.token && !!responseLogin.refresh_token) {
          localStorage.setItem('token', responseLogin.token);
          localStorage.setItem('refresh_token', responseLogin.refresh_token);
          dispatchUser(responseLogin.token).finally();
        } else {
          throw new Error(responseLogin.detail.message ?? '');
        }
      } else {
        const errors = { ...registration.error };
        if (response.Errors.length > 0) {
          response.Errors.forEach((error: any) => {
            if (error.Property === 'Email') {
              errors.email = error.Message[0];
            }
            if (error.Property === 'CodigoVerificacao') {
              errors.recoveryCode = error.Message[0];
            }
            if (error.Property === 'Password') {
              errors.password = error.Message[0];
            }
            if (error.Property === 'ConfirmacaoNovaSenha') {
              errors.password = error.Message[0];
            }
          });
          setRegistration({ ...registration, error: errors });
        } else {
          toastNotification(
            'error',
            response.Message || getTranslation('toast.error.resetPasswordError')
          );
        }
      }
    } catch (e: any) {
      toastNotification(
        'error',
        e.Message || getTranslation('toast.error.resetPasswordError')
      );
    }
    setLoading(false);
  };

  const renderLoading = () => {
    return (
      <S.ContainerGrid>
        <PersonSearchIcon size="40" />
        <Typography
          variant="h6"
          style={{ marginBottom: 24, marginTop: 16, textAlign: 'center' }}
        >
          {getTranslation('recoverPassword.message')}
        </Typography>
        <CircularProgress color="primary" style={{ marginBottom: 32 }} />
      </S.ContainerGrid>
    );
  };

  const passwordInputProps = {
    endAdornment: (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={() => setShowPassword(!showPassword)}
          onMouseDown={(e: React.MouseEvent<HTMLButtonElement>) =>
            e.preventDefault()
          }
          edge="end"
        >
          {showPassword ? (
            <VisibilityIcon name="Visibility" />
          ) : (
            <VisibilityOffIcon name="VisibilityOff" />
          )}
        </IconButton>
      </InputAdornment>
    ),
  };

  const onEnterDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      if (newPasswordMode) handleConfirmResetPassword();
      else handleSendVerifyCode();
    }
  };

  const renderNewPassword = () => {
    return (
      <S.Form onKeyPress={onEnterDown}>
        <S.ContainerContent>
          <Typography variant="caption" style={{ padding: '8px 32px' }}>
            {firstAccess ? (
              <>{getTranslation('recoverPassword.subtitleFirstAccess')}</>
            ) : (
              <>{getTranslation('recoverPassword.subtitle')}</>
            )}
          </Typography>
          <Grid
            item
            xs={12}
            sm={8}
            md={6}
            style={{ width: '100%', marginTop: 24 }}
          >
            {!firstAccess && (
              <>
                <TextField
                  label={getTranslation('recoverPassword.verificationCode')}
                  variant="outlined"
                  autoComplete="recovery-code"
                  fullWidth
                  value={registration.recoveryCode}
                  onChange={(e) =>
                    setRegistration({
                      ...registration,
                      recoveryCode: e.target.value,
                    })
                  }
                  error={!!registration.error.recoveryCode}
                  helperText={registration.error.recoveryCode}
                />
                <Tooltip
                  title={getTranslation(
                    'recoverPassword.resendVerificationCode'
                  )}
                  placement="right"
                >
                  <Fab
                    color="primary"
                    size="small"
                    onClick={handleSendVerifyCode}
                  >
                    <SyncIcon name="Sync" fill="#FFF" />
                  </Fab>
                </Tooltip>
              </>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            md={6}
            style={{ width: '100%', marginTop: 8 }}
          >
            <TextField
              label={getTranslation('recoverPassword.newPassword')}
              variant="outlined"
              value={registration.password}
              autoComplete="new-password"
              onChange={(e) =>
                setRegistration({ ...registration, password: e.target.value })
              }
              type={showPassword ? 'text' : 'password'}
              error={!!registration.error.password}
              helperText={registration.error.password}
              InputProps={{ ...passwordInputProps }}
              fullWidth
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            md={6}
            style={{ width: '100%', marginTop: 8, marginBottom: 40 }}
          >
            <TextField
              label={getTranslation('recoverPassword.confirmPassword')}
              variant="outlined"
              autoComplete="confirmation-password"
              value={registration.passwordConfirmation}
              onChange={(e) =>
                setRegistration({
                  ...registration,
                  passwordConfirmation: e.target.value,
                })
              }
              error={!!registration.error.passwordConfirmation}
              helperText={registration.error.passwordConfirmation}
              type={showPassword ? 'text' : 'password'}
              InputProps={{ ...passwordInputProps }}
              fullWidth
            />
          </Grid>
        </S.ContainerContent>
        <S.MttDialogActions>
          <MttButton
            onClick={handleReturn}
            color="default"
            className={classes.enabledButton}
          >
            {getTranslation('back')}
          </MttButton>
          <MttButton
            autoFocus
            onClick={handleConfirmResetPassword}
            color="primary"
            className={classes.enabledConfirmButton}
          >
            {getTranslation('save')}
          </MttButton>
        </S.MttDialogActions>
      </S.Form>
    );
  };

  const renderEmail = () => {
    return (
      <S.Form>
        <S.ContainerContent>
          <Typography variant="caption" style={{ padding: '8px 32px' }}>
            {getTranslation('recoverPassword.subtitle')}
          </Typography>
          <Grid
            item
            xs={12}
            sm={8}
            md={6}
            style={{ width: '100%', marginTop: 24, marginBottom: 14 }}
          >
            <TextField
              label={getTranslation('emailUpper')}
              variant="outlined"
              autoComplete="email"
              fullWidth
              value={registration.email}
              type="email"
              onChange={(e) =>
                setRegistration({ ...registration, email: e.target.value })
              }
              error={!!registration.error.email}
              helperText={registration.error.email}
            />
          </Grid>
        </S.ContainerContent>
        <S.MttDialogActions>
          <MttButton
            onClick={handleClose}
            color="default"
            className={classes.enabledButton}
          >
            {getTranslation('cancel')}
          </MttButton>
          <MttButton
            autoFocus
            onClick={handleSendVerifyCode}
            disabled={registration.email === ''}
            className={
              registration.email === ''
                ? classes.button
                : classes.enabledConfirmButton
            }
            color="primary"
          >
            {getTranslation('confirm')}
          </MttButton>
        </S.MttDialogActions>
      </S.Form>
    );
  };

  const renderBody = () => {
    if (newPasswordMode || firstAccess) return renderNewPassword();
    else return renderEmail();
  };

  const modalProps = {
    onClose: handleClose,
    open: open,
    noButtons: true,
  };

  return (
    <Dialog {...modalProps} PaperProps={{ style: { padding: '16px' } }}>
      <DialogTitle color="primary" className={classes.dialogTitle}>
        <Typography variant="h5" color="primary">
          {getTranslation('recoverPassword.title')}
        </Typography>
        <IconButton className={classes.closeButton}>
          <CloseIcon onClick={handleClose} />
        </IconButton>
      </DialogTitle>
      {loading ? renderLoading() : renderBody()}
    </Dialog>
  );
};

export default ModalResetPassword;
