import { useFlow } from 'contexts/Flow/flowContext';
import WithHasAccess from 'utils/WithHasAccess';
import useTranslator from 'utils/hooks/Translator';
import useAccessControlLayer from 'utils/hooks/useAccessControlLayer';

import * as S from './styles';

export default function ModalBlockedGroup() {
  const classes = S.useStyles();
  const { state, verifyEditGroup } = useFlow();
  const { hasPermissionToResource, isFintalkStaff } = useAccessControlLayer();
  const { getTranslation } = useTranslator();

  const handleEdit = async () => {
    if (state.idGroup && state.botName) {
      await verifyEditGroup(state.idGroup, state.botName);
    }
  };

  if (!state.openModalBlockedGroup || !state.botName) return <></>;

  return (
    <S.Container>
      <S.ContentContainer>
        <S.ModalTitle
          classes={{
            h6: classes.modalTitle,
          }}
        >
          {getTranslation('visualizationMode.title')}
        </S.ModalTitle>
        {(hasPermissionToResource(['edit']) || isFintalkStaff()) && (
          <S.TextContainer>
            {getTranslation('visualizationMode.subtitle')}
          </S.TextContainer>
        )}
      </S.ContentContainer>
      <S.ButtonContainer>
        {/* {(hasPermissionToResource(['edit']) || isFintalkStaff()) && ( */}
        <WithHasAccess action={['flow:write']}>
          <S.ButtonOk
            color="primary"
            variant={'contained'}
            onClick={handleEdit}
            disabled={state.idGroup === ''}
            className={classes.buttonOk}
          >
            {getTranslation('visualizationMode.button')}
          </S.ButtonOk>
        </WithHasAccess>
        {/* )} */}
      </S.ButtonContainer>
    </S.Container>
  );
}
