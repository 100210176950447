import React from 'react';

export interface ISVGProps {
  size?: string;
  color?: string;
}

const VariablesIcon: React.FC<ISVGProps> = (props) => {
  const { size, color } = props;

  return (
    <svg
      width={size || '24'}
      height={size || '24'}
      viewBox="0 0 24 24"
      fill={color || '#424242'}
    >
      <g>
        <rect
          xmlns="http://www.w3.org/2000/svg"
          id="Retângulo_1367"
          data-name="Retângulo 1367"
          width="24"
          height="24"
          fill="none"
        />
      </g>
      <g>
        <path
          xmlns="http://www.w3.org/2000/svg"
          id="Caminho_4999"
          data-name="Caminho 4999"
          d="M1.09-5.2a13.488,13.488,0,0,1,.536-3.832,10.112,10.112,0,0,1,1.582-3.221A5.907,5.907,0,0,1,5.476-14.2l.492,1.371a6.118,6.118,0,0,0-2,2.936,13.592,13.592,0,0,0-.721,4.641v.272A13.852,13.852,0,0,0,3.955-.325,6.237,6.237,0,0,0,5.968,2.654L5.476,4A5.91,5.91,0,0,1,3.248,2.1,9.943,9.943,0,0,1,1.67-1.028a13.408,13.408,0,0,1-.58-3.709ZM11.516-6.671l1.6-2.839h2.716L13.124-4.852,15.946,0H13.221l-1.7-2.988L9.837,0H7.1L9.925-4.852,7.227-9.51H9.952ZM22.03-5a12.8,12.8,0,0,1-.571,3.823,10.378,10.378,0,0,1-1.644,3.243A5.921,5.921,0,0,1,17.521,4l-.492-1.345A6.18,6.18,0,0,0,19-.246a13.431,13.431,0,0,0,.747-4.509v-.466a13.735,13.735,0,0,0-.716-4.632,6.442,6.442,0,0,0-2-3.006l.492-1.345a5.923,5.923,0,0,1,2.254,1.881,10.244,10.244,0,0,1,1.644,3.173A12.708,12.708,0,0,1,22.03-5.4Z"
          transform="translate(0 17)"
          fill="#424242"
        />
      </g>
    </svg>
  );
};

export default VariablesIcon;
