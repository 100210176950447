import styled from 'styled-components';
import MttButton from 'components/Material/MttButton/MttButton';
import MttGrid from 'components/Material/MttGrid/MttGrid';

import { createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';

export const ContainerDialog = styled(MttGrid)<{ colorHeader?: string }>`
  width: 40vw;
  height: 100%;

  padding: 8px 16px;
`;

export const ContainerHeader = styled(MttGrid)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalTitle = styled(Typography).attrs({
  variant: 'h6',
})`
  color: #dc1414;
  padding: 10px;
`;
export const Error = styled.p`
  color: #dc1414;
  font-size: 14px;
  /* padding: 10px; */
`;

export const FieldContainer = styled(Grid)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ul {
    list-style-type: none;
  }
  
  p {
    padding: 5px;
    text-align: center;
  }
`;

export const ButtonContainer = styled(Grid)`
  padding: 10px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;

  button {
    margin: 5px;
  }
`;

export const Button = styled(MttButton)`
  height: 50px;
`;

export const LineContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const NewKeyContainer = styled.div`
  margin-top: 40px;
  width: 100%;

  .MuiInputBase-input {
    outline: 0;
  }

  .MuiInputBase-input::-webkit-input-placeholder {
    opacity: 0.65;
    font-weight: 500;
  }
`;

export const TextContainer = styled.div`
  height: 64px;

  margin-right: 10px;
  width: 100%;
`;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      background: '#F2F2F2',
    },

    input: {
      background: '#FFFFFF',
    },
  })
);