import React from 'react';
import { IconButton, Menu, Typography } from '@material-ui/core';
import { useFlow } from 'contexts/Flow/flowContext';

import TwitterIcon from '@material-ui/icons/Twitter';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import TelegramIcon from '@material-ui/icons/Telegram';
import FacebookIcon from '@material-ui/icons/Facebook';
import PhoneIcon from '@material-ui/icons/Phone';
import FintalkIcon from '../../Icons/fintalk.png';

import { ListContainer } from './styles';
import useTranslator from 'utils/hooks/Translator';

interface FlowsUrlsProps {
  handleCloseUrlsTests: () => void;
  openFlowTest: boolean;
  anchorElFlow: any;
  openFalazap: () => void;
}

export default function FlowsUrls(props: FlowsUrlsProps) {
  const { state, toastNotification } = useFlow();
  const [falazapIframeElement, setFalazapIframeElement] =
    React.useState<HTMLElement | null>(null);
  const { anchorElFlow, openFlowTest, handleCloseUrlsTests, openFalazap } =
    props;
  const { getTranslation } = useTranslator();

  const handleOpenLink = (url?: string) => {
    if (url) {
      try {
        const validUrl = new URL(url);
        window.open(validUrl, '_blank');
      } catch (_) {
        toastNotification('error', getTranslation('toast.error.invalidUrl'));
        return false;
      }
    }
  };

  const botsUrls =
    state.botsUrls.urlFalazap ||
    state.botsUrls.urlFacebook ||
    state.botsUrls.urlTwitter ||
    state.botsUrls.urlTelegram ||
    state.botsUrls.urlWhatsApp;

  if (!botsUrls) return <></>;

  return (
    <Menu
      id="long-menu"
      MenuListProps={{
        'aria-labelledby': 'long-button',
      }}
      anchorEl={anchorElFlow}
      open={openFlowTest}
      onClose={handleCloseUrlsTests}
      PaperProps={{
        style: {
          marginTop: '56px',
          marginLeft: '-8px',
          background: 'rgb(255, 255, 255)',
          width: '148px',
        },
      }}
    >
      <ListContainer>
        <IconButton
          disabled={!state.botsUrls.urlFalazap}
          aria-label="filter-list"
          onClick={() => {
            if (!falazapIframeElement) {
              const iframe = document.getElementById('falazap-iframe');
              if (iframe) {
                iframe.setAttribute('src', state.botsUrls.urlFalazap + '');
                setFalazapIframeElement(iframe);
              }
            } else
              falazapIframeElement.setAttribute(
                'src',
                state.botsUrls.urlFalazap + ''
              );

            handleCloseUrlsTests();
            openFalazap();
          }}
        >
          <img src={FintalkIcon} style={{ width: '20px' }} alt="falazapIcon" />
          <Typography>FalaZap</Typography>
        </IconButton>

        <IconButton
          disabled={!state.botsUrls.urlTelegram}
          aria-label="filter-list"
          onClick={() => handleOpenLink(state.botsUrls.urlTelegram)}
        >
          <TelegramIcon />
          <Typography variant="subtitle1">Telegram</Typography>
        </IconButton>

        <IconButton
          disabled={!state.botsUrls.urlWhatsApp}
          aria-label="filter-list"
          onClick={() => handleOpenLink(state.botsUrls.urlWhatsApp)}
        >
          <WhatsAppIcon />
          <Typography variant="subtitle1">WhatsApp</Typography>
        </IconButton>
        <IconButton
          disabled={true}
          aria-label="filter-list"
          onClick={() => handleOpenLink(state.botsUrls.urlTwitter)}
        >
          <TwitterIcon />
          <Typography variant="subtitle1">Twitter</Typography>
        </IconButton>
        <IconButton
          disabled={true}
          aria-label="filter-list"
          onClick={() => handleOpenLink(state.botsUrls.urlFacebook)}
        >
          <FacebookIcon />
          <Typography variant="subtitle1">Messenger</Typography>
        </IconButton>
        <IconButton
          disabled={true}
          aria-label="filter-list"
          onClick={() => handleOpenLink(state.botsUrls.urlFacebook)}
        >
          <PhoneIcon />
          <Typography variant="subtitle1">URA</Typography>
        </IconButton>
      </ListContainer>
    </Menu>
  );
}
