export interface ICampaign {
  _id?: string;
  name: string;
  description: string;
  template: string;
  status: ICampaignStatus;
  variables: ITemplateVariable[] | null;
  creation_date: Date;
  bot_name: string;
  sender: ISender;
}

export type ICampaignStatus = 'active' | 'inactive';

export type ISenderChannel = 'phone' | 'email';

export interface ISender {
  channel: ISenderChannel;
  column: number | null;
}

export interface ITemplateVariable {
  variable: string;
  column: string;
}

export interface IGetCampaignsResponse {
  data: ICampaign[];
}

export interface IGetCampaignsRequest extends Pick<ICampaign, 'bot_name'> {}

export interface IGetOneCampaignRequest extends ICampaign {
  campaignId: string;
}

export interface IGetOneCampaignResponse {
  data: ICampaign;
}

export interface ICreateCampaignRequest extends ICampaign {}

export interface ICreateCampaignResponse {
  data: ICampaign;
}

export interface IUpdateCampaignRequest extends ICampaign, ICampaign {}

export interface IUpdateCampaignResponse {
  data: ICampaign;
}

export interface IDeleteCampaignRequest
  extends Pick<ICampaign, 'bot_name' | '_id'> {}

export const emptyCampaign: ICampaign = {
  name: '',
  description: '',
  template: '',
  status: 'inactive',
  variables: [],
  creation_date: new Date(),
  bot_name: '',
  sender: { channel: 'phone', column: 1 },
};
