import SchemaIcon from 'components/Icons/SchemaIcon';
import VariablesIcon from 'components/Icons/VariablesIcon';
import AdminIcon from 'components/Icons/AdminIcon';
// import BotConfigIcon from 'components/Icons/BotConfigIcon';
// import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import TrainingIcon from 'components/Icons/TrainingIcon';
import SmsIcon from '@material-ui/icons/Sms';
import SmsFailedIcon from '@material-ui/icons/SmsFailed';
import SendIcon from '@material-ui/icons/Send';
import RestoreIcon from '@material-ui/icons/Restore';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import BusinessIcon from '@material-ui/icons/Business';
import FlagIcon from '@material-ui/icons/Flag';
import StarsIcon from '@material-ui/icons/Stars';
import ForumIcon from '@material-ui/icons/Forum';
import ImportExportIcon from '@material-ui/icons/ImportExport';
// import LockIcon from '@material-ui/icons/Lock';
import WidgetsIcon from '@material-ui/icons/Widgets';
import { IOptions } from 'views/Canvas/Main/types';
import { GetApp, Publish } from '@material-ui/icons';

import useTranslator from './Translator';
import TemplateIcon from 'components/Icons/TemplateIcon';
import CampaignIcon from 'components/Icons/CampaignIcon';
import ActioningIcon from 'components/Icons/ActioningIcon';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import FintalkIcon from 'components/Icons/FintalkIcon';

type Props = {
  handleHistoryConversation: () => void;
};

export const slugsListOptions = {
  EDIT_ACCESS_KEYS: 'EDIT_ACCESS_KEYS',
  IMPORT_EXPORT_BOT: 'IMPORT_EXPORT_BOT',
  MANAGE_ACCOUNT: 'MANAGE_ACCOUNT',
  MANAGE_COMPANY: 'MANAGE_COMPANY',
  MANAGE_PERMISSIONS: 'MANAGE_PERMISSIONS',
  OPEN_CAMPAIGN: 'OPEN_CAMPAIGN',
  MANAGE_CORRECT_SENTENCES: 'MANAGE_CORRECT_SENTENCES',
  MODAL_PHRASES_OF_INTENT: 'MODAL_PHRASES_OF_INTENT',
  BOT_HISTORY: 'BOT_HISTORY',
  OPEN_CAMPAIGN_TEMPLATES: 'OPEN_CAMPAIGN_TEMPLATES',
  OPEN_CAMPAIGN_CAMPAIGN: 'OPEN_CAMPAIGN_CAMPAIGN',
  OPEN_CAMPAIGN_ACTIONING: 'OPEN_CAMPAIGN_ACTIONING',
  OPEN_FALAZAP_THEME: 'OPEN_FALAZAP_THEME',
  OPEN_FALAZAP_TOKEN: 'OPEN_FALAZAP_TOKEN',
};

export default function useSidebarItems({ handleHistoryConversation }: Props) {
  const { getTranslation } = useTranslator();

  const listMenuOptionsAdmin: IOptions[] = [
    {
      canBeUsed: ['adm'],
      icon: <BusinessIcon />,
      name: getTranslation('menu.adminChildren.manageOrganization'),
      path: '/teste',
      slug: slugsListOptions.MANAGE_COMPANY,
    },
    // {
    //   canBeUsed: ['adminStaff', 'admin'],
    //   icon: <LockIcon />,
    //   name: getTranslation('menu.adminChildren.managePermissions'),
    //   path: '/teste',
    //   slug: slugsListOptions.MANAGE_PERMISSIONS,
    // },
  ];

  const listLibrary: Array<IOptions> = [
    {
      canBeUsed: ['flow:write'],
      name: getTranslation('menu.libraryChildren.defaultTemplates'),
      path: '/teste',
      icon: <FlagIcon />,
    },
    {
      canBeUsed: ['flow:write'],
      name: getTranslation('menu.libraryChildren.savedBlocks'),
      path: '/',
      icon: <WidgetsIcon />,
    },
    {
      canBeUsed: ['flow:write'],
      name: getTranslation('menu.libraryChildren.saveSelectedBlocks'),
      path: '',
      icon: <BeenhereIcon />,
    },
  ];

  const listImporExportBot: Array<IOptions> = [
    {
      canBeUsed: ['flow:publish'],
      name: getTranslation('menu.uploadDownloadAgentChildren.upload'),
      path: '/',
      icon: <Publish />,
    },
    {
      canBeUsed: ['flow:publish'],
      name: getTranslation('menu.uploadDownloadAgentChildren.download'),
      path: '/',
      icon: <GetApp />,
    },
  ];

  const listTraining: Array<IOptions> = [
    {
      canBeUsed: ['flow:write'],
      name: getTranslation('menu.trainingChildren.createUpdatePhrases'),
      path: '/',
      icon: <SmsIcon />,
      slug: slugsListOptions.MODAL_PHRASES_OF_INTENT,
    },
    {
      canBeUsed: ['flow:write'],
      name: getTranslation('menu.trainingChildren.fixPhrases'),
      path: '/',
      icon: <SmsFailedIcon />,
      slug: slugsListOptions.MANAGE_CORRECT_SENTENCES,
    },
  ];

  const listMenuOptions: IOptions[] = [
    {
      canBeUsed: ['flow:read', 'flow:write'],
      icon: <SchemaIcon />,
      name: getTranslation('menu.flows'),
      path: '/',
    },
    {
      canBeUsed: ['flow:write'],
      icon: <VariablesIcon />,
      name: getTranslation('menu.variables'),
      path: '/entities',
    },
    {
      canBeUsed: ['flow:write'],
      icon: <StarsIcon />,
      name: getTranslation('menu.library'),
      path: '/',
      children: listLibrary,
    },
    {
      action: () => handleHistoryConversation(),
      canBeUsed: ['flow:read', 'flow:publish', 'flow:write'],
      icon: <ForumIcon />,
      name: getTranslation('menu.conversationHistory'),
    },
    {
      canBeUsed: ['flow:publish'],
      icon: <ImportExportIcon />,
      name: getTranslation('menu.uploadDownloadAgent'),
      path: '/',
      slug: slugsListOptions.IMPORT_EXPORT_BOT,
      children: listImporExportBot,
    },
    {
      canBeUsed: ['flow:publish'],
      icon: <RestoreIcon />,
      name: getTranslation('menu.publishHistory'),
      slug: slugsListOptions.BOT_HISTORY,
    },
    {
      canBeUsed: ['access_key:read', 'access_key:write'],
      icon: <VpnKeyIcon />,
      name: getTranslation('menu.editAccessKeys'),
      path: '/teste',
      slug: slugsListOptions.EDIT_ACCESS_KEYS,
    },
  ];

  const listCampaignOptions: IOptions[] = [
    {
      canBeUsed: [
        'communication_guideline:read',
        'communication_guideline:write',
      ],
      icon: <TemplateIcon />,
      name: 'Templates',
      path: '/',
      slug: slugsListOptions.OPEN_CAMPAIGN_TEMPLATES,
    },
    {
      canBeUsed: [
        'communication_guideline:read',
        'communication_guideline:write',
      ],
      icon: <CampaignIcon />,
      name: getTranslation('campaign'),
      path: '/',
      slug: slugsListOptions.OPEN_CAMPAIGN_CAMPAIGN,
    },
    {
      canBeUsed: [
        'communication_guideline:read',
        'communication_guideline:write',
      ],
      icon: <ActioningIcon />,
      name: getTranslation('actionings'),
      path: '/',
      slug: slugsListOptions.OPEN_CAMPAIGN_ACTIONING,
    },
  ];

  const falazapOptions: IOptions[] = [
    {
      canBeUsed: ['adm'],
      icon: <ColorLensIcon />,
      name: getTranslation('menu.falazapConfig.editTheme'),
      path: '/',
      slug: slugsListOptions.OPEN_FALAZAP_THEME,
      disabled: true,
    },
    {
      canBeUsed: ['adm'],
      icon: <FingerprintIcon />,
      name: getTranslation('menu.falazapConfig.identificationToken'),
      path: '/',
      slug: slugsListOptions.OPEN_FALAZAP_TOKEN,
    },
  ];

  const listAdminStuff: IOptions[] = [
    {
      canBeUsed: ['adm'],
      icon: <FintalkIcon />,
      name: getTranslation('menu.falazapConfig.title'),
      path: '/',
      children: falazapOptions,
    },
    {
      canBeUsed: [
        'communication_guideline:read',
        'communication_guideline:write',
      ],
      icon: <SendIcon />,
      name: getTranslation('menu.communicationRuler'),
      path: '/',
      slug: slugsListOptions.OPEN_CAMPAIGN,
      children: listCampaignOptions,
    },
    {
      canBeUsed: ['*'],
      icon: <TrainingIcon />,
      name: getTranslation('menu.training'),
      path: '/',
      children: listTraining,
    },
    {
      canBeUsed: ['adm'],
      icon: <AdminIcon />,
      name: getTranslation('menu.admin'),
      path: '/',
      children: listMenuOptionsAdmin,
    },
  ];

  return {
    listMenuOptions,
    listMenuOptionsAdmin,
    listLibrary,
    listCampaignOptions,
    listAdminStuff,
  };
}
