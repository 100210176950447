import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import DeleteIcon from '@material-ui/icons/Delete';

import {
  Dialog,
  Divider,
  InputAdornment,
  Slide,
  TextField,
  Tooltip,
} from '@material-ui/core';

import PublishIcon from '@material-ui/icons/Publish';

import { useFlow } from 'contexts/Flow/flowContext';

import './styles.css';
import {
  ContainerDialog,
  ContainerHeader,
  FieldContainer,
  SavedBlocksContainer,
  Button,
  ContainerRight,
  ModalTitle,
  SavedBlocks,
  Description,
  Section,
  ContainerDivider,
  ContainerDetails,
  ImportExportContainer,
  ImportExportButtons,
  useStyles,
} from './styles';

import { CircularProgress } from '@material-ui/core';
import { useLibrary } from 'contexts/Library/libraryContext';
import {
  GetLibrary,
  RemoveLibrary,
  SaveLibrary,
  UpdateLibrary,
} from 'services/LibraryService';
import AddBlockToFlowController from 'controllers/library/AddBlockToFlowController';
import { DataFlow } from 'models/DataFlow';
import GetOffsetPositionFlowController from 'controllers/library/GetOffsetPositionFlowController';
import { DescriptionInfo } from '../ModalStandardTemplates/styles';
import { formatDate } from 'utils/Date';
import { useApp } from 'contexts/App/appContext';
import { Block } from 'contexts/Library/types';
import useTranslator from 'utils/hooks/Translator';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalSavedBlocks() {
  const { dispatch: dispatchApp } = useApp();
  const { state, dispatch, toastNotification, mountNodeContent } = useFlow();
  const { botName } = state;
  const classes = useStyles();

  const options = ['a', 'b', 'c', 'd', 'e', 'f', 'g'];

  const [search, setSearch] = useState('');
  const [load, setLoad] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const { getTranslation } = useTranslator();

  const { state: libraryState, dispatch: libraryDispatch } = useLibrary();

  const loadLibrary = useCallback(async () => {
    if (state.openModalSavedBlocks) {
      const result = await GetLibrary({ bot_name: botName || '' }, dispatchApp);
      if (result.Success) {
        libraryDispatch({
          type: 'saveBlockSet',
          data: { blockToSaveList: result.Data.data },
        });
      } else {
        toastNotification(
          'error',
          result.Message || getTranslation('toast.error.getBlocks')
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.openModalSavedBlocks, botName, libraryDispatch, toastNotification]);

  useEffect(() => {
    setLoad(true);
    setSearch('');
    loadLibrary();
    setLoad(false);
  }, [loadLibrary]);

  function handleClose() {
    setSelectedIndex(null);
    dispatch({ type: 'closeModalSavedBlocks' });
  }

  if (!state.openModalSavedBlocks) return <></>;

  const handleSelectBlock = (index: number) => {
    setSelectedIndex(index);
  };

  const handleRemoveBlock = async (index: number) => {
    if (load) return;
    setLoad(true);
    const library = [...libraryState.blockToSaveList];
    const result = await RemoveLibrary(
      {
        bot_name: botName || '',
        library_name: library[index].name,
      },
      dispatchApp
    );
    if (result.Success) {
      toastNotification('success', getTranslation('toast.success.deleteBlock'));
      setSelectedIndex(null);
      await loadLibrary();
    } else {
      toastNotification(
        'error',
        getTranslation('toast.error.deleteBlock') || result.Message
      );
    }
    setLoad(false);
  };

  const handleExportOneBlock = async (index: number) => {
    const library = [...libraryState.blockToSaveList];
    const { name, _id, description, author, created_at, last_updated, blocks } =
      library[index];

    const savedBlocksData = {
      name,
      _id,
      description,
      author,
      created_at,
      last_updated,
    };
    const blockToSave = {
      savedBlocksData: savedBlocksData,
      drawflow: {
        Home: {
          data: blocks,
        },
      },
    };

    const documentHtml = document.getElementById('drawflow');
    const dataStr =
      'data:text/json;charset=utf-8,' +
      encodeURIComponent(JSON.stringify(blockToSave));
    const dlAnchorElem = document.createElement('a');
    documentHtml?.appendChild(dlAnchorElem);
    if (dlAnchorElem) {
      dlAnchorElem.setAttribute('href', dataStr);
      const dateGenerated = new Date();
      const dataFormated =
        dateGenerated.getDate() +
        '-' +
        dateGenerated.getMonth() +
        '-' +
        dateGenerated.getFullYear() +
        '-' +
        dateGenerated.getHours() +
        '-' +
        dateGenerated.getMinutes();
      dlAnchorElem.setAttribute(
        'download',
        `dataflow-${blockToSave.savedBlocksData.name}-${dataFormated}.json`
      );
      dlAnchorElem.click();
      dlAnchorElem.parentNode?.removeChild(dlAnchorElem);
    }
  };

  const handleUpdateBlock = async (index: number) => {
    const documentHtml = document.getElementById('drawflow');
    const uploadInput = document.createElement('input');
    uploadInput.type = 'file';
    uploadInput.accept = 'application/json';
    uploadInput.onchange = async (e: any) => {
      const file: File = e.target.files?.item(0);
      const drawflow = JSON.parse(await file.text());

      if (drawflow.drawflow) {
        if (drawflow.drawflow.Home) {
          if (drawflow.drawflow.Home.data) {
            const library = libraryState;
            library.blockToSaveList[index].blocks = drawflow.drawflow.Home.data;

            const updateLibraryResult = await UpdateLibrary(
              botName || '',
              library.blockToSaveList[index],
              dispatchApp
            );
            if (updateLibraryResult.Success) {
              toastNotification(
                'success',
                getTranslation('toast.success.blocksUpdated')
              );
            } else {
              toastNotification(
                'error',
                getTranslation('toast.error.blocksUpdated')
              );
            }
            loadLibrary();
          }
        } else
          toastNotification(
            'error',
            getTranslation('toast.error.invalidBlock')
          );
      } else
        toastNotification('error', getTranslation('toast.error.invalidBlock'));

      uploadInput.parentNode?.removeChild(uploadInput);
    };

    documentHtml?.appendChild(uploadInput);
    uploadInput.click();
    uploadInput.parentNode?.removeChild(uploadInput);
  };

  const handleExportAllBlocks = () => {
    const library = { savedBlocks: [...libraryState.blockToSaveList] };

    const documentHtml = document.getElementById('drawflow');
    const dataStr =
      'data:text/json;charset=utf-8,' +
      encodeURIComponent(JSON.stringify(library));
    const dlAnchorElem = document.createElement('a');
    documentHtml?.appendChild(dlAnchorElem);
    if (dlAnchorElem) {
      dlAnchorElem.setAttribute('href', dataStr);
      const dateGenerated = new Date();
      const dataFormated =
        dateGenerated.getDate() +
        '-' +
        dateGenerated.getMonth() +
        '-' +
        dateGenerated.getFullYear() +
        '-' +
        dateGenerated.getHours() +
        '-' +
        dateGenerated.getMinutes();
      dlAnchorElem.setAttribute(
        'download',
        `${state.botName}-blocos-salvos-${dataFormated}.json`
      );
      dlAnchorElem.click();
      dlAnchorElem.parentNode?.removeChild(dlAnchorElem);
    }
  };

  const handleAddBlocks = async (
    savedBlocks: Block[],
    erroredBlocks: string[]
  ) => {
    savedBlocks.forEach(async (block) => {
      if (!libraryState.blockToSaveList.some((b) => b.name === block.name)) {
        libraryState.blockToSaveList.push(block);

        await SaveLibrary(
          {
            ...block,
            bot_name: botName || '',
          },
          dispatchApp
        );

        loadLibrary();
      } else {
        erroredBlocks.push(block.name);
      }
    });
    return erroredBlocks;
  };

  const handleImportSingleBlock = async () => {
    const documentHtml = document.getElementById('drawflow');
    const uploadInput = document.createElement('input');

    uploadInput.type = 'file';
    uploadInput.accept = 'application/json';

    uploadInput.onchange = async (e: any) => {
      const file: File = e.target.files?.item(0);
      const drawflow = JSON.parse(await file.text());

      if (drawflow.drawflow) {
        if (drawflow.drawflow.Home) {
          if (drawflow.drawflow.Home.data) {
            const block = drawflow.drawflow.Home.data;
            const savedBlocks: Block[] = [];
            savedBlocks.push({
              name: drawflow.savedBlocksData?.name || file.name,
              description: drawflow.savedBlocksData?.description || '',
              blocks: block,
            });

            const erroredBlocks = await handleAddBlocks(savedBlocks, []);

            if (erroredBlocks.length > 0) {
              let sErroredBlocks = '[';

              erroredBlocks.map((block) => {
                return (sErroredBlocks += `${block}, `);
              });

              sErroredBlocks = sErroredBlocks.substring(
                0,
                sErroredBlocks.length - 2
              );
              sErroredBlocks += ']';

              toastNotification(
                'error',
                getTranslation('toast.error.importBlockCollection', {
                  blocks: sErroredBlocks,
                })
              );
            } else {
              toastNotification(
                'success',
                getTranslation('toast.success.blockUploaded')
              );
            }

            loadLibrary();
          }
        } else
          toastNotification(
            'error',
            getTranslation('toast.error.invalidBlock')
          );
      } else
        toastNotification('error', getTranslation('toast.error.invalidBlock'));

      uploadInput.parentNode?.removeChild(uploadInput);
    };
    documentHtml?.appendChild(uploadInput);
    uploadInput.click();
    uploadInput.parentNode?.removeChild(uploadInput);
  };

  const handleImportBlocks = () => {
    const documentHtml = document.getElementById('drawflow');
    const uploadInput = document.createElement('input');

    uploadInput.type = 'file';
    uploadInput.accept = 'application/json';

    uploadInput.onchange = async (e: any) => {
      const file = e.target.files?.item(0);
      const blocksSaved = JSON.parse(await file.text());

      const erroredBlocks: string[] = [];

      if (blocksSaved.savedBlocks) {
        if (blocksSaved.savedBlocks.length >= 0) {
          const savedBlocks: Block[] = blocksSaved.savedBlocks;

          const errors = await handleAddBlocks(savedBlocks, erroredBlocks);

          if (errors.length > 0) {
            let sErroredBlocks = '[';

            erroredBlocks.map((block) => {
              return (sErroredBlocks += `${block}, `);
            });

            sErroredBlocks = sErroredBlocks.substring(
              0,
              sErroredBlocks.length - 2
            );
            sErroredBlocks += ']';

            toastNotification(
              'error',
              getTranslation('toast.error.importBlockCollection', {
                blocks: sErroredBlocks,
              })
            );
          }
        }
      } else {
        toastNotification(
          'error',
          getTranslation('toast.error.invalidBlockCollection')
        );
      }
    };
    documentHtml?.appendChild(uploadInput);
    uploadInput.click();
    uploadInput.parentNode?.removeChild(uploadInput);
  };

  const isSelected = (index: number) => {
    return selectedIndex === index;
  };

  const renderDetails = () => {
    if (selectedIndex === null) return <></>;
    const block = libraryState.blockToSaveList[selectedIndex];
    return (
      <ContainerDetails>
        <strong> {block.name} </strong>
        <DescriptionInfo>
          <span>{getTranslation('modal.templates.description')}</span>
          <strong>{block.description}</strong>
          <span>{getTranslation('modal.templates.createdBy')}</span>
          <strong>{block?.author || ''}</strong>
          <span>{getTranslation('modal.templates.createdAt')}</span>
          <strong>{formatDate(block?.created_at || '')}</strong>
        </DescriptionInfo>
      </ContainerDetails>
    );
  };

  const isDisabled = () => {
    return selectedIndex === null;
  };

  const handleSendToFlow = async () => {
    if (state.editorFlow?.drawflow && selectedIndex != null) {
      const { editorFlow } = state;

      const offsetPosition = new GetOffsetPositionFlowController(
        editorFlow
      ).getLastPosition();

      const drawflowCurrent = { ...editorFlow.drawflow };
      const controller = new AddBlockToFlowController(
        drawflowCurrent,
        state.idGroup || '',
        offsetPosition
      );
      await controller.addBlock(
        libraryState.blockToSaveList[selectedIndex].blocks
      );
      const drawflow = controller.getDrawflow();

      editorFlow.clear();
      editorFlow.import(drawflow);
      const dataFlow: DataFlow = drawflow.drawflow.Home.data;
      mountNodeContent(dataFlow);
      handleClose();
    }
  };

  return (
    <Dialog
      open={state.openModalSavedBlocks}
      TransitionComponent={Transition}
      maxWidth="lg"
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <ContainerDialog className={classes.dialog}>
        <ContainerHeader>
          <ModalTitle style={{ fontWeight: 600 }}>
            {getTranslation('modal.blocks.title')}
          </ModalTitle>
          <IconButton
            edge="start"
            color="default"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </ContainerHeader>
        <FieldContainer>
          <Autocomplete
            freeSolo
            options={options.map((option) => option)}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={getTranslation('modal.templates.search')}
                variant="outlined"
                value={search}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setSearch(e.target.value)
                }
                InputProps={{
                  type: 'search',
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            )}
          />
        </FieldContainer>
        <SavedBlocksContainer>
          <SavedBlocks>
            {load ? (
              <CircularProgress />
            ) : (
              libraryState.blockToSaveList
                .map((renderCardList, index) => ({
                  renderCardList,
                  index,
                }))
                .filter(
                  (item) =>
                    item.renderCardList.name
                      .toLowerCase()
                      .indexOf(search.toLowerCase()) > -1 || !search
                )
                .sort((a, b) =>
                  a.renderCardList.name.localeCompare(
                    b.renderCardList.name,
                    undefined,
                    { sensitivity: 'base' }
                  )
                )
                .map((item, index) => (
                  <>
                    <Section
                      key={`CL${index}`}
                      onClick={() => handleSelectBlock(item.index)}
                      selected={isSelected(item.index)}
                    >
                      <span>{item.renderCardList.name}</span>
                      <div className="icons">
                        <Tooltip title={getTranslation('import')}>
                          <IconButton
                            edge="start"
                            color="default"
                            aria-label="save"
                            onClick={() => handleUpdateBlock(item.index)}
                          >
                            <PublishIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={getTranslation('export')}>
                          <IconButton
                            edge="start"
                            color="default"
                            aria-label="save"
                            onClick={() => handleExportOneBlock(item.index)}
                          >
                            <PublishIcon
                              style={{ transform: 'scale(-1,-1)' }}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={getTranslation('delete')}>
                          <IconButton
                            edge="start"
                            color="default"
                            aria-label="close"
                            onClick={() => handleRemoveBlock(item.index)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </Section>
                    <ContainerDivider>
                      <Divider variant="fullWidth" />
                    </ContainerDivider>
                  </>
                ))
            )}
          </SavedBlocks>
          <ContainerRight>
            <Description>{renderDetails()}</Description>
          </ContainerRight>
        </SavedBlocksContainer>
        <ImportExportContainer>
          <ImportExportButtons>
            <Button
              style={{ letterSpacing: 2, borderRadius: 8 }}
              variant="contained"
              color="primary"
              onClick={handleImportSingleBlock}
            >
              {getTranslation('modal.blocks.importBlock')}
            </Button>
            <Button
              style={{ letterSpacing: 2, borderRadius: 8 }}
              variant="contained"
              color="primary"
              onClick={handleImportBlocks}
            >
              {getTranslation('modal.blocks.importCollection')}
            </Button>
            <Button
              style={{ letterSpacing: 2, borderRadius: 8 }}
              variant="contained"
              color="primary"
              onClick={handleExportAllBlocks}
              disabled={libraryState.blockToSaveList.length === 0}
            >
              {getTranslation('modal.blocks.exportCollection')}
            </Button>
          </ImportExportButtons>
          <ContainerRight>
            <Button
              style={{ letterSpacing: 2, borderRadius: 8 }}
              disabled={isDisabled()}
              variant="contained"
              color="primary"
              onClick={handleSendToFlow}
            >
              {getTranslation('modal.templates.sendToFlowButton')}
            </Button>
          </ContainerRight>
        </ImportExportContainer>
      </ContainerDialog>
    </Dialog>
  );
}
