import React from 'react';
import FooterButtons from 'components/inputs/Material/FooterButtons/index';
import Info from 'components/inputs/Material/Info/index';
import NewCarouselContent from 'components/inputs/Material/NewCarouselContent';
import { FormFieldsContainer } from '../Shared/styles';
import MultipleTags from 'components/inputs/Material/MultipleTags';

export default function NewCarousel() {
  return (
    <>
      <FormFieldsContainer>
        <Info />
        <MultipleTags />
        <NewCarouselContent />
      </FormFieldsContainer>
      <FooterButtons showLogicButton={false} />
    </>
  );
}
