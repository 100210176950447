import styled from 'styled-components';
import MttGrid from '../Material/MttGrid/MttGrid';
import MttButton from '../Material/MttButton/MttButton';

import { Grid, Typography } from '@material-ui/core';

export const ContainerDialog = styled(MttGrid) <{ colorHeader?: string }>`
  width: 55vw;
  height: 100%;

  padding: 8px 16px;
`;

export const ContainerHeader = styled(MttGrid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ModalTitle = styled(Typography).attrs({
  variant: 'h6',
})`
  color: #6c50a4;
`;

export const FieldContainer = styled(Grid)`
  padding: 8px 0;

  .MuiInput-root {
    padding: 12px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background: #ffffff;
  }

  .MuiInputBase-input::-webkit-input-placeholder {
    opacity: 0.65;
    font-weight: 500;
  }

  .MuiInput-underline:before {
    display: none;
  }

  .MuiInputBase-multiline {
    padding: 0;
  }
`;

export const TemplatesContainer = styled(Grid)`
  display: flex;

  padding: 8px 0;
`;

export const Section = styled(MttGrid)<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 8px;
  margin-right: 6px;

  background-color: ${(props) => (props.selected ? '#6C50A41A' : '#FFFFFF')};

  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.2);

  span {
    font-weight: 500;
  }

  div {
    opacity: 0.6;
  }

  &:hover {
    background-color: ${(props) => (props.selected ? 'null' : '#f2f2f2')};
  }
`;

export const Templates = styled(Grid)`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  width: 55%;
  height: 20rem;
`;

export const Description = styled(Grid)`
  display: flex;
  flex-direction: column;

  width: 45%;
  border-radius: 5px;
  background: #ffffff;
  padding: 16px 16px;
`;

export const DescriptionInfo = styled(Grid)`
  display: flex;
  flex-direction: column;

  padding-top: 16px;

  span {
    padding-top: 8px;
    opacity: 0.7;
  }
`;

export const ButtonsContainer = styled(Grid)`
  padding: 16px 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const Button = styled(MttButton)`
  height: 50px;
`;

export const ContainerDivider = styled.div`
  .MuiDivider-root {
    height: 2px;
    color: #aeaeae;
  }
`;
