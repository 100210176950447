import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import IconLLego from 'components/Icons/LLego';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as S from './styles';

import { useApp } from 'contexts/App/appContext';
import { useFlow } from 'contexts/Flow/flowContext';
import { IUser, userValidation } from 'models/LoginModel';

import { CognitoLogin } from 'services/AuthService';
import { IconButton, InputAdornment, Typography } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { PublicClientApplication } from '@azure/msal-browser';

import ModalResetPassword from './ModalResetPassword';

import ChooseLanguage from 'components/ChooseLanguage';
import useTranslator from 'utils/hooks/Translator';
import { IForgotPasswordOpenProps } from './ModalResetPassword/types';

import MicrosoftIcon from 'components/Icons/MicrosoftIcon';
import jwtDecode from 'jwt-decode';
import { IPermissionCompany } from 'contexts/Permissions/types';

const APP_ID = process.env.REACT_APP_AVENUE_AD_CLIENT_ID as string;
const TENANT_ID = process.env.REACT_APP_AVENUE_AD_TENANT_ID;

const config = {
  appId: APP_ID,
  redirectUri: '',
  scopes: ['user.read'],
  authority: `https://login.microsoftonline.com/${TENANT_ID}`,
};

const Login: React.FC = () => {
  const { dispatch } = useApp();
  const { toastNotification, generatePermissions } = useFlow();
  const history = useHistory();
  const { getTranslation } = useTranslator();

  const publicClientApplication = new PublicClientApplication({
    auth: {
      clientId: config.appId,
      redirectUri: config.redirectUri,
      authority: config.authority,
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false,
    },
  });

  const [user, setUser] = useState<IUser>({
    email: '',
    password: '',
    error: {
      email: getTranslation('validations.required', {
        field: getTranslation('emailUpper'),
      }),
      password: getTranslation('validations.required', {
        field: getTranslation('passwordUpper'),
      }),
    },
  });
  const [errors, setErrors] = useState<Partial<IUser>>({
    email: '',
    password: '',
  });
  const [load, setLoad] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [openResetPassword, setOpenResetPassword] =
    useState<IForgotPasswordOpenProps>({
      open: false,
      firstAccess: false,
      email: '',
    });

  const passwordInputProps = {
    endAdornment: (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={() => setShowPassword(!showPassword)}
          onMouseDown={(e: React.MouseEvent<HTMLButtonElement>) =>
            e.preventDefault()
          }
          edge="end"
        >
          {showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    ),
  };

  const handleAdLogin = async () => {
    try {
      const response = await publicClientApplication.loginPopup({
        scopes: config.scopes,
        prompt: 'select_account',
      });

      const token = response.accessToken;

      if (!!token) {
        const responseCognitoLogin = await CognitoLogin({ token });
        if (
          !!responseCognitoLogin.token &&
          !!responseCognitoLogin.refresh_token
        ) {
          localStorage.setItem('token', responseCognitoLogin.token);
          localStorage.setItem(
            'refresh_token',
            responseCognitoLogin.refresh_token
          );
          dispatchUser(responseCognitoLogin.token).finally();
        } else {
          throw new Error(responseCognitoLogin.detail.message ?? '');
        }
      }
    } catch (e) {
      console.log('erro', e);
    }
  };

  const handleChangeInput =
    (key: keyof IUser) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setUser((oldState) => ({ ...oldState, [key]: e.target.value }));
      setErrors((oldState) => ({ ...oldState, [key]: '' }));
    };

  const handleForgotPassword = () => {
    setOpenResetPassword({ open: true, firstAccess: false, email: user.email });
  };

  const handleSubmit = async (
    e:
      | React.FormEvent<HTMLFormElement>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    e.stopPropagation();

    if (load) return;

    const resultUserValidate = await userValidation(user);
    setErrors(resultUserValidate.errors);

    if (!resultUserValidate.isValid) {
      toastNotification(
        'error',
        getTranslation('toast.error.userOrPasswordIncorrect')
      );
      return;
    }

    try {
      setLoad(true);
      const { email, password } = user;

      const response = await CognitoLogin({ user: email, password });
      if (!!response.token && !!response.refresh_token) {
        localStorage.setItem('token', response.token);
        localStorage.setItem('refresh_token', response.refresh_token);
        dispatchUser(response.token);
      } else if (
        response.detail.error === 'Change password before authenticating'
      ) {
        setOpenResetPassword({
          open: true,
          firstAccess: true,
          temporaryPassword: user.password,
          email: user.email,
        });
      } else {
        throw new Error(response.detail.message ?? '');
      }

      setUser({ email: '', password: '' });
    } catch (error: any) {
      toastNotification(
        'error',
        error.message || getTranslation('toast.error.userOrPasswordInvalid')
      );
    } finally {
      setLoad(false);
    }
  };

  async function dispatchUser(token: string) {
    const decodedToken: { isRoot: boolean; companies: IPermissionCompany[] } =
      jwtDecode(token);
    await generatePermissions(decodedToken);
    dispatch({ type: 'updateApp', data: { signed: true } });
    history.push('/');
  }

  return (
    <S.LoginContainer>
      <div style={{ marginBottom: 30 }}>
        <IconLLego />
      </div>
      <S.FormContainer>
        <form onSubmit={(e) => handleSubmit(e)}>
          <S.FieldContainer>
            <S.InputEmail
              variant="outlined"
              label={getTranslation('emailUpper')}
              value={user.email}
              error={!!errors.email}
              helperText={errors.email}
              fullWidth
              onChange={handleChangeInput('email')}
            />
          </S.FieldContainer>
          <S.FieldContainer>
            <S.InputPassword
              variant="outlined"
              label={getTranslation('passwordUpper')}
              value={user.password}
              error={!!errors.password}
              helperText={errors.password}
              type={showPassword ? 'text' : 'password'}
              fullWidth
              onChange={handleChangeInput('password')}
              InputProps={passwordInputProps}
            />
          </S.FieldContainer>
          <S.FieldContainer>
            <S.ButtonEnter
              variant="contained"
              color="primary"
              fullWidth
              onClick={(e) => handleSubmit(e)}
              type="submit"
            >
              {getTranslation('enter')}
              {load && (
                <CircularProgress
                  color="inherit"
                  size={18}
                  style={{ marginLeft: '10px' }}
                />
              )}
            </S.ButtonEnter>
          </S.FieldContainer>
          <S.FieldContainerMicrosoft>
            <S.ButtonLoginMicrosoft type="button" onClick={handleAdLogin}>
              <MicrosoftIcon size="24" />
              <span>{getTranslation('signInWith')} Microsoft</span>
            </S.ButtonLoginMicrosoft>
          </S.FieldContainerMicrosoft>
          <S.FieldContainer>
            <Typography>
              {getTranslation('login.phrase')}
              <p></p>
              <S.ForgotEmailButton type="button" onClick={handleForgotPassword}>
                {getTranslation('login.resetPassword')}
              </S.ForgotEmailButton>
            </Typography>
          </S.FieldContainer>
        </form>
      </S.FormContainer>

      {openResetPassword.open && (
        <ModalResetPassword
          open={openResetPassword.open}
          firstAccess={openResetPassword.firstAccess}
          temporaryPassword={openResetPassword.temporaryPassword || ''}
          email={openResetPassword.email}
          onClose={() =>
            setOpenResetPassword({ open: false, firstAccess: false, email: '' })
          }
          dispatchUser={(token) => dispatchUser(token)}
        />
      )}
      <ChooseLanguage />
    </S.LoginContainer>
  );
};

export default Login;
