import React from "react";
import { useEntity } from "contexts/Entity/entityContext";
import { GridChildren } from "views/Canvas/Main/styles";
import NewVariable from "../NewVariable";
import VariableEntities from "../VariableEntities";

const Main: React.FC = () => {

  const {state}  = useEntity()

  const renderScreen = () =>{
    switch (state.screen) {
      case 'list':
        return <VariableEntities  />
        case 'add':
          return  <NewVariable/>
      default:
        return <VariableEntities  />
    }
  }

  return (
    <GridChildren>       
     {renderScreen()}    
    </GridChildren>
  );
};

export default Main;
