import {
  FormHelperText,
  OutlinedInput,
  TextField,
  Typography,
} from '@material-ui/core';
import { BoxContainer, TextContainer } from '../../../styles';
import MttButton from 'components/Material/MttButton/MttButton';
import { useStyles } from '../styles';
import useTranslator from 'utils/hooks/Translator';
import { Dispatch, SetStateAction } from 'react';
import { IComponents, ITemplateError } from '..';
interface IFormFalazap {
  setTemplateComponents: (value: SetStateAction<IComponents>) => void;
  templateComponents: IComponents;
  file?: File;
  setImportFile: Dispatch<SetStateAction<boolean>>;
  isNotEditable: boolean;
  error: ITemplateError;
}

export const FormFalazap = ({
  setTemplateComponents,
  templateComponents,
  setImportFile,
  file,
  isNotEditable,
  error,
}: IFormFalazap) => {
  const { getTranslation } = useTranslator();
  const classes = useStyles();
  return (
    <>
      <BoxContainer>
        <Typography
          variant="h6"
          children={getTranslation('title')}
          color="primary"
        />
      </BoxContainer>
      <BoxContainer>
        <TextContainer>
          <OutlinedInput
            disabled={isNotEditable}
            fullWidth
            value={templateComponents.header.text}
            error={!!error.falazapTitle}
            onChange={(e) =>
              setTemplateComponents((prev) => ({
                ...prev,
                header: {
                  ...prev.header,
                  text: e.target.value,
                },
              }))
            }
            inputProps={{ maxLength: 60 }}
          />
          {error.falazapTitle && (
            <FormHelperText style={{ marginLeft: '12px' }} error>
              {getTranslation('validations.required', {
                field: getTranslation('title'),
              })}
            </FormHelperText>
          )}
        </TextContainer>
      </BoxContainer>
      <div>
        <Typography
          variant="h6"
          children={getTranslation('image')}
          color="primary"
        />
      </div>
      <BoxContainer>
        <TextContainer>
          <TextField
            disabled={isNotEditable}
            fullWidth
            value={templateComponents.image || file?.name}
            variant="outlined"
            onChange={(e) =>
              setTemplateComponents((prev) => ({
                ...prev,
                image: e.target.value,
              }))
            }
          />
        </TextContainer>
        <MttButton
          disabled={isNotEditable}
          className={classes.selectFileButton}
          fullWidth
          onClick={() => setImportFile(true)}
        >
          {getTranslation('modal.templates.uploadImage')}
        </MttButton>
      </BoxContainer>
      <div>
        <Typography
          variant="h6"
          children={getTranslation('description')}
          color="primary"
        />
      </div>
      <BoxContainer>
        <TextContainer style={{ height: 'auto' }}>
          <OutlinedInput
            disabled={isNotEditable}
            placeholder={getTranslation(
              'modal.templates.descriptionLimit'
            )}
            fullWidth
            multiline
            rows={4}
            inputProps={{ maxLength: 1024 }}
            value={templateComponents.body}
            onChange={(e) =>
              setTemplateComponents((prev) => ({
                ...prev,
                body: e.target.value,
              }))
            }
          />
        </TextContainer>
      </BoxContainer>
      <></>
    </>
  );
};
