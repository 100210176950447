import React, { useEffect, useState } from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import {
  AppBar,
  Dialog,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
} from '@material-ui/core';

import { useFlow } from 'contexts/Flow/flowContext';

import EditIcon from '@material-ui/icons/Edit';

import * as S from './styles';

import { ModalCompanyEdit } from './ModalCompanyEdit';
import useTranslator from 'utils/hooks/Translator';
import { useCompanies } from 'contexts/Company';
import { Company, emptyCompany } from 'contexts/Company/types';
import { GetCompany, GetCompanys } from 'services/CompaniesService/Company';
import { useApp } from 'contexts/App/appContext';
import { usePermissions } from 'contexts/Permissions/permissionsContext';
import { HoverableRow } from './styles';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalManageCompanies() {
  const { state, dispatch, toastNotification } = useFlow();
  const { isRoot, getCompanies } = usePermissions();
  const { dispatch: dispatchApp } = useApp();

  const [companies, setCompanies] = useState<string[]>([]);
  const { setCompany } = useCompanies();
  const classes = S.useStyles();

  const { getTranslation } = useTranslator();

  const [showEdit, setShowEdit] = useState(false);

  function handleClose() {
    setCompany(emptyCompany, true);
    dispatch({ type: 'closeModalManageCompany' });
  }

  function closeEditCompany() {
    setCompany(emptyCompany, true);
    setShowEdit(false);
    if (isRoot()) handleSetCompanyIsRoot();
  }

  async function handleSetCompanyIsRoot() {
    const companysFetch = await GetCompanys(dispatchApp);
    if (companysFetch.Success) {
      const companysNames = (companysFetch.Data as Company[]).map(
        (company) => company.name
      );
      setCompanies(companysNames);
    } else {
      toastNotification('error', 'Erro ao buscar dados da empresa');
    }
  }

  function handleGetAdminNames(): string[] {
    return getCompanies()
      .filter((obj) => obj.isAdmin)
      .map((obj) => obj.name);
  }

  async function handleEditCompany(companyName: string) {
    const result = await GetCompany(companyName, dispatchApp);
    if (result.Success) {
      const {
        agents_limit: agentsLimit,
        users_limit: usersLimit,
        ...rest
      } = result.Data;
      setCompany(
        {
          ...rest,
          agentsLimit,
          usersLimit,
        },
        true
      );
      setShowEdit(true);
    } else {
      toastNotification('error', 'Erro ao buscar dados da empresa');
    }
  }

  useEffect(() => {
    if (isRoot() && state.openModalManageCompany) {
      handleSetCompanyIsRoot();
    } else {
      setCompanies(handleGetAdminNames());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.openModalManageCompany]);

  if (!state.openModalManageCompany) return <></>;

  return (
    <>
      <Dialog
        open={state.openModalManageCompany}
        TransitionComponent={Transition}
        fullScreen
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <S.ContainerDialog className={classes.dialog}>
          <AppBar>
            <Toolbar className={classes.toolbar}>
              <S.ModalTitle style={{ fontWeight: 600 }}>
                {getTranslation('modal.manageCompany.title')}
              </S.ModalTitle>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <S.Container>
            <S.FieldContainer>
              <Table size="small" style={{ width: '100%' }}>
                <TableHead>
                  <TableRow>
                    <TableCell>{getTranslation('company')}</TableCell>
                    <TableCell className="tableHeadCell-Button" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {companies?.sort().map((company) => (
                    <HoverableRow white key={company}>
                      <TableCell component="th">{company}</TableCell>
                      <TableCell className="tableCell-Button">
                        <IconButton
                          edge="end"
                          color="default"
                          onClick={() => handleEditCompany(company)}
                        >
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </HoverableRow>
                  ))}
                </TableBody>
              </Table>
            </S.FieldContainer>
          </S.Container>

          <S.ButtonContainer>
            {isRoot() && (
              <S.Button
                style={{ letterSpacing: 2 }}
                variant="contained"
                color="primary"
                onClick={() => setShowEdit(true)}
              >
                {getTranslation('add')}
              </S.Button>
            )}
          </S.ButtonContainer>
        </S.ContainerDialog>
      </Dialog>

      {showEdit && (
        <ModalCompanyEdit
          handleEditCompany={handleEditCompany}
          handleClose={closeEditCompany}
        />
      )}
    </>
  );
}
