import React from 'react';

export interface ISVGProps {
  size?: string;
  color?: string;
}

const LogoutIcon: React.FC<ISVGProps> = (props) => {
  const { size, color } = props;

  return (
    <svg
      width={size || '24'}
      height={size || '24'}
      viewBox="0 0 24 24"
      fill={color || '#424242'}
    >
      <g
        xmlns="http://www.w3.org/2000/svg"
        id="Grupo_2466"
        data-name="Grupo 2466"
      >
        <path
          id="Caminho_4784"
          data-name="Caminho 4784"
          d="M0,0H24V24H0Z"
          fill="none"
        />
      </g>
      <g
        xmlns="http://www.w3.org/2000/svg"
        id="Grupo_2467"
        data-name="Grupo 2467"
      >
        <path
          id="Caminho_4785"
          data-name="Caminho 4785"
          d="M17,8,15.59,9.41,17.17,11H9v2h8.17l-1.58,1.58L17,16l4-4ZM5,5h7V3H5A2.006,2.006,0,0,0,3,5V19a2.006,2.006,0,0,0,2,2h7V19H5Z"
          fill="#424242"
        />
      </g>
    </svg>
  );
};

export default LogoutIcon;
