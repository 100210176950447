import styled from 'styled-components';
import MttGrid from 'components/Material/MttGrid/MttGrid';
import MttTextField from 'components/Material/MttTextField/MttTextFIeld';

export const HeaderContent = styled(MttGrid)`
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: #f2f2f2;

  margin: 32px 16px 16px 16px;
  padding: 16px;

  border-radius: 4px;

  .MuiInputBase-input {
    background-color: #ffffff;
  }
`;

export const HeaderOptions = styled(MttGrid)`
  width: 100%;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;

  margin-bottom: 16px;
`;

export const GridDescriptionText = styled(MttGrid)<{ disabled: boolean }>`
  /* display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px; */

  .MuiFormControl-root {
    background-color: ${(props) => (props.disabled ? '#F2F2F2' : '#fff')};
  }
`;

export const StyledMttTextField = styled(MttTextField)`
  background-color: white;
`;
