import React from 'react';
import FooterButtons from 'components/inputs/Material/FooterButtons/index';
import Info from 'components/inputs/Material/Info/index';
import { FormFieldsContainer } from '../Shared/styles';
import OptionsBlockContent from 'components/inputs/Material/OptionsBlockContent';

export default function FalaGPT() {
  return (
    <>
      <FormFieldsContainer>
        <Info />
        <OptionsBlockContent isFalaGPT />
      </FormFieldsContainer>
      <FooterButtons showLogicButton={false}/>
    </>
  );
}
