import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Grid)`
  align-items: center;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%),
    0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
  display: flex;
  flex-direction: column;
  padding: 16px 48px 16px 48px;
  width: 400px;
  text-align: center;
`;

export const ModalTitle = styled(Typography).attrs({
  variant: 'h6',
})`
  color: #6c50a4;
  padding-bottom: 8px;
`;
