import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IconButton, TextField, Tooltip, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { useLocation } from 'react-router-dom';
import { useApp } from 'contexts/App/appContext';
import { useFlow } from 'contexts/Flow/flowContext';

import {
  AutoCompleteOption,
  AutoCompleteWrap,
  StyledMttButtonPurple,
  BotAndGroupInfo,
  LabelTitle,
  ImportExportIcons,
} from './styles';
import { GetBotsGroups } from 'services/BotService';
import {
  UpdateLastAccess,
  ReleaseGroupAccess,
  GetGroupIsLiberated,
} from 'services/FlowService';
import { Group } from 'contexts/Flow/types';

import { Autocomplete } from '@material-ui/lab';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { localStore } from 'utils/Stores';

import { CloudDownload, CloudUpload } from '@material-ui/icons';

import { exportGroup, importGroup } from 'utils/ImportExportGroup';
import { GetFlags } from 'services/FlagsService';
import useTranslator from 'utils/hooks/Translator';
import { usePermissions } from 'contexts/Permissions/permissionsContext';
import { GetProject } from 'services/CompaniesService/Projects';
import { IProjectResponse } from 'services/CompaniesService/Projects/types';
import { ChangeProjectProps } from './types';
import { GetFlexBlocks } from 'services/FlexBlocksService';

interface IProps {
  setDeleteGroupSelected: React.Dispatch<React.SetStateAction<Group | null>>;
  isClearFields: boolean;
}

interface LastFielsProps {
  companyName: string;
  projectName: string;
  agent: string;
  group: string;
}

const RenderBots = ({ setDeleteGroupSelected, isClearFields }: IProps) => {
  const location = useLocation();
  const {
    dispatch,
    saveFlow,
    toastNotification,
    state,
    loadFlow,
    mountNodeContent,
  } = useFlow();

  const { state: permissionsState, hasPermissionToAction } = usePermissions();

  const { companies } = permissionsState;

  const dispatchApp = useApp().dispatch;

  const [lastFields, setLastFields] = useState({
    companyName: '',
    projectName: '',
    agent: '',
    group: '',
  });

  const { botName, groupsNames, bot } = state;
  const [groupsNamesLocal, setGroupsNamesLocal] = useState<string[]>([]);
  const [botValue, setBotValue] = useState('');
  const [currentCompany, setCurrentCompany] = useState('');
  const [project, setProject] = useState<IProjectResponse>();
  const [currentProject, setCurrentProject] = useState('');
  const defaultFilterOptions = createFilterOptions<any>();

  const { getTranslation } = useTranslator();
  const closeTranslated = getTranslation('close');
  const openTranslated = getTranslation('open');
  const noResultsTranslated = getTranslation('noResults');

  const isFlowWrite = hasPermissionToAction({
    company: state.companyName || '',
    agent: state.botName || '',
    action: ['flow:write'],
  });

  const companiesNames = useMemo(() => {
    return companies.map((company) => company.name).sort();
  }, [companies]);

  const projectsNames = useMemo(() => {
    const company = companies.find(
      (company) => company.name === currentCompany
    );
    if (company) return company.projects.map((project) => project.name).sort();
    else return [];
  }, [currentCompany, companies]);

  const agents = useMemo(() => {
    const company = companies.find(
      (company) => company.name === currentCompany
    );
    if (company && project)
      return project.agents
        .filter((agent) =>
          company.agents.some((agentkey) => agentkey.name === agent)
        )
        .sort();
    return [];
  }, [companies, currentCompany, project]);

  useEffect(() => {
    const lastFields = localStore.get('lastFields');
    if (lastFields) {
      setLastFields(JSON.parse(lastFields));
    }
  }, [groupsNames]);

  useEffect(() => {
    setGroupsNamesLocal(groupsNames.map((group) => group));
  }, [groupsNames]);

  useEffect(() => {
    setBotValue(state.botName || '');
  }, [state.botName]);

  useEffect(() => {
    if (companiesNames.length === 0 && !state.editorFlow) {
      setCurrentCompany('');
      setCurrentProject('');
      setBotValue('');
    } else {
      handleChangeCompany(lastFields.companyName || companiesNames[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companiesNames, state.editorFlow]);

  useEffect(() => {
    if (isClearFields) {
      setCurrentCompany('');
      setBotValue('');
      setCurrentProject('');
    }
  }, [isClearFields]);

  const changeLastFields = ({
    companyName,
    projectName,
    agent,
    group,
  }: LastFielsProps) => {
    localStorage.setItem(
      'lastFields',
      JSON.stringify({
        companyName,
        projectName,
        agent,
        group,
      })
    );
  };

  const fixGroupsNamesOrder = useCallback(() => {
    const curGroups = groupsNamesLocal;

    const principalIndex = curGroups.findIndex(
      (group) => group === 'principal'
    );
    const principal = curGroups[principalIndex];

    if (principalIndex >= 0) {
      curGroups.splice(principalIndex, 1);
      setGroupsNamesLocal([
        principal,
        ...curGroups.map((group) => group).sort(),
      ]);
    } else {
      setGroupsNamesLocal(curGroups.map((group) => group).sort());
    }
  }, [groupsNamesLocal]);

  const releaseGroup = async () => {
    if (state.idGroup && state.botName && state.isEditing) {
      await ReleaseGroupAccess(
        {
          bot_name: state.botName,
          groupName: state.idGroup,
        },
        dispatchApp
      );
    }

    state.botsUrls.urlFacebook = '';
    state.botsUrls.urlFalazap = '';
    state.botsUrls.urlTelegram = '';
    state.botsUrls.urlTwitter = '';
    state.botsUrls.urlWhatsApp = '';
  };

  const getAgentData = async (agent: string, company?: string) => {
    await updateFlow();

    await releaseGroup();
    const flagsResponse = await GetFlags(agent, dispatchApp);
    let flags;

    if (flagsResponse.Success) {
      flags = flagsResponse.Data.data.flags;
    }

    const response = await GetBotsGroups({ bot_name: agent }, dispatchApp);

    let groups = response.Data.data;
    bot.groups = groups;
    bot.bot_name = agent;

    if (Array.isArray(groups)) {
      const idGroup =
        groups.find((group) => group === lastFields.group) || 'principal';

      const isFlowRead = hasPermissionToAction({
        company: company || state.companyName || '',
        agent,
        action: ['flow:read', 'flow:write', 'flow:publish'],
      });

      if (
        idGroup &&
        state.editorFlow &&
        location.pathname === '/' &&
        isFlowRead
      ) {
        dispatch({ type: 'updateForm', data: { loading: true } });
        await loadFlow(idGroup, agent);
      } else {
        state.editorFlow?.clear();
      }

      const companyName = company || currentCompany;

      changeLastFields({
        companyName,
        agent,
        group: idGroup,
        projectName: currentProject,
      });

      dispatch({
        type: 'updateForm',
        data: {
          companyName,
          botName: agent,
          idGroup,
          groupsNames: groups,
          loading: false,
          bot,
          flags,
        },
      });
    }
  };

  const handleClearState = () => {
    state.editorFlow?.clear();
    dispatch({
      type: 'updateForm',
      data: {
        botName: '',
        idGroup: '',
        flags: [],
        bot: {
          _id: '',
          bot_name: '',
          groups: [],
          version: 0,
        },
      },
    });
  };

  const handleChangeCompany = async (companyName: string) => {
    const selectedCompany = companies.find(
      (company) => company.name === companyName
    );

    if (!selectedCompany) return;

    dispatch({
      type: 'updateForm',
      data: {
        companyName: selectedCompany.name,
      },
    });

    setCurrentCompany(selectedCompany.name);
    if (selectedCompany.projects[0]) {
      const projectName =
        selectedCompany.projects.find(
          (project) => project.name === lastFields.projectName
        )?.name ||
        selectedCompany.projects.sort((projectA, projectB) =>
          projectA.name.localeCompare(projectB.name)
        )[0].name;

      if (!!projectName)
        await handleChangeProject({
          project: projectName,
          company: selectedCompany.name,
        });

      changeLastFields({
        companyName: selectedCompany.name,
        agent: botName || '',
        group: state.idGroup || '',
        projectName: projectName,
      });
    } else {
      setCurrentProject('');
      handleClearState();
    }
  };

  const handleChangeProject = async ({
    project,
    e,
    company,
  }: ChangeProjectProps) => {
    const projectName = project || String(e?.target.textContent) || '';
    const companyName = company || currentCompany || '';
    const response = await GetProject(
      { projectName, companyName },
      dispatchApp
    );

    setCurrentProject(projectName);

    if (response.Success) {
      const selectedCompany = companies.find(
        (company) => company.name === companyName
      );

      if (!selectedCompany) return;

      const selectedProject = response.Data;
      setProject(response.Data);
      const botNameSelected =
        selectedProject.agents.find((agent) => agent === lastFields.agent) ||
        selectedProject?.agents
          .filter((agent) =>
            selectedCompany.agents.some((agentkey) => agentkey.name === agent)
          )
          .sort()[0] ||
        '';
      if (botNameSelected) await getAgentData(botNameSelected, companyName);
      else handleClearState();
    }
  };

  const handleChangeBot = async (
    e: React.ChangeEvent<{
      name?: string | undefined;
      textContent: unknown;
    }>
  ) => {
    const botNameSelected = String(e.target.textContent);
    await getAgentData(botNameSelected);
  };

  const handleChangeGroup = async (
    e: React.ChangeEvent<{
      name?: string | undefined;
      textContent: unknown;
    }>
  ) => {
    if (!e || !e.target.textContent || !state.editorFlow) return;
    const idGroup = String(e.target.textContent);
    await changeGroupFlow(idGroup);
  };

  const changeGroupFlow = async (group_id: string, deleteGroup = false) => {
    dispatch({ type: 'updateForm', data: { loading: true } });

    !deleteGroup && (await updateFlow());

    if (state.isEditing) {
      await ReleaseGroupAccess(
        {
          bot_name: botName || '',
          groupName: state.idGroup || '',
        },
        dispatchApp
      );
    }

    setLastFields({
      companyName: currentCompany,
      projectName: currentProject,
      agent: botName || '',
      group: group_id,
    });

    changeLastFields({
      companyName: currentCompany,
      agent: botName || '',
      group: group_id,
      projectName: currentProject,
    });

    await loadFlow(group_id, botName || '', false, deleteGroup);
    dispatch({
      type: 'updateForm',
      data: { idGroup: group_id, loading: false },
    });
  };

  const updateFlow = async () => {
    const groupLastVersion = localStore.get('groupLastVersion') || '';

    if (
      !!state.drawFlowRef &&
      !!state.editorFlow &&
      state.isEditing &&
      JSON.stringify(state.editorFlow.drawflow) !== groupLastVersion
    ) {
      const sBotAndGroup = localStore.get('current_bot_group');

      if (sBotAndGroup) {
        const { botName, groupName } = JSON.parse(sBotAndGroup);

        if (botName && groupName) {
          await UpdateLastAccess(
            {
              bot_name: botName,
              groupName,
            },
            dispatchApp
          );
        }
      }
      await saveFlow();
    }
  };

  function handleAddNewGroup() {
    if (botName) {
      dispatch({ type: 'openModalCreateGroup' });
    } else {
      toastNotification('warning', '*Selecione um bot para criar uma grupo!');
    }
  }

  const handleOpenModalDeleteGroup = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    group: string
  ) => {
    e.stopPropagation();

    if (botName) {
      const isFlexGroup = group.startsWith('flex-');
      if (isFlexGroup) {
        const flexBlocksResponse = await GetFlexBlocks(
          state.botName!,
          dispatchApp
        );

        if (flexBlocksResponse.Data.data) {
          const flexBlocks = flexBlocksResponse.Data.data;
          const flexBlockBeingDeleted = flexBlocks.flex_blocks.find(
            (block) => `flex-${block.block_name}` === group
          );
          if (
            flexBlockBeingDeleted &&
            flexBlockBeingDeleted.groups_used.length > 0
          ) {
            const groups = flexBlockBeingDeleted.groups_used.map(
              (group) => group.group_name
            );

            toastNotification(
              'error',
              `Não foi possível apagar esse grupo. Blocos Flex referentes a ele estão em uso no(s) grupo(s) "${groups}".`
            );
            return;
          }
        }
      }

      const response = await GetGroupIsLiberated(
        {
          bot_name: botName,
          groupName: group,
        },
        dispatchApp
      );
      if (!response.Data.group_is_liberated) {
        toastNotification(
          'error',
          getTranslation('toast.error.agentOrGroupInUse', {
            object: getTranslation('group'),
            user: response.Data.current_user,
            status: getTranslation('agentOrGroupStatus.removed'),
          })
        );
      } else {
        const selectedGroup = groupsNames.find(
          (newGroup) => newGroup === group
        );
        if (selectedGroup)
          setDeleteGroupSelected({ id: selectedGroup, name: selectedGroup });
      }
    }
  };

  const importFileDefault = () => {
    const button = document.getElementById(
      'import-button'
    ) as HTMLButtonElement;
    if (button) {
      const disabled = button.disabled;

      if (!disabled) {
        importGroup(state, toastNotification, mountNodeContent, {
          invalidFileForGroup: getTranslation(
            'toast.error.invalidFileForGroup'
          ),
          invalidFileForPrincipal: getTranslation(
            'toast.error.invalidFileForPrincipal'
          ),
          onlyValidForPrincipal: getTranslation(
            'toast.error.onlyValidForPrincipal'
          ),
        });
      }
    }
  };

  const exportFileDefault = () => {
    exportGroup(state);
  };

  return (
    <>
      <BotAndGroupInfo>
        <AutoCompleteWrap>
          <LabelTitle>{getTranslation('company')}</LabelTitle>

          <Autocomplete
            value={currentCompany}
            onClose={() => setBotValue(state.botName || '')}
            options={companiesNames}
            noOptionsText={noResultsTranslated}
            renderOption={(option) => (
              <AutoCompleteOption>{option}</AutoCompleteOption>
            )}
            onChange={(event: any) => {
              handleChangeCompany(String(event.target.textContent));
            }}
            style={{ width: '100%' }}
            openText={openTranslated}
            closeText={closeTranslated}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={getTranslation('selectCompany')}
              />
            )}
          />
        </AutoCompleteWrap>
        <AutoCompleteWrap>
          <LabelTitle>{getTranslation('project')}</LabelTitle>

          <Autocomplete
            value={currentProject}
            onClose={() => setCurrentProject(currentProject || '')}
            disabled={!currentCompany}
            options={projectsNames}
            noOptionsText={noResultsTranslated}
            renderOption={(option) => (
              <AutoCompleteOption>{option}</AutoCompleteOption>
            )}
            onChange={(event: any, newValue: string | null) => {
              setCurrentProject(newValue || '');
              handleChangeProject({ project: '', e: event });
            }}
            style={{ width: '100%' }}
            openText={openTranslated}
            closeText={closeTranslated}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={getTranslation('selectCompany')}
              />
            )}
          />
        </AutoCompleteWrap>
        <AutoCompleteWrap>
          <LabelTitle>{getTranslation('agent')}</LabelTitle>

          <Autocomplete
            value={botValue}
            onClose={() => setBotValue(state.botName || '')}
            options={Array.from(new Set(agents)).sort()}
            disabled={!currentCompany}
            noOptionsText={noResultsTranslated}
            renderOption={(option) => (
              <AutoCompleteOption>{option}</AutoCompleteOption>
            )}
            onChange={(event: any) => {
              handleChangeBot(event);
            }}
            style={{ width: '100%' }}
            openText={openTranslated}
            closeText={closeTranslated}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={getTranslation('selectAgent')}
              />
            )}
          />
        </AutoCompleteWrap>
        <AutoCompleteWrap>
          <Typography variant="body1">{getTranslation('group')}</Typography>
          <Autocomplete
            id="group-autocomplete"
            style={{ width: '100%' }}
            disabled={!state.botName}
            onOpen={fixGroupsNamesOrder}
            options={groupsNamesLocal}
            value={state.idGroup || ''}
            noOptionsText={noResultsTranslated}
            onChange={(event: any) => {
              const addGroup = getTranslation('addGroup');
              if (event.target.textContent.trim() !== addGroup) {
                handleChangeGroup(event);
              }
            }}
            renderOption={(option) =>
              !option || typeof option !== 'string' ? (
                option
              ) : (
                <React.Fragment>
                  <AutoCompleteOption>{option}</AutoCompleteOption>
                  {option !== 'principal' && (
                    <>
                      {isFlowWrite && (
                        <button
                          style={{
                            margin: 4,
                            border: 'none',
                            backgroundColor: 'transparent',
                          }}
                          onClick={(e) => handleOpenModalDeleteGroup(e, option)}
                        >
                          <DeleteIcon style={{ color: '#AEAEAE' }} />
                        </button>
                      )}
                    </>
                  )}
                </React.Fragment>
              )
            }
            openText={openTranslated}
            closeText={closeTranslated}
            filterOptions={(options, state) => {
              const results = defaultFilterOptions(options, state);
              return [
                ...results,
                <>
                  {isFlowWrite && (
                    <>
                      <StyledMttButtonPurple
                        variant="contained"
                        color="secondary"
                        startIcon={<AddIcon />}
                        onClick={handleAddNewGroup}
                        fullWidth
                        disabled={!botName}
                      >
                        {getTranslation('addGroup').trim()}
                      </StyledMttButtonPurple>
                      ,
                    </>
                  )}
                </>,
              ];
            }}
            renderInput={(params) => (
              <>
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder={getTranslation('selectGroup')}
                />
              </>
            )}
          />
        </AutoCompleteWrap>
      </BotAndGroupInfo>
      {!!state.botName && !state.idGroup?.startsWith('flex') && (
        <ImportExportIcons>
          {state.isEditing && (
            <Tooltip title={getTranslation('tooltip.uploadGroup')}>
              <IconButton
                id="import-button"
                aria-label="import-file"
                onClick={() => importFileDefault()}
              >
                <CloudUpload />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title={getTranslation('tooltip.downloadGroup')}>
            <IconButton
              aria-label="download-file"
              onClick={() => exportFileDefault()}
            >
              <CloudDownload />
            </IconButton>
          </Tooltip>
        </ImportExportIcons>
      )}
    </>
  );
};
export default RenderBots;
