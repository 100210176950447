import { useState, useMemo } from 'react';

import {
  AppBar,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  FormControlLabel,
  IconButton,
  TextField,
  Toolbar,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import { ButtonContainer, CustomPaper, ModalTitle, useStyles } from '../styles';

import {
  OrganizationEditForm,
  ContainerDialog,
  Container,
  LoginWithAdContainer,
  LoginWithAdInfoContainer,
} from './styles';
import InfoIcon from '@material-ui/icons/Info';
import { ManageUsers } from './ManageUsers';
import { ManageAgents } from './ManageAgents';
import { useFlow } from 'contexts/Flow/flowContext';
import useTranslator from 'utils/hooks/Translator';
import { useCompanies } from 'contexts/Company';
import { ManageProjects } from './ManageProjects';
import { useApp } from 'contexts/App/appContext';
import {
  CreateCompany,
  UpdateCompany,
} from 'services/CompaniesService/Company';
import { arraysAreEqual } from 'utils/arraysAreEqual';
import { Alert } from '@material-ui/lab';

interface Props {
  handleClose: () => void;
  handleEditCompany(companyName: string): Promise<void>;
}

export function ModalCompanyEdit({ handleClose, handleEditCompany }: Props) {
  const { toastNotification, state: flowState } = useFlow();
  const { botsNames } = flowState;
  const { dispatch } = useApp();
  const { state, dispatch: dispatchCompany, setCompany } = useCompanies();
  const { company, companyCopy } = state;
  const isEdit = !!company._id;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [nameCompany, setNameCompany] = useState(company.name);
  const { getTranslation } = useTranslator();
  const [showLoginWithAdInfo, setShowLoginWithAdInfo] = useState(false);

  const hasUserChanges = useMemo(
    () => !arraysAreEqual(companyCopy.users, company.users),
    [company.users, companyCopy.users]
  );
  const hasAgentChanges = useMemo(
    () => !arraysAreEqual(companyCopy.agents, company.agents),
    [company.agents, companyCopy.agents]
  );
  const hasProjecysChanges = useMemo(
    () => !arraysAreEqual(companyCopy.projects, company.projects),
    [company.projects, companyCopy.projects]
  );

  const hasUserslimitChanges = companyCopy.usersLimit !== company.usersLimit;
  const hasAgentsLimitChanges = companyCopy.agentsLimit !== company.agentsLimit;
  const loginWithAdChanges = company.loginWithAd !== companyCopy.loginWithAd;
  const hasChanges = useMemo(
    () =>
      company.name !== nameCompany ||
      hasUserChanges ||
      hasAgentChanges ||
      hasProjecysChanges ||
      hasUserslimitChanges ||
      hasAgentsLimitChanges ||
      loginWithAdChanges,
    [
      company.name,
      nameCompany,
      hasUserChanges,
      hasAgentChanges,
      hasProjecysChanges,
      hasUserslimitChanges,
      hasAgentsLimitChanges,
      loginWithAdChanges,
    ]
  );

  const currentProjects = useMemo(() => {
    return company.projects?.map((project) => project) || [];
  }, [company.projects]);

  const currentGroups = useMemo(() => {
    return company.groups?.map((group) => group.name) || [];
  }, [company.groups]);

  const disabledErrorLimits =
    company.usersLimit < company.users.length ||
    company.agentsLimit < company.agents.length;

  async function handleCreateOrUpdateCompany() {
    setLoading(true);
    setNameCompany(nameCompany.trim());

    const response = await getResponse();
    if (response.Success) {
      await handleEditCompany(nameCompany.trim());
      toastNotification(
        'success',
        `Empresa ${isEdit ? 'atualizada' : 'criada'} com sucesso.`
      );
      dispatchCompany({
        type: 'setCompanyCopy',
        data: { companyCopy: company },
      });
    } else {
      toastNotification(
        'error',
        `Ocorreu um erro ao ${
          isEdit ? 'atualizar' : 'criar'
        } a empresa, verifique e tente novamente.`
      );
    }
    setLoading(false);
  }

  async function getResponse() {
    if (!isEdit)
      return await CreateCompany(
        {
          ...company,
          name: nameCompany.trim(),
          projects: currentProjects,
          groups: currentGroups,
          agents_limit: company.agentsLimit as number,
          users_limit: company.usersLimit as number,
          login_with_ad: company.loginWithAd,
        },
        dispatch
      );
    else
      return await UpdateCompany(
        {
          ...company,
          _id: company._id || '',
          name: nameCompany.trim(),
          projects: currentProjects,
          groups: currentGroups,
          agents_limit: company.agentsLimit as number,
          users_limit: company.usersLimit as number,
          login_with_ad: company.loginWithAd,
        },
        dispatch
      );
  }
  function hanldeChangeNameCompany(name: string) {
    if (!name.includes('/')) {
      setNameCompany(name);
    }
  }

  return (
    <Dialog
      className="ModalPermissions"
      open={true}
      fullScreen
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <ContainerDialog className={classes.dialog}>
        <AppBar>
          <Toolbar className={classes.toolbar}>
            <ModalTitle style={{ fontWeight: 600 }}>
              {isEdit ? getTranslation('edit') : getTranslation('create')}{' '}
              {getTranslation('company')}
            </ModalTitle>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Container>
          <OrganizationEditForm onSubmit={(e) => e.preventDefault()}>
            <CustomPaper>
              <LoginWithAdContainer>
                <TextField
                  label={getTranslation('name')}
                  value={nameCompany}
                  onChange={(e) => {
                    const name = e.target.value;
                    hanldeChangeNameCompany(name);
                  }}
                />

                <div style={{ position: 'relative' }}>
                  <FormControlLabel
                    control={
                      <>
                        <Checkbox
                          checked={company.loginWithAd}
                          onChange={() =>
                            setCompany({
                              ...company,
                              loginWithAd: !company.loginWithAd,
                            })
                          }
                          name="loginWithAd"
                          color="primary"
                        />
                      </>
                    }
                    label={getTranslation('loginWithAdCheckbox')}
                  />
                  <InfoIcon
                    fontSize="small"
                    className={classes.infoIcon}
                    onMouseOver={() => setShowLoginWithAdInfo(true)}
                    onMouseLeave={() => setShowLoginWithAdInfo(false)}
                  />

                  {showLoginWithAdInfo && (
                    <LoginWithAdInfoContainer>
                      <Alert
                        severity="info"
                        className={classes.loginWithAdInfo}
                      >
                        {getTranslation('loginWithAdInfo')}
                      </Alert>
                    </LoginWithAdInfoContainer>
                  )}
                </div>
              </LoginWithAdContainer>
            </CustomPaper>

            <ManageProjects />
            <ManageUsers
              currentUsers={company.users.concat(company.admins) || []}
              objectToEdit="company"
            />

            <ManageAgents
              objectToEdit="company"
              sourceAgents={botsNames}
              currentAgents={company.agents}
            />
          </OrganizationEditForm>
        </Container>

        <ButtonContainer>
          <Button
            style={{ letterSpacing: 2 }}
            variant="contained"
            color="primary"
            disabled={!nameCompany || disabledErrorLimits || !hasChanges}
            onClick={handleCreateOrUpdateCompany}
          >
            {getTranslation('save')}
            {loading && (
              <CircularProgress size={18} style={{ marginLeft: '12px' }} />
            )}
          </Button>
        </ButtonContainer>
      </ContainerDialog>
    </Dialog>
  );
}
