import React, { useEffect, useCallback } from 'react';
import { Route, RouteComponentProps, useHistory } from 'react-router-dom';
import { useApp } from 'contexts/App/appContext';

interface IPrivateRouteProps {
  Component: React.FC<RouteComponentProps>;
  path: string;
  exact: boolean;
}

const PrivateRoute: React.FC<IPrivateRouteProps> = (props: IPrivateRouteProps) => {
  const { Component, path, exact } = props;
  const { state } = useApp();
  const { replace } = useHistory();

  const goToOrRedirect = useCallback(
    (pathRequest: string) => {
      let pathResult = path;

      if (state.signed) {
        if (path === '/login') pathResult = '/';
        else pathResult = path;
      } else {
        pathResult = '/login'
      }

      return pathResult;
    },
    [path, state.signed],
  );

  useEffect(() => {
    let pathResult = goToOrRedirect(path);
  
    replace(pathResult)
  }, [path, replace,
    goToOrRedirect]);

  return (
    <Route
      path={goToOrRedirect(path)}
      exact={exact}
      render={(renderProps: RouteComponentProps) => <Component {...renderProps} />}
    />
  );
};

export default PrivateRoute;
