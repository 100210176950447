import { Grid, Typography } from "@material-ui/core";
import styled from "styled-components";
import MttButton from "../Material/MttButton/MttButton";
import MttTextField from "../Material/MttTextField/MttTextFIeld";

export const InputName = styled(MttTextField)``;

export const ButtonSave = styled(MttButton)`
  height: 56px;
`;

export const FieldContainer = styled(Grid)`
  padding: 8px 0;
  width:100%;
`;

export const ButtonsContainer = styled(Grid)`
  padding: 8px;
  width:100%;  
  display:flex;
  justify-content: flex-end;
`;

export const ModalTitle = styled(Typography).attrs({
  variant:'h6'
})`
  color:#6c50a4;
  padding-bottom: 8px;
`;