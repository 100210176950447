import { IAppContextActions } from 'contexts/App/types';
import { json } from 'services/RequestService';
import {
  ICreateCompanyRequest,
  ICreateCompanyResponse,
  IGetCompanyResponse,
  IUpdateCompanyRequest,
} from './types';
import { IBaseResponse } from 'services/RequestService/Response';

const baseUrl = 'companies';

export async function CreateCompany(
  request: ICreateCompanyRequest,
  dispatch: React.Dispatch<IAppContextActions>
) {
  const action = `${baseUrl}`;
  return await json<ICreateCompanyResponse>('POST', action, dispatch, request);
}
export async function UpdateCompany(
  request: IUpdateCompanyRequest,
  dispatch: React.Dispatch<IAppContextActions>
) {
  const action = `${baseUrl}/${request._id}`;
  return await json<ICreateCompanyResponse>('PATCH', action, dispatch, request);
}

export async function GetCompany(
  companyName: string,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IGetCompanyResponse>> {
  const action = `${baseUrl}/${companyName}`;
  return await json('GET', action, dispatch);
}

export async function GetCompanys(
  dispatch: React.Dispatch<IAppContextActions>
) {
  const action = `${baseUrl}`;
  return await json('GET', action, dispatch);
}
