import React from 'react';
import { createContext, ReactNode, useContext, useReducer } from 'react';
import { initialState, permissionsReducer } from './permissionsReducer';
import {
  HasPermissionToActionParams,
  IPermissionCompany,
  IPermissionsContext,
} from './types';

interface Props {
  children: ReactNode;
}

const PermissionsContext = createContext<IPermissionsContext>(
  {} as IPermissionsContext
);

export function PermissionsProvider({ children }: Props) {
  const [state, dispatch] = useReducer(permissionsReducer, initialState);

  function isRoot() {
    return state.isRoot;
  }

  function getPermissions() {
    const companies = state.companies;
    const permissions: any = [];

    companies.forEach((company) => {
      const perm = company.agents.map((agent) => agent.actions);

      permissions.push({ company: company.name, agents: perm });
    });

    return companies;
  }

  function getCompaniesNames() {
    const companies = state.companies;

    return companies.map((company) => company.name);
  }

  function getCompanies() {
    return state.companies;
  }

  function hasPermissionToCompany(company: string) {
    const companies = state.companies;
    return companies.find((c) => c.name === company);
  }

  function hasPermissionToAgent(company: IPermissionCompany, agent: string) {
    return company.agents.find((a) => a.name === agent);
  }

  function isCompanyAdmin(companyName: string) {
    const companies = state.companies;

    const company = companies.find((c) => c.name === companyName);
    return company?.isAdmin || false;
  }

  function hasPermissionToAction({
    company,
    agent,
    action,
  }: HasPermissionToActionParams) {
    const currentCompany = hasPermissionToCompany(company);
   
    if (!currentCompany) return false;
    if (currentCompany.isAdmin) return true;

    const currentAgent = hasPermissionToAgent(currentCompany, agent);
    if (!currentAgent) return false;

    if (action.includes('*')) return true;

    let alreadyFoundPermission = false;

    const hasAccess = action.some((a) => {
      if (alreadyFoundPermission) return true;
      return currentAgent.actions.includes(a);
    });

    return hasAccess;
  }

  return (
    <PermissionsContext.Provider
      value={{
        state: state || initialState,
        dispatch,
        getPermissions,
        getCompaniesNames,
        hasPermissionToAction,
        isCompanyAdmin,
        isRoot,
        getCompanies,
      }}
    >
      {children}
    </PermissionsContext.Provider>
  );
}

export function usePermissions(): IPermissionsContext {
  const context = useContext(PermissionsContext);
  return context;
}
