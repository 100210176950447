import styled from 'styled-components';
import { makeStyles, Typography } from '@material-ui/core';
import MttButton from '../Material/MttButton/MttButton';
import { themeDefault } from 'themes/DefaultTheme';

export const Container = styled.div`
  position: absolute;
  left: 32%;
  top: 82%;
  border-radius: 10px;

  z-index: 10;
  background-color: #e0e0e0;
  box-shadow: 1px 1px 3px 1px #00001150;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  max-width: 500px;
  width: 100%;
`;

export const ContentContainer = styled.div`
  width: 100%;
  max-width: 350px;
  max-height: 67px;
`;

export const TextContainer = styled(Typography).attrs({})`
  display: flex;
  max-width: 355px;
  font-size: 0.9rem !important;
`;

export const ModalTitle = styled(Typography).attrs({
  variant: 'h6',
})``;

export const ButtonContainer = styled.div``;

export const ButtonOk = styled(MttButton)`
  width: 134px;
  height: 35px;
`;

export const useStyles = makeStyles({
  modalTitle: {
    fontSize: '1rem',
    fontWeight: 'bold',
    lineHeight: 1.6,
    color: themeDefault.palette.primary.main,
  },
  buttonOk: {
    fontSize: '0.8rem',
  },
});
