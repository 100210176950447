import React from 'react';

import * as S from './style';
import { IInfoProps } from './types';
import { SimilarityComponent } from './SimilarityComponent';
import { Container, TitleTypography } from '../MessageForUser/styles';

export default function MultipleTags(props: IInfoProps) {
  return (
    <Container>
      <TitleTypography variant="h6" color="primary">
        Tags
      </TitleTypography>
      <S.HeaderContent>
        <SimilarityComponent />
      </S.HeaderContent>
    </Container>
  );
}
