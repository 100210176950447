import { Grid } from "@material-ui/core";
import styled from "styled-components";

export const Container = styled(Grid)`
  background: #FFFFFF;
  border-radius: 4px;
`;

export const AutoCompleteOption = styled.span`
  flex: 1;
  margin: 3px 0;
`;

export const ActionsContainer = styled(Grid)`
  display: flex;

  .MuiAutocomplete-fullWidth {
    margin-right: 8px;
  }
`;

export const GridHeader = styled(Grid)`
  display: flex;
  border-bottom: solid 2px #6c50a4;
  padding: 8px 0;
  font-weight: 600;
`

export const GridBody = styled(Grid)`
  display: flex;
  padding: 8px 0;
`

export const GridColumn = styled(Grid)`
  flex: 1;
`

export const NewPhraseContainer = styled(Grid)`
  display: flex;
  align-items: center;
  flex: 1;
`;
