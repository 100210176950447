import styled from 'styled-components';
import MttGrid from '../Material/MttGrid/MttGrid';
import MttButton from '../Material/MttButton/MttButton';

import { Grid, Typography } from '@material-ui/core';

export const ContainerDialog = styled(MttGrid)<{ colorHeader?: string }>`
  width: 25vw;
  height: 100%;

  padding: 8px 16px;
`;

export const ContainerHeader = styled(MttGrid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ModalTitle = styled(Typography).attrs({
  variant: 'h6',
})`
  color: #6c50a4;
`;

export const FieldContainer = styled(Grid)`
  width: 100%;
  margin-top: 10px;
  display: flex;
  align-items: center;

  .MuiFormHelperText-root {
    background-color: rgb(242 242 242);
    margin: 0;
  }

  .MuiInputBase-input {
    outline: 0;
  }

  .MuiInput-underline:before {
    display: none;
  }

  .MuiInputBase-input::-webkit-input-placeholder {
    opacity: 0.65;
    font-weight: 500;
  }

  .MuiIconButton-root {
    padding: 8px;
  }
`;

export const ButtonContainer = styled(Grid)`
  padding: 16px 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const Button = styled(MttButton)`
  height: 50px;
`;
