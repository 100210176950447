import styled from 'styled-components';
import MttTypography from 'components/Material/MttTypography/MttTypography';
import MttGrid from 'components/Material/MttGrid/MttGrid';
import TextField from '@material-ui/core/TextField/TextField';

export const GridContainer = styled(MttGrid)`
  margin: 16px;
`;

export const StyledMttTypographyFrase = styled(MttTypography)``;

export const ContainerButton = styled(MttGrid)`
  justify-content: flex-end;
  display: flex;
`;

export const ContentGrid = styled(MttGrid)`
  width: calc(100% - 32px);
  max-height: 192px;

  padding: 16px;

  border-radius: 4px;

  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;

  overflow-x: hidden;
  overflow-y: auto;

  .MuiTypography-body1 {
    color: #6c50a4;
    font-weight: 500;
  }
`;

export const ContentTitlesGrid = styled(MttGrid)`
  display: flex;

  margin-top: 8px;

  background-color: #f2f2f2;

  .MuiInputBase-input {
    background-color: #ffffff;
  }
`;

export const StyledMttTextField = styled(TextField)``;

export const StyledDeleteIconGrid = styled(MttGrid)`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 16px;
  cursor: pointer;
`;
