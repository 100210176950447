import { createTheme } from "@material-ui/core/styles";

export const themeDefault = createTheme({
  palette: {
    primary: {
      main: "#6c50a4",
    },
  },
  zIndex: {
    modal: 500,
  },
});
