import MttGrid from 'components/Material/MttGrid/MttGrid';
import styled from 'styled-components';

export const TagHeaderContainer = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  min-width: 276px;
  gap: 8px;

	.MuiOutlinedInput-root {
		padding: 16px;
    background: #fff
	}
`;

export const SimilarityContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  gap: 8px;

  input {
    padding: 4px;
  }
`;

export const ContentSynonyms = styled(MttGrid)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const GridSynonym = styled(MttGrid).attrs((props) => ({
  className: props.className,
}))`
  height: 32px;

  margin-left: 8px;

  padding: 0 8px;
  margin: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;

  font-weight: 500;

  svg {
    margin: inherit;
  }
`;

export const GridSynonymRegular = styled(GridSynonym)`
  background-color: #fff;
`;
