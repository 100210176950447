import React, { useRef, useState } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import CodeIcon from '@material-ui/icons/Code';
import CheckIcon from '@material-ui/icons/Check';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import * as S from './styles';
import MttButton from 'components/Material/MttButton/MttButton';
import { useForm } from 'contexts/Form/formContext';
import { useFlow } from 'contexts/Flow/flowContext';
import { FooterButtonsProps } from './types';
import ModalLogic from 'components/ModalLogic';
import {
  formValidators,
  getValidatorsIntentType,
} from 'validations/validations';
import { CircularProgress } from '@material-ui/core';
import EIntentType from 'enums/EIntentType';
import useTranslator from 'utils/hooks/Translator';
import CreateAppGroup from 'utils/AppBlockManagement/CreateAppGroup';
import { useApp } from 'contexts/App/appContext';
import { GetGroup } from 'services/FlowService';
import { UpdateGroup } from 'services/FlowService';
import UpdateAgentGroup from 'utils/AppBlockManagement/UpdateAppGroup';
import { DataForm } from 'models/DataFlow';
import { GetFlexBlocks } from 'services/FlexBlocksService';

export default function FooterButtons(props: FooterButtonsProps) {
  const { showLogicButton = true, isFlexBlock } = props;
  const { dispatch, addIntent, updateIntent, state, toastNotification } =
    useFlow();
  const { dispatch: dispatchApp } = useApp();
  const { dataForm } = useForm().state;
  const dispatchForm = useForm().dispatch;
  const { getTranslation } = useTranslator();
  const [loading, setLoading] = useState(false);
  const currentName = useRef(dataForm?.name);

  const translatedErrors = {
    intentNameRequired: getTranslation('validations.required', {
      field: getTranslation('name'),
    }),
    intentNameDuplicated: getTranslation('validations.intentNameDuplicated'),
    outputTitleRequired: getTranslation('validations.outputTitleRequired'),
    outputTitleDuplicated: getTranslation('validations.outputTitleDuplicated'),
    totalSum: getTranslation('validations.totalSum'),
    formMessage: getTranslation('validations.required', {
      field: getTranslation('message'),
    }),
    entryName: 'Por favor, informe o nome do bloco de entrada.',
  };

  const submit = async (event: any) => {
    event.preventDefault();

    if (!dataForm || loading) return;

    setLoading(true);
    const keys = getValidatorsIntentType(dataForm.intentType);
    let formErrors = {};
    for (const key of keys) {
      if (!!key) {
        const data = state.editorFlow?.drawflow.drawflow.Home.data;
        const error = await formValidators[key](
          dataForm,
          translatedErrors,
          data,
          state.nodeId
        );
        formErrors = { ...formErrors, ...error };
      }
    }
    dispatchForm({ type: 'updateForm', data: { formErrors } });
    const isValid = !Object.keys(formErrors).length;

    if (isValid) {
      await handleCreateIntent();
      const currGroup = JSON.stringify(state.editorFlow?.drawflow);
      localStorage.setItem('last_updated', currGroup);
    } else {
      toastNotification('error', getTranslation('toast.error.requiredFields'));
    }
    setLoading(false);
  };

  const handleCreateIntent = async () => {
    if (dataForm) {
      if (state.nodeId) {
        if (!showLogicButton && hasLogic()) {
          if (
            dataForm &&
            dataForm.dataBlockly &&
            dataForm.dataBlockly.payload &&
            dataForm.dataBlockly.xml
          ) {
            dataForm.dataBlockly.payload = '';
            dataForm.dataBlockly.xml = '';

            if (
              dataForm.intentType === EIntentType.Fallback ||
              dataForm.intentType === EIntentType.Cancel
            ) {
              dataForm.outputs?.push({ title: 'Outros', isChip: false });
            }
          }
        }
        const blockPreviousName =
          state.editorFlow?.drawflow.drawflow.Home.data[state.nodeId].data.name;

        await updateIntent(dataForm, state.nodeId);

        if (isFlexBlock)
          await UpdateAgentGroup({
            dataForm,
            state,
            toastNotification,
            dispatchApp,
            dispatch,
            getTranslation,
            blockPreviousName,
          });

        const isFlexGroup = state.idGroup?.startsWith('flex-');
        if (isFlexGroup) handleAppGroupUpdate(dataForm);
      } else {
        if (isFlexBlock) {
          const flexBlocksResponse = await GetFlexBlocks(
            state.botName!,
            dispatchApp
          );
          if (flexBlocksResponse.Success) {
            const data = flexBlocksResponse.Data.data;
            const flexBlocksNameExists = data.flex_blocks.filter(
              (flexBlock) => flexBlock.block_name === dataForm.name
            );

            if (flexBlocksNameExists.length > 0) {
              toastNotification(
                'error',
                'Um bloco flex com esse nome já existe nesse agente.'
              );

              return;
            }
            await CreateAppGroup({
              dataForm,
              state,
              toastNotification,
              dispatchApp,
              dispatch,
              getTranslation,
            });

            await addIntent(dataForm);
          }
        } else {
          await addIntent(dataForm);
        }
      }
      handleClose();
    }
  };

  const handleAppGroupUpdate = async (dataForm: DataForm) => {
    const principalGroupResponse = await GetGroup(
      {
        bot_name: state.botName!,
        groupName: 'principal',
      },
      dispatchApp
    );

    if (principalGroupResponse.Success) {
      const principalGroup = principalGroupResponse.Data.data;
      const principalGroupBlocks = principalGroup.blocks.drawflow.Home.data;
      const blocksKeys = Object.keys(principalGroupBlocks);

      for (const key in blocksKeys) {
        const currentBlock = principalGroupBlocks[Number(blocksKeys[key])];
        const currentBlockOutputs = currentBlock.data.outputs;
        if (
          `flex-${currentBlock.data.name}` === state.idGroup &&
          currentBlockOutputs
        ) {
          const existingOutputIndex = currentBlockOutputs.findIndex(
            (output) => output.title === currentName.current
          );

          const isOutputUpdate = existingOutputIndex !== -1;

          if (isOutputUpdate) {
            currentBlockOutputs[existingOutputIndex] = {
              title: dataForm.name,
              description: dataForm.description,
              block_id: dataForm.block_id,
            };
          } else {
            currentBlockOutputs.push({
              title: dataForm.name,
              description: dataForm.description,
              block_id: dataForm.block_id,
            });
            // state.editorFlow?.addNodeOutput(currentBlock.id);
            currentBlock.outputs[`output_${currentBlockOutputs.length - 1}`] = {
              connections: [],
            };
          }

          await UpdateGroup(
            {
              bot_name: state.botName!,
              group_name: principalGroup.group_name,
              blocks: {
                drawflow: { Home: { data: principalGroupBlocks } },
              },
            },
            dispatchApp
          );
        }
      }
    }
  };

  const handleClose = () => {
    if (loading) return;
    dispatchForm({ type: 'updateForm', data: { formErrors: {} } });
    dispatch({ type: 'closeModalForms', data: { dataForm: null } });
  };

  const handleOpenModalLogicEditor = () => {
    if (loading) return;

    dispatchForm({
      type: 'updateForm',
      data: { openModalLogicEditor: true },
    });
  };

  const hasLogic = () => {
    return !!dataForm?.dataBlockly?.payload;
  };

  return (
    <S.StyledGridButtons>
      {showLogicButton ? (
        <MttButton
          variant="contained"
          color="primary"
          startIcon={hasLogic() ? <EditIcon /> : <CodeIcon />}
          onClick={handleOpenModalLogicEditor}
        >
          {getTranslation('footerButtons.advancedEditor')}
        </MttButton>
      ) : (
        <div style={{ width: 200 }} />
      )}
      <S.StyledRightButtons>
        <MttButton
          variant="contained"
          color="primary"
          startIcon={<CheckIcon />}
          onClick={submit}
        >
          {getTranslation('footerButtons.saveAndExit')}
          {loading && <CircularProgress color="inherit" size={18} />}
        </MttButton>
        <MttButton
          variant="contained"
          color="default"
          startIcon={<CloseRoundedIcon />}
          onClick={handleClose}
        >
          {getTranslation('footerButtons.discardAndExit')}
        </MttButton>
      </S.StyledRightButtons>
      <ModalLogic />
    </S.StyledGridButtons>
  );
}
