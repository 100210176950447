import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  TextField,
  Button,
  CircularProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { ContainerDialog } from '../../styles';
import * as S from './styles';
import useTranslator from 'utils/hooks/Translator';

import { useEffect, useMemo, useState } from 'react';
import { ManageAgents } from '../../../../ManageAgents';
import { useCompanies } from 'contexts/Company';
import { ManageActions } from './ManageActions';
import { ManageUsers } from '../../../../ManageUsers';
import { CreateGroup, UpdateGroup } from 'services/CompaniesService/Groups';
import { useApp } from 'contexts/App/appContext';
import { useFlow } from 'contexts/Flow/flowContext';
import {
  CustomPaper,
  FormsContainer,
} from 'components/ModalManageCompanies/styles';
import { GetProject } from 'services/CompaniesService/Projects';
import { arraysAreEqual } from 'utils/arraysAreEqual';

interface Props {
  handleClose: () => void;
  editGroup: boolean;
}
interface Errors {
  name: string;
}

export function ManageGroup({ handleClose, editGroup }: Props) {
  const classes = S.useStyles();
  const { toastNotification } = useFlow();
  const { dispatch: dispatchApp } = useApp();
  const { state, dispatch, setCompany } = useCompanies();
  const { group, company, project, projectCopy } = state;
  const [error, setError] = useState<Errors>({ name: '' });
  const [loading, setLoading] = useState(false);

  const [groupCopy, setGroupCopy] = useState(group);
  const isEdit = !!group._id;
  const { getTranslation } = useTranslator();

  const [agents, setAgents] = useState(['']);
  const [users, setUsers] = useState(['']);

  const hasChanges = useMemo(() => {
    return (
      group.name !== groupCopy.name ||
      !arraysAreEqual(groupCopy.users, group.users) ||
      !arraysAreEqual(groupCopy.agents, group.agents) ||
      !arraysAreEqual(groupCopy.actions, group.actions)
    );
  }, [group, groupCopy]);

  async function handleGetProject() {
    const response = await GetProject(
      { companyName: company.name, projectName: project.name },
      dispatchApp
    );
    if (response.Success) {
      setUsers(response.Data.users);
      setAgents(response.Data.agents);
    } else
      toastNotification(
        'error',
        'Ocorreu um erro ao buscar os grupos, verifique e tente novamente'
      );
  }
  function handleValidate(): boolean {
    const { name } = groupCopy;
    if (!name) {
      setError((prev) => ({
        ...prev,
        name: getTranslation('validations.required', {
          field: getTranslation('name'),
        }),
      }));
      return true;
    }
    return false;
  }
  useEffect(
    () => {
      handleGetProject();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editGroup]
  );

  useEffect(() => {
    if (!isEdit) {
      dispatch({
        type: 'setGroup',
        data: {
          group: {
            ...group,
            project: project.name || '',
            company: company.name || '',
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  async function handleFetchAndSetGrups() {
    const response = await GetProject(
      { companyName: company.name, projectName: project.name },
      dispatchApp
    );
    if (response.Success) {
      dispatch({
        type: 'setProject',
        data: { project: { ...project, groups: response.Data.groups } },
      });
      dispatch({
        type: 'setProjectCopy',
        data: { projectCopy: { ...projectCopy, groups: response.Data.groups } },
      });
    } else
      toastNotification(
        'error',
        'Ocorreu um erro ao buscar os grupos, verifique e tente novamente'
      );
  }

  async function handleCreateOrUpdateGroup() {
    if (handleValidate()) return;
    if (!!groupCopy.name && !!group.project) {
      setGroupCopy((prev) => ({ ...prev, name: prev.name.trim() }));
      setLoading(true);
      const response = await getResponse();
      if (response.Success) {
        const companyGroups = company.groups;
        if (!companyGroups) return;

        const currentGroupId = companyGroups.findIndex(
          (g) => g._id === group._id
        );

        if (currentGroupId !== -1) {
          companyGroups[currentGroupId] = { ...group, name: groupCopy.name };
          setCompany({ ...company, groups: companyGroups });
          setCompany({ ...company, groups: companyGroups });
        } else {
          setCompany({ ...company, groups: [...companyGroups, group] });
        }
        await handleFetchAndSetGrups();
        toastNotification(
          'success',
          `Grupo ${isEdit ? 'atualizado' : 'criado'} com sucesso.`
        );

        handleClose();
      } else {
        toastNotification(
          'error',
          `Ocorreu um erro ao ${
            isEdit ? 'atualizar' : 'criar'
          } o grupo, verifique e tente novamente.`
        );
      }
      setLoading(false);
    } else {
      toastNotification(
        'error',
        `Ocorreu um erro ao ${
          isEdit ? 'atualizar' : 'criar'
        } o grupo, verifique e tente novamente.`
      );
    }
  }

  async function getResponse() {
    if (!isEdit)
      return await CreateGroup(
        {
          ...group,
          name: groupCopy.name.trim(),
          projects: [group.project],
          company: group.company,
        },
        dispatchApp
      );
    else
      return await UpdateGroup(
        {
          ...group,
          name: groupCopy.name.trim(),
          company: group.company,
        },
        dispatchApp
      );
  }
  function handleChangeName(name: string) {
    if (!name.includes('/')) {
      setError((prev) => ({ ...prev, name: '' }));
      setGroupCopy((prev) => ({ ...prev, name }));
    }
  }
  return (
    <Dialog
      open
      fullScreen
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <ContainerDialog className={classes.dialog}>
        <AppBar>
          <Toolbar className={classes.toolbar}>
            <S.ModalTitle style={{ fontWeight: 600 }}>
              {isEdit ? getTranslation('edit') : getTranslation('create')}{' '}
              {getTranslation('group')}
            </S.ModalTitle>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Container className={classes.container}>
          <FormsContainer onSubmit={(e) => e.preventDefault()}>
            <CustomPaper>
              <TextField
                error={!!error.name}
                label={getTranslation('name')}
                value={groupCopy.name}
                onChange={(e) => handleChangeName(e.target.value)}
                helperText={error.name}
              />
            </CustomPaper>

            <S.ChildrenContainer>
              <ManageUsers
                currentUsers={group.users || []}
                sourceUsers={users || []}
                objectToEdit="group"
              />
              <ManageAgents
                objectToEdit="group"
                sourceAgents={agents}
                currentAgents={group.agents}
              />
              <ManageActions />
            </S.ChildrenContainer>
          </FormsContainer>
          <S.ButtonContainer>
            <Button
              disabled={!hasChanges}
              style={{ letterSpacing: 2 }}
              variant="contained"
              color="primary"
              onClick={handleCreateOrUpdateGroup}
            >
              {getTranslation('save')}
              {loading && <CircularProgress size={18} />}
            </Button>
          </S.ButtonContainer>
        </Container>
      </ContainerDialog>
    </Dialog>
  );
}
