import { FormControl, MenuItem, Select, Typography } from '@material-ui/core';

import BrazilFlagIcon from 'components/Icons/BrazilFlagIcon';
import USFlagIcon from 'components/Icons/USFlagIcon';
import SpainFlagIcon from 'components/Icons/SpainFlagIcon';
import * as S from './styles';
import i18next from 'i18next';
import { useState } from 'react';
import useTranslator from 'utils/hooks/Translator';

export default function ChooseLanguage() {
  const [language, setLanguage] = useState(i18next.language === 'pt-PT' ? 'pt-BR' : i18next.language);
  const { getTranslation } = useTranslator();

  const languages = [
    {
      id: 'en-US',
      name: getTranslation('english'),
      icon: <USFlagIcon />,
    },
    {
      id: 'es',
      name: getTranslation('spanish'),
      icon: <SpainFlagIcon />,
    },
    {
      id: 'pt-BR',
      name: getTranslation('portuguese'),
      icon: <BrazilFlagIcon />,
    },
  ];

  const handleChangeLanguage = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setLanguage(event.target.value as string);
    i18next.changeLanguage(event.target.value as string);
  };

  return (
    <S.Container>
      <FormControl>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={language}
          onChange={(e) => handleChangeLanguage(e)}
          variant="outlined"
        >
          {languages.map((language, index) => (
            <MenuItem key={index} value={language.id}>
              {language.icon}
              <Typography variant="body1" style={{ marginLeft: 8 }}>
                {language.name}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </S.Container>
  );
}
