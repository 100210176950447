import React from 'react';
import FooterButtons from 'components/inputs/Material/FooterButtons';
import Info from 'components/inputs/Material/Info';
import ToNewIntention from 'components/inputs/Material/ToNewIntention';
import { FormFieldsContainer } from '../Shared/styles';
import MultipleTags from 'components/inputs/Material/MultipleTags';

export default function NewGroup() {
  return (
    <>
      <FormFieldsContainer>
        <Info />
        <MultipleTags />
        <ToNewIntention />
      </FormFieldsContainer>
      <FooterButtons showLogicButton={false} />
    </>
  );
}
