import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import styled from 'styled-components';
import MttGrid from 'components/Material/MttGrid/MttGrid';

export const ContainerDialog = styled(MttGrid)`
  width: 100%;
  height: 100%;

  position: relative;
  padding-top: 54px;

  .MuiAppBar-colorPrimary {
    background-color: #6c50a4;
    position: absolute;
  }
`;

export const ContainerHeader = styled(MttGrid)`
  overflow-y: auto;
  max-height: 65vh;
`;

export const ContainerImage = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffff;
`;

export const ContentContainer = styled(Grid)`
  overflow-y: auto;
  max-height: 65vh;

  table {
    thead {
      border-bottom: 2px solid #6c50a4;
    }

    tbody {
      tr {
        td {
          padding: 8px;
          button {
            color: #6c50a4ad;
          }
        }
        :hover {
          background: #6c50a4ad;
          td {
            color: #fff;
            button {
              color: #fff;
            }
          }
        }
      }
    }
  }
`;

export const TagsContainer = styled(Grid)`
  margin-top: 20px;
  padding: 10px;
`;

export const SearchTagContainer = styled(Grid)`
  width: 100%;
  display: flex;
  align-items: center;

  button {
    margin: 5px;
    width: 200px;
  }
`;

export const TextContainer = styled.div`
  height: 64px;

  margin-right: 10px;
  width: 100%;
`;

export const ChipContainer = styled.div`
  max-width: 100px;

  div {
    margin: 3px;
  }
`;

export const TagsList = styled(Grid)`
  display: flex;
  margin: 10px 8px;
  align-items: center;
  max-width: 100%;
  flex-wrap: wrap;
`;

export const ModalTitle = styled(Typography).attrs({
  variant: 'h6',
})``;

export const ModalFooter = styled(Typography).attrs({})`
  display: flex;
  text-align: center;
`;

export const ContainerFooter = styled.div`
  align-items: center;
  justify-content: center;
  margin: 0 24px 16px 24px;

  .MuiTypography-body1 {
    color: #808080;
  }
`;

export const ContainerMessage = styled.div`
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;

  .MuiTypography-body1 {
    color: #6c50a4;
    font-weight: 500;
  }
`;

export const ModalMessage = styled(Typography).attrs({})`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
`;

export const useStyles = makeStyles((theme: Theme) => ({
  table: {
    minWidth: 650,
  },
  appBar: {
    position: 'relative',
    background: theme.palette.primary.main,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));
