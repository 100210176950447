import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { useFlow } from 'contexts/Flow/flowContext';

import './styles.css';
import * as S from './styles';

import { CircularProgress } from '@material-ui/core';
import { INewGroup, newGroupMask, newGroupValidation } from 'models/NewGroup';
import { emptyFlow } from 'contexts/Flow/staticData';
import {
  BlockGroupAccess,
  ReleaseGroupAccess,
  SaveGroup,
  UpdateGroup,
} from 'services/FlowService';
import { useApp } from 'contexts/App/appContext';
import { isEmptyFlow } from 'utils/Helpers';
import useTranslator from 'utils/hooks/Translator';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalNewGroup() {
  const { state, dispatch, toastNotification, startEditing } = useFlow();
  const { dispatch: dispatchApp } = useApp();
  const { getTranslation } = useTranslator();

  const [load, setLoad] = useState(false);
  const [newGroup, setNewGroup] = useState<INewGroup>({
    name: '',
    description: '',
    unidBus: '',
  });
  const [errors, setErrors] = useState<Partial<INewGroup>>({
    name: '',
  });

  async function handleCreateGroup() {
    const sBotAndGroup = localStorage.getItem('current_bot_group');

    if (state.editorFlow && sBotAndGroup && state.isEditing) {
      const { groupName } = JSON.parse(sBotAndGroup);

      if (!state.botName) return;

      await ReleaseGroupAccess(
        {
          bot_name: state.botName,
          groupName,
        },
        dispatchApp
      );

      if (!isEmptyFlow(state))
        await UpdateGroup(
          {
            bot_name: state.botName,
            group_name: groupName,
            blocks: {
              drawflow: state.editorFlow.drawflow.drawflow,
            },
          },
          dispatchApp
        );
    }

    newGroup.error = getTranslation('validations.required', {
      field: getTranslation('name'),
    });

    const { isValid, errors } = await newGroupValidation(newGroup);
    setErrors(errors);

    if (!isValid) return;

    let groups = state.groupsNames;

    if (newGroup.name.startsWith('flex-')) {
      setErrors({ name: "Nome do grupo não pode começar com 'flex-'" });
      return;
    }

    const groupExist = !!groups.find(
      (g) => g.toLowerCase() === newGroup.name.toLowerCase()
    );

    if (groupExist) {
      setErrors({ name: getTranslation('validations.groupAlreadyExists') });
      return;
    }

    if (state.editorFlow) state.editorFlow.editor_mode = 'edit';
    dispatch({ type: 'isEditing' });
    dispatch({ type: 'closeModalBlockedGroup' });
    dispatch({ type: 'closeModalUsedGroup' });
    dispatch({ type: 'closeModalWarning' });

    setLoad(true);
    const bots = [...state.botsNames];
    const bot = state.botName;
    const drawflow = emptyFlow;

    const resultFlows = await SaveGroup(
      {
        bot_name: bot || '',
        group_name: newGroup.name,
        request: {
          groups: [
            {
              drawflow,
              groupId: newGroup.name,
              groupName: newGroup.name,
              bot: bot || '',
            },
          ],
        },
      },
      dispatchApp
    );

    if (resultFlows.Success) {
      await UpdateGroup(
        {
          bot_name: bot || '',
          group_name: newGroup.name,
          blocks: { drawflow: { Home: { data: {} } } },
        },
        dispatchApp
      );

      let sNewGroup = newGroup.name;
      let sBot = '';

      if (bot) {
        state.bot._id = resultFlows.Data.data._id;
        state.bot.version = state.bot.version + 1;
        sBot = bot;
      }

      groups.push(newGroup.name);

      if (!!state.editorFlow) {
        state.editorFlow.clear();
      }

      const principalIndex = groups.findIndex((group) => group === 'principal');

      const principal = groups[principalIndex];

      if (principalIndex >= 0) {
        groups.splice(principalIndex, 1);
        groups = [principal, ...groups];
      }

      dispatch({
        type: 'updateForm',
        data: {
          botsNames: [...bots],
          groupsNames: groups,
          idGroup: newGroup.name,
          drawFlowRef: drawflow.drawflow,
        },
      });

      dispatch({ type: 'closeModalCreateGroup' });
      setNewGroup({ name: '', description: '', unidBus: '' });

      localStorage.setItem(
        'current_bot_group',
        JSON.stringify({ botName: sBot, groupName: sNewGroup })
      );

      await BlockGroupAccess(
        {
          groupName: newGroup.name,
          bot_name: bot || '',
        },
        dispatchApp
      );

      startEditing();
    } else {
      toastNotification(
        'error',
        resultFlows.Message || getTranslation('toast.error.saveGroup')
      );
    }
    setLoad(false);
  }

  const handleChangeInput =
    (key: keyof INewGroup) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const valueWithMask = newGroupMask[key](e.target.value);
      setNewGroup((old) => ({ ...old, [key]: valueWithMask }));
      setErrors((old) => ({ ...old, [key]: '' }));
    };

  function handleCancel() {
    dispatch({ type: 'closeModalCreateGroup' });
    setNewGroup({ name: '', description: '', unidBus: '' });
    setErrors({ name: '', description: '', unidBus: '' });
  }

  if (!state.openModalCreateGroup) return <></>;

  return (
    <Dialog
      open={state.openModalCreateGroup}
      TransitionComponent={Transition}
      fullWidth={true}
      maxWidth="sm"
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent style={{ overflowY: 'initial' }}>
        <S.ModalTitle>{getTranslation('createNewGroup')}</S.ModalTitle>
        <S.FieldContainer>
          <S.InputName
            variant="outlined"
            label={getTranslation('name')}
            inputProps={{ maxLength: 15 }}
            value={newGroup.name}
            onChange={handleChangeInput('name')}
            error={!!errors.name}
            helperText={errors.name}
            fullWidth
            required
          />
        </S.FieldContainer>
        <S.FieldContainer>
          <S.InputName
            variant="outlined"
            label={getTranslation('description')}
            inputProps={{ maxLength: 40 }}
            value={newGroup.description}
            onChange={handleChangeInput('description')}
            fullWidth
          />
        </S.FieldContainer>
        <S.ButtonsContainer>
          <S.ButtonSave
            variant="contained"
            color="primary"
            onClick={handleCreateGroup}
          >
            {getTranslation('saveGroup')}
            {load && <CircularProgress color="inherit" size={18} />}
          </S.ButtonSave>
          <S.ButtonSave
            style={{ marginLeft: 16 }}
            variant="contained"
            color="default"
            onClick={handleCancel}
          >
            {getTranslation('cancel')}
          </S.ButtonSave>
        </S.ButtonsContainer>
      </DialogContent>
    </Dialog>
  );
}
