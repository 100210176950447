export interface IPermissionsContext {
  state: IPermissionsContextState;
  dispatch: React.Dispatch<IPermissionsContextActions>;
  getPermissions: () => IPermissionCompany[];
  getCompaniesNames: () => string[];
  hasPermissionToAction: (
    params: HasPermissionToActionParams
  ) => boolean | undefined;
  isCompanyAdmin: (company: string) => boolean;
  isRoot: () => boolean;
  getCompanies: () => IPermissionCompany[];
}

export interface HasPermissionToActionParams {
  company: string;
  agent: string;
  action: Actions[];
}

export interface IPermissionsContextState {
  isRoot: boolean;
  companies: IPermissionCompany[];
}

export interface IPermissionsContextActions {
  type: IPermissionsContextActionsTypes;
  data?: Partial<IPermissionsContextState>;
}

export type IPermissionsContextActionsTypes =
  | 'isRoot'
  | 'isNotRoot'
  | 'updatePermissions';

export interface IPermission {}

export interface IPermissionCompany {
  name: string;
  isAdmin: boolean;
  agents: IPermissionCompanyAgent[];
	projects: IPermissionCompanyProject[];
}

export interface IPermissionCompanyGroup {
  id: string;
  name: string;
  agents: string[];
  users: IPermissionCompanyUser[];
  company: string;
}
export interface IPermissionCompanyUser {
  email: string;
  isAdmin: boolean;
}

export interface IPermissionCompanyAgent {
  name: string;
  actions: Actions[];
}
export interface IPermissionCompanyProject {
	_id?: string;
  name: string;
  agents: string[];
}

export type Actions =
  | '*'
  | 'flow:write'
  | 'flow:publish'
  | 'flow:read'
  | 'communication_guideline:read'
  | 'communication_guideline:write'
  | 'access_key:write'
  | 'access_key:read'
  | 'adm'

export const emptyAgent = {
  name: '',
  actions: [],
};

export const emptyGroup = {
  id: '',
  name: '',
  company: '',
  actions: [],
  agents: [],
  users: [],
};

export const emptyCompany = {
  id: '',
  name: '',
  isAdmin: false,
  agents: [],
  creationDate: '',
  users: [],
};

export const emptyUser = {
  id: '',
  email: '',
  isAdmin: false,
};
