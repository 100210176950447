import React, { useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { FilterFieldProps } from './types';
import { useFlow } from 'contexts/Flow/flowContext';
import GetIntentsByName from 'controllers/intent/GetIntentsByName';
import {
  getNodePosition,
  markHighlighted,
  markSearch,
  umarkSearch,
  unmarkHighlighted,
} from '../utils/NodeContainer';
import ArrowLeftOutlinedIcon from '@material-ui/icons/ArrowLeftOutlined';
import ArrowRightOutlinedIcon from '@material-ui/icons/ArrowRightOutlined';
import MttTypography from '../../Material/MttTypography/MttTypography';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: 450,
      position: 'absolute',
      top: 80,
      left: '50%',
      transform: 'translateX(-50%)',
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    results: {
      margin: 5,
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: '0.9rem',
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
  })
);

export default function FilterField(props: FilterFieldProps) {
  const { state } = useFlow();

  const { handleClose } = props;

  const [search, setSearch] = useState('');
  const [totalResults, setTotalResults] = useState<string[]>([]);
  const [currentResult, setCurrentResult] = useState(0);

  const classes = useStyles();

  const handleSearchIntent = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearch(e.target.value);
    if (state.editorFlow && e.target.value) {
      const block = state.editorFlow.drawflow.drawflow.Home.data;
      const keys = new GetIntentsByName().getIntents(block, e.target.value);
      setTotalResults(keys);
      markSearch(keys);
      setCurrentResult(0);
    } else {
      umarkSearch();
      unmarkHighlighted();
    }
  };

  const handleNextItemOnSearch = () => {
    let curr;
    if (currentResult < totalResults.length) {
      setCurrentResult(currentResult + 1);
      curr = currentResult;
    } else {
      setCurrentResult(1);
      curr = 0;
    }
    markHighlighted(totalResults[curr]);
    goToNode(curr);
  };

  const handlePreviousItemOnSearch = () => {
    let curr;
    if (currentResult > 1) {
      setCurrentResult(currentResult - 1);
      curr = currentResult - 2;
    } else {
      setCurrentResult(totalResults.length);
      curr = totalResults.length - 1;
    }

    markHighlighted(totalResults[curr]);
    goToNode(curr);
  };

  const goToNode = (curr: number) => {
    const { x, y } = getNodePosition(totalResults[curr] as unknown as number);

    if (state.editorFlow) {
      handleZoomAll();
      const newX = x / 2 + x * 0.1;
      const newY = y / 2 + y * 0.1;
      state.editorFlow.canvas_x = -newX;
      state.editorFlow.canvas_y = -newY;

      if (state.editorFlow.precanvas) {
        state.editorFlow.precanvas.style.transform = `translate(${state.editorFlow.canvas_x}px, 
          ${state.editorFlow.canvas_y}px) 
          scale(0.6) `;
      }
    }
  };

  const handleZoomAll = () => {
    if (state.editorFlow) {
      if (state.editorFlow.zoom < 0.6) {
        state.editorFlow.zoom_in();
        handleZoomAll();
      } else if (state.editorFlow.zoom > 0.7) {
        state.editorFlow.zoom_out();
        handleZoomAll();
      }
    }
  };

  const handleCloseAndCleanSearch = () => {
    umarkSearch();
    handleClose();
    unmarkHighlighted();
  };

  return (
    <Paper
      component="form"
      className={classes.root}
      onSubmit={(e: React.FormEvent<HTMLDivElement>) => e.preventDefault()}
    >
      <InputBase
        className={classes.input}
        placeholder="Busque a intenção"
        inputProps={{ 'aria-label': 'busque a intenção' }}
        value={search}
        onChange={handleSearchIntent}
        autoFocus
      />
      <MttTypography variant="subtitle1" className={classes.results}>
        {search && `${currentResult}/${totalResults.length}`}
      </MttTypography>
      <Divider className={classes.divider} orientation="vertical" />

      <IconButton
        size="small"
        onClick={handlePreviousItemOnSearch}
        disabled={!(totalResults.length !== 0) || !!!search}
      >
        <ArrowLeftOutlinedIcon />
      </IconButton>

      <IconButton
        size="small"
        onClick={handleNextItemOnSearch}
        disabled={!(totalResults.length !== 0) || !!!search}
      >
        <ArrowRightOutlinedIcon />
      </IconButton>

      <Divider className={classes.divider} orientation="vertical" />
      <IconButton
        color="primary"
        className={classes.iconButton}
        aria-label="directions"
        onClick={handleCloseAndCleanSearch}
      >
        <CloseIcon />
      </IconButton>
    </Paper>
  );
}
