import React, { useState } from 'react';

import { useFlow } from 'contexts/Flow/flowContext';
import EIntentType from 'enums/EIntentType';
import { DataForm } from 'models/DataFlow';
import SmsIcon from '@material-ui/icons/Sms';
import ReplyAllIcon from '@material-ui/icons/ReplyAll';
// import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import Dashboard from '@material-ui/icons/Dashboard';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import DescriptionIcon from '@material-ui/icons/Description';
import InputIcon from '@material-ui/icons/Input';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import { useStyles } from './styles';
import './styles.css';
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  Popover,
} from '@material-ui/core';
import LogicIcon from 'components/Icons/LogicIcon';
import NluIcon from 'components/Icons/NluIcon';
import MultipleChoiceIcon from 'components/Icons/MultipleChoiceIcon';
import useTranslator from 'utils/hooks/Translator';
import ABTestIcon from 'components/Icons/ABTestIcon';
import FalaGPTIcon from 'components/Icons/FalaGPTIcon';
import AppIcon from 'components/Icons/AppIcon';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import NewFlexBlockIcon from 'components/Icons/NewFlexBlockIcon';

const environment = process.env.REACT_APP_ENV;

export default function ModalOptions(props: { anchorEl: any }) {
  const { state, dispatch } = useFlow();
  const classes = useStyles();
  const { getTranslation } = useTranslator();
  const [flexBlockPopover, setFlexBlockPopover] = useState(false);
  const [popoverAnchorEl, setPopoverAnchorEl] =
    useState<HTMLDivElement | null>();

  function handleCreateIntent(intentType: EIntentType) {
    const payload =
      intentType === EIntentType.MultipleChoiceParent
        ? ''
        : '// #PARSER#CONNECTOR#Saída#\n';
    const xml =
      intentType === EIntentType.MultipleChoiceParent
        ? ''
        : '<xml xmlns="https://developers.google.com/blockly/xml"><block type="connector" id="cAsx5InyFQ]_.i,bM.[~" x="150" y="150"><field name="TEXT">Saída</field></block></xml>';

    const dataForm: DataForm = {
      groupId: state.idGroup || '',
      name: '',
      description: '',
      intentType,
      outputs: [],
      dataBlockly: {
        lastVersion: 1,
        payload,
        xml,
        compiledPayload: '',
      },
      listType: 1,
    };

    dispatch({ type: 'openModalForms', data: { dataForm } });
    dispatch({ type: 'closeModalIntentType' });
  }

  function handleCancel() {
    setFlexBlockPopover(false);
    setPopoverAnchorEl(null);
    dispatch({ type: 'closeModalIntentType' });
  }

  function handleOpenModalFlexBlocks() {
    dispatch({ type: 'closeModalIntentType' });
    dispatch({ type: 'openModalFlexBlocks' });
  }

  if (!state.openModalIntentType) return null;

  return (
    <Popover
      open={true}
      anchorEl={props.anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClose={handleCancel}
      PaperProps={{
        style: {
          marginLeft: '8px',
          background: 'rgb(255, 255, 255)',
        },
      }}
    >
      <List>
        <ListItem
          button
          onClick={() => handleCreateIntent(EIntentType.SendMessage)}
        >
          <ListItemIcon className={classes.listItem}>
            <SmsIcon className={classes.listSvg} />
            {getTranslation('intents.sendMessage')}
          </ListItemIcon>
        </ListItem>

        <ListItem
          button
          onClick={() => handleCreateIntent(EIntentType.SimpleQuestion)}
        >
          <ListItemIcon className={classes.listItem}>
            <InputIcon className={classes.listSvg} />
            {getTranslation('intents.simpleQuestion')}
          </ListItemIcon>
        </ListItem>

        <ListItem
          button
          onClick={() => handleCreateIntent(EIntentType.OptionsBlock)}
        >
          <ListItemIcon className={classes.listItem}>
            <MultipleChoiceIcon />
            {getTranslation('intents.optionsBlock')}
          </ListItemIcon>
        </ListItem>

        <ListItem
          button
          onClick={() => handleCreateIntent(EIntentType.Carousel)}
        >
          <ListItemIcon className={classes.listItem}>
            <RecentActorsIcon className={classes.listSvg} />
            {getTranslation('intents.carousel')}
          </ListItemIcon>
        </ListItem>

        <ListItem
          button
          onClick={() => handleCreateIntent(EIntentType.SendDocument)}
        >
          <ListItemIcon className={classes.listItem}>
            <DescriptionIcon className={classes.listSvg} />
            {getTranslation('intents.sendDocument')}
          </ListItemIcon>
        </ListItem>

        <ListItem button onClick={() => handleCreateIntent(EIntentType.NLU)}>
          <ListItemIcon className={classes.listItem}>
            <NluIcon />
            {getTranslation('intents.nlu')}
          </ListItemIcon>
        </ListItem>

        <ListItem button onClick={() => handleCreateIntent(EIntentType.Logic)}>
          <ListItemIcon className={classes.listItem}>
            <LogicIcon size="20" />
            {getTranslation('intents.logic')}
          </ListItemIcon>
        </ListItem>

        <ListItem
          button
          onClick={() => handleCreateIntent(EIntentType.NewGroup)}
        >
          <ListItemIcon className={classes.listItem}>
            <ReplyAllIcon className={classes.listSvg} />
            {getTranslation('intents.newGroup')}
          </ListItemIcon>
        </ListItem>

        <ListItem
          button
          onClick={() => handleCreateIntent(EIntentType.MultipleChoiceParent)}
        >
          <ListItemIcon className={classes.listItem}>
            <DeviceHubIcon className={classes.listSvg} />
            {getTranslation('intents.multipleChoice')}
          </ListItemIcon>
        </ListItem>

        <ListItem button onClick={() => handleCreateIntent(EIntentType.ABTest)}>
          <ListItemIcon className={classes.listItem}>
            <ABTestIcon size="20" />
            {getTranslation('intents.abTest')}
          </ListItemIcon>
        </ListItem>

        <ListItem
          button
          onClick={() => handleCreateIntent(EIntentType.OpenHTML)}
        >
          <ListItemIcon className={classes.listItem}>
            <OpenInNewIcon className={classes.listSvg} />
            {getTranslation('intents.toHtml')}
          </ListItemIcon>
        </ListItem>

        <ListItem
          button
          onClick={() => handleCreateIntent(EIntentType.Advanced)}
        >
          <ListItemIcon className={classes.listItem}>
            <Dashboard className={classes.listSvg} />
            {getTranslation('intents.advanced')}
          </ListItemIcon>
        </ListItem>
        {environment !== 'production' && (
          <ListItem
            button
            onClick={() => handleCreateIntent(EIntentType.FalaGPT)}
          >
            <ListItemIcon className={classes.listItem}>
              <FalaGPTIcon size="20" />
              123Fala
            </ListItemIcon>
          </ListItem>
        )}

        <ListItem style={{ width: '100%' }}>
          <ListItemIcon style={{ width: '100%' }}>
            <Divider style={{ width: '100%' }} />
          </ListItemIcon>
        </ListItem>

        {environment !== 'production' && (
          <>
            <ListItem
              button
              onClick={(e) => {
                setFlexBlockPopover(true);
                setPopoverAnchorEl(e.currentTarget as HTMLDivElement);
              }}
            >
              <ListItemIcon className={classes.listItem}>
                <Popover
                  open={flexBlockPopover}
                  anchorEl={popoverAnchorEl}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  onClose={handleCancel}
                  PaperProps={{
                    style: {
                      marginLeft: '8px',
                      background: 'rgb(255, 255, 255)',
                    },
                  }}
                >
                  <List>
                    <ListItem
                      button
                      onClick={() => {
                        handleCreateIntent(EIntentType.FlexBlock);
                      }}
                    >
                      <ListItemIcon className={classes.listItem}>
                        <NewFlexBlockIcon
                          size="20"
                          style={{
                            marginLeft: '2px',
                          }}
                        />
                        Novo Bloco Flex
                      </ListItemIcon>
                    </ListItem>
                    <ListItem button onClick={handleOpenModalFlexBlocks}>
                      <ListItemIcon className={classes.listItem}>
                        <FormatListBulletedIcon className={classes.listSvg} />
                        Usar Bloco Flex
                      </ListItemIcon>
                    </ListItem>
                  </List>
                </Popover>
                <AccountTreeIcon />
                Bloco Flex
              </ListItemIcon>
            </ListItem>
            {state.idGroup?.startsWith('flex-') && (
              <ListItem
                button
                onClick={() => handleCreateIntent(EIntentType.FlexBlockExit)}
              >
                <ListItemIcon className={classes.listItem}>
                  <AppIcon />
                  Saída do Flex
                </ListItemIcon>
              </ListItem>
            )}
          </>
        )}
      </List>
    </Popover>
  );
}
