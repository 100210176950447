import styled from 'styled-components';
import MttGrid from 'components/Material/MttGrid/MttGrid';
import MttButton from 'components/Material/MttButton/MttButton';
import MttBox from 'components/Material/MttBox/MttBox';
import { Fab } from '@material-ui/core';

export const ContainerHeader = styled(MttGrid)`
  display: flex;
  width: 100%;

  .MuiButton-containedPrimary {
    width: 100px;
    margin-left: 16px;
  }
`;

export const StyledBackButtom = styled(Fab)`
  min-width: 16px;
  max-width: 48px;
  min-height: 16px;
  max-height: 48px;
`;

export const ContainerButtom = styled(MttGrid)`
  margin-right: 16px;
`;

export const StyledSaveButtom = styled(MttButton)`
  .MuiButton-label {
    width: 124%;
  }
`;

export const GridBackButton = styled(MttGrid)`
  margin-top: 16px;
`;

export const StyledBackButton = styled(MttButton).attrs({
  children: 'Voltar',
})``;

export const BoxWords = styled(MttBox)`
  background: #f2f2f2;
  margin-top: 10px;
  input {
    color: #6c50a4;
  }
`;

export const BoxHeader = styled(MttBox)`
  padding: 10px 20px;
`;

export const BoxAddLine = styled(MttBox)`
  width: calc(100% - 40px);
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 8px;
  padding: 0 20px;

  .MuiButton-containedPrimary {
    margin-left: 16px;
  }
`;

export const DisplayLines = styled(MttGrid)`
  height: 600px;
`;

export const StyledAddButtom = styled(MttButton)``;

export const TesteContainer = styled(MttGrid)`
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
`;
