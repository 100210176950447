import React from 'react';

export interface ISVGProps {
  size?: string;
  color?: string;
}

const SchemaIcon: React.FC<ISVGProps> = (props) => {
  const { size, color } = props;

  return (
    <svg
      width={size || '24'}
      height={size || '24'}
      viewBox="0 0 24 24"
      fill={color || '#424242'}
    >
      <g>
        <rect
          xmlns="http://www.w3.org/2000/svg"
          id="Retângulo_877"
          data-name="Retângulo 877"
          width="24"
          height="24"
          fill="none"
        />
      </g>
      <g>
        <path
          xmlns="http://www.w3.org/2000/svg"
          id="Caminho_4778"
          data-name="Caminho 4778"
          d="M14,9v2H11V9H8.5V7H11V1H4V7H6.5V9H4v6H6.5v2H4v6h7V17H8.5V15H11V13h3v2h7V9Z"
          fill="#424242"
        />
      </g>
    </svg>
  );
};

export default SchemaIcon;
