import { createStyles, Grid, makeStyles, Typography } from '@material-ui/core';
import MttGrid from 'components/Material/MttGrid/MttGrid';
import styled from 'styled-components';

export const ContainerDialog = styled(MttGrid)<{ colorHeader?: string }>`
  width: 100%;
  height: 100%;

  position: relative;
  padding-top: 54px;

  overflow: hidden;
  min-height: 400px;

  .MuiAppBar-colorPrimary {
    background-color: #6c50a4;
    position: absolute;
  }
`;

export const ContentItemAdd = styled.div`
  display: flex;
  padding: 10px;
`;

export const ChildrenContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
`;

export const TextContainer = styled.div`
  height: 64px;

  margin-right: 10px;
  width: 100%;
`;

export const AutoCompleteWrap = styled.div`
  width: 100%;
  margin: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  input {
    height: auto;
  }

  button {
    width: auto;
    height: auto;
  }
`;

export const ButtonContainer = styled(Grid)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const ModalTitle = styled(Typography).attrs({
  variant: 'h6',
})`
  color: #fff;
`;

export const useStyles = makeStyles(() =>
  createStyles({
    dialog: {
      background: '#F2F2F2',
      overflowY: 'scroll',
    },

    input: {
      background: '#FFFFFF',
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
    },

    container: {
      display: 'flex',
      height: '100%',
      justifyContent: 'space-between',
      flexDirection: 'column',
      padding: '24px',
      maxWidth: '100%',
      width: '100%',
    },
  })
);
