import { makeStyles, Theme } from '@material-ui/core';
import MttGrid from 'components/Material/MttGrid/MttGrid';
import styled from 'styled-components';
import { themeDefault } from 'themes/DefaultTheme';
import { BoxContainer } from '../styles';

export const StyledRightButtons = styled(MttGrid)`
  width: 100%;

  display: flex;
  justify-content: flex-end;

  div,
  button {
    margin: 0 8px;
  }
`;

export const BoxVariableAndColumnContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 8px;
`;

export const BoxVariableAndColumn = styled(BoxContainer)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: auto;
 
`;

export const Status = styled.div<{ bgColor: string }>`
  background: ${(props) => props.bgColor};

  border-radius: 50%;
  min-width: 14px !important;
  height: 14px !important;

  margin-right: 6px;
`;

export const StatusDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonContainer = styled(MttGrid)<{
  bgcolor?: string;
  color?: string;
  disabledColor?: string;
}>`
  display: flex;
  align-items: center;
  height: 56px;
  color: #ffffff;
  background-color: ${(props) => props.bgcolor || 'inherit'};
  border-radius: 4px;

  button {
    padding: 24px;
    width: 100%;
    height: 100%;
    color: ${(props) => props.color || '#fff'};
  }

  .MuiButton-root.Mui-disabled {
    color: ${(props) => props.disabledColor || 'rgb(0 0 0 / 32%)'};
  }
`;

export const actioningStyles = makeStyles((theme: Theme) => ({
  selectFileButton: {
    maxWidth: 225,
    height: '50px',
    background: `${themeDefault.palette.primary.main}`,
    color: '#fff',

    '&:hover': {
      background: '#512d9a',
      color: '#fff',
    },
  },
}));
