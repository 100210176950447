import styled from 'styled-components';
import MttGrid from 'components/Material/MttGrid/MttGrid';
import MttTypography from 'components/Material/MttTypography/MttTypography';
import MttTextField from 'components/Material/MttTextField/MttTextFIeld';

export const Container = styled(MttGrid)`
  margin: 16px;
`;

export const ContentBody = styled(MttGrid)`
  background-color: #f2f2f2;
  padding: 16px;

  border-radius: 4px;
  .MuiFormControl-fullWidth {
    background-color: #fff;
  }
`;

export const StyledMttTypography = styled(MttTypography).attrs({
  children: 'Conteúdo do Carrossel',
  variant: 'h6',
  color: 'primary',
})``;

export const GridTextField = styled(MttGrid)`
  width: 100%;
`;

export const BoxHeader = styled(MttGrid)`
  display: block;
`;

export const RadioGrid = styled(MttGrid)`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 8px 0;
`;

export const GridContainerInfos = styled(MttGrid)`
  width: 100%;
  display: flex;
  grid-template-columns: 1fr 200px 70px;
  grid-gap: 8px;
`;

export const GridInfos = styled(MttGrid)`
  height: 120px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 15px;

  .MuiOutlinedInput-input {
    background-color: #fff;
  }
`;

export const GridInfosFirstLine = styled(MttGrid)`
  width: 100%;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
`;

export const StyledMttTextField = styled(MttTextField)``;

export const ContainerMedia = styled(MttGrid)`
  width: 180px;
  height: 120px;

  margin: auto 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #aeaeae;
  color: #ffffff;
`;

export const StyledDeleteGrid = styled(MttGrid)`
  width: 46px;
  height: 46px;
  color: #ffffff;
  background-color: #6c50a4;

  margin: auto 0;
  border-radius: 100px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
`;
