import React, { useState, useEffect } from 'react';
import { useFlow } from 'contexts/Flow/flowContext';
import * as S from './styles';
import zzz from 'components/Icons/zzz.png';
import useTranslator from 'utils/hooks/Translator';

const maxTimer = 60;
let timeout: NodeJS.Timeout;

export default function ModalInactiveWarning() {
  const classes = S.useStyles();
  const { state, dispatch, verifyEditGroup } = useFlow();

  const [timer, setTimer] = useState(true);
  const [timerDisplayer, setTimerDisplayer] = useState(maxTimer);
  const { getTranslation } = useTranslator();

  useEffect(() => {
    if (timer) {
      if (timerDisplayer > 0) {
        timeout = setTimeout(() => {
          const updatedTimer = timerDisplayer;
          setTimerDisplayer(updatedTimer - 1);
        }, 1000);
      } else {
        setTimerDisplayer(maxTimer);
        setTimer(false);
      }
    }

    return () => clearTimeout(timeout);
  }, [timer, timerDisplayer]);

  async function handleOk() {
    if (state.idGroup && state.botName) {
      dispatch({ type: 'updateForm', data: { loading: true } });
      await verifyEditGroup(state.idGroup, state.botName);
      dispatch({ type: 'updateForm', data: { loading: false } });
    }
    dispatch({ type: 'closeModalWarning' });
  }

  if (!state.openModalWarning) return <></>;

  return (
    <S.Container>
      <S.ImageContainer>
        <img src={zzz} alt="inactive" />
      </S.ImageContainer>
      <S.ContentContainer>
        <S.ModalTitle
          classes={{
            h6: classes.modalTitle,
          }}
        >
          {getTranslation('modal.inactive.title')}
        </S.ModalTitle>
        <S.TextContainer variant="body1">
          {getTranslation('modal.inactive.subtitle')}
        </S.TextContainer>
      </S.ContentContainer>
      <S.ButtonContainer>
        <S.ButtonOk color="primary" variant={'contained'} onClick={handleOk}>
          {getTranslation('modal.inactive.button')}
        </S.ButtonOk>
      </S.ButtonContainer>
    </S.Container>
  );
}
