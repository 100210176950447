import {
  DataFlow,
  InputOutputObject,
  IPosition,
  Output,
} from 'models/DataFlow';
import DataFormModel from 'models/DataFormModel';

export default class AddBlockToFlowController {
  protected drawflow: any;
  protected groupId: string;
  protected position: IPosition;

  constructor(drawflow: any, groupId: string, position: IPosition) {
    this.drawflow = drawflow;
    this.groupId = groupId;
    this.position = position;
  }

  async addBlock(block: DataFlow) {
    const home = this.drawflow.drawflow.Home;
    const dataUpdated = { ...home.data };
    const blockUpdated = this.updateBlock(block);
    home.data = { ...dataUpdated, ...blockUpdated };
  }

  updateBlock(block: DataFlow) {
    const blockUpdatedPositions = this.updatePosition(block);
    const blockUpdatedInputsOutputs = this.removeInputsAndOutputsNotUsed(
      blockUpdatedPositions
    );
    const blockUpdatedId = this.updateIdsBlock(blockUpdatedInputsOutputs);
    this.updateData(blockUpdatedId);
    const blockUpdateName = this.insertCopyName(blockUpdatedId);
    return blockUpdateName;
  }

  updatePosition(block: DataFlow) {
    const keys = Object.keys(block);
    const newBlock: DataFlow = {};
    if (keys.length) {
      const key = keys[0];
      const node = block[Number(key)];
      const offsetX = node.pos_x;
      const offsetY = node.pos_y;
      for (let index = 0; index < keys.length; index++) {
        const node = { ...block[Number(keys[index])] };
        node.pos_x = node.pos_x - offsetX + 400 + this.position.pos_x;
        node.pos_y = node.pos_y - offsetY + this.position.pos_y;
        newBlock[Number(keys[index])] = node;
      }
    }
    return newBlock;
  }

  removeInputsAndOutputsNotUsed(block: DataFlow) {
    const newBlock: DataFlow = { ...block };
    for (const key in block) {
      const node = { ...newBlock[key] };
      node.inputs = this.removeFieldsNotUsed(newBlock, { ...node.inputs });
      node.outputs = this.removeFieldsNotUsed(newBlock, { ...node.outputs });
      newBlock[Number(key)] = { ...node };
    }
    return newBlock;
  }

  removeFieldsNotUsed(block: DataFlow, fields: InputOutputObject) {
    const newFields: InputOutputObject = { ...fields };
    for (const key in fields) {
      const field = { ...newFields[key] };
      field.connections = field.connections.filter(
        (conn) => !!block[Number(conn.node)]
      );
      newFields[key] = { ...field };
    }
    return newFields;
  }

  updateIdsBlock(block: DataFlow) {
    let newBlock: DataFlow = { ...block };
    let currentFlowId = this.getNextNodeId();
    let currentBlockId = this.getNextNodeIdBlock(block);
    let currentId =
      currentFlowId > currentBlockId ? currentFlowId : currentBlockId;
    const keys = Object.keys(newBlock);
    for (const key of keys) {
      const nodeId = Number(key);
      newBlock = this.updateInputsAndOutputs(newBlock, nodeId, currentId);
      const node = { ...newBlock[nodeId] };
      node.id = currentId;
      node.data.groupId = this.groupId;
      delete node.flowid;
      delete newBlock[nodeId];
      newBlock[currentId] = { ...node };
      currentId++;
    }
    return newBlock;
  }

  updateInputsAndOutputs(
    block: DataFlow,
    prevNodeId: number,
    newNodeId: number
  ) {
    const newBlock: DataFlow = { ...block };
    for (const key in newBlock) {
      const node = { ...newBlock[key] };
      if (node.data.parentId === prevNodeId) {
        node.data.parentId = newNodeId;
      }
      node.inputs = this.updateFields(node.inputs, prevNodeId, newNodeId);
      node.outputs = this.updateFields(node.outputs, prevNodeId, newNodeId);
      newBlock[Number(key)] = { ...node };
    }
    return newBlock;
  }

  updateFields(
    fields: InputOutputObject,
    prevNodeId: number,
    newNodeId: number
  ) {
    const newFields: InputOutputObject = { ...fields };
    for (const key in newFields) {
      const field = { ...fields[key] };
      field.connections = field.connections.map((conn) => {
        if (Number(conn.node) === prevNodeId) {
          conn.node = newNodeId;
        }
        return conn;
      });
      newFields[key] = { ...field };
    }
    return newFields;
  }

  getNextNodeIdBlock(block: DataFlow) {
    const data = block;
    const keys = Object.keys(data)
      .map((k) => Number(k))
      .sort((a, b) => (a < b ? 1 : a > b ? -1 : 0));
    const key = keys.length > 0 ? Number(keys[0]) + 1 : 1;
    return key;
  }

  getNextNodeId() {
    const data = this.drawflow.drawflow.Home.data;
    const keys = Object.keys(data)
      .map((k) => Number(k))
      .sort((a, b) => (a < b ? 1 : a > b ? -1 : 0));
    const key = keys.length > 0 ? Number(keys[0]) + 1 : 1;
    return key;
  }

  updateData(block: DataFlow) {
    for (const key in block) {
      const { data, outputs } = block[key];
      data.outputs = this.updateOutputData(data.outputs || [], outputs);
    }
  }

  updateOutputData(outputsData: Output[], outputs: InputOutputObject) {
    const keys = Object.keys(outputs);
    for (let index = 0; index < keys.length; index++) {
      const output = outputs[keys[index]];
      if (output.connections && !!output.connections.length) {
        const nodeId = output.connections[0].node;
        const outputData = outputsData.find(
          (_, outputIndex) => outputIndex === index
        );
        if (outputData) {
          outputData.outputid = String(nodeId);
        }
      }
    }
    return [...outputsData];
  }

  insertCopyName(block: DataFlow) {
    const numberCopy = this.getNextCopyNumber();
    const newBlock: DataFlow = { ...block };
    if (!this.hasCopy(newBlock)) return newBlock;
    for (const key in block) {
      const node = { ...newBlock[key] };
      const newData = new DataFormModel(node.data);
      if (newData.isParent()) {
        const outputs = newData.getOutputs();
        for (let index = 0; index < outputs.length; index++) {
          const output = outputs[index];
          const childId = output.outputid;
          const nodeChild = { ...newBlock[Number(childId)] };
          if (nodeChild && nodeChild.data) {
            nodeChild.data.name = nodeChild.data.name.replace(
              newData.name,
              `${node.data.name.replace(/-copy-([0-9]*)/,"")}-copy-${numberCopy}`
            );
            newBlock[Number(childId)] = nodeChild;
          }
        }
        node.data.name = `${node.data.name.replace(/-copy-([0-9]*)/,"")}-copy-${numberCopy}`;
      } else if (!newData.isChild() && !newData.isOthers()) {
        node.data.name = `${node.data.name.replace(/-copy-([0-9]*)/,"")}-copy-${numberCopy}`;
      }
      newBlock[Number(key)] = { ...node };
    }
    return newBlock;
  }

  getNextCopyNumber() {
    const data: DataFlow = { ...this.drawflow.drawflow.Home.data };
    const numbers = Object.keys(data)
      .map((key) => data[Number(key)].data.name)
      .filter((name) => {
        const nameCopyRegex = /-copy-([0-9]*)/g;
        const matches = name.match(nameCopyRegex);
        const result = !!matches ? matches[0] : '';
        return !!result;
      })
      .map((name) =>{
        const nameCopyRegex = /-copy-([0-9]*)/g;
        const matches = name.match(nameCopyRegex);
        const result = !!matches ? matches[0] : '';
        return Number(result.replace(/[^0-9]/g,''))
      }
        
      )
      .sort((a, b) => (a < b ? 1 : a > b ? -1 : 0));

    return !!numbers.length ? numbers[0] + 1 : 1;
  }

  hasCopy(block: DataFlow) {
    const data: DataFlow = { ...this.drawflow.drawflow.Home.data };
    const namesIntentFlow = Object.keys(data).map(
      (key) => data[Number(key)].data.name
    );
    const namesIntentBlock = Object.keys(block).map(
      (key) => block[Number(key)].data.name
    );
    return namesIntentBlock.some((name) => namesIntentFlow.includes(name));
  }

  getDrawflow() {
    return this.drawflow;
  }
}
