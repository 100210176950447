import MttGrid from 'components/Material/MttGrid/MttGrid';
import styled from 'styled-components';

export const ContainerDialog = styled(MttGrid)<{ colorHeader?: string }>`
  width: 100%;
  height: 100%;

  padding-top: 54px;

  overflow: hidden;
  min-height: 400px;

  .MuiAppBar-colorPrimary {
    background-color: #6c50a4;
    position: absolute;
  }
`;

export const Container = styled.div`
  max-height: calc(100% - 104px);
  overflow-y: auto;
  padding: 24px;
`;

export const OrganizationEditForm = styled.form`
  display: flex;
  flex-direction: column;

  .MuiFormHelperText-root {
    color: red;
  }
`;

export const ContentLimit = styled.div<{ marginBottom?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 10px 0 10px;
`;

export const ContentItemAdd = styled.div<{ comanyEdit?: boolean }>`
  display: flex;
  align-items: center;
  padding: ${(props) => (props.comanyEdit ? '10px' : '5px 0px')};
`;

export const TextContainer = styled.div`
  height: 64px;

  margin-right: 10px;
  width: 100%;
`;

export const AutoCompleteWrap = styled.div`
  width: 100%;
  margin: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  input {
    height: auto;
  }

  button {
    width: auto;
    height: auto;
  }
`;
export const TableContainer = styled.div`
  width: 100%;
  max-height: 50vh;
  overflow: auto;
`;

export const LoginWithAdContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LoginWithAdInfoContainer = styled.div`
  position: absolute;
  left: -160px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
