/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import {
  AppBar,
  Button,
  Dialog,
  Grid,
  IconButton,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import CheckIcon from '@material-ui/icons/Check';

import * as S from './styles';
import { Autocomplete } from '@material-ui/lab';
import { useFlow } from 'contexts/Flow/flowContext';
import { GetBotsGroups } from 'services/BotService';
import { useApp } from 'contexts/App/appContext';
import { GetGroup, UpdateGroup } from 'services/FlowService';
import PhraseOfIntent from './PhraseOfIntent';
import { DataForm, ItemDataFlow } from 'models/DataFlow';
import { FormProvider } from 'contexts/Form/formContext';
import { DataFlowObject } from 'contexts/Flow/types';
import EIntentType from 'enums/EIntentType';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import useTranslator from 'utils/hooks/Translator';
import { usePermissions } from 'contexts/Permissions/permissionsContext';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
class IInfo {
  bot_name = '';
  group = '';
  intent = '';
}

const ModalPhrasesOfIntentManage: React.FC = () => {
  const { state, dispatch, toastNotification, stopEditing } = useFlow();
  const { dispatch: dispatchApp } = useApp();
  const { state: permissionsState, hasPermissionToAction } = usePermissions();
  const { companies } = permissionsState;
  const [info, setInfo] = useState(new IInfo());
  const [groups, setGroups] = useState(['']);
  const [intentions, setIntentions] = useState<ItemDataFlow[]>([]);
  const [drawflow, setDrawflow] = useState<DataFlowObject>();
  const isFlowWrite = hasPermissionToAction({
    agent: info.bot_name,
    company: state.companyName || '',
    action: ['flow:write'],
  });

  const [viewPhrase, setViewPhrase] = useState('');
  const { getTranslation } = useTranslator();
  const agents = useMemo(() => {
    const company = companies.find(
      (company) => company.name === state.companyName
    );

    return company
      ? company.agents
          .filter((agent) =>
            hasPermissionToAction({
              agent: agent.name,
              company: state.companyName || '',
              action: ['flow:write', 'flow:read'],
            })
          )
          .map((agent) => agent.name)
          .sort()
      : [];
  }, [companies, state.companyName]);

  useEffect(() => {
    dispatch({
      type: 'updateForm',
      data: { dataForm: null },
    });

    stopEditing('openModalBlockedGroup');
    // eslint-disable-next-line react-hooks/exhaustive-deps

    if (agents && state.openModalPhrasesOfIntentManager)
      handleChangeBot(agents[0]);
  }, [state.openModalPhrasesOfIntentManager]);

  useEffect(() => {
    if (info.bot_name && info.group && info.intent) {
      // getPhrasesNotUnderstood();
      setViewPhrase('');
    } else {
      setViewPhrase(getTranslation('modal.createPhrasesOfIntent.infoPhrase'));
    }
  }, [info]);

  const handleChangeBot = async (newValue: string | null) => {
    const botNameSelected = newValue || '';

    setInfo({ ...info, bot_name: botNameSelected, group: '', intent: '' });

    if (botNameSelected) {
      const response = await GetBotsGroups(
        { bot_name: botNameSelected },
        dispatchApp
      );
      const flows = response.Data.data;

      const principal = flows.find((group) => group === 'principal');

      if (principal) {
        setGroups([]);
        const updatedGroups = [
          principal,
          ...flows.filter((group) => group !== 'principal').sort(),
        ];
        setGroups(updatedGroups);
      }
    }
  };

  const handleChangeGroup = async (botName: string, groupName: string) => {
    setInfo({ ...info, group: groupName, intent: '' });

    if (groupName !== '') {
      const response = await GetGroup(
        { bot_name: botName || '', groupName },
        dispatchApp
      );

      if (response.Success) {
        const group = response.Data.data;

        const keys = Object.keys(group.blocks.drawflow.Home.data);
        const newIntentions = [];

        setDrawflow(group.blocks.drawflow);

        for (const key in keys) {
          const block = group.blocks.drawflow.Home.data[Number(keys[key])];
          if (
            block.data.intentType === EIntentType.InitialFlow ||
            block.data.intentType === EIntentType.MultipleChoiceChild ||
            block.data.intentType === EIntentType.CarouselChild
          )
            newIntentions.push(block);
        }
        setIntentions(newIntentions);
      }
    } else {
      const newIntentionsList: [] = [];
      setIntentions(newIntentionsList);
    }
  };

  const handleClose = () => {
    dispatch({ type: 'closeModalPhrasesOfIntentManager' });
    dispatch({ type: 'updateForm', data: { dataForm: null } });
    setInfo({ bot_name: '', group: '', intent: '' });
  };

  const handleSave = async () => {
    const { editorFlow } = state;
    if (!editorFlow) return;

    if (info.intent) {
      if (drawflow) {
        const response = await UpdateGroup(
          {
            bot_name: info.bot_name,
            group_name: info.group,
            blocks: {
              drawflow: drawflow,
            },
          },
          dispatchApp
        );

        if (response.Success) {
          toastNotification(
            'success',
            getTranslation('toast.success.update'),
            3000
          );
        } else {
          toastNotification(
            'error',
            response.Message || getTranslation('toast.error.update')
          );
        }
      }
    } else {
      toastNotification('error', getTranslation('toast.error.selectIntent'));
    }
  };

  const noView = () => (
    <S.GridInfo item>
      <InfoOutlinedIcon />
      <Typography
        variant="body1"
        style={{ marginTop: 24, textAlign: 'center' }}
      >
        {viewPhrase}
      </Typography>
    </S.GridInfo>
  );

  const renderPhrasesOfIntent = () => (
    <PhraseOfIntent bot_name={info.bot_name} />
  );

  return (
    <FormProvider>
      <Dialog
        open={state.openModalPhrasesOfIntentManager}
        TransitionComponent={Transition}
        fullScreen
        fullWidth
      >
        <S.ContainerDialog>
          <AppBar>
            <Toolbar>
              <Typography variant="h6">
                {getTranslation('modal.createPhrasesOfIntent.title')}
              </Typography>
              <IconButton
                edge="end"
                color="inherit"
                onClick={() => handleClose()}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <S.Container>
            <S.ContentContainer>
              <Grid container spacing={2}>
                <Grid item style={{ flex: 1 }}>
                  <Autocomplete
                    value={info.bot_name}
                    options={agents}
                    noOptionsText={'Sem resultados'}
                    renderOption={(option) => (
                      <S.AutoCompleteOption>{option}</S.AutoCompleteOption>
                    )}
                    onChange={(event: any, newValue: string | null) => {
                      handleChangeBot(newValue);
                    }}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={getTranslation('agent')}
                        placeholder="Selecione um agente"
                      />
                    )}
                  />
                </Grid>
                <Grid item style={{ flex: 1 }}>
                  <Autocomplete
                    disabled={!info.bot_name}
                    value={info.group}
                    options={groups}
                    noOptionsText={'Sem resultados'}
                    renderOption={(option) => (
                      <S.AutoCompleteOption>{option}</S.AutoCompleteOption>
                    )}
                    onChange={(event: any, newValue: string | null) => {
                      handleChangeGroup(info.bot_name, newValue || '');
                    }}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={getTranslation('group')}
                        placeholder={getTranslation('selectOne')}
                      />
                    )}
                  />
                </Grid>
                <Grid item style={{ flex: 1 }}>
                  <Autocomplete
                    disabled={!info.group}
                    value={info.intent}
                    options={intentions.map((option) => option.data.name)}
                    noOptionsText={'Sem resultados'}
                    renderOption={(option) => (
                      <S.AutoCompleteOption>{option}</S.AutoCompleteOption>
                    )}
                    onChange={(event: any, newValue: string | null) => {
                      const { editorFlow } = state;

                      if (editorFlow) {
                        const node = intentions.find(
                          (i) => i.data.name === newValue
                        );

                        const dataForm = node?.data as DataForm;

                        dispatch({
                          type: 'updateForm',
                          data: { dataForm },
                        });
                      }
                      setInfo({ ...info, intent: newValue || '' });
                    }}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={getTranslation('block')}
                        placeholder={getTranslation('selectOne')}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                style={{ padding: 20, flexDirection: 'column' }}
              >
                {viewPhrase ? noView() : renderPhrasesOfIntent()}
              </Grid>
            </S.ContentContainer>
            <div>
              <Grid
                container
                spacing={2}
                style={{
                  justifyContent: 'flex-end',
                  padding: '16px 8px',
                  width: '100%',
                }}
              >
                <S.ButtonContainer item>
                  {isFlowWrite && (
                    <Button
                      startIcon={<CheckIcon />}
                      onClick={() => handleSave()}
                      variant="contained"
                      color="primary"
                    >
                      {getTranslation('save')}
                    </Button>
                  )}
                </S.ButtonContainer>
              </Grid>
            </div>
          </S.Container>
        </S.ContainerDialog>
      </Dialog>
    </FormProvider>
  );
};

export default ModalPhrasesOfIntentManage;
