import styled from 'styled-components';
import MttTypography from 'components/Material/MttTypography/MttTypography';
import MttGrid from 'components/Material/MttGrid/MttGrid';
import TextField from '@material-ui/core/TextField/TextField';

export const GridContainer = styled(MttGrid)`
  margin: 16px;
`;

export const StyledMttTypographyMsgForUser = styled(MttTypography).attrs({
  children: 'Mensagem quando resposta não atender nenhuma das opções acima:',
  variant: 'body1',
  color: 'primary',
})`
  margin-bottom: 16px !important;
`;

export const ContainerButton = styled(MttGrid)`
  display: flex;
  justify-content: flex-end;

  padding: 8px 0;
`;

export const ContentGrid = styled(MttGrid)`
  width: calc(100% - 32px);

  padding: 16px;

  border-radius: 4px;

  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;

  overflow-y: auto;

  .MuiTypography-body1 {
    color: #6c50a4;
    font-weight: 500;
  }
`;

export const ContentMsgForUserGrid = styled.div`
  padding: 16px;

  background-color: #f2f2f2;

  .MuiInputBase-input {
    background-color: #ffffff;
  }
`;

export const StyledMttTextField = styled(TextField)`
  background: #fff;
`;
