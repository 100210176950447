import React from 'react';
import { useForm } from 'contexts/Form/formContext';
import { FormHelperText, TextField, Typography } from '@material-ui/core';
import * as S from './styles';

import useTranslator from 'utils/hooks/Translator';
import { FlexBlockContentOutputs } from './FlexBlockContentOutputs';
import { infoFormMask } from 'models/InfoForm';

export default function FlexBlockContent() {
  const { state, dispatch } = useForm();
  const { dataForm, formErrors } = state;

  const { getTranslation } = useTranslator();

  const handleChangeEntryName = (newName: string) => {
    if (dataForm) {
      const valueWithMask = infoFormMask['name'](newName);
      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
            entryName: valueWithMask,
            dataBlockly: {
              compiledPayload: `event('flex-${dataForm.name}_${newName}')`,
              lastVersion: dataForm.dataBlockly?.lastVersion || 0,
              payload: dataForm.dataBlockly?.payload || '',
              xml: dataForm.dataBlockly?.xml || '',
            },
          },
        },
      });
    }
  };

  return (
    <S.GridContainer>
      <S.ContentGrid>
        <Typography color="primary" variant="h6" children="Entrada" />
        <TextField
          variant="outlined"
          fullWidth
          value={dataForm?.entryName}
          onChange={(e) => handleChangeEntryName(e.target.value)}
          label={'Nome da Entrada'}
          style={{ marginTop: '16px' }}
					error={!!formErrors['entryName']}
					/>
        <FormHelperText error>{formErrors['entryName']}</FormHelperText>
      </S.ContentGrid>

      <S.ContentGrid>
        {dataForm?.outputs && dataForm?.outputs.length > 0 && (
          <S.ContainerButton>
            <Typography
              variant="h6"
              children={getTranslation('outputs')}
              color="primary"
            />
          </S.ContainerButton>
        )}
        {dataForm?.outputs?.map((output, index) => (
          <FlexBlockContentOutputs key={index} index={index} output={output} />
        ))}
      </S.ContentGrid>
    </S.GridContainer>
  );
}
