import { useCallback, useEffect, useState } from 'react';
import EIntentType from 'enums/EIntentType';

import * as S from './styles';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import SmsIcon from '@material-ui/icons/Sms';
import ReplyAllIcon from '@material-ui/icons/ReplyAll';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import Dashboard from '@material-ui/icons/Dashboard';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import WarningIcon from '@material-ui/icons/Warning';
import CancelIcon from '@material-ui/icons/Cancel';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import DescriptionIcon from '@material-ui/icons/Description';
import InputIcon from '@material-ui/icons/Input';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import {
  ColorAndBorder,
  HeatMapColorsAndBorders,
  IBlockAccessCountProps,
} from './types';

import ModalBlockOptions from '..';
import RulesIcon from 'components/Icons/RulesIcon';
import LogicIcon from 'components/Icons/LogicIcon';
import NluIcon from 'components/Icons/NluIcon';
import MultipleChoiceIcon from 'components/Icons/MultipleChoiceIcon';
import ABTestIcon from 'components/Icons/ABTestIcon';
import FalaGPTICon from 'components/Icons/FalaGPTIcon';
import FlagsContainer from './FlagsContainer';
import AppIcon from 'components/Icons/AppIcon';

function BlockAccessCount(props: IBlockAccessCountProps) {
  const {
    blocksData,
    name,
    intentType,
    nodeId,
    node,
    state,
    dispatch,
    addNodeToList,
    clearNodeList,
    deleteNode,
    duplicateNode,
    form,
    toastNotification,
  } = props;
  const [color, setColor] = useState<ColorAndBorder>();
  const [data, setData] = useState(0);
  const [backgroundColor, setBackgroundColor] = useState('#fff');
  const [icon, setIcon] = useState<any>(null);

  const load = useCallback(() => {
    const keys = Object.keys(blocksData);
    const result: string | any = keys.find((block) => block === name);

    if (result) {
      const value = blocksData[result];
      setData(value);

      const chosenColor: ColorAndBorder =
        value <= 10
          ? HeatMapColorsAndBorders.colder
          : value <= 20
          ? HeatMapColorsAndBorders.cold
          : value <= 30
          ? HeatMapColorsAndBorders.medium
          : value <= 40
          ? HeatMapColorsAndBorders.warm
          : HeatMapColorsAndBorders.hot;

      setColor(chosenColor);
    } else setColor(HeatMapColorsAndBorders.colder);
  }, [blocksData, name]);

  const loadColor = useCallback(() => {
    switch (intentType) {
      case EIntentType.CarouselParent:
        setBackgroundColor('#016B60');
        setIcon(<RecentActorsIcon />);
        break;
      case EIntentType.CarouselChild:
        setBackgroundColor('#016B60');
        setIcon(<RecentActorsIcon />);
        break;
      case EIntentType.MultipleChoiceParent:
        setBackgroundColor('#016B60');
        setIcon(<DeviceHubIcon />);
        break;
      case EIntentType.MultipleChoiceChild:
        setBackgroundColor('#016B60');
        setIcon(<DeviceHubIcon />);
        break;
      case EIntentType.OptionsBlock:
        setBackgroundColor('#016B60');
        setIcon(<MultipleChoiceIcon />);
        break;
      case EIntentType.InitialFlow:
        setIcon(<SmsIcon />);
        setBackgroundColor('#50A450');
        break;
      case EIntentType.Advanced:
        setIcon(<Dashboard />);
        setBackgroundColor('#AC005F');
        break;
      case EIntentType.QuestionsAndAnswers:
        setBackgroundColor('#2F4073');
        setIcon(<SpeakerNotesIcon />);
        break;
      case EIntentType.NewGroup:
        setBackgroundColor('#FEAE01');
        setIcon(<ReplyAllIcon />);
        break;
      case EIntentType.Welcome:
        setBackgroundColor('#59A96A');
        setIcon(<PlayCircleFilledWhiteIcon />);
        break;
      case EIntentType.Fallback:
        setBackgroundColor('#D69F28');
        setIcon(<WarningIcon />);
        break;
      case EIntentType.Cancel:
        setBackgroundColor('#CD5334');
        setIcon(<CancelIcon />);
        break;
      case EIntentType.Others:
        setBackgroundColor('#008E7F');
        if (node.data.parentId) {
          const parent = state.editorFlow.getNodeFromId(node.data.parentId);
          if (parent) {
            if (parent.data.intentType === EIntentType.MultipleChoiceParent) {
              setIcon(<DeviceHubIcon />);
            } else {
              setIcon(<RecentActorsIcon />);
            }
          }
        }
        break;
      case EIntentType.Livechat:
        setBackgroundColor('#E46B01');
        setIcon(<Dashboard />);
        break;
      case EIntentType.SaveTable:
        setBackgroundColor('#FFE78B');
        break;
      case EIntentType.SendMessage:
        setBackgroundColor('#7A81FF');
        setIcon(<SmsIcon />);
        break;
      case EIntentType.SimpleQuestion:
        setBackgroundColor('#4F8F00');
        setIcon(<InputIcon />);
        break;
      case EIntentType.SendDocument:
        setBackgroundColor('#016D00');
        setIcon(<DescriptionIcon />);
        break;
      case EIntentType.Logic:
        setBackgroundColor('#ab7845');
        setIcon(<LogicIcon />);
        break;
      case EIntentType.NLU:
        setBackgroundColor('#FF0000');
        setIcon(<NluIcon />);
        break;
      case EIntentType.OpenHTML:
        setBackgroundColor('#ffd479');
        setIcon(<OpenInNewIcon />);
        break;
      case EIntentType.Carousel:
        setBackgroundColor('#9966CC');
        setIcon(<RecentActorsIcon />);
        break;
      case EIntentType.Rules:
        setBackgroundColor('#FFAB40');
        setIcon(<RulesIcon />);
        break;
      case EIntentType.ABTest:
        setBackgroundColor('#90f');
        setIcon(<ABTestIcon />);
        break;
      case EIntentType.FalaGPT:
        setBackgroundColor('#ff914d');
        setIcon(<FalaGPTICon />);
        break;
      case EIntentType.FlexBlock:
        setBackgroundColor('#ff40ff');
        setIcon(<AppIcon />);
        break;
      case EIntentType.FlexBlockExit:
        setBackgroundColor('#ff40ff');
        setIcon(<AppIcon />);
        break;
      case EIntentType.FlexBlockEntry:
        setBackgroundColor('#ff40ff');
        setIcon(<AppIcon />);
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intentType]);

  useEffect(() => {
    load();
    loadColor();
  }, [load, loadColor]);

  return (
    <S.Content id={`header-${nodeId}`} colors={color} color={backgroundColor}>
      <S.CountContainer>{data}</S.CountContainer>
      <S.NameContainer>
        {icon}
        <div title={name}>
          <span>{name}</span>
        </div>
      </S.NameContainer>
      <FlagsContainer
        state={state}
        form={form}
        node={nodeId}
        toast={toastNotification}
      />
      <S.ConfigContainer>
        <ModalBlockOptions
          node={node}
          nodeId={nodeId}
          state={state}
          dispatch={dispatch}
          addNodeToList={addNodeToList}
          clearNodeList={clearNodeList}
          deleteNode={deleteNode}
          duplicateNode={duplicateNode}
          form={form}
        />
      </S.ConfigContainer>
    </S.Content>
  );
}

export default BlockAccessCount;
