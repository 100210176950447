import React, { useCallback, useEffect, useState } from 'react';
import { MenuItem, Select } from '@material-ui/core';
import { useForm } from 'contexts/Form/formContext';
import { MessageType } from 'models/DataFlow';
import { Container, TitleTypography } from './styles';
import useTranslator from 'utils/hooks/Translator';

export default function TypeOfMessage() {
  const { state, dispatch } = useForm();
  const { dataForm } = state;
  const [selectedOption, setSelectedOption] = useState<MessageType>(
    (dataForm?.messageType as MessageType) || 'text'
  );

  const { getTranslation } = useTranslator();

  const load = useCallback(() => {
    if (dataForm && !dataForm.messageType) {
      dataForm.messageType = 'text';

      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
  }, [dataForm, dispatch]);

  useEffect(() => {
    load();
  }, [load]);

  const handleChangeOption = (newValue: string) => {
    if (dataForm) {
      const value = newValue as MessageType;
      setSelectedOption(value);

      dataForm.messageType = value;

      dispatch({
        type: 'updateForm',
        data: { dataForm: { ...dataForm } },
      });
    }
  };

  return (
    <Container>
      <TitleTypography variant="h6" color="primary">
        {getTranslation('typeOfMessage')}
      </TitleTypography>
      <Select
        value={selectedOption}
        variant="outlined"
        onChange={(e) => handleChangeOption(String(e.target.value))}
      >
        <MenuItem value={'text'}>{getTranslation('typesOfMessage.text')}</MenuItem>
        <MenuItem value={'image'}>{getTranslation('typesOfMessage.image')}</MenuItem>
        <MenuItem value={'tts'}>{getTranslation('typesOfMessage.audio')}</MenuItem>
        <MenuItem value={'document'}>{getTranslation('typesOfMessage.document')}</MenuItem>
        <MenuItem value={'video'}>{getTranslation('typesOfMessage.video')}</MenuItem>
      </Select>
    </Container>
  );
}
