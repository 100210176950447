import './styles.css';


const Select: any = ({ ...rest }) => {

	function getSelectOptions(options: { id: string, name: string }[]) {
		return options.map((item, i) => <option key={i} value={item.id}>{item.name}</option>)
	}

	return (
		<label>
			{rest.children}
			<select className="select" {...rest} >
				{getSelectOptions(rest.options)}
			</select>
		</label>
	)
};

export default Select;