import { IAppContextActions } from 'contexts/App/types';
import { json } from 'services/RequestService';
import { IBaseResponse } from 'services/RequestService/Response';
import {
  ICreateTemplateResponse,
  IDeleteTemplateRequest,
  IGetTemplatesResponse,
  IGetOneTemplateRequest,
  IGetOneTemplateResponse,
  IUpdateTemplateResponse,
  IGetTemplatesRequest,
  ICreateTemplateRequest,
  IUpdateTemplateRequest,
  IGetTemplateOnMetaRequest,
  IGetTemplateOnMetaResponse,
} from './types';


const templateAction = 'templates';
const botsAction = 'bots';

export const GetTemplates = async (
  request: IGetTemplatesRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IGetTemplatesResponse>> => {
  const action = `${botsAction}/${request.bot_name}/${templateAction}`;
  return await json<IGetTemplatesResponse>('GET', action, dispatch);
};

export const GetOneTemplate = async (
  request: IGetOneTemplateRequest,
  dispatch: React.Dispatch<IAppContextActions>
) => {
  const action = `${botsAction}/${request.bot_name}/${templateAction}/${request._id}`;
  return await json<IBaseResponse<IGetOneTemplateResponse>>(
    'GET',
    action,
    dispatch
  );
};

export const GetTemplateStatus = async (
  request: IGetOneTemplateRequest,
  dispatch: React.Dispatch<IAppContextActions>
) => {
  const action = `${botsAction}/${request.bot_name}/${templateAction}/${request._id}/status`;
  return await json<IGetOneTemplateResponse>('GET', action, dispatch);
};

export const CreateTemplate = async (
  request: ICreateTemplateRequest,
  dispatch: React.Dispatch<IAppContextActions>
) => {
  const action = request.already_in_meta
    ? `${botsAction}/${request.bot_name}/${templateAction}?already_in_meta=true`
    : `${botsAction}/${request.bot_name}/${templateAction}`;
  return await json<IBaseResponse<ICreateTemplateResponse>>(
    'POST',
    action,
    dispatch,
    request
  );
};

export const UpdateTemplate = async (
  request: IUpdateTemplateRequest,
  dispatch: React.Dispatch<IAppContextActions>
) => {
  const action = `${botsAction}/${request.bot_name}/${templateAction}/${request._id}`;
  return await json<IBaseResponse<IUpdateTemplateResponse>>(
    'PATCH',
    action,
    dispatch,
    request
  );
};

export const DeleteTemplate = async (
  request: IDeleteTemplateRequest,
  dispatch: React.Dispatch<IAppContextActions>
) => {
  const action = `${botsAction}/${request.bot_name}/${templateAction}/${request._id}`;
  return await json<IBaseResponse<void>>('DELETE', action, dispatch);
};

export const GetTemplateOnMeta = async (
  request: IGetTemplateOnMetaRequest,
  dispatch: React.Dispatch<IAppContextActions>
): Promise<IBaseResponse<IGetTemplateOnMetaResponse>> => {
  const action = `${botsAction}/${request.bot_name}/${templateAction}/meta/${request.name}/`;
  return await json<IGetTemplateOnMetaResponse>('GET', action, dispatch);
};
